import React, { Component, createRef, useEffect, useRef, useState } from "react";
import { Row, Container } from 'react-bootstrap';
import { Switch, Route, Link, Redirect } from 'react-router-dom';
import { connect, useDispatch, useSelector } from "react-redux";
import * as ActionsWeb from "../../../../actions/indexWeb";
import "./style.css";
import * as LINK from "../../../../constants/Links";


import { formatMoney, validateNUmber, replaceThous, formatMN2, formatMN1 } from "./../../../../service";
import {
    Form,
    Input,
    Button,
    Select,
    Switch as SwitchA,
    message,
    Card, DatePicker
} from 'antd';
import {
    UserSwitchOutlined
} from '@ant-design/icons';
import moment from 'moment';

import * as Colors from "../../../../constants/Colors";
import { META } from "../../../../utils/constant";
import Validator from "../../../../utils/Validator";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
const { TextArea } = Input;

const { Option } = Select;


const defaultTime = moment().subtract(15, 'years');
const Index = ({ ...props }) => {



    const [record, setrecord] = useState({
        birthday: defaultTime,
        deleted: 0,
        hidden: 0
    });
    const dispatch = useDispatch()

    const { Web } = useSelector(state => state);
    const [meta, setMeta] = useState({ ...META });
    const [isloading, SetisLoading] = useState(false);
    const [ListProvinces, setListProvinces] = useState([]);
    const [ListDistricts, setListDistricts] = useState([]);
    const [ListWars, setListWars] = useState([]);

    const history = useHistory();
    const formRef = useRef();
    const params = useParams();
    const { id } = params;

    useEffect(() => {
        console.log(Web.StoreSupplier);

        if (Web.StoreSupplier) {
            try {
                if (Web.StoreSupplier.code === 200) {
                    if (Web.StoreSupplier.message && typeof Web.StoreSupplier.message === "string") {
                        message.success(Web.StoreSupplier.message)
                        this.setState(state => {
                            return {
                                record: {

                                    hidden: 0,
                                    deleted: 0
                                }
                            }
                        })
                    } else {

                        // message.error("Có lỗi!")
                    }

                }
                else {
                    if (Web.StoreSupplier.message && typeof Web.StoreSupplier.message === "string") {

                        message.error(Web.StoreSupplier.message)
                    } else {
                        for (let x in Web.StoreSupplier.message) {
                            let mess = Web.StoreSupplier.message[x];
                            for (let y in mess) {
                                if (mess[y]) {
                                    message.error(mess[y])
                                }

                            }
                        }
                        // message.error("Có lỗi!")
                    }

                }

            } catch (error) {
                // message.error("Có lỗi!")
                SetisLoading(false)
            }
            dispatch(ActionsWeb.ResetStoreSupplier());
            SetisLoading(false)
            // setStoreSupplier(Web.StoreSupplier);
        }
        return () => {

        };
    }, [Web.StoreCustomers]);
    const getProvinces = async () => {

        SetisLoading(true);
        const response = await ActionsWeb.ListProvincesNodispatchRequest({
            limit: meta.per_page * 10,
            page: meta.current_page, column: meta.column, sort: meta.sort
        });


        SetisLoading(false);
        if (response.code == 200) {


            if (response?.data?.data) {
                setListProvinces(response?.data?.data);
            }
        } else {

        }
    }
    const getDistrict = async (province_id) => {

        SetisLoading(true);
        const response = await ActionsWeb.ListDistrictsNoDispatchRequest({
            limit: meta.per_page * 10,
            page: meta.current_page, column: meta.column, sort: meta.sort, provinceid: parseInt(province_id)
        });

        SetisLoading(false);
        if (response.code == 200) {


            if (response?.data?.data) {
                setListDistricts(response?.data?.data);

            }
        } else {

        }
    }
    const getWars = async (province_id, district_id) => {
        console.log("responseresponse ", province_id, district_id);
        SetisLoading(true);
        const response = await ActionsWeb.ListWarsNoDispatchRequest({
            limit: meta.per_page * 10,
            page: meta.current_page, column: meta.column, sort: meta.sort, districtid: parseInt(district_id), provinceid: parseInt(province_id)
        });
        console.log("responseresponse ", response);

        SetisLoading(false);
        if (response.code == 200) {


            if (response?.data?.data) {
                setListWars(response?.data?.data);

            }
        } else {

        }
    }
    const getCustomer = async () => {
        SetisLoading(true);
        const response = await ActionsWeb.CustomerByIdNoDispatchRequest({ id });

        console.log("responseresponse ", response);
        SetisLoading(false);
        if (response.code == 200) {


            if (formRef.current) {
                if (response.data) {
                    response.data.birthday = moment(response.data.birthday, 'YYYY-MM-DD');
                    if (response.data.province_id) {
                        await getDistrict(response.data.province_id);
                        if (response.data.district_id) {
                            await getWars(response.data.province_id, response.data.district_id);
                        }
                    }
                    setrecord(response.data);
                    formRef.current.setFieldsValue(response.data)
                }

            }
        } else {

        }
    }
    useEffect(() => {
        getProvinces();
        if (parseInt(id) && parseInt(id) > 0) {

            getCustomer();

        }

        return () => {

        };
    }, [id]);

    const onFinishForm = async (values) => {

        const record_ = { ...record, ...values, deleted: 0 }
        SetisLoading(true)
        const response = await ActionsWeb.StoreCustomersNoDisPatchRequest(record_, record_.id ? record_.id : "")
        SetisLoading(false)
        if (response.code == 200) {
            if (response.message) {
              message.success(response.message);
            } else {
              message.success("Cập nhật thành công");
            }
          } else {
            if (response.message) {
              if (typeof response.message == "string") {
                message.error(response.message);
              } else {
                (Object.values(response.message) || []).map((value, index) => {
                  message.error(value);
                })
              }
      
            } else {
              message.warning("Cập nhật không thành công");
            }
          }
    }

    const handleChangeProvince = async (e) => {
        console.log(e);
        await getDistrict(e);
        if (formRef.current) {
            formRef.current.setFieldsValue({ district_id: "", war_id: "" })
        }

    }

    const handleChangeDistrict = async (e) => {
        console.log(e);

        if (formRef.current) {
            const fomrvalue = formRef.current.getFieldsValue()
            if (fomrvalue.province_id) {
                await getWars(fomrvalue.province_id, e);
            }
        }
    }
    const handleChangeWar = (e) => {
        console.log(e);

    }

    const getInfoCompany = async (e) => {
        const target = e.target;
        const value = target.value;
        const oldvalue = target.getAttribute("data-old");
        if (!value) {
            return;
        }
        if (oldvalue == value) {
            return;
        }
        target.setAttribute("data-old", value)
        SetisLoading(true);

        const response = await ActionsWeb.GetcompanyTaxcodeRequest({ taxcode: value });
        SetisLoading(false);
        if (response.code == 200) {
            if (response.message) {
                message.success(response.message);
            } else {
                message.success("lấy thông tin thành công");
            }
            if (response.data) {
                if (formRef.current) {
                    formRef.current.setFieldsValue({ address: response.data?.dc, name: response.data?.ten })
                }
            }


        } else {
            if (response.message) {
                if (typeof response.message == "string") {
                    message.error(response.message);
                } else {
                    (Object.values(response.message) || []).map((value, index) => {
                        message.error(value);
                    })
                }

            } else {
                message.warning("lấy thông tin không thành công");
            }
        }
    }
    return (

        <div className="table_w" >

            <Card title={<div style={{ display: "flex", alignItems: "center" }}><UserSwitchOutlined style={{ color: Colors.pop2[3] }} />
                <span style={{ marginLeft: "10px", color: Colors.pop2[3] }}>Thông tin Khách hàng</span>
            </div>} bordered={false}
                style={{ width: "100%", }}
                bodyStyle={{
                    padding: "10px",

                }}
                headStyle={{
                    backgroundColor: Colors.colorgrey,
                    display: "flex",
                    minHeight: "30px",
                    borderRadius: "3px"
                }}
            >
                <Form
                    labelCol={{
                        span: 4,
                    }}
                    wrapperCol={{
                        span: 16,
                    }}
                    layout="horizontal"
                    name="formadd" onFinish={onFinishForm} ref={formRef}
                >

                    <div className="row ">


                        <Form.Item label="Mã số thuế" name="taxcode" >
                            <Input placeholder="Mã số thuế" onBlur={getInfoCompany} data-old={record?.taxcode} />
                        </Form.Item>
                        <Form.Item label="Tên" name="name" rules={[
                            {
                                required: true,
                                message: "Tên khách hàng là bắt buộc"
                            },
                        ]}>
                            <Input placeholder="Tên" />
                        </Form.Item>
                        <Form.Item label="Mã KH / Ngày sinh">
                            <Input.Group compact>
                                <Form.Item name="code">
                                    <Input placeholder="Mã KH" />
                                </Form.Item>
                                <Form.Item label="Ngày sinh" name="birthday" initialValue={defaultTime} className="ms-2" >
                                    <DatePicker defaultValue={defaultTime} placeholder="Ngày sinh" />
                                </Form.Item>
                            </Input.Group>
                        </Form.Item>
                        <Form.Item label="Điện thoại / Email">
                            <Input.Group compact>
                                <Form.Item name="phone" rules={[
                                    {
                                        validator: (_, value) => {

                                            if (value) {
                                                const min = Validator.minLength(10)(value, "Số điện thoại lớn hơn 10 số");
                                                if (min) {
                                                    return Promise.reject(min);
                                                }
                                                const max = Validator.maxLength(12)(value, "Số điện thoại nhỏ hơn 12 số");
                                                if (max) {
                                                    return Promise.reject(max);
                                                }
                                                const isPhone = Validator.validatePhoneNumber(value);
                                                if (isPhone) {
                                                    return Promise.reject(isPhone);
                                                }
                                            }

                                            return Promise.resolve();

                                        }
                                    },
                                ]}>
                                    <Input placeholder="Điện thoại" />
                                </Form.Item>
                                <Form.Item label="Email" name="email" className="ms-2" >
                                    <Input placeholder="Email" />
                                </Form.Item>
                            </Input.Group>
                        </Form.Item>
                        <Form.Item label="Địa chỉ" name="address">
                            <Input placeholder="address" />
                        </Form.Item>
                        <Form.Item label="Tỉnh/Thành, Quận/Huyện">
                            <Input.Group compact>
                                <Form.Item noStyle name="province_id">
                                    <Select
                                        showSearch

                                        placeholder={"Tỉnh/Thành"}
                                        style={{ width: "33%", }}
                                        defaultActiveFirstOption={true}
                                        showArrow={false}


                                        onChange={handleChangeProvince}
                                        notFoundContent={null}
                                        filterOption={(input, option) => {

                                            return (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }}
                                        options={(ListProvinces || []).map((d) => ({
                                            value: d.id,
                                            label: d.name,
                                        }))}

                                    />
                                </Form.Item>
                                <Form.Item name="district_id" noStyle>
                                    <Select
                                        showSearch

                                        placeholder={"Quận/Huyện"}
                                        style={{ width: "33%", }}
                                        defaultActiveFirstOption={true}
                                        showArrow={false}

                                        // onSearch={this.handleSearch}
                                        onChange={handleChangeDistrict}
                                        notFoundContent={null}
                                        filterOption={(input, option) => {

                                            return (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }}
                                        options={(ListDistricts || []).map((d) => ({
                                            value: d.id,
                                            label: d.name,
                                        }))}

                                    />
                                </Form.Item>
                                <Form.Item name="war_id" noStyle>
                                    <Select
                                        showSearch

                                        placeholder={"Phường/xã"}
                                        style={{ width: "33%", }}
                                        defaultActiveFirstOption={true}
                                        showArrow={false}

                                        // onSearch={this.handleSearch}
                                        onChange={handleChangeWar}
                                        notFoundContent={null}
                                        filterOption={(input, option) => {

                                            return (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }}
                                        options={(ListWars || []).map((d) => ({
                                            value: d.id,
                                            label: d.name,
                                        }))}

                                    />
                                </Form.Item>
                            </Input.Group>
                        </Form.Item>
                        <Form.Item label="Ghi chú" name="note" >
                            <TextArea style={{ height: "100px" }} placeholder="Ghi chú" />
                        </Form.Item>

                    </div>

                    <Form.Item >
                        <div className="flex_">

                            <Button type="default" style={{ marginRight: "10px" }} danger onClick={history.goBack}>Quay lại</Button>

                            <Button type="primary" loading={isloading} style={{ marginRight: "10px" }} htmlType="submit">Cập nhật</Button>
                        </div>
                    </Form.Item>
                </Form >
            </Card >
        </div >

    );

}

export default Index;