import React, { useEffect, useRef, useState } from 'react'
import { Button, Card, Checkbox, DatePicker, Drawer, Form, Input, message, Radio, Select, Spin, Table, TreeSelect } from 'antd';
import * as LINK from "../../../../constants/Links";

import "./../style.css"
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { META } from '../../../../utils/constant';
import { useDispatch, useSelector } from 'react-redux';

import {
  FolderViewOutlined, LoadingOutlined
} from '@ant-design/icons';
import * as Colors from "../../../../constants/Colors";
import moment from 'moment';
import ProductsDetail from "./../../../../components/productsDetail/index";
import { SearchAccountantNormMaterialsRequest, SearchProductsNodisPatchRequest, StoreAccountantNormMaterialsRequest } from '../../../../actions/indexWeb';
import ItemProduct from "./../../../../components/itemProduct/index";
import Planning from './planning'
import { formatMN1, parseNumberV_ } from '../../../../service';
const Index = () => {

  const dispatch = useDispatch()
  const { Web } = useSelector(state => state);
  const params = useParams();

  const formRef = useRef(null);

  const defaultDate = moment();
  const [meta, setMeta] = useState({ ...META });
  const [visible, setVisible] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [product, setProduct] = useState(null);
  const [detail, setDetail] = useState(null);

  const submitForm = async (values) => {
    setIsloading(true);
    if (product) {
      values.product_id = product.id;
      values.product_name = product.title;
    } else {
      return;
    }
    const detail = {};
    for (const x in values) {
      const xarr = x.split("-");
      if (xarr[0] == "d") {
        let key = xarr[1];
        let row = xarr[2];
        if (!detail[row]) {
          detail[row] = {};
        }
        
        if (["price", "product_warehouse_price"].includes(key)) {
          detail[row][key] = parseNumberV_(values[x]);

        } else {
          detail[row][key] = values[x];
        }

      }

    }
    values.detail = Object.values(detail);
    // console.log("responseresponseresponse ", values);
    // return;
    const response = await StoreAccountantNormMaterialsRequest(values);
  
    setIsloading(false);
    if (response.code == 200) {
      if (response.message) {
        message.success(response.message);
      } else {
        message.success("Cập nhật thành công");
      }

    } else {
      if (response.message) {
        if (typeof response.message == "string") {
          message.error(response.message);
        } else {
          (Object.values(response.message) || []).map((value, index) => {
            message.error(value);
          })
        }

      } else {
        message.warning("Cập nhật không thành công");
      }
    }
  }
  const setOderdetailsMutilpe = (newValue) => {
    // console.log(newValue);
    setProduct(newValue);
    getListNorm({product_id:newValue.id});
    if (formRef.current) {
      formRef.current.setFieldsValue({ product_code: newValue.code })
    }
  }
  const getListNorm = async (data) => {
    const meta_ = { ...META }
    setIsloading(true)
    const response = await SearchAccountantNormMaterialsRequest({
      limit: meta_.per_page / 10,
      page: meta_.current_page, column: meta_.column, sort: meta_.sort
    }, { data: data });
   

    if (response.code == 200) {
     
      
      setDetail(response.data);
      // setListProducts(response.data);
    } else {

    }
    setIsloading(false)
  }
  const SearchProducts = async (e) => {
    const value = e.target.value;
    const oldValue = e.target.getAttribute("data-old");
    if (!value) {
      return;
    }
    if (value == oldValue) {
      return;
    }
    e.target.setAttribute("data-old", value);
    const meta_ = { ...META }
    setIsloading(true)
    const response = await SearchProductsNodisPatchRequest({
      limit: meta_.per_page / 10,
      page: meta_.current_page, column: meta_.column, sort: meta_.sort
    }, { data: { isdetail: true, code: value } });


    if (response.code == 200) {
      if (response.data) {
        if (response.data.data && response.data.data[0]) {
          setProduct(response.data.data[0]);

          if (formRef.current) {

          }
          console.log("response.data.data[0]response.data.data[0] ", response.data.data[0]);

        }
      }
    } else {

    }
    setIsloading(false)
  }
  return (
    <>
      <Drawer
        title={<div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6"><span>Chọn sản phẩm</span></div>
          <div className="col-lg-6 col-md-6 col-sm-6 flex_" style={{ justifyContent: "flex-end" }}></div>
        </div>} placement="right"
        onClose={() => { setVisible(false) }}
        visible={visible}
        size={"large"}

      >
        <ProductsDetail isListShow={false} isNewList={false} clickRow={setOderdetailsMutilpe} isgroup={true} isdetail={false} />
      </Drawer>
      <Card title={<div style={{ display: "flex", alignItems: "center" }}>
        <Link to={LINK.WEB_ACCOUNTANT}><Button size='small'><i className="fas fa-chevron-left"></i></Button></Link>
        <FolderViewOutlined style={{ color: Colors.pop2[3] }} />
        <span style={{ marginLeft: "10px", color: Colors.pop2[3] }} >Định mức NVL</span>
        {/* <span style={{ marginLeft: "10px", color: tab == 2 ? Colors.pop2[3] : "#000", cursor: "pointer" }} onClick={() => setTab(2)}>Hóa đơn</span> */}
      </div>} bordered={false}
        style={{ width: "100%", margin: "0 auto" }}
        bodyStyle={{
          padding: "10px",
          // maxHeight: "90vh",
          // overflowY: "scroll"

        }}
        headStyle={{
          backgroundColor: Colors.colorgrey,
          display: "flex",
          minHeight: "30px",
          borderRadius: "3px"
        }}
      >
        <Form name="formadd" onFinish={submitForm} ref={formRef}
          labelCol={{ span: 2 }}
          wrapperCol={{ span: 24 }}
        >
          <div className='w-100 m-auto row  p-0'>

            <div className='col-lg-12 col-md-12 col-sm-12 bg-white'>

              <Form.Item label="Mã SP"
                name="product_code"
                rules={[
                  {
                    required: true,
                    message: "Mã SP là bắt buộc"
                  },
                ]}
              >
                <Input placeholder="Mã SP" style={{ width: "100%" }} data-old="" onBlur={(e) => SearchProducts(e)}
                  addonAfter={<span style={{ cursor: "pointer" }} onClick={() => setVisible(true)}>Mở danh sách</span>} />
              </Form.Item>

            </div>
            <div className='col-lg-12 col-md-12 col-sm-12 bg-white'>

              {isloading && <div className='w-100 d-flex justify-content-center align-items-center'>  <Spin indicator={<LoadingOutlined
                style={{
                  fontSize: 24,
                }}
                spin
              />} /> </div>}

              {product && <ItemProduct item={product} placement={"right"} />}
            </div>
            <Form.Item label="Ghi chú"
              name="description"

            >
              <Input placeholder="Ghi chú" style={{ width: "100%" }} />
            </Form.Item>
            <Planning formRef={formRef} detail={detail} />
            {/* <div className='col-lg-12 col-md-12 col-sm-12 mt-2'>
              <div className=' bg-white p-2'>
                <table className='w-100'>
                  <tr className='bg_main_2 fw-bold'>

                    <td colSpan={5}>
                      Tổng
                    </td>
                    <td className='text-center'>
                      {(total_.amount)}
                    </td>
                    <td className='text-right'>
                      {formatMN1(total_.price)}
                    </td>
                  </tr>
                </table>
              </div>
            </div> */}
            <div className={`row`}>
              <div className='col-lg-12 col-md-12 col-sm-12 mt-2'>
                <div className=' bg-white p-2'>
                  <Form.Item >
                    <div className="flex_">
                      {/* <Button type="default" style={{ marginRight: "10px" }} danger onClick={() => { }}>Hủy</Button> */}

                      <Button type="primary" loading={isloading} style={{ marginRight: "10px" }} htmlType="submit"  >Cập nhật</Button>
                    </div>
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>

        </Form>
      </Card>
    </>
  )
}

export default Index
