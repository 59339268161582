import React, { Component, createRef, useEffect, useRef, useState } from "react";
import { Row, Container } from 'react-bootstrap';
import { Switch, Route, Link, Redirect } from 'react-router-dom';
import { connect, useDispatch, useSelector } from "react-redux";
import * as ActionsWeb from "../../../../actions/indexWeb";
import "./style.css";
import * as LINK from "../../../../constants/Links";


import { formatMoney, validateNUmber, replaceThous, formatMN2, formatMN1, getParamsUrl } from "./../../../../service";
import {
Form,
Input,
Button,
Select,
Switch as SwitchA,
message,
Card

} from 'antd';

import * as Colors from "../../../../constants/Colors";

import * as Services from "./../../../../service";
import { UserSwitchOutlined } from '@ant-design/icons';
import Validator from "../../../../utils/Validator";
import { META } from "../../../../utils/constant";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
const { TextArea } = Input;
const { Option } = Select;


const Index = ({ ...props }) => {

    const [record, setrecord] = useState({
        name: "",
        code: "",
        bank: "",
        bank_name: "",
        link: "",
        address: "",
        phone: "",
        deleted: 0
    });

    const [StoreSupplier, setStoreSupplier] = useState({});
    const [data, setdata] = useState([]);
    const [isloading, SetisLoading] = useState(false);
    const dispatch = useDispatch()
    const { Web } = useSelector(state => state);
    const [meta, setMeta] = useState({ ...META });
    const history = useHistory();
    const formRef = useRef();

    const params = useParams();
    const { id } = params;
    useEffect(() => {
        console.log(Web.StoreSupplier);
        
        if (Web.StoreSupplier) {
            try {
                if (Web.StoreSupplier.code === 200) {
                    if (Web.StoreSupplier.message && typeof Web.StoreSupplier.message === "string") {
                        message.success(Web.StoreSupplier.message)
                        this.setState(state => {
                            return {
                                record: {

                                    hidden: 0,
                                    deleted: 0
                                }
                            }
                        })
                    } else {

                        // message.error("Có lỗi!")
                    }

                }
                else {
                    if (Web.StoreSupplier.message && typeof Web.StoreSupplier.message === "string") {

                        message.error(Web.StoreSupplier.message)
                    } else {
                        for (let x in Web.StoreSupplier.message) {
                            let mess = Web.StoreSupplier.message[x];
                            for (let y in mess) {
                                if (mess[y]) {
                                    message.error(mess[y])
                                }

                            }
                        }
                        // message.error("Có lỗi!")
                    }

                }

            } catch (error) {
                // message.error("Có lỗi!")
                SetisLoading(false)
            }
            dispatch(ActionsWeb.ResetStoreSupplier());
            SetisLoading(false)
            // setStoreSupplier(Web.StoreSupplier);
        }
        return () => {

        };
    }, [Web.StoreSupplier]);

    useEffect(() => {

        if (Web.ListSupplier) {
            setrecord(Web.ListSupplier);
            if (formRef.current) {
                formRef.current.setFieldsValue({ ...Web.ListSupplier })
            }
        }
        return () => {

        };
    }, [Web.ListSupplier]);



    useEffect(() => {

        if (parseInt(id) && parseInt(id) > 0) {

            dispatch(ActionsWeb.SupplierByIdRequest({ id }))

        }

        return () => {

        };
    }, [id]);

    const onFinishForm = (values) => {

        const record_ = { ...record, ...values, deleted: 0 }
        SetisLoading(true)
        dispatch(ActionsWeb.StoreSupplierRequest(record_, record_.id ? record_.id : ""));

    }

    const getInfoCompany=async (e)=>{
        const target=e.target;
        const value=target.value;
        const oldvalue=target.getAttribute("data-old");
        if(!value){
            return;
        }
        if(oldvalue==value){
            return;
        }
        target.setAttribute("data-old",value)
        SetisLoading(true);
       
        const response = await ActionsWeb.GetcompanyTaxcodeRequest({taxcode:value});
        SetisLoading(false);
        if (response.code == 200) {
          if (response.message) {
            message.success(response.message);
          } else {
            message.success("lấy thông tin thành công");
          }
          if(response.data){
            if(formRef.current){
                formRef.current.setFieldsValue({address:response.data?.dc,name:response.data?.ten})
            }
          }
          
    
        } else {
          if (response.message) {
            if (typeof response.message == "string") {
              message.error(response.message);
            } else {
              (Object.values(response.message) || []).map((value, index) => {
                message.error(value);
              })
            }
    
          } else {
            message.warning("lấy thông tin không thành công");
          }
        }
    }
    return (

        <div className="table_w" >

            <Card title={<div style={{ display: "flex", alignItems: "center" }}><UserSwitchOutlined style={{ color: Colors.pop2[3] }} />
                <span style={{ marginLeft: "10px", color: Colors.pop2[3] }}>Thông tin cơ bản</span>
            </div>} bordered={false}
                style={{ width: "100%", }}
                bodyStyle={{
                    padding: "10px",
                    // maxHeight: "90vh",
                    // overflowY: "scroll"
                }}
                headStyle={{
                    backgroundColor: Colors.colorgrey,
                    display: "flex",
                    minHeight: "30px",
                    borderRadius: "3px"
                }}
            >
                <Form
                    labelCol={{
                        span: 4,
                    }}
                    wrapperCol={{
                        // span: 14,
                    }}
                    layout="horizontal"
                    name="formadd" onFinish={onFinishForm} ref={formRef}
                >

                    <div className="section_ section_img">
                     <Form.Item label="Mã số thuế" name="taxcode" >
                            <Input placeholder="Mã số thuế" onBlur={getInfoCompany} data-old={record?.taxcode}/>
                        </Form.Item>

                        <Form.Item label="Tên" name="name" rules={[
                            {
                                required: true,
                                message: "Tên nhà cung cấp là bắt buộc"
                            },
                        ]}>
                            <Input placeholder="Tên" onChange={(e) => {
                                formRef.current.setFieldsValue({ code: Services.createSlug(e.target.value) })
                            }} />
                        </Form.Item>
                        <Form.Item label="Mã NCC" name="code">
                            <Input placeholder="code" />
                        </Form.Item>
                        <Form.Item label="phone" name="phone" rules={[
                            {
                                validator: (_, value) => {

                                    if (value) {
                                        const min = Validator.minLength(10)(value, "Số điện thoại lớn hơn 10 số");
                                        if (min) {
                                            return Promise.reject(min);
                                        }
                                        const max = Validator.maxLength(12)(value, "Số điện thoại nhỏ hơn 12 số");
                                        if (max) {
                                            return Promise.reject(max);
                                        }
                                        const isPhone = Validator.validatePhoneNumber(value);
                                        if (isPhone) {
                                            return Promise.reject(isPhone);
                                        }
                                    }

                                    return Promise.resolve();

                                }
                            },
                        ]} >
                            <Input placeholder="phone" />
                        </Form.Item>
                        <Form.Item label="Email" name="email">
                            <Input placeholder="email" />
                        </Form.Item>
                        <Form.Item label="Địa chỉ" name="address">
                            <Input placeholder="address" />
                        </Form.Item>
                        <Form.Item label="Link" name="link">
                            <Input placeholder="link" />
                        </Form.Item>
                        <Form.Item label="Số TK Ngân hàng" name="bank" >
                            <Input placeholder="bank" />
                        </Form.Item>
                        <Form.Item label="Tên TK Ngân hàng" name="bank_name">
                            <Input placeholder="bank_name" />
                        </Form.Item>

                    </div>

                    <Form.Item >
                        <div className="flex_">
                            <Button type="default" style={{ marginRight: "10px" }} danger onClick={() => { history.goBack() }}>Quay lại</Button>

                            <Button type="primary" loading={isloading} style={{ marginRight: "10px" }} htmlType="submit">Cập nhật</Button>
                        </div>
                    </Form.Item>
                </Form >
            </Card>
        </div >

    )
}
export default Index