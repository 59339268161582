import React, { useEffect, useRef, useState } from 'react'
import { Button, DatePicker, Form, Input, message, Pagination, Select, Table, TreeSelect } from 'antd';
import * as LINK from "../../../constants/Links";

import "./style.css"
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { META } from '../../../utils/constant';
import { useDispatch, useSelector } from 'react-redux';
import { ListAccountantRequest, ListAccountingAccountRequest, ListEmployeesRequest, SearchAccountantPurchaseRequest, SearchCustomersNoDispatchRequest, SearchSupplierNoDispatchRequest, StoreAccountingAccountRequest } from '../../../actions/indexWeb';
import { DATA_ECOMERCE_ACCOUNTANT, DATA_GROUP_ACCOUNT, DATA_KEY_CODE_ACCOUNT, DATA_TYPE_ACCOUNTING } from '../../../constants/DefaultData';
import {
  FolderViewOutlined, AppstoreOutlined,
  DeleteOutlined, PlusCircleOutlined,
  EditOutlined, UnorderedListOutlined, SearchOutlined
} from '@ant-design/icons';
import * as Colors from "../../../constants/Colors";
import { formatNumber, getParamsUrl } from '../../../service';
import moment from 'moment';
const { RangePicker } = DatePicker;

const Index = () => {

  const dispatch = useDispatch()
  const { Web } = useSelector(state => state);
  const [meta, setMeta] = useState({ ...META });
  const [listAccount, setListAccount] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const [data, setData] = useState(null);
  const [groupTotal, setGroupTotal] = useState(null);
  const formSearchRef = useRef(null);
  const [isLoadingSearch, SetisLoadingSeach] = useState(false);
  const history = useHistory();
  const [listSuplier, setListSuplier] = useState([]);
  const [listCustomers, setListCustomers] = useState([]);
  const [listUser, setlistUser] = useState([]);
  const getSuplier = async () => {


    const response = await SearchSupplierNoDispatchRequest({
      limit: meta.per_page * 100,
      page: meta.current_page, column: "name", sort: "ASC"
    });
    // console.log(response);
    if (response.code == 200) {
      if (response.data) {

        if (response.data.data) {

          setListSuplier(response.data.data);
        }
      }
    }
  }
  const getCustomer = async () => {
   
   const response = await SearchCustomersNoDispatchRequest({
      limit: meta.per_page * 100,
      page: meta.current_page, column: "name", sort: "ASC"
    });

    // console.log(response);
    if (response.code == 200) {
      if (response.data) {

        if (response.data.data) {

          setListCustomers(response.data.data);
        }
      }
    }
  }
  const getUser = async () => {


    const response = await ListEmployeesRequest({
      limit: meta.per_page * 100,
      page: meta.current_page, column: "name", sort: "ASC"
    });
    console.log(response);
    if (response.data) {

      if (response.data.data) {

        setlistUser(response.data.data);
      }
    }
  }
  const columns = [
    {
      title: 'Số chứng từ',
      dataIndex: 'code',
      key: 'code',
      sorter: (a, b) => a.code - b.code,
      width: "20%",
      render: (value, record, index) => {
        if (value && value.startsWith("BH")) {
          return <Link to={`${LINK.WEB_ACCOUNTANT_SELL}/${value}`}>{value}</Link>;
        }
        if (value && value.startsWith("MH")) {
          return <Link to={`${LINK.WEB_ACCOUNTANT_PURCHASE_RECEIPT}/${value}`}>{value}</Link>;
        }
        if (value && value.startsWith("NVK")) {
          return <Link to={`${LINK.WEB_ACCOUNTANT_OTHER_DOCUMENT}/${value}`}>{value}</Link>;
        }
        if (value && value.startsWith("PC")) {
          if ([6, 7, 8, 9].includes(parseInt(record.type_payment))) {
            return <Link to={`${LINK.WEB_ACCOUNTANT_RECEIPT_AND_PAYMENT}/${2}/${value}`}>{value}</Link>;

          }
          if ([ 10, 11, 12, 13].includes(parseInt(record.type))) {
            return <Link to={`${LINK.WEB_ACCOUNTANT_RECEIPT_AND_PAYMENT}/${2}/${value}`}>{value}</Link>;

          }
          if ([1, 2, 3, 4, 26].includes(parseInt(record.type_payment))) {
            return <Link to={`${LINK.WEB_ACCOUNTANT_PURCHASE_RECEIPT}/${value}`}>{value}</Link>;

          }
        }
        if (value && value.startsWith("PT")) {
          return <Link to={`${LINK.WEB_ACCOUNTANT_RECEIPT_AND_PAYMENT}/${1}/${value}`}>{value}</Link>;
        }
        if (value && (value.startsWith("NTTK"))) {
          return <Link to={`${LINK.WEB_ACCOUNTANT_RECEIPT_AND_PAYMENT_BANK}/${1}/${value}`}>{value}</Link>;
        }
        if (value && (value.startsWith("UNC") || value.startsWith("STM") || value.startsWith("SCK"))) {
          return <Link to={`${LINK.WEB_ACCOUNTANT_RECEIPT_AND_PAYMENT_BANK}/${2}/${value}`}>{value}</Link>;
        }

        if (value && (value.startsWith("NK"))) {
          if ([6, 7, 8, 9, 10, 11, 12, 13].includes(parseInt(record.type_payment))) {
            return <Link to={`${LINK.WEB_ACCOUNTANT_RECEIPT_AND_PAYMENT}/${2}/${record.document_code}`}>{value}</Link>;

          }
          if ([1, 2, 3, 4, 26].includes(parseInt(record.type_payment))) {
            return <Link to={`${LINK.WEB_ACCOUNTANT_PURCHASE_RECEIPT}/${record.document_code}`}>{value}</Link>;

          }
          return <Link to={`${LINK.WEB_ACCOUNTANT_STOCK}/${1}/${value}`}>{value}</Link>;
        }
        if (value && (value.startsWith("XK"))) {
          if ([6, 7, 8, 9, 10, 11, 12, 13].includes(parseInt(record.type_payment))) {
            return <Link to={`${LINK.WEB_ACCOUNTANT_RECEIPT_AND_PAYMENT}/${2}/${record.document_code}`}>{value}</Link>;

          }
          if ([1, 2, 3, 4, 26].includes(parseInt(record.type_payment))) {
            return <Link to={`${LINK.WEB_ACCOUNTANT_PURCHASE_RECEIPT}/${record.document_code}`}>{value}</Link>;

          }
          return <Link to={`${LINK.WEB_ACCOUNTANT_STOCK}/${2}/${value}`}>{value}</Link>;
        }
        return value;
      }
    },
    {
      title: 'Mô tả',
      dataIndex: 'note',
      key: 'note',
      sorter: (a, b) => a.note - b.note,
    },
    // {
    //   title: 'Loại',
    //   dataIndex: 'type',
    //   key: 'type',
    //   sorter: (a, b) => a.type - b.type,

    // },
    {
      title: 'Số tiền',
      dataIndex: 'total_price',
      key: 'total_price',
      sorter: (a, b) => a.total_price - b.total_price,
      render: (text, record, index) => {
        return formatNumber(text)
      }
    },
    {
      title: 'Vat',
      dataIndex: 'total_vat',
      key: 'total_vat',
      sorter: (a, b) => a.vat_price - b.vat_price,
      render: (text, record, index) => {
        return formatNumber(text)
      }
    },
    {
      title: 'Ngày chứng từ',
      dataIndex: 'document_date',
      key: 'document_date',
      sorter: (a, b) => a.document_date - b.document_date,
      render: (text, record, index) => {
        return moment(text).format('DD/MM/YYYY');;
      }
    },
  ];

  const getListDocument = async () => {

    const urlParams = getParamsUrl();
    const filteredvalues = Object.fromEntries(
      Object.entries(urlParams).filter(([_, value]) => { return value !== undefined && value !== "" })
    );
    // created_at
    if (filteredvalues.fromdate) {
      filteredvalues.created_at = [];
      filteredvalues.created_at[0] = moment.utc(filteredvalues.fromdate, "YYYY-MM-DD");
      filteredvalues.created_at[1] = moment.utc(filteredvalues.todate, "YYYY-MM-DD");
    }
    let quarterMoment = null;
    if (filteredvalues.precious) {

      const quarter = filteredvalues.precious;
      const year = filteredvalues.year ? filteredvalues.year : moment().year();
      quarterMoment = moment().year(year).quarter(quarter);

    } else {
      // Lấy tháng hiện tại
      const currentMonth = moment().month() + 1; // moment().month() trả về giá trị từ 0-11, cần +1 để từ 1-12
      let quarter = 1;

      const year = moment().year();
      // Tính toán quý hiện tại
      if (currentMonth <= 3) {
        quarter = 1; // Quý 1
      } else if (currentMonth <= 6) {
        quarter = 2;
      } else if (currentMonth <= 9) {
        quarter = 3;
      } else {
        quarter = 4;
      }
      filteredvalues.precious = quarter;
      filteredvalues.year = year;
      quarterMoment = moment().year(year).quarter(quarter);


    }

    if (filteredvalues.account) {
      filteredvalues.account = filteredvalues.account.split(",").map((value) => {
        return parseInt(value)
      })
    }

    console.log("filteredvaluesfilteredvalues", filteredvalues);

    const searchParams = new URLSearchParams(filteredvalues).toString();

    history.push(`${LINK.WEB_ACCOUNTANT_LIST_DOCUMENT}?${searchParams}`);

    const response = await SearchAccountantPurchaseRequest({
      limit: meta.per_page ,
      page: meta.current_page, column: "accountant_purchase.document_date", sort: "ASC"
    }, { data: filteredvalues });
    if (response.code == 200) {
      if (response.data) {
        console.log(response.data.data);
        if (response.data.data) {

          setData([...response.data.data]);
          setMeta({...meta,current_page:response.data.meta.current_page,per_page:response.data.meta.perPage,total:response.data.meta.total});
          setGroupTotal(response.group)
        } 
      }
    }

    if (quarterMoment) {
      filteredvalues.precious = quarterMoment;
    }
    if (filteredvalues.ecomerce_shop) {
      filteredvalues.ecomerce_shop = parseInt(filteredvalues.ecomerce_shop)
    }
    if (formSearchRef.current) {
      formSearchRef.current.setFieldsValue(filteredvalues)

    }
  }


  useEffect(() => {
    // getListAccount();
    getCustomer();
    getUser();
    getSuplier();
    getListDocument();
    return () => {

    };
  }, []);
  const onSearch = async (values) => {
    const filteredvalues = Object.fromEntries(
      Object.entries(values).filter(([_, value]) => { return value !== undefined && value !== "" })
    );

    if (filteredvalues.created_at) {
      filteredvalues.fromdate = filteredvalues.created_at[0].format("YYYY-MM-DD")
      filteredvalues.todate = filteredvalues.created_at[1].format("YYYY-MM-DD")
    }
    if (filteredvalues.precious) {
      const quarter = moment(filteredvalues.precious).quarter();
      const year = moment(filteredvalues.precious).year();
      filteredvalues.precious = quarter;
      filteredvalues.year = year;

    }
    if (!values.precious && !values.created_at) {
      message.warning("Vui lòng chọn khoảng thời gian!");
      return;
    }
    SetisLoadingSeach(true);
    const response = await SearchAccountantPurchaseRequest({
      limit: meta.per_page,
      page: meta.current_page, column: "accountant_purchase.document_date", sort: "ASC"
    }, { data: { ...filteredvalues } });

    SetisLoadingSeach(false);

    if (response.code == 200) {
      if (response.data) {
        console.log(response.data.data);
        if (response.data.data) {
          setMeta({...meta,current_page:response.data.meta.current_page,per_page:response.data.meta.perPage,total:response.data.meta.total});
          setData([...response.data.data]);
          setGroupTotal(response.group)
        }
      }
    }
    delete filteredvalues["created_at"]
    const searchParams = new URLSearchParams(filteredvalues).toString();

    history.push(`${LINK.WEB_ACCOUNTANT_LIST_DOCUMENT}?${searchParams}`);


  }
  const resetFormSearch = () => {
    if (formSearchRef.current) {
      formSearchRef.current.resetFields();
      formSearchRef.current.submit();
    }
  }

  const OnPagination =async (page, pageSize) => {
    const values=formSearchRef.current.getFieldsValue();
    const filteredvalues = Object.fromEntries(
      Object.entries(values).filter(([_, value]) => { return value !== undefined && value !== "" })
    );

    if (filteredvalues.created_at) {
      filteredvalues.fromdate = filteredvalues.created_at[0].format("YYYY-MM-DD")
      filteredvalues.todate = filteredvalues.created_at[1].format("YYYY-MM-DD")
    }
    if (filteredvalues.precious) {
      const quarter = moment(filteredvalues.precious).quarter();
      const year = moment(filteredvalues.precious).year();
      filteredvalues.precious = quarter;
      filteredvalues.year = year;

    }
    if (!values.precious && !values.created_at) {
      message.warning("Vui lòng chọn khoảng thời gian!");
      return;
    }
    SetisLoadingSeach(true);
    const response = await SearchAccountantPurchaseRequest({
      limit: pageSize,
      page: page, column: "accountant_purchase.document_date", sort: "ASC"
    }, { data: { ...filteredvalues } });

    SetisLoadingSeach(false);

    if (response.code == 200) {
      if (response.data) {
        console.log(response.data.data);
        if (response.data.data) {
          setMeta({...meta,current_page:response.data.meta.current_page,per_page:response.data.meta.perPage,total:response.data.meta.total});
          setData([...response.data.data]);
          setGroupTotal(response.group)
        }
      }
    }
    delete filteredvalues["created_at"]
    const searchParams = new URLSearchParams(filteredvalues).toString();

    history.push(`${LINK.WEB_ACCOUNTANT_LIST_DOCUMENT}?${searchParams}`);
  }
  return (
    <div className='w-100 row '>
      <div className='col-lg-12 col-md-12 col-sm-12'>
        <Form name="formsearch" ref={formSearchRef} onFinish={onSearch} style={{
          padding: "0px",
          width: "100%",
          display: "flex"
        }}>

          <Form.Item name="created_at" noStyle >
            <RangePicker style={{ width: "100%", }} />
          </Form.Item>
          <Form.Item name="type_code" noStyle >
            <Select
              multiple
              mode="multiple"
              style={{ width: "100%" }}
              maxTagCount='responsive'
              placeholder="Loại phiếu"
              options={DATA_KEY_CODE_ACCOUNT}
            />
          </Form.Item>
          <Form.Item name="code" noStyle>

            <Input placeholder="Chứng từ" />
          </Form.Item>
          <Form.Item name="precious" noStyle >
            <DatePicker picker="quarter" style={{ width: "100%" }} placeholder='Chọn quý' />
            {/* <Select
              multiple
              mode="multiple"
              style={{ width: "100%" }}
              maxTagCount='responsive'
              placeholder="Quý "
              options={DATA_PRECIOUS}
            /> */}
          </Form.Item>
          {/* <Form.Item name="year" noStyle >
            <DatePicker picker="year" style={{ width: "100%" }}  placeholder='Chọn năm' />
          </Form.Item> */}
          <Form.Item name="account" noStyle >
            {/* <TreeSelect
              multiple
              mode="multiple"
              style={{ width: "100%" }}
              maxTagCount='responsive'
              placeholder="Tài khoản"
              treeData={[...listAccount]}
              filterTreeNode={filterTreeNode}
            /> */}
          </Form.Item>
          <Form.Item name="description" noStyle>

            <Input placeholder="Diễn giải/ số phiếu" />
          </Form.Item>
          <Form.Item name="money" noStyle>

            <Input placeholder="số tiền" />
          </Form.Item>

          <Form.Item name="ecomerce_shop" noStyle >
            <Select
              style={{ width: "100%" }}
              maxTagCount='responsive'
              placeholder="Shop"
              options={DATA_ECOMERCE_ACCOUNTANT}
            />
          </Form.Item>

          <Form.Item name="suplier_id" noStyle >
             <Select
                          showSearch
                          style={{ width: '100%' }}
                          options={(listSuplier || []).map((value, index) => {
                            return {
                              value: value.code,
                              label: value.name
                            }
                          })}

                          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                          placeholder="Nhà cung cấp"
                          allowClear
                          // multiple
                          treeDefaultExpandAll
                          // onChange={changeSuplier}
                          filterOption={(input, option) =>
                            option?.label?.toLowerCase().includes(input.toLowerCase())
                          }
                        />
          </Form.Item>
          <Form.Item name="customer_id" noStyle >
             <Select
                          showSearch
                          style={{ width: '100%' }}
                          options={(listCustomers || []).map((value, index) => {
                            return {
                              value: value.code,
                              label: value.name
                            }
                          })}

                          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                          placeholder="Khách hàng"
                          allowClear
                          // multiple
                          treeDefaultExpandAll
                          // onChange={changeSuplier}
                          filterOption={(input, option) =>
                            option?.label?.toLowerCase().includes(input.toLowerCase())
                          }
                        />
          </Form.Item>
          <Form.Item name="employee_id_object" noStyle >
             <Select
                          showSearch
                          style={{ width: '100%' }}
                          options={(listUser || []).map((value, index) => {
                            return {
                              value: value.code,
                              label: value.name
                            }
                          })}

                          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                          placeholder="Nhân viên"
                          allowClear
                          // multiple
                          treeDefaultExpandAll
                          // onChange={changeSuplier}
                          filterOption={(input, option) =>
                            option?.label?.toLowerCase().includes(input.toLowerCase())
                          }
                        />
          </Form.Item>
          <Form.Item noStyle style={{
            width: "20%"
          }}>
            <Button type="primary" htmlType="submit" style={{ width: "20%" }} icon={<SearchOutlined />} loading={isLoadingSearch} />
            <Button className='bg-warning' htmlType="button" style={{ width: "20%" }} loading={isLoadingSearch} onClick={resetFormSearch}>reset</Button>
          </Form.Item>

        </Form>
      </div>
      <div className='col-lg-12 col-md-12 col-sm-12 w-100'>
        <Table columns={columns} dataSource={data} pagination={false} scroll={{
          y: 700,
        }}>
        </Table>

      </div>
      <div className={`row `}>

        <div className='col-lg-7 col-md-7 col-sm-7 bg-white'>
          <div className={`row`}>
          <div className='col-lg-4 col-md-4 col-sm-4 fw-bold'>
              Số dòng: {groupTotal?.total_amount}
            </div>
            <div className='col-lg-4 col-md-4 col-sm-4 fw-bold'>
              Tổng tiền hàng: {formatNumber(groupTotal?.total_prices, 2)}
            </div>
            <div className='col-lg-4 col-md-4 col-sm-4 fw-bold'>
              Tổng tiền Thuế: {formatNumber(groupTotal?.total_vats, 2)}
            </div>

          </div>
        </div>
        <div className='col-lg-5 col-md-5  col-sm-5 bg-white'>
          <div className="d-flex justify-content-end" style={{}}>
            <Pagination showTotal={(total, range) => `${range[0]}-${range[1]} của ${total} dòng`} pageSize={meta && meta.per_page ? meta.per_page : 10} total={meta && meta.total ? meta.total : 10} showSizeChanger onChange={OnPagination} />
          </div>
        </div>
        {/* <div className='col-lg-6 col-md-6 col-sm-6'>
  <div className={`row`}>
    <div className='col-lg-6 col-md-6 col-sm-6'>
      Tiền thuế: {formatNumber(total_.totalVat, 0)}
    </div>
    <div className='col-lg-6 col-md-6 col-sm-6'>
      Tiền tiền thanh toán: {formatNumber(total_.total, 0)}
    </div>
  </div>
</div> */}
      </div>
    </div>
  )
}

export default Index
