import React, { useEffect, useRef, useState } from 'react'
import { Button, Card, Checkbox, DatePicker, Form, Input, message, Radio, Select, Table, TreeSelect } from 'antd';
import * as LINK from "../../../../constants/Links";

import "./../style.css"
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { META } from '../../../../utils/constant';
import { useDispatch, useSelector } from 'react-redux';
import { ListAccountantBankRequest, ListAccountantInvoiceTemplateRequest, ListAccountingAccountRequest, ListDefaultAccountRequest, ListEmployeesRequest, ListUsersNodispatchRequest, SearchCustomersNoDispatchRequest, SearchSupplierNoDispatchRequest, ShowAccountantPurchaseRequest, StoreAccountantPurchaseRequest, StoreAccountingAccountRequest, UnLockAccountantRequest } from '../../../../actions/indexWeb';
import { DATA_GROUP_ACCOUNT, DATA_REASON_PAYMENT, DATA_REASON_RECEIPT, DATA_STOCK_EXPORT, DATA_STOCK_IMPORT, DATA_TYPE_ACCOUNTING, DATA_TYPE_PURCHASE_RECEIPT, DATA_TYPE_PURCHASE_RECEIPT_ADD_BILL, DATA_TYPE_PURCHASE_RECEIPT_PAYMENT, DATA_TYPE_PURCHASE_RECEIPT_PAYMENT_ACCOUNT, DATA_TYPE_SELL, DATA_TYPE_SELL_ACCOUNT } from '../../../../constants/DefaultData';
import {
  FolderViewOutlined, AppstoreOutlined,
  DeleteOutlined, PlusCircleOutlined,
  EditOutlined, UnorderedListOutlined, SearchOutlined
} from '@ant-design/icons';
import * as Colors from "../../../../constants/Colors";
import Planning from "./planning";
import moment from 'moment';
import { calculateDiscount, calculateTotal, calculateVat, createSlug, formatMN1, formatNumber, parseNumberV_ } from '../../../../service';

const Index = () => {

  const dispatch = useDispatch()
  const { Web } = useSelector(state => state);
  const params = useParams();
  const { code } = params;
  // console.log(typeV);

  const [meta, setMeta] = useState({ ...META });

  const [listDefaultAccount, setListDefaultAccount] = useState([]);
  const [listDefaultAccountFil, setListDefaultAccountFill] = useState(null);
  const [isloading, setIsloading] = useState(false);
  const [tab, setTab] = useState(1);
  const [typeObject, setTypeObject] = useState(1);
  const [record, setRecord] = useState(null);
  const [isloadingUnlock, setIsloadingUnlock] = useState(false);
  const [listUser, setlistUser] = useState([]);
  const [listSuplier, setListSuplier] = useState([]);
  const [listInvoiceTem, setListInvoiceTem] = useState([]);
  const [isBilll, setisBilll] = useState(1);

  const [total_, setTotal_] = useState({
    totalPrice: 0,
    totalVat: 0,
    totalDiscount: 0,
    total: 0,

  });

  const [AccountPayment, setAccountPayment] = useState(1);

  const formRef = useRef(null);
  const dataTab1 = [{ value: 1, label: "Chứng từ ghi nợ" }, { value: 2, label: "Phiếu xuất" }, { value: 3, label: "Hóa đơn" }];
  const dataTab2 = [{ value: 1, label: "Phiếu thu" }, { value: 2, label: "Phiếu xuất" }, { value: 3, label: "Hóa đơn" }];

  const defaultDate = moment().startOf('day');
  const [dataDetail, setdataDetail] = useState(null);

  const getListDefaultAccount = async () => {
    const response = await ListDefaultAccountRequest({
      limit: meta.per_page * 100,
      page: meta.current_page, column: "code", sort: "ASC"
    });
    console.log("responseresponse ", response);

    if (response.code == 200) {
      if (response.data) {

        setListDefaultAccount(response.data);

      }
    }


  }
  useEffect(() => {
    filterAccount();

    return () => {

    };
  }, [listDefaultAccount]);
  const removePrefixThu = (str, text) => {
    if (str.startsWith(text)) {
      return str.slice(3).trim();
    }
    return str;
  };
  const filterAccount = () => {

    if (listDefaultAccount.length > 0) {
      let filKey = "chung-tu-nghiep-vu-khac";



      const fildata = listDefaultAccount.filter((value, index) => {
        return value.code == filKey;
      })
      setListDefaultAccountFill(fildata[0]);

    }
    return;
  }

  const changeSuplier = (e) => {

    const suplier = listSuplier.filter((value, index) => {
      return value.code == e;
    })
    if (suplier[0]) {
      if (formRef.current) {
        formRef.current.setFieldsValue({ suplier_name: suplier[0].name, suplier_address: suplier[0].address, suplier_taxcode: suplier[0].taxcode })
        // const formvalue= formRef.current.getFieldsValue();
        // if (suplier[0].name) {
        //   formRef.current.setFieldsValue({ note: "Chi tiền mua hàng " + suplier[0].name })
        // }
      }
    }


  }
  useEffect(() => {
    if (record?.id) {
      console.log("recordrecordrecord ", record);

      if (formRef.current) {
        // if(record.invoice==1){
        //   setisBilll(1)
        // }
        // if(record.stock_code){
        //   setisExport(1)
        // }else{
        //   setisExport(0)
        // }
        record.percent_vat = parseInt(record.percent_vat);

        record.document_date = moment(record.document_date);
        record.accounting_date = moment(record.accounting_date);
        record.invoice_date = moment(record.invoice_date);
        record.suplier_id = record.suplier_id;
        record.suplier_address = record.address;
        record.suplier_name = record.name;
        record.suplier_taxcode = record.taxcode;
        record.price = formatNumber(record.price)
        record.price_of_period = formatNumber(record.price_of_period)
        formRef.current.setFieldsValue({ ...record })
        // if(record.employee_id){
        //   const employee=listUser.filter((value, index) => {
        //     return value.code==record.employee_id;
        //   })
        //   if(employee[0]){
        //       formRef.current.setFieldsValue({employee_name:employee[0].name,employee_position:employee[0].position_name})

        //   }
        // }
        // setIsProduct(parseInt(record?.isProduct))
        setdataDetail(record.children)
      }

    } else {
      setdataDetail(null)
    }
    return () => {

    };
  }, [record]);
  const getRecord = async () => {

    if (code) {
      const response = await ShowAccountantPurchaseRequest({ code: code });
      // console.log(response);
      if (response.data) {

        setRecord(response.data);
      }
    }

  }
  const getListInvoiceTemplate = async () => {
    const response = await ListAccountantInvoiceTemplateRequest({
      limit: meta.per_page * 100,
      page: meta.current_page, column: "code", sort: "ASC"
    });
    if (response.code == 200) {
      if (response.data) {

        if (response.data.data) {

          setListInvoiceTem(response.data.data);
        }
      }
    }


  }
  // console.log(defaultDate.format('YYYY-MM-DD'));
  const getSuplier = async () => {


    const response = await SearchSupplierNoDispatchRequest({
      limit: meta.per_page * 100,
      page: meta.current_page, column: "name", sort: "ASC"
    });
    // console.log(response);
    if (response.code == 200) {
      if (response.data) {

        if (response.data.data) {

          setListSuplier(response.data.data);
        }
      }
    }
  }
  const getUser = async () => {


    const response = await ListEmployeesRequest({
      limit: meta.per_page * 100,
      page: meta.current_page, column: "name", sort: "ASC"
    });
    console.log(response);
    if (response.data) {

      if (response.data.data) {

        setlistUser(response.data.data);
      }
    }
  }
  useEffect(() => {
    getSuplier();
    getUser();
    getListInvoiceTemplate();
    getListDefaultAccount();
    getRecord();
    return () => {

    };
  }, []);

  const submitForm = async (values) => {
    setIsloading(true);
    if (record) {
      values.id = record.id;
      values.code = record.code;

    }
    values.type = 21;
    const detail = {};
    for (const x in values) {
      const xarr = x.split("-");
      if (xarr[0] == "d") {
        let key = xarr[1];
        let row = xarr[2];
        if (!detail[row]) {
          detail[row] = {};
        }
        const price = parseNumberV_(values["d-price-" + row]) ?? 0;

        const amount = parseNumberV_(values["d-amount-" + row]) ?? 0;
        const percent_discount = parseNumberV_(values["d-percent_discount-" + row]) ?? 0;
        const percent_vat = parseNumberV_(values["d-percent_vat-" + row]) ?? 0;
        if (key == "total_price") {
          const total_price = calculateTotal(amount, price, true);
          values[x] = total_price;

        }

        if (key == "total_discount") {
          const total_discount = calculateDiscount(amount, price, percent_discount, true, 0);
          values[x] = total_discount;
        }
        if (key == "vat_price") {
          const vat_price = calculateVat(amount, price, percent_discount, percent_vat, true, 0);
          values[x] = vat_price;

        }
        if (["price", "total", "percent_discount", "amount"].includes(key)) {
          detail[row][key] = parseNumberV_(values[x]);
        } else {
          detail[row][key] = values[x];
        }

      }

    }
    values.document_date = moment(values.document_date).format('YYYY-MM-DD');
    values.accounting_date = moment(values.accounting_date).format('YYYY-MM-DD');
    values.invoice_date = moment(values.invoice_date).format('YYYY-MM-DD');
    values.detail = Object.values(detail);

    values = { ...values, ...total_ };
    const response = await StoreAccountantPurchaseRequest(values);
    setIsloading(false);
    if (response.code == 200) {
      if (response.message) {
        message.success(response.message);
      } else {
        message.success("Cập nhật thành công");
      }
      // setRecord(null);
    } else {
      if (response.message) {
        if (typeof response.message == "string") {
          message.error(response.message);
        } else {
          (Object.values(response.message) || []).map((value, index) => {
            message.error(value);
          })
        }

      } else {
        message.warning("Cập nhật không thành công");
      }
    }
  }
  const unlockAccountant = async () => {
    if (record.code) {
      setIsloadingUnlock(true);
      const response = await UnLockAccountantRequest({ code: record.code });
      setIsloadingUnlock(false);
      if (response.code == 200) {
        if (response.message) {
          message.success(response.message);
        } else {
          message.success("Cập nhật thành công");
        }
        // setData([...data, { date: '', code: '', description: '', tkno: '', tkco: '', money: '' }]);
        // getListAccountant();

      } else {
        if (response.message) {
          if (typeof response.message == "string") {
            message.error(response.message);
          } else {
            (Object.values(response.message) || []).map((value, index) => {
              message.error(value);
            })
          }

        } else {
          message.warning("Cập nhật không thành công");
        }
      }
    }
  }

  return (
    <Card title={<div className='d-flex align-items-center justify-content-between'>
      <div className='d-flex align-items-center'>
        <Link to={LINK.WEB_ACCOUNTANT}><Button size='small'><i className="fas fa-chevron-left"></i></Button></Link>
        <FolderViewOutlined style={{ color: Colors.pop2[3] }} />
        <span style={{ marginLeft: "10px", color: Colors.pop2[3] }} >Chứng từ NVK</span>
      </div>
      <div className='d-flex align-items-center'>
        {record?.is_close == 1 && record?.is_close_lv2 == 0 && <Button className='bg-danger text-white' size='small' onClick={() => { unlockAccountant() }} loading={isloadingUnlock}>Mở khóa</Button>}
      </div>
    </div>} bordered={false}
      style={{ width: "100%", margin: "0 auto" }}
      bodyStyle={{
        padding: "10px",
        // maxHeight: "90vh",
        // overflowY: "scroll"

      }}
      headStyle={{
        backgroundColor: Colors.colorgrey,
        display: "flex",
        minHeight: "30px",
        borderRadius: "3px"
      }}
    >
      <div className='w-100 m-auto row  p-0'>
        <div className='col-lg-12 col-md-12 col-sm-12 p-0 '>
          <Form name="formadd" onFinish={submitForm} ref={formRef}
            labelCol={{ span: 2 }}
            wrapperCol={{ span: 24 }}
          >
            <div className={`row`}>
              <div className='col-lg-12 col-md-12 col-sm-12 '>
                <div className=' bg-white p-2'>
                  <Form.Item className='m-0'>
                    <Radio.Group
                      options={[{ value: 1, label: "Chứng từ" }, { value: 3, label: "Hóa đơn" }]}
                      onChange={(e) => {
                        setTab(e.target.value)
                      }}
                      value={tab}
                      optionType="button"
                      buttonStyle="solid"
                      checked
                    />

                  </Form.Item>
                </div>
              </div>
            </div>
            <div className={`row ${parseInt(tab) == 1 ? "" : "d-none"}`}>
              <div className='col-lg-8 col-md-8 col-sm-8 '>
                <div className=' bg-white p-2'>

                  <Form.Item label="Diễn giải" name="note" className='m-0'>

                    <Input />
                  </Form.Item>

                </div>
              </div>
              <div className='col-lg-4 col-md-4 col-sm-4 '>
                <div className=' bg-white p-2'>
                  <Form.Item label="Ngày hoạch toán"
                    name="accounting_date"
                    rules={[
                      {
                        required: true,
                        message: "Ngày hoạch toán là bắt buộc"
                      },
                    ]}
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 14 }}
                    initialValue={defaultDate} className='m-0'
                  >

                    <DatePicker placeholder='ngày hoạch toán' style={{ width: "100%" }} />
                  </Form.Item>
                  <Form.Item label="Ngày chứng từ"
                    name="document_date"
                    rules={[
                      {
                        required: true,
                        message: "Ngày chứng từ là bắt buộc"
                      },
                    ]}
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 14 }} className='m-0'
                    initialValue={defaultDate}

                  >

                    <DatePicker placeholder='ngày chứng từ' style={{ width: "100%" }} />
                  </Form.Item>

                  <Form.Item label="Số chứng từ"
                    name="documnent_number"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Số chứng từ là bắt buộc"
                    //   },
                    // ]}
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 14 }} className='m-0'
                  >

                    <Input placeholder="Số chứng từ" style={{ width: "100%" }} disabled />
                  </Form.Item>
                </div>
              </div>



            </div>

            <div className={`row ${parseInt(tab) == 3 ? "" : "d-none"}`}>

              <div className='col-lg-8 col-md-8 col-sm-8 '>
                <div className=' bg-white p-2'>
                  <Form.Item label="Nhà cung cấp" className='m-0'>
                    <Input.Group compact>
                      <Form.Item name={"suplier_id"}

                        // rules={[
                        //   {
                        //     required: true,
                        //     message: "Nhà cung cấp là bắt buộc"
                        //   },
                        // ]}
                        style={{ width: "40%" }} className='m-0'
                      >
                        <Select
                          showSearch
                          style={{ width: '100%' }}
                          options={(listSuplier || []).map((value, index) => {
                            return {
                              value: value.code,
                              label: value.name
                            }
                          })}

                          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                          placeholder="Nhà cung cấp"
                          allowClear
                          // multiple
                          treeDefaultExpandAll
                          onChange={changeSuplier}
                          filterOption={(input, option) =>
                            option?.label?.toLowerCase().includes(input.toLowerCase())
                          }
                        />

                      </Form.Item>
                      <Form.Item name="suplier_name" style={{ width: "60%" }} className='m-0'>

                        <Input />
                      </Form.Item>

                      <Form.Item
                        name="suplier_taxcode"
                        style={{ width: "40%" }}
                        className='m-0'
                      >
                        <Input />

                      </Form.Item>
                      <Form.Item name="suplier_address" style={{ width: "60%" }} className='m-0'>

                        <Input />
                      </Form.Item>
                    </Input.Group>
                  </Form.Item>


                </div>
              </div>
              <div className='col-lg-4 col-md-4 col-sm-4 '>
                <div className=' bg-white p-2'>
                  <Form.Item label="Mẫu hóa đơn" name={"invoice_template"}

                    // rules={[
                    //   ({ getFieldValue }) => ({
                    //     validator(_, value) {
                    //       if (isBilll == 1 && !value) {
                    //         return Promise.reject(new Error("Mẫu hóa đơn là bắt buộc"));
                    //       }
                    //       return Promise.resolve();
                    //     },
                    //   }),
                    // ]}
                    style={{ width: "100%" }}

                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 14 }} className='m-0'
                  >
                    <Select
                      showSearch
                      style={{ width: '100%' }}
                      options={(listInvoiceTem || []).map((value, index) => {
                        return {
                          value: value.id,
                          label: value.code + "-" + value.name
                        }
                      })}

                      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                      placeholder="Mẫu hóa đơn"
                      // allowClear
                      // multiple
                      treeDefaultExpandAll
                      onChange={changeSuplier}

                    />

                  </Form.Item>

                  <Form.Item label="ký hiệu hóa đơn" name="invoice_symbol" style={{ width: "100%" }}
                    // rules={[
                    //   ({ getFieldValue }) => ({
                    //     validator(_, value) {
                    //       if (isBilll == 1 && !value) {
                    //         return Promise.reject(new Error("ký hiệu hóa đơn là bắt buộc"));
                    //       }
                    //       return Promise.resolve();
                    //     },
                    //   }),
                    // ]}
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 14 }}
                    className='m-0'
                  >

                    <Input placeholder='ký hiệu hóa đơn' />
                  </Form.Item>
                  <Form.Item label="Số hóa đơn" name="invoice_number" style={{ width: "100%" }}

                    // rules={[
                    //   ({ getFieldValue }) => ({
                    //     validator(_, value) {
                    //       if (isBilll == 1 && !value) {
                    //         return Promise.reject(new Error("Số hóa đơn là bắt buộc"));
                    //       }
                    //       return Promise.resolve();
                    //     },
                    //   }),
                    // ]}
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 14 }}
                    className='m-0'
                  >

                    <Input placeholder='số hóa đơn' />
                  </Form.Item>
                  <Form.Item label="Ngày hóa đơn"
                    name="invoice_date"
                    // rules={[
                    //   ({ getFieldValue }) => ({
                    //     validator(_, value) {
                    //       if (isBilll == 1 && !value) {
                    //         return Promise.reject(new Error("Ngày hóa đơn là bắt buộc"));
                    //       }
                    //       return Promise.resolve();
                    //     },
                    //   }),
                    // ]}
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 14 }}
                    className='m-0'
                  >

                    <DatePicker placeholder='ngày hóa đơn' style={{ width: "100%" }} />
                  </Form.Item>

                </div>
              </div>



            </div>


            <Planning formRef={formRef} listDefaultAccountFil={listDefaultAccountFil} setTotal={setTotal_} dataDetail={dataDetail} />
            <div className={`row`}>
              <div className='col-lg-6 col-md-6 col-sm-6'>
                <div className={`row`}>
                  <div className='col-lg-6 col-md-6 col-sm-6'>
                    Tổng tiền hàng: {formatNumber(total_.totalPrice)}
                  </div>
                  <div className='col-lg-6 col-md-6 col-sm-6'>
                    Tổng tiền chiết khấu: {formatNumber(total_.totalDiscount)}
                  </div>
                </div>
              </div>
              <div className='col-lg-6 col-md-6 col-sm-6'>
                <div className={`row`}>
                  <div className='col-lg-6 col-md-6 col-sm-6'>
                    Tiền thuế: {formatNumber(total_.totalVat)}
                  </div>
                  <div className='col-lg-6 col-md-6 col-sm-6'>
                    Tiền tiền thanh toán: {formatNumber(total_.total)}
                  </div>
                </div>
              </div>
            </div>
            <div className={`row`}>
              <div className='col-lg-12 col-md-12 col-sm-12 mt-2'>
                <div className=' bg-white p-2'>
                  <Form.Item >
                    <div className="flex_">
                      <Button type="default" style={{ marginRight: "10px" }} danger onClick={() => { }}>Hủy</Button>

                      <Button type="primary" loading={isloading} style={{ marginRight: "10px" }} htmlType="submit"  >Cập nhật</Button>
                    </div>
                  </Form.Item>
                </div>
              </div>
            </div>
          </Form>
        </div>
        <div className='col-lg-12 col-md-12 col-sm-12 bg-white'>


        </div>
      </div>
    </Card>
  )
}

export default Index
