import React, { useEffect, useRef, useState } from 'react'
import { Button, Form, Input, message, Select, Table, TreeSelect } from 'antd';
import * as LINK from "../../../constants/Links";

import "./style.css"
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { META } from '../../../utils/constant';
import { useDispatch, useSelector } from 'react-redux';
import { ListAccountingAccountRequest, StoreAccountingAccountRequest, UpdateBalanceAccountRequest } from '../../../actions/indexWeb';
import { DATA_GROUP_ACCOUNT, DATA_TYPE_ACCOUNTING } from '../../../constants/DefaultData';
import {
FolderViewOutlined, AppstoreOutlined,
DeleteOutlined, PlusCircleOutlined,
EditOutlined, UnorderedListOutlined, SearchOutlined
} from '@ant-design/icons';
import * as Colors from "./../../../constants/Colors";
import { formatMN1 } from '../../../service';
const Index = () => {

  const dispatch = useDispatch()
  const { Web } = useSelector(state => state);
  const [meta, setMeta] = useState({ ...META });
  const [listAccount, setListAccount] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const [record, setRecord] = useState(null);
  const formRef = useRef(null);
  const getListAccount = async () => {
    const response = await ListAccountingAccountRequest({
      limit: meta.per_page * 100,
      page: meta.current_page, column: "code", sort: "ASC"
    });
    if (response.code == 200) {
      if (response.data) {
        console.log(response.data.data);
        if (response.data.data) {

          setListAccount(response.data.data);
        }
      }
    }


  }
  useEffect(() => {
    getListAccount();
    return () => {

    };
  }, []);
  const columns = [
    {
      title: 'Số TK',
      dataIndex: 'code',
      key: 'code',
      sorter: (a, b) => a.code - b.code,
      width: "20%"

    },
    {
      title: 'Mô tả',
      dataIndex: 'description',
      key: 'description',
      sorter: (a, b) => a.description - b.description,
    },
    {
      title: 'Loại',
      dataIndex: 'type',
      key: 'type',
      sorter: (a, b) => a.type - b.type,
      render: (text, record, index) => {
        const res=DATA_TYPE_ACCOUNTING.filter((item) => {
          return item.value==text;
        })
        // console.log(DATA_TYPE_ACCOUNTING,text);
        
        return res?.[0]?.label;
      }
    },
    {
      title: 'Nhóm TK',
      dataIndex: 'group_type',
      sorter: (a, b) => a.group_type - b.group_type,
      render: (text, record, index) => {
        const result = DATA_GROUP_ACCOUNT.filter((item) => {
          return item.value == record.group_type;
        })

        if (result[0]) {
          return result[0].label;
        }
        return;
      },
      key: 'group',
    },
    {
      title: 'Dư nợ',
      dataIndex: 'debit_balance',
      render: (text, record, index) => {
        return  <Form.Item name={`debit_balance-${record.id}`} noStyle initialValue={formatMN1(text)} ><Input data-old={text} onBlur={(e)=>updateBlance(e,record.id,"debit_balance")} onChange={(e)=>handleMoneyChange(e,"debit_balance",record.id)}/></Form.Item>
      },
      key: 'debit_balance',
    },
    {
      title: 'Dư có',
      dataIndex: 'credit_balance',
      render: (text, record, index) => {
        return <Form.Item  name={`credit_balance-${record.id}`} noStyle  initialValue={formatMN1(text)}><Input data-old={text} onBlur={(e)=>updateBlance(e,record.id,"credit_balance")} onChange={(e)=>handleMoneyChange(e,"credit_balance",record.id)} onFocus={(e)=>{e.target.select()}}/></Form.Item>
      },
      key: 'credit_balance',
    },
  ];

  const updateBlance=async(e,id,type)=>{
    const target =e.target;
    const oldvalue=target.getAttribute("data-old");
    const value=target.value;
    if(formatMN1(oldvalue)==value){
      return;
    }
    // console.log(value,oldvalue);
    const inputValue = value.replace(/\D/g, '');
    const response = await UpdateBalanceAccountRequest({id:id,[type]:inputValue});
    if (response.code == 200) {
      if (response.message) {
        message.success(response.message);
      } else {
        message.success("Cập nhật thành công");
      }
     

    } else {
      if (response.message) {
        if (typeof response.message == "string") {
          message.error(response.message);
        } else {
          (Object.values(response.message) || []).map((value, index) => {
            message.error(value);
          })
        }

      } else {
        message.warning("Cập nhật không thành công");
      }
    }
  }
  const handleMoneyChange = (e,name,row) => {
    const inputValue = e.target.value.replace(/\D/g, ''); // Loại bỏ ký tự không phải số
    const formattedValue = formatMN1(inputValue);
    formRef.current.setFieldsValue({
      [`${name}-${row}`]: formattedValue,
    });
   
  };
  // const handleMoneyChange = (e) => {
  //   const inputValue = e.target.value.replace(/\D/g, ''); // Loại bỏ ký tự không phải số
  //   const formattedValue = formatMN1(inputValue);
  //   e.target.value=formattedValue;
  // };
  return (
    <div className='w-100 row   bg-white p-2  position-relative'>
       <div className='col-lg-12 col-md-12 col-sm-12  d-flex'>
        <Link to={LINK.WEB_ACCOUNTANT}><Button><i className="fas fa-chevron-left"></i></Button></Link>
        <h4> Cập nhật số dư ban đầu</h4>
      </div>
      <div className='col-lg-12 col-md-612 col-sm-12 '>
      <Form name="form" ref={formRef}  style={{
          padding: "0px",
          width: "100%",
          display: "flex"
        }}>
          <Table columns={columns} dataSource={listAccount} pagination={false}  scroll={{
            y: 800,
          }}>

          </Table>
        </Form>
      </div>
    
    </div>
  )
}

export default Index
