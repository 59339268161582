import React, { useEffect, useRef, useState } from 'react'
import { Button, Form, Input, message, Modal, Select, Table, TreeSelect } from 'antd';
import * as LINK from "../../../constants/Links";

import "./style.css"
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { META } from '../../../utils/constant';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteAccountantBankRequest, DeletePositionsRequest, ListPositionsRequest, StoreAccountantBankRequest, StorePositionsRequest } from '../../../actions/indexWeb';
import { DATA_GROUP_ACCOUNT, DATA_TYPE_ACCOUNTING } from '../../../constants/DefaultData';
import {
FolderViewOutlined, AppstoreOutlined,
DeleteOutlined, PlusCircleOutlined,ExclamationCircleOutlined,
EditOutlined, UnorderedListOutlined, SearchOutlined
} from '@ant-design/icons';
import * as Colors from "../../../constants/Colors";
const Index = () => {

  const dispatch = useDispatch()
  const { Web } = useSelector(state => state);
  const [meta, setMeta] = useState({ ...META });
  const [listPositions, setListPositions] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const [record, setRecord] = useState(null);
  const formRef = useRef(null);
  const getListPositions = async () => {
    const response = await ListPositionsRequest({
      limit: meta.per_page * 100,
      page: meta.current_page, column: "code", sort: "ASC"
    });
    if (response.code == 200) {
      if (response.data) {
        console.log(response.data.data);
        if (response.data.data) {

            setListPositions(response.data.data);
        }
      }
    }


  }
  useEffect(() => {
    getListPositions();
    return () => {

    };
  }, []);
  const columns = [
    
    {
      title: 'Mã',
      dataIndex: 'code',
      key: 'code',
      sorter: (a, b) => a.code - b.code,
      width: "20%"

    },
  
    {
        title: 'Tên',
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => a.name - b.name,
      },

    {
      title: 'Mô tả',
      dataIndex: 'description',
      key: 'description',
      sorter: (a, b) => a.description - b.description,
    },
    
    {
      title: 'Chỉnh sửa',
      dataIndex: '',
      render: (text, record, index) => (
        <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }} >
          <span className="__action_">
            <button className="reset_btn btn_edit btn__" onClick={() => editRow(record)}> <EditOutlined style={{ color: Colors.colorView }} /> </button>
            <button className="reset_btn btn_delete btn__" onClick={() => { confirmDeleted(index,record) }}><DeleteOutlined style={{ color: Colors.colorDelete }} /></button>
            {/* <button className="reset_btn btn_view btn__" onClick={() => { editRow(record) }}> <FolderViewOutlined   style={{ color: Colors.colorView }} /></button> */}

          </span>
        </div>
      ),
      key: 'group',
    },
  ];
  const confirmDeleted = (row, record) => {
    Modal.confirm({
      title: 'Xác nhận',
      icon: <ExclamationCircleOutlined />,
      content: 'Bạn có chắc muốn xóa',
      okText: 'Xóa',
      cancelText: 'Bỏ qua',
      onOk: () => {
        deleteRow(row, record);

      }
    });
  };
  const deleteRow = async (row, record) => {
    const response = await DeletePositionsRequest({ id: record.id });
    setIsloading(false);
    if (response.code == 200) {
      if (response.message) {
        message.success(response.message);
      } else {
        message.success("Cập nhật thành công");
      }

      getListPositions();

    } else {
      if (response.message) {
        if (typeof response.message == "string") {
          message.error(response.message);
        } else {
          (Object.values(response.message) || []).map((value, index) => {
            message.error(value);
          })
        }

      } else {
        message.warning("Cập nhật không thành công");
      }
    }
  }
  const editRow = (record) => {
    setRecord(record)
    formRef.current.setFieldsValue({ ...record })
  }
  const submitForm = async (values) => {
    setIsloading(true);
    if (record) {
      values.id = record.id;
    }
    const response = await StorePositionsRequest(values);
    setIsloading(false);
    if (response.code == 200) {
      if (response.message) {
        message.success(response.message);
      } else {
        message.success("Cập nhật thành công");
      }


      getListPositions()
    } else {
      if (response.message) {
        if (typeof response.message == "string") {
          message.error(response.message);
        } else {
          (Object.values(response.message) || []).map((value, index) => {
            message.error(value);
          })
        }

      } else {
        message.warning("Cập nhật không thành công");
      }
    }
  }

  const addnewRecord = () => {
    formRef.current.resetFields();
  }
  return (
    <div className='w-100 row '>
      <div className='col-lg-6 col-md-6 col-sm-12 '>
        <Table columns={columns} dataSource={listPositions} pagination={false} scroll={{
          y: 800,
        }}>

        </Table>
      </div>
      <div className='col-lg-6 col-md-6 col-sm-12 bg-white'>
        <div className='w-100 d-flex justify-content-end m-2'>
          <Button onClick={addnewRecord}><i class="fas fa-plus"></i></Button>
        </div>
        <Form name="formadd" onFinish={submitForm} ref={formRef}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
        >
         
          <Form.Item label="Mã"
            name="code"
            rules={[
              {
                required: true,
                message: "code là bắt buộc"
              },
            ]}
          >

            <Input  placeholder="code" />
          </Form.Item>
          <Form.Item label="Tên"
            name="name"
            rules={[
              {
                required: true,
                message: "Tên là bắt buộc"
              },
            ]}
          >

            <Input  placeholder="Tên" />
          </Form.Item>

        
          <Form.Item label="Mô tả"
            name="description"
           
          >

            <Input placeholder="Mô tả" />
          </Form.Item>
         

          <Form.Item >
            <div className="flex_">
              <Button type="default" style={{ marginRight: "10px" }} danger onClick={() => { }}>Hủy</Button>

              <Button type="primary" loading={isloading} style={{ marginRight: "10px" }} htmlType="submit"  >Cập nhật</Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export default Index
