import React, { useState } from 'react'
import { Button,Divider } from 'antd';
import * as LINK from "../../../constants/Links";

import "./style.css"
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const Index = () => {

  const [listFunction, setlistFunstion] = useState([
    {
      value: 1, label: "KHAI BÁO", link: "", children: [
        { value: "1-1", label: "TT DN", link: LINK.WEB_ACCOUNTANT_INFO_COMPANY },
        { value: "1-2", label: "DTK-SDĐK", link: LINK.WEB_ACCOUNTANT_OPEN_BALANCE },
      ],
      bgColor:"#ffffff"
    },
    {
      value: 2, label: "TSCĐ & CDCD", link: "", children: [
        { value: "2-1", label: "PB CCDC", link:LINK.WEB_ACCOUNTANT_ALLOCATION },
        { value: "2-2", label: "Ghi tăng CCDC", link: LINK.WEB_ACCOUNTANT_INCREASE_TOOL},
        { value: "2-3", label: "KH TSCĐ", link: LINK.WEB_ACCOUNTANT_ALLOCATION_ASSETS },
        { value: "2-4", label: "Ghi tăng TSCĐ", link: LINK.WEB_ACCOUNTANT_INCREASE_ASSETS },
        { value: "2-5", label: "PB CPTT", link: LINK.WEB_ACCOUNTANT_ALLOCATION_PREPAID_EXPENSES },
        { value: "2-6", label: "Chi phí trả trước", link: LINK.WEB_ACCOUNTANT_PREPAID_EXPENSES },
      ],
      bgColor:"#ffffff"
    },
    {
      value: 3, label: "BC THUẾ", link: "", children: [
        { value: "3-1", label: "BC HĐ MUA vào", link: "" },
        { value: "3-2", label: "BC HĐ Bán ra", link: "" },
        { value: "3-3", label: "Tờ khai thuế GTGT", link: "" },
        { value: "3-4", label: "Tờ khai thuế TNCN", link: "" },
      ],
      bgColor:"#ffffff"
    },
    {
      value: 4, label: "KT LƯƠNG", link: "", children: [
        { value: "4-1", label: "Bảng chấm công", link: LINK.WEB_ACCOUNTANT_TIMEKEEPING },
        { value: "4-2", label: "Bảng Lương", link:LINK.WEB_ACCOUNTANT_SALARY },

        { value: "4-2", label: "Bảng thanh toán lương", link: "" },
        { value: "4-3", label: "HĐLĐ", link: "" },
        { value: "4-4", label: "Sổ quản lý lao động", link: "" },
        { value: "4-5", label: "DS nhân viên", link: LINK.WEB_EMPLOYEES_EMPLOYEES },
      ],
      bgColor:"#ffffff"
    },
    {
      value: 5, label: "KT QUỸ", link: "", children: [
        { value: "5-1", label: "Phiếu thu", link: LINK.WEB_ACCOUNTANT_RECEIPT_AND_PAYMENT+"/1" },
        { value: "5-2", label: "Phiếu chi", link: LINK.WEB_ACCOUNTANT_RECEIPT_AND_PAYMENT+"/2" },
        { value: "5-3", label: "Sổ quỹ tiền mặt", link: "" },
        { value: "5-4", label: "Sổ tiền gửi ngân hàng", link: "" },
      ],
      bgColor:"#ffffff"
    },
    {
      value: 14, label: "Ngân hàng", link: "", children: [
        { value: "14-1", label: "Phiếu thu", link: LINK.WEB_ACCOUNTANT_RECEIPT_AND_PAYMENT_BANK+"/1" },
        { value: "14-2", label: "Phiếu chi", link: LINK.WEB_ACCOUNTANT_RECEIPT_AND_PAYMENT_BANK+"/2" },
        // { value: "5-3", label: "Sổ quỹ tiền mặt", link: "" },
        // { value: "5-4", label: "Sổ tiền gửi ngân hàng", link: "" },
      ],
      bgColor:"#ffffff"
    },
    {
      value: 6, label: "KT GIÁ THÀNH", link: "", children: [
        { value: "6-1", label: "Định mức sản xuất", link: LINK.WEB_ACCOUNTANT_NORM_MATERIALS },
        { value: "6-2", label: "Bảng tính giá thành", link: "" },
        { value: "6-3", label: "Thẻ tính giá thành", link: "" },
        { value: "6-4", label: "Sổ chi phí SXKD", link: "" },
      ],
      bgColor:"#ffffff"
    },
    {
      value: 7, label: "KT KHO", link: "", children: [
        { value: "7-1", label: "Phiếu NK", link: LINK.WEB_ACCOUNTANT_STOCK+"/1"},
        { value: "7-2", label: "Phiếu XK", link: LINK.WEB_ACCOUNTANT_STOCK+"/2" },
        { value: "7-3", label: "Phân bổ chi phí mua hàng", link: "" },
        { value: "7-4", label: "Bảng N-X-T kho", link: "" },
        { value: "7-5", label: "Sổ chi tiết HH-VT", link: "" },
      ],
      bgColor:"#ffffff"
    },
    {
      value: 8, label: "KT CÔNG NỢ", link: "", children: [
        { value: "8-1", label: "Sổ CT phải thu - TK 131", link: "" },
        { value: "8-2", label: "Sổ Ct phải trả - TK 331", link: "" },
        { value: "8-3", label: "Bảng TH CN phải thu - TK 131", link: "" },
        { value: "8-4", label: "Bảng TH CN phải trả - TK 331", link: "" },
     
      ],
      bgColor:"#ffffff"
    },
    {
      value: 9, label: "BC TÀI CHÍNH", link: "", children: [
        { value: "9-1", label: "CĐPS Năm", link: LINK.WEB_ACCOUNTANT_BALANCE },
        { value: "9-2", label: "CĐKT", link: "" },
        { value: "9-3", label: "Báo cáo KQKD", link: "" },
        { value: "9-4", label: "Thuyết minh BCTC", link: "" },
     
      ],
      bgColor:"#ffffff"
    },
    {
      value: 10, label: "Sổ KT KHÁC", link: "", children: [
        { value: "10-1", label: "Nhập liệu", link: LINK.WEB_ACCOUNTANT_LEDGER },
        { value: "10-1", label: "Sổ NKC", link: LINK.WEB_ACCOUNTANT_GENERAL_JOURNAL },
        { value: "10-2", label: "Bảng CĐPS TK", link: LINK.WEB_ACCOUNTANT_BALANCE },
        { value: "10-3", label: "SCT TK", link: "" },
        { value: "10-4", label: "Sổ cái TK", link: "" },
     
      ],
      bgColor:"#ffffff"
    },
    {
      value: 11, label: "DANH MỤC", link: "", children: [
        { value: "11-1", label: "Nhà CCC", link: LINK.WEB_SUPPLIER },
        { value: "11-2", label: "Khách hàng", link: LINK.WEB_CUSTOMERS},
        { value: "11-3", label: "Hàng hóa, vật liệu", link: LINK.WEB_PRODUCTS_WAREHOUSE },
        { value: "11-4", label: "Dm TK TT200", link: LINK.WEB_ACCOUNTING_ACCOUNT },
        { value: "11-5", label: "Dm Mẫu hóa đơn", link: LINK.WEB_ACCOUNTANT_INVOICE_TEMPLATE },
        { value: "11-6", label: "Dm TKNH", link: LINK.WEB_ACCOUNTANT_INVOICE_BANK },
        { value: "11-6", label: "TK ngầm định", link: LINK.WEB_ACCOUNTANT_DEFAULT_ACCOUNT },
        { value: "11-7", label: "Loại CCDC/TSCĐ", link: LINK.WEB_ACCOUNTANT_TYPE_ASSETS },
      
      ],
      bgColor:"#ffffff"
    },
    {
      value: 12, label: "CHỨNG TỪ", link: "", children: [
        { value: "12-1", label: "Chứng từ mua hàng", link: LINK.WEB_ACCOUNTANT_PURCHASE_RECEIPT },
        { value: "12-2", label: "Chứng từ bán hàng", link: LINK.WEB_ACCOUNTANT_SELL},
        { value: "12-3", label: "Chứng từ NVK", link: LINK.WEB_ACCOUNTANT_OTHER_DOCUMENT},
        { value: "13-4", label: "Danh sách chứng từ", link: LINK.WEB_ACCOUNTANT_LIST_DOCUMENT},
     
      ],
      bgColor:"#ffffff"
    },
    { value: 13, label: "Nhập liệu PS", link: "",
      bgColor:"#ffffff" },
    // { value: 2, label: "Bảng cân đối", link: LINK.WEB_ACCOUNTANT_BALANCE },
    // { value: 3, label: "Tài khoản", link: LINK.WEB_ACCOUNTING_ACCOUNT },
    // { value: 4, label: "Số dư đầu", link: LINK.WEB_ACCOUNTANT_OPEN_BALANCE },
    // { value: 5, label: "Phiếu thu", link: LINK.WEB_ACCOUNTANT_RECEIPT },
  ]);
  return (
    <div>
      <div className='w-80 m-auto d-grid   justify-content-center ' style={{ gridTemplateColumns:"repeat(6, 1fr)",gap:"10px" }}>
        {listFunction && listFunction.map((value, index) => {
          if(value.children){
            return <Button className='btn-function me-2 d-flex flex-column justify-content-start' style={{ backgroundColor:value?.bgColor }}><div className='fw-bold'>{value.label}</div>
            {value.children.map((vchil, ichil) => {
              return <div key={ichil} className='w-100'><Divider className='m-1'/><Link to={vchil.link}>{vchil.label}</Link></div>
            })}
          </Button>}
          else{
            return <Button className='btn-function me-2 fw-bold' style={{ backgroundColor:value?.bgColor }}><Link to={value.link}>{value.label}</Link></Button> ;
          }
        

        })}
      </div>
    </div>
  )
}

export default Index
