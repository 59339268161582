import { Button, Divider, Form, Input, InputNumber, message, Radio, Select, Space, TreeSelect, Typography } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { META } from '../../../../utils/constant';
import { ListAccountantBankRequest, ListAccountingAccountRequest, ListUnitNoDispatchRequest, SearchProductsWarehouseNoDispatchRequest, StoreUnitNoDispatchRequest } from '../../../../actions/indexWeb';
import { DATA_PERCENT_VAT } from '../../../../constants/DefaultData';
import {

    PlusOutlined,
} from '@ant-design/icons';
import { filAcounts, formatMN1, formatNumber, handleNumberInput, parseNumberV_ } from '../../../../service';
const Index = ({ formRef, listDefaultAccountFil,listSuplier,detail }) => {

    const [tab, setTab] = useState(1);
    const [dataDetail1, setdataDetail1] = useState([
        { code_pro: "", name_pro: "", unit_id: "", amount: ""},
    ]);
    const [listAccount, setListAccount] = useState([]);
    const [listProducts, setListProducts] = useState([]);
    const [listUnits, setListUnits] = useState([]);

    const [listTKno, setListTKno] = useState([]);
    const [listTKco, setListTKco] = useState([]);
    const [listTKvat, setListTKvat] = useState([]);

    const [meta, setMeta] = useState({ ...META });
    const [listBanks, setListBanks] = useState([]);

    const newRowRef = useRef(null);
    const tableRef = useRef(null);
    const [total, setTotal] = useState({
        amount: 0,
        price: 0,
    
    
      });
    const addRow = () => {
        setdataDetail1([...dataDetail1, { code_pro: "", name_pro: "", unit_id: "", amount: ""},])

    }
    const deleteRow = (i) => {
        if (dataDetail1.length <= 1) {
            return;
        }
        const newrow = dataDetail1.filter((value, index) => {
            return index != i;
        })
        setdataDetail1(newrow);
    }
    const handleKeyDown = (e, isLastCell = false) => {


        // console.log(e);
        if (isLastCell && (e.key === 'Tab' || e.key === 'Enter')) {
            e.preventDefault();
            addRow();
        }

        if (!isLastCell && (e.key === 'Enter')) {
            e.preventDefault();
            addRow();

        }
    };
  
    const setTotal_=()=>{
        const total_={
            amount:0,
            price:0,
     
            
          };
          if(formRef.current){
            const formvalue=formRef.current.getFieldsValue();
            dataDetail1.map((value, index) => {
              
                total_.amount+=parseNumberV_(formvalue["d-total-" + index]);
                total_.price+=parseNumberV_(formvalue["d-vat_price-" + index]);
                // total_.amount+=Number(formvalue["d-amount-" + index]);
                // total_.price+=Number(formvalue["d-price-" + index]?.toString().replace(/\D/g, ''));
                // total_.totalDiscount+=Number(formvalue["d-total_discount-" + index].toString().replace(/\D/g, ''));
               
            })
          }
       
       
        setTotal({...total_})
    }
    useEffect(() => {
        scrollToElement(tab)
      
        getListProducts();
        getListUnits();
        return () => {

        };
    }, []);

    useEffect(() => {
        console.log("detaildetaildetail ",detail);
        
        if(detail && detail.length>0){
            setdataDetail1(detail)
            detail.map((value, index) => {
                if(formRef.current){
                    formRef.current.setFieldsValue({ ["d-product_warehouse_name-" + index]: value.product_warehouse_name, 
                        ["d-unit_id-" + index]:  value.unit_id,
                        ["d-product_warehouse_price-" + index]:  formatMN1(value.product_warehouse_price),
                        ["d-product_warehouse_id-" + index]:  value.product_warehouse_id,
                        ["d-amount-" + index]:  value.amount,
                        ["d-price-" + index]:  formatMN1(value.price),
                        ["d-loss_rate-" + index]:  (value.loss_rate),
                        ["d-id-" + index]:  (value.id),
                     });
                }
            })
        }else{
            setdataDetail1([{ code_pro: "", name_pro: "", unit_id: "", amount: ""}])
            if(formRef.current){
                formRef.current.setFieldsValue({ ["d-product_warehouse_name-0"]: "", 
                    ["d-unit_id-0"]:  "", 
                    ["d-product_warehouse_price-0"]:  "", 
                    ["d-product_warehouse_id-0"]:  "", 
                    ["d-amount-0"]:  "", 
                    ["d-price-0"]:  "", 
                    ["d-loss_rate-0"]:  "", 
                    ["d-id-0"]:  "", 
                 });
            }
        }
        return () => {
            
        };
    }, [detail]);
    // useEffect(() => {
       
    //     dataDetail1.map((value, index) => {
    //         if(formRef.current){
    //             formRef.current.setFieldsValue({ ["d-product_warehouse_name-" + index]: value.product_warehouse_name, 
    //                 ["d-unit_id-" + index]:  value.unit_id,
    //                 ["d-product_warehouse_price-" + index]:  formatMN1(value.product_warehouse_price),
    //                 ["d-product_warehouse_id-" + index]:  value.product_warehouse_id,
    //                 ["d-amount-" + index]:  value.amount,
    //                 ["d-price-" + index]:  formatMN1(value.price),
    //              });
    //         }
    //     })
    //     return () => {
            
    //     };
    // }, [dataDetail1]);
    const scrollToElement = (value) => {

        setTab(value)
        const element = document.getElementById("tab" + value); // Lấy phần tử dựa trên ID
        console.log(value, element);

        if (element && tableRef.current.contains(element)) {
            element.scrollIntoView({ behavior: "smooth", block: "nearest" });
        }
    };

    const storeUnit = async (e, type) => {
        const formvalues = formRef.current.getFieldsValue();

        const response = await StoreUnitNoDispatchRequest({
            "name": formvalues[type],
            "code": "",
            "type": "0",
            "hidden": "0",
            "deleted": "0"
        });
        if (response.code == 200) {
            if (response.message) {
                message.success(response.message);
            } else {
                message.success("Cập nhật thành công");
            }
            getListUnits();

        } else {
            if (response.message) {
                if (typeof response.message == "string") {
                    message.error(response.message);
                } else {
                    (Object.values(response.message) || []).map((value, index) => {
                        message.error(value);
                    })
                }

            } else {
                message.warning("Cập nhật không thành công");
            }
        }
    }
    const getListUnits = async () => {
        const response = await ListUnitNoDispatchRequest({
            limit: meta.per_page * 100,
            page: meta.current_page, column: "name", sort: "ASC"
        });
        console.log("getListUnitsgetListUnits", response);
        if (response.code == 200) {
            if (response.data) {

                if (response.data.data) {

                    setListUnits(response.data.data);
                }
            }
        }


    }
    const getListProducts = async () => {
        const response = await SearchProductsWarehouseNoDispatchRequest({
            limit: meta.per_page * 100,
            page: meta.current_page, column: "code", sort: "ASC"
        });
        if (response.code == 200) {
            if (response.data) {
                console.log(response.data.data);
                if (response.data.data) {

                    setListProducts(response.data.data);
                }
            }
        }


    }
   

    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const handleMouseDown = (e) => {


        if (e.target.tagName != "TH") return;
        setIsDragging(true);
        setStartX(e.pageX - tableRef.current.offsetLeft);
        setScrollLeft(tableRef.current.scrollLeft);

    };

    const handleMouseMove = (e) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - tableRef.current.offsetLeft;
        const walk = (x - startX) * 2; // Tốc độ cuộn (tăng hoặc giảm giá trị này tùy thích)
        tableRef.current.scrollLeft = scrollLeft - walk;
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleMouseLeave = () => {
        setIsDragging(false);
    };
    
 
    const handleMoneyChange = (e, name, index) => {
        let inputValue = "";
        if (e.target.value) {
            inputValue = e.target.value // Loại bỏ ký tự không phải số
        } else {
            inputValue = e.toString() // Loại bỏ ký tự không phải số
        }
        const formattedValue= handleNumberInput(inputValue);

        formRef.current.setFieldsValue({
            [`${name}-${index}`]: formattedValue,
        });

    };

    const proccessPriceNorm=(index)=>{
        const fromvalue=formRef.current.getFieldsValue();
        const product_warehouse_price=parseNumberV_(fromvalue["d-product_warehouse_price-" + index]);
        const amount=parseNumberV_(fromvalue["d-amount-" + index]);
        const price=product_warehouse_price*amount;
        formRef.current.setFieldsValue({ ["d-price-" + index]: formatNumber(price)});
        setTotal_();
    }
    return (
        <div>
            <div className={`row`}>
                <div className='col-lg-12 col-md-12 col-sm-12 mt-2'>
                    <div className=' bg-white p-2'>
                        <Radio.Group
                            options={[{ value: 1, label: "Định mức" }]}
                            onChange={(e) => {
                                const value = e.target.value;
                                scrollToElement(value)
                            }}
                            value={tab}
                            optionType="button"
                            buttonStyle="solid"
                            checked
                        />
                        <div className="table-responsive" style={{ height: "300px" }} ref={tableRef}
                            onMouseDown={handleMouseDown}
                            onMouseMove={handleMouseMove}
                            onMouseUp={handleMouseUp}
                            onMouseLeave={handleMouseLeave}
                        >
                            <table className="table table-ledger table-sm table-hover" style={{ width: "max-content", minWidth: "100%" }} >
                                <thead style={{ position: "sticky", top: 0, zIndex: 1000, cursor: "all-scroll" }} >

                                    <tr className='noscroll'>
                                        <th className='p-1 text-center align-middle' ></th>
                                        <th className='p-1 text-center align-middle' id='tab1'  > Mã hàng</th>
                                        <th className='p-1 text-center align-middle'  >Tên hàng</th>
                                        <th className='p-1 text-center align-middle'  >Đơn vị tính</th>
                                        <th className='p-1 text-center align-middle' >Giá NVL</th>
                                        <th className='p-1 text-center align-middle' >Số lượng</th>

                                        <th className='p-1 text-center align-middle' >Giá định mức</th>
                                        <th className='p-1 text-center align-middle' >Hao hụt (%)</th>
                                    </tr>

                                </thead>
                                <tbody>

                                    {dataDetail1 && dataDetail1.map((row, index) => {
                                        const isLastRow = index === dataDetail1.length - 1;
                                        return <tr key={index}>
                                            <td style={{ width:"50px" }}>
                                                <Button size='small' onClick={() => deleteRow(index)}><i class="fas fa-trash-alt text-danger"></i></Button>
                                                <Form.Item name={"d-id-" + index} style={{ width: "100%" }} className='m-0 d-none' >

                                                <Input onKeyDown={(e) => { handleKeyDown(e, false) }} readOnly />
                                                </Form.Item>
                                            </td>
                                            <td style={{ width:"150px",maxWidth:"150px" }}>
                                                <Form.Item name={"d-product_warehouse_id-" + index}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Mã hàng là bắt buộc"
                                                        },
                                                    ]}
                                                    style={{ width:"150px", }}
                                                    className='m-0'
                                                >
                                                    <Select
                                                        showSearch
                                                        style={{ width: '100%' }}
                                                        options={(listProducts || []).map((value, i) => {
                                                            return {
                                                                value: value.id,
                                                                label: value.code + "-" + value.title
                                                            }
                                                        })}

                                                        dropdownStyle={{ maxHeight: 400, minWidth: "400px", overflow: 'auto' }}
                                                        placeholder="Mã hàng"
                                                        // allowClear
                                                        // multiple
                                                        treeDefaultExpandAll
                                                        onKeyDown={(e) => { handleKeyDown(e, false) }}
                                                        onChange={(e) => {
                                                            proccessPriceNorm(index)
                                                            const product = listProducts.filter((item, i) => {
                                                                return item.id == e;

                                                            })
                                                            if (product[0]) {
                                                                if (formRef.current) {
                                                                    console.log("product[0]product[0] ",product[0]);
                                                                    formRef.current.setFieldsValue({ ["d-product_warehouse_name-" + index]: product[0].title, ["d-unit_id-" + index]:  product[0].unit_id,["d-product_warehouse_price-" + index]:  formatNumber(product[0].price)  });

                                                                  
                                                                   
                                                                     
                                                                }
                                                            }


                                                        }}

                                                        ref={isLastRow ? newRowRef : null}
                                                    />

                                                </Form.Item>
                                            </td>
                                            <td>
                                                <Form.Item name={"d-product_warehouse_name-" + index} style={{ width: "100%" }} className='m-0'>

                                                    <Input onKeyDown={(e) => { handleKeyDown(e, false) }} readOnly />
                                                </Form.Item>
                                            </td>
                                            <td style={{ width:"100px" }}>
                                                <Form.Item name={"d-unit_id-" + index}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Đơn vị tính là bắt buộc"
                                                        },
                                                    ]}
                                                    style={{ width:"100px", }} className='m-0'
                                                >
                                                    <Select
                                                        showSearch
                                                        style={{ width: '100%' }}
                                                        options={(listUnits || []).map((value, index) => {
                                                            return {
                                                                value: value.id,
                                                                label: value.name
                                                            }
                                                        })}
                                                        readOnly
                                                        dropdownStyle={{ maxHeight: 400, minWidth: "250px", overflow: 'auto' }}
                                                        placeholder="Đơn vị tính"
                                                        dropdownRender={(menu) => (
                                                            <>
                                                                {menu}
                                                                <Divider
                                                                    style={{
                                                                        margin: '8px 0',
                                                                    }}
                                                                />
                                                                <div className='d-flex align-items-center'>
                                                                    <Form.Item name={`unitadd`} noStyle >
                                                                        <Input placeholder="nhập tên đơn vị" />
                                                                    </Form.Item>
                                                                    <Typography.Link
                                                                        onClick={(event) => storeUnit(event, "unitadd")}
                                                                        style={{
                                                                            whiteSpace: 'nowrap', marginLeft: "5px"
                                                                        }}
                                                                    >
                                                                        <PlusOutlined /> Thêm
                                                                    </Typography.Link>
                                                                </div>

                                                            </>
                                                        )}
                                                        treeDefaultExpandAll

                                                        onKeyDown={(e) => { handleKeyDown(e, false) }}
                                                    />

                                                </Form.Item>
                                            </td>
                                            <td>
                                                <Form.Item name={"d-product_warehouse_price-" + index} style={{ width: "100%" }} className='m-0'>

                                                    <Input onKeyDown={(e) => { handleKeyDown(e, false) }} readOnly />
                                                </Form.Item>
                                            </td>
                                            <td style={{ width:"100px" }}>
                                                <Form.Item name={"d-amount-" + index} style={{ width: "100%" }} className='m-0'>

                                                    <Input min={0} onKeyDown={(e) => { handleKeyDown(e, false) }}
                                                        onChange={(e) => {
                                                            handleMoneyChange(e, "d-amount", index);
                                                            proccessPriceNorm(index)
                                                        }}   style={{ width: "100%" }}
                                                        placeholder='Số lượng' />
                                                </Form.Item>
                                            </td>
                                            <td>
                                                <Form.Item name={"d-price-" + index} style={{ width: "100%" }} className='m-0'>

                                                    <Input onKeyDown={(e) => { handleKeyDown(e, false) }}  readOnly/>
                                                </Form.Item>
                                            </td>
                                            <td>
                                                <Form.Item name={"d-loss_rate-" + index} style={{ width: "100%" }} className='m-0'>

                                                    <Input onKeyDown={(e) => { handleKeyDown(e, false) }} />
                                                </Form.Item>
                                            </td>
                                        </tr>
                                    })}
                                                <tr className='bg_main_2 fw-bold'>

                                                <td colSpan={5}>
                                                Tổng
                                                </td>
                                                <td className='text-center'>
                                                {(total.amount)}
                                                </td>
                                                <td className='text-right'>
                                                {formatMN1(total.price)}
                                                </td>
                                                <td className='text-right'>
                                              
                                                </td>
                                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Index
