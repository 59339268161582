import React, { useEffect, useRef, useState } from 'react'
import { Button, Form, Input, message, Select, Table, TreeSelect } from 'antd';
import * as LINK from "../../../constants/Links";

import "./style.css"
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { META } from '../../../utils/constant';
import { useDispatch, useSelector } from 'react-redux';
import { ListAccountingAccountRequest, StoreAccountingAccountRequest } from '../../../actions/indexWeb';
import { DATA_GROUP_ACCOUNT, DATA_TYPE_ACCOUNTING } from '../../../constants/DefaultData';
import {
FolderViewOutlined, AppstoreOutlined,
DeleteOutlined, PlusCircleOutlined,
EditOutlined, UnorderedListOutlined, SearchOutlined
} from '@ant-design/icons';
import * as Colors from "./../../../constants/Colors";
const Index = () => {

  const dispatch = useDispatch()
  const { Web } = useSelector(state => state);
  const [meta, setMeta] = useState({ ...META });
  const [listAccount, setListAccount] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const [record, setRecord] = useState(null);
  const formRef = useRef(null);
  const getListAccount = async () => {
    const response = await ListAccountingAccountRequest({
      limit: meta.per_page * 100,
      page: meta.current_page, column: "code", sort: "ASC",key:1
    });
    if (response.code == 200) {
      if (response.data) {
        console.log(response.data.data);
        if (response.data.data) {

          setListAccount(response.data.data);
        }
      }
    }


  }
  useEffect(() => {
    getListAccount();
    return () => {

    };
  }, []);
  const columns = [
    {
      title: 'Số TK',
      dataIndex: 'code',
      key: 'code',
      sorter: (a, b) => a.code - b.code,
      width: "20%"

    },
    {
      title: 'Mô tả',
      dataIndex: 'description',
      key: 'description',
      sorter: (a, b) => a.description - b.description,
    },
    {
      title: 'Loại',
      dataIndex: 'type',
      key: 'type',
      sorter: (a, b) => a.type - b.type,
      render: (text, record, index) => {
        const res=DATA_TYPE_ACCOUNTING.filter((item) => {
          return item.value==text;
        })
        // console.log(DATA_TYPE_ACCOUNTING,text);
        
        return res?.[0]?.label;
      }
    },
    {
      title: 'Nhóm TK',
      dataIndex: 'group_type',
      sorter: (a, b) => a.group_type - b.group_type,
      render: (text, record, index) => {
        const result = DATA_GROUP_ACCOUNT.filter((item) => {
          return item.value == record.group_type;
        })

        if (result[0]) {
          return result[0].label;
        }
        return;
      },
      key: 'group',
    },
    {
      title: 'Chỉnh sửa',
      dataIndex: '',
      render: (text, record, index) => (
        <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }} >
          <span className="__action_">
            <button className="reset_btn btn_edit btn__" onClick={() => editRow(record)}> <EditOutlined style={{ color: Colors.colorView }} /> </button>
            <button className="reset_btn btn_delete btn__" onClick={() => { deleteRow(record) }}><DeleteOutlined style={{ color: Colors.colorDelete }} /></button>
            {/* <button className="reset_btn btn_view btn__" onClick={() => { editRow(record) }}> <FolderViewOutlined   style={{ color: Colors.colorView }} /></button> */}

          </span>
        </div>
      ),
      key: 'group',
    },
  ];

  const deleteRow = () => {

  }
  const editRow = (record) => {
    setRecord(record)
    formRef.current.setFieldsValue({ ...record })
  }
  const submitForm = async (values) => {
    setIsloading(true);
    if (record) {
      values.id = record.id;
    }
    const response = await StoreAccountingAccountRequest(values);
    setIsloading(false);
    if (response.code == 200) {
      if (response.message) {
        message.success(response.message);
      } else {
        message.success("Cập nhật thành công");
      }


      getListAccount()
    } else {
      if (response.message) {
        if (typeof response.message == "string") {
          message.error(response.message);
        } else {
          (Object.values(response.message) || []).map((value, index) => {
            message.error(value);
          })
        }

      } else {
        message.warning("Cập nhật không thành công");
      }
    }
  }

  const addnewRecord = () => {
    formRef.current.resetFields();
    setRecord(null)
  }
  return (
    <div className='w-100 row '>
      <div className='col-lg-6 col-md-6 col-sm-12 '>
        <Table columns={columns} dataSource={listAccount} pagination={false} scroll={{
          y: 800,
        }}>

        </Table>
      </div>
      <div className='col-lg-6 col-md-6 col-sm-12 bg-white'>
        <div className='w-100 d-flex justify-content-end m-2'>
          <Button onClick={addnewRecord}><i class="fas fa-plus"></i></Button>
        </div>
        <Form name="formadd" onFinish={submitForm} ref={formRef}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
        >
          <Form.Item label="Tk cha" name={"parent_id"}

            rules={[
              {
                required: true,
                message: "Tk cha là bắt buộc"
              },
            ]}>
            <TreeSelect
              showSearch
              style={{ width: '100%' }}
              treeData={[{ value: 0, label: "gốc" }, ...listAccount]}

              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              placeholder="Tk cha"
              // allowClear
              // multiple
              treeDefaultExpandAll


            />

          </Form.Item>
          <Form.Item label="code"
            name="code"
            rules={[
              {
                required: true,
                message: "code là bắt buộc"
              },
            ]}
          >

            <Input name="name" id="name" placeholder="code" />
          </Form.Item>
          <Form.Item label="Mô tả"
            name="description"
            rules={[
              {
                required: true,
                message: "Mô tả là bắt buộc"
              },
            ]}
          >

            <Input placeholder="Mô tả" />
          </Form.Item>
          <Form.Item label="Loại TK"
                  name="type"
                  rules={[
                      {
                          required: true,
                          message: "Loại TK"
                      },
                  ]}
              >

                  <Select
                      showSearch
                      style={{ width: "100%", }}
                      showArrow={true}
                      defaultActiveFirstOption={true}
                      options={DATA_TYPE_ACCOUNTING}


                  />
              </Form.Item>
          {/* <Form.Item label="Nhóm TK"
                  name="group_type"
                  rules={[
                      {
                          required: true,
                          message: "Nhóm TK"
                      },
                  ]}
              >

                  <Select
                      showSearch
                      style={{ width: "100%", }}
                      showArrow={true}
                      defaultActiveFirstOption={true}
                      options={DATA_GROUP_ACCOUNT}


                  />
              </Form.Item> */}


          <Form.Item >
            <div className="flex_">
              <Button type="default" style={{ marginRight: "10px" }} danger onClick={() => { }}>Hủy</Button>

              <Button type="primary" loading={isloading} style={{ marginRight: "10px" }} htmlType="submit"  >Cập nhật</Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export default Index
