import React, { useEffect, useRef, useState } from 'react'
import { Button, Card, Checkbox, DatePicker, Form, Input, InputNumber, message, Radio, Select, Table, TreeSelect } from 'antd';
import * as LINK from "../../../../constants/Links";

import "./../style.css"
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { META } from '../../../../utils/constant';
import { useDispatch, useSelector } from 'react-redux';
import { ListAccountantBankRequest, ListAccountantInvoiceTemplateRequest, ListAccountingAccountRequest, ListDefaultAccountRequest, ListDeterminePrepaidExRequest, ListEmployeesRequest, ListPrepaidExRequest, ListUsersNodispatchRequest, SearchCustomersNoDispatchRequest, SearchSupplierNoDispatchRequest, StoreAccountantPurchaseRequest, StoreAccountingAccountRequest, StorePrepaidExpensesRequest } from '../../../../actions/indexWeb';
import { DATA_GROUP_ACCOUNT, DATA_REASON_PAYMENT, DATA_REASON_RECEIPT, DATA_STOCK_EXPORT, DATA_STOCK_IMPORT, DATA_TYPE_ACCOUNTING, DATA_TYPE_PURCHASE_RECEIPT, DATA_TYPE_PURCHASE_RECEIPT_ADD_BILL, DATA_TYPE_PURCHASE_RECEIPT_PAYMENT, DATA_TYPE_PURCHASE_RECEIPT_PAYMENT_ACCOUNT, DATA_TYPE_SELL, DATA_TYPE_SELL_ACCOUNT } from '../../../../constants/DefaultData';
import {
  FolderViewOutlined, AppstoreOutlined,
  DeleteOutlined, PlusCircleOutlined,
  EditOutlined, UnorderedListOutlined, SearchOutlined
} from '@ant-design/icons';
import * as Colors from "../../../../constants/Colors";
import Planning from "./planning";
import moment from 'moment';
import { createSlug, filAcounts, formatMN1, formatNumber, handleNumberInput, parseNumberV_ } from '../../../../service';

const Index = () => {

  const dispatch = useDispatch()
  const { Web } = useSelector(state => state);
  const params = useParams();
  const { typeV } = params;
  // console.log(typeV);

  const [meta, setMeta] = useState({ ...META });

  const [listDefaultAccount, setListDefaultAccount] = useState([]);
  const [listDefaultAccountFil, setListDefaultAccountFill] = useState(null);
  const [isloading, setIsloading] = useState(false);
  const [tab, setTab] = useState(1);
  const [typeObject, setTypeObject] = useState(1);
  const [record, setRecord] = useState(null);
  const [listAccount, setListAccount] = useState([]);
  const [listTKno, setListTKno] = useState([]);
  const [listTKco, setListTKco] = useState([]);
  const [listTKvat, setListTKvat] = useState([]);
  const [listPurchase, setpurchase] = useState(null);
  const [listPurchaseEdit, setpurchaseEdit] = useState(null);
  const [data, setData] = useState(null);
  const [dataDetail, setdataDetail] = useState(null);
  const [total_, setTotal_] = useState({
    totalPrice: 0,
    totalVat: 0,
    totalDiscount: 0,
    total: 0,

  });

  const [AccountPayment, setAccountPayment] = useState(1);

  const formRef = useRef(null);
  const dataTab1 = [{ value: 1, label: "Chứng từ ghi nợ" }, { value: 2, label: "Phiếu xuất" }, { value: 3, label: "Hóa đơn" }];
  const dataTab2 = [{ value: 1, label: "Phiếu thu" }, { value: 2, label: "Phiếu xuất" }, { value: 3, label: "Hóa đơn" }];

  const columns = [
    {
      title: 'Ngày',
      dataIndex: 'date',
      key: 'date',
    },

    {
      title: 'Mã',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Tên',
      dataIndex: 'name',
      key: 'name',
    },

    {
      title: 'Số tiền',
      dataIndex: 'total',
      key: 'total',
      render: (text, record, index) => {
        return formatNumber(text)
      }
    },
    {
      title: 'Chọn',
      dataIndex: '',
      align: "center",
      width: "25%",
      render: (text, record, index) => (
        <div style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }} >
          <span className="__action_">
            {/* <button className="reset_btn btn_edit btn__" onClick={() => editRow(record)}> <EditOutlined style={{ color: Colors.colorView }} /> </button> */}
            {/* <Button className=" " onClick={() => { setpurchase(record) }}>Chọn phiếu</Button> */}
            <Button className="" onClick={() => { editRow(record) }}> <FolderViewOutlined   style={{ color: Colors.colorView }} /></Button>

          </span>
        </div>
      ),
      key: 'group',
    },
  ];
  
  const defaultDate = moment().startOf('day');
  const editRow=(record)=>{
    resetForm();
     setRecord(record);
     setTab(1);
  }
  const getListPrepaid = async (data) => {
    const response = await ListPrepaidExRequest({
      limit: meta.per_page * 100,
      page: meta.current_page, column: "prepaid_expenses.id", sort: "ASC"
    }, { data: data });

    if (response.code == 200) {
      if (response.data) {
        if (response.data.data) {
          setData([...response.data.data]);

        }
      }
    }


  }


  const getListAccount = async () => {
    const response = await ListAccountingAccountRequest({
      limit: meta.per_page * 100,
      page: meta.current_page, column: "code", sort: "ASC"
    });
    if (response.code == 200) {
      if (response.data) {
        console.log(response.data.data);
        if (response.data.data) {

          setListAccount(response.data.data);
        }
      }
    }


  }
  const getListDefaultAccount = async () => {
    const response = await ListDefaultAccountRequest({
      limit: meta.per_page * 100,
      page: meta.current_page, column: "code", sort: "ASC"
    });
    console.log("responseresponse ", response);

    if (response.code == 200) {
      if (response.data) {

        setListDefaultAccount(response.data);

      }
    }


  }
  useEffect(() => {

    if (listDefaultAccountFil && listAccount?.length > 0) {
      processListAccount();
    }

    return () => {

    };
  }, [listDefaultAccountFil, listAccount]);
  const processListAccount = async () => {
    if (listDefaultAccountFil) {

      const listTKno_ = await filAcounts(listDefaultAccountFil, "tkno", listAccount);
      const listTKco_ = await filAcounts(listDefaultAccountFil, "tkco", listAccount);

      console.log("listTKno_ listTKco_listTKco_listTKco_", listTKno_);

      setListTKno(listTKno_);
      setListTKco(listTKco_);
      // setListTKvat(listTKvat_);

      if (formRef.current) {
        formRef.current.setFieldsValue({ accounting_code: listTKno_.default });
      }


    }
  }
  useEffect(() => {
    filterAccount();

    return () => {

    };
  }, [listDefaultAccount]);
  const removePrefixThu = (str, text) => {
    if (str.startsWith(text)) {
      return str.slice(3).trim();
    }
    return str;
  };
  const filterAccount = () => {

    if (listDefaultAccount.length > 0) {
      const fildata = listDefaultAccount.filter((value, index) => {
        return value.code == "phan-bo-chi-phi-tra-truoc";
      })

      setListDefaultAccountFill(fildata[0]);

    }
    return;
  }




  useEffect(() => {
    getListPrepaid({ tkno: 242, code_allocation: 2 });
    getListAccount();
    getListDefaultAccount();
    return () => {

    };
  }, []);

  const submitForm = async (values) => {

    if (record) {
      values.id = record.id;
    }
    values.type = 21;
    const detail = {};
    let totalPercent = 0;

    for (const x in values) {
      const xarr = x.split("-");
      if (xarr[0] == "d") {
        let key = xarr[1];
        let row = xarr[2];
        if (!detail[row]) {
          detail[row] = {};
        }
        if (["price", "total", "total_price", "total_discount", "vat_price", "amount"].includes(key)) {
          detail[row][key] = parseNumberV_(values[x]);

        } else {
          detail[row][key] = values[x];
        }
        if (key == "percent") {
          totalPercent += values[x];
        }
      }

    }
    if (totalPercent != 100) {
      message.warning("Tổng tỉ lệ phân bổ không phù hợp");
      return;
    }
    values.detail = Object.values(detail);

    const price = values.price ? parseNumberV_(values.price) : 0;
    const periods = values.periods ?? 0;
    const per_period = periods && periods > 0 ? (price / periods) : 0;
    values.price = price;
    values.price_of_period = per_period;
    let totalMoney = 0;
    if (listPurchase && listPurchase.length > 0) {
      values.purchase_id = listPurchase.map((value, index) => {
      
        totalMoney += parseFloat(value.total_price)
        return value.code;
      })
    
      if (totalMoney != values.price) {
        message.warning("Số tiền tập hợp chứng từ không bằng số tiền");
        return;
      }
    }

    setIsloading(true);
    console.log("valuesvaluesvalues ", values);
    values.purchase_id = values.purchase_id
    const response = await StorePrepaidExpensesRequest(values);
    setIsloading(false);
    if (response.code == 200) {
      if (response.message) {
        message.success(response.message);
      } else {
        message.success("Cập nhật thành công");
      }
      setRecord(null);
      resetForm();
      getListPrepaid();
    } else {
      if (response.message) {
        if (typeof response.message == "string") {
          message.error(response.message);
        } else {
          (Object.values(response.message) || []).map((value, index) => {
            message.error(value);
          })
        }

      } else {
        message.warning("Cập nhật không thành công");
      }
    }
  }
  const filterTreeNode = (inputValue, treeNode) => {
    const { title, description, code } = treeNode;
    // console.log(treeNode.props.data);

    return (
      title.toLowerCase().includes(inputValue.toLowerCase()) || // Tìm kiếm theo title
      description.toLowerCase().includes(inputValue.toLowerCase()) || // Tìm kiếm theo description
      code.toString().includes(inputValue) // Tìm kiếm theo code
    );
  };
  const handleMoneyChange = (e, name) => {
    let inputValue = "";
    if (e.target.value) {
      inputValue = e.target.value // Loại bỏ ký tự không phải số
    } else {
      inputValue = e.toString() // Loại bỏ ký tự không phải số
    }
    const formattedValue = handleNumberInput(inputValue);

    formRef.current.setFieldsValue({
      [`${name}`]: formattedValue,
    });

  };

  const proccessPeriods = (e) => {

    const values = formRef.current.getFieldsValue();
    const price = values.price ? parseNumberV_(values.price) : 0;
    const periods = values.periods ?? 0;
    const per_period = periods && periods > 0 ? (price / periods) : 0;

    formRef.current.setFieldsValue({ price_of_period: formatNumber(per_period) })


  }

  const setpurchase_ = (rows) => {
    console.log("rowsrowsrows ", rows);
    setpurchase(rows)

  }
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      // setpurchase(selectedRows)
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  useEffect(() => {
      if(record?.id){
        console.log("recordrecordrecord ",record);
        
        if(formRef.current){
          record.date=moment(record.date);
          record.price=formatNumber(record.price)
          record.price_of_period=formatNumber(record.price_of_period)
          formRef.current.setFieldsValue({...record})
          setdataDetail(record.children)
        }
        if(record.purchase_id){
          const purchase_ids=JSON.parse(record.purchase_id);
          const listpurchase=purchase_ids.map((value, index) => {
            return {
              code:value
            }
          })
          setpurchaseEdit(listpurchase)
          setpurchase(listpurchase)

         
        }
      }else{
        setdataDetail(null)
      }
    return () => {
      
    };
  }, [record]);

  const resetForm=()=>{
    if(formRef.current){
      formRef.current.resetFields();
      setRecord(null)
      setpurchase(null)
      setpurchaseEdit(null)

    }
  }
  return (
    <Card title={<div style={{ display: "flex", alignItems: "center" }}>
      <Link to={LINK.WEB_ACCOUNTANT}><Button size='small'><i className="fas fa-chevron-left"></i></Button></Link>
      <FolderViewOutlined style={{ color: Colors.pop2[3] }} />
      <span style={{ marginLeft: "10px", color:tab==1? Colors.pop2[3]:Colors.arrColors[2],cursor:"pointer" }} onClick={()=>setTab(1)} >Chi phí trả trước</span>
      <span style={{ marginLeft: "10px", color:tab==2? Colors.pop2[3]:Colors.arrColors[2],cursor:"pointer" }}  onClick={()=>setTab(2)}>Danh sách</span>

    </div>} bordered={false}
      style={{ width: "100%", margin: "0 auto" }}
      bodyStyle={{
        padding: "10px",
        // maxHeight: "90vh",
        // overflowY: "scroll"

      }}
      headStyle={{
        backgroundColor: Colors.colorgrey,
        display: "flex",
        minHeight: "30px",
        borderRadius: "3px"
      }}
    >
      <div className='w-100 m-auto row  p-0'>
        <div className={`col-lg-12 col-md-12 col-sm-12 p-0 ${parseInt(tab) == 1 ? "" : "d-none"} `}>
          <Form name="formadd" onFinish={submitForm} ref={formRef}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 24 }}
          >


            <div className={`row `}>
              <div className='col-lg-6 col-md-6 col-sm-6 '>

                <div className=' bg-white p-2'>

                  <Form.Item label="Mã" name="code" className='m-0'>

                    <Input readOnly />
                  </Form.Item>
                  <Form.Item label="Tên" name="name" className='m-0' rules={[
                    {
                      required: true,
                      message: "Tên là bắt buộc"
                    },
                  ]}>

                    <Input />
                  </Form.Item>
                  <Form.Item label="Ngày ghi nhận"
                    name="date"
                    rules={[
                      {
                        required: true,
                        message: "Ngày ghi nhận là bắt buộc"
                      },
                    ]}

                    initialValue={defaultDate} className='m-0'
                  >

                    <DatePicker placeholder='Ngày ghi nhận' style={{ width: "100%" }} format={"DD/MM/YYYY"} />
                  </Form.Item>
                  <Form.Item label="Số tiền" name="price" className='m-0' rules={[
                    {
                      required: true,
                      message: "Số tiền là bắt buộc"
                    },
                  ]}>

                    <Input onChange={(e) => { handleMoneyChange(e, "price"); proccessPeriods(e) }} />
                  </Form.Item>
                </div>
              </div>
              <div className='col-lg-6 col-md-6 col-sm-6 '>
                <div className=' bg-white p-2'>
                  <Form.Item label="Số Kỳ PB" name="periods" className='m-0'>

                    <InputNumber onChange={(e) => proccessPeriods(e)} />
                  </Form.Item>

                  <Form.Item label="Số tiền mỗi kỳ" name="price_of_period" className='m-0'>

                    <Input onChange={(e) => { }} readOnly />
                  </Form.Item>

                  <Form.Item label="Tk chờ PB" name="accounting_code" className='m-0' initialValue={listTKno?.default}>

                    <TreeSelect
                      showSearch
                      style={{ width: '100%' }}
                      treeData={listTKno?.listAccount}
                      dropdownStyle={{ maxHeight: 400, minWidth: "250px", overflow: 'auto' }}
                      placeholder="Tài khoản"
                      filterTreeNode={filterTreeNode}
                      treeDefaultExpandAll



                    />
                  </Form.Item>
                </div>
              </div>



            </div>



            <Planning formRef={formRef} listDefaultAccountFil={listDefaultAccountFil} setTotal={setTotal_} listTKco={listTKco} setpurchase={setpurchase_} listPurchase={listPurchase} listPurchaseEdit={listPurchaseEdit} dataDetail={dataDetail} />
            {/* <div className={`row`}>
              <div className='col-lg-6 col-md-6 col-sm-6'>
                <div className={`row`}>
                  <div className='col-lg-6 col-md-6 col-sm-6'>
                    Tổng tiền hàng: {formatNumber(total_.totalPrice)}
                  </div>
                  <div className='col-lg-6 col-md-6 col-sm-6'>
                    Tổng tiền chiết khấu: {formatNumber(total_.totalDiscount)}
                  </div>
                </div>
              </div>
              <div className='col-lg-6 col-md-6 col-sm-6'>
                <div className={`row`}>
                  <div className='col-lg-6 col-md-6 col-sm-6'>
                    Tiền thuế: {formatNumber(total_.totalVat)}
                  </div>
                  <div className='col-lg-6 col-md-6 col-sm-6'>
                    Tiền tiền thanh toán: {formatNumber(total_.total)}
                  </div>
                </div>
              </div>
            </div> */}
              {record?.cost_allocation && <div className={`row`}>
              <div className='col-lg-12 col-md-12 col-sm-12 mt-2'>
                <span className='text-danger fst-italic '>(*) Đã phân bổ chi phí Không thể sửa!</span>
              </div>
            </div>}
            <div className={`row`}>
              <div className='col-lg-12 col-md-12 col-sm-12 mt-2'>
                <div className=' bg-white p-2'>
                  <Form.Item >
                    <div className="flex_">
                     <Link to={LINK.WEB_ACCOUNTANT}>  <Button type="default" style={{ marginRight: "10px" }} danger onClick={() => { }}>Hủy</Button></Link>
                     <Button type="warning" loading={isloading} style={{ marginRight: "10px" }} htmlType="button" onClick={resetForm} >Reset</Button>
                      <Button type="primary" loading={isloading} style={{ marginRight: "10px" }} htmlType="submit" disabled={record?.cost_allocation} >Cập nhật</Button>
                    </div>
                  </Form.Item>
                </div>
              </div>
            </div>
          </Form>
        </div>
        <div className={`col-lg-12 col-md-12 col-sm-12 p-0 ${parseInt(tab) == 2 ? "" : "d-none"}`}>
          <Table dataSource={(data || []).map((value, index) => ({
            ...value,
            key: value.id || index, // Dùng index nếu id không tồn tại
          }))} columns={columns} rowSelection={rowSelection} />
        </div>
      </div>
    </Card>
  )
}

export default Index
