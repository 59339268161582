// import axios from "axios";
import * as LinkApi from "./../constants/LinkApi";

import { getCookie } from "./../service";

// web______________________________________________
export function PostWeb(endpoint, data, method = "GET")
{
  const token = getCookie("acccess_token");
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Content-Type", "application/json");
  var requestOptions = {
    method: method,
    headers: myHeaders,
    redirect: 'follow'
  };
  if (data) {
    var raw = JSON.stringify(data);
    requestOptions = {
      method: method,
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };


  }

  var url = LinkApi.linkWeb + endpoint;
  return fetch(url, requestOptions);



}
export function GetWeb(endpoint)
{
  const token = getCookie("acccess_token");
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Content-Type", "application/json");


  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: 'follow'
  };
  var url = LinkApi.linkWeb + endpoint;
  return fetch(url, requestOptions);



}
export function UploadFile(fileList, folder, type = 1,key=null)
{
  try {
    const token = getCookie("acccess_token");
    const formdata = new FormData();
   
    for (let i = 0; i < fileList.length; i++) {
      const element = fileList[i]?.originFileObj ? fileList[i]?.originFileObj : fileList;

      formdata.append("files[]", element);
      // if (element instanceof File) {
      //   console.warn(`Phần tử là một File object.`);
      // } else {
      //   console.warn(`Phần tử tại index ${i} không phải là một File object.`);
      // }
    }

    formdata.append("folder", folder);
    formdata.append("type", type);
    formdata.append("key", key);

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Cookie", "laravel_session=m8GtjuJTpuAtVFzalaFdMLeIMECHpL0POmRMkWMN");
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };


    return fetch(LinkApi.linkWeb + "uploadfile", requestOptions);


    // console.log("server res: ", res);
  } catch (err) {
    console.log("Eroor: ", err);

  }
}


export function UploadPdfFile(endpoint,pdf_file)
{
  try {
    const token = getCookie("acccess_token");
    const formdata = new FormData();
    formdata.append("pdf_file", pdf_file);
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Cookie", "laravel_session=m8GtjuJTpuAtVFzalaFdMLeIMECHpL0POmRMkWMN");
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    var url = LinkApi.linkWeb + endpoint;
    return fetch(url, requestOptions);

    // console.log("server res: ", res);
  } catch (err) {
    console.log("Eroor: ", err);

  }
}

export function UploadExcelFile(endpoint,excel_file,data=null)
{
  try {
    const token = getCookie("acccess_token");
    const formdata = new FormData();
    formdata.append("file", excel_file);
    formdata.append("data", data?JSON.stringify(data):null);
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Cookie", "laravel_session=m8GtjuJTpuAtVFzalaFdMLeIMECHpL0POmRMkWMN");
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    var url = LinkApi.linkWeb + endpoint;
    return fetch(url, requestOptions);

    // console.log("server res: ", res);
  } catch (err) {
    console.log("Eroor: ", err);

  }
}
export async function PostWebDownload(endpoint, data = null, method = "GET", responseType = "json") {
  try {
    const token = getCookie("acccess_token");
    const url = `${LinkApi.linkWeb}${endpoint}`;

    // Cấu hình headers
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Accept", "application/json");

    // Nếu gửi JSON, thêm Content-Type
    if (method !== "GET" && method !== "HEAD") {
      myHeaders.append("Content-Type", "application/json");
    }

    // Cấu hình request
    const requestOptions = {
      method: method,
      headers: myHeaders,
      redirect: "follow",
    };

    // Thêm body nếu có dữ liệu và phương thức là POST, PUT, DELETE
    if (data && method !== "GET" && method !== "HEAD") {
      requestOptions.body = JSON.stringify(data);
    }

    // Gọi API
    const response = await fetch(url, requestOptions);

    // Kiểm tra trạng thái HTTP
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    // console.log(response);
    // Xử lý loại dữ liệu trả về
    if (responseType === "blob") {
      return await response.blob(); // Dùng khi tải file
    } else if (responseType === "text") {
      return await response.text(); // Dùng khi trả về chuỗi
    } else {
      return await response.json(); // Mặc định là JSON
    }
  } catch (error) {
    console.error("Error in PostWeb:", error);
    throw error; // Ném lỗi để xử lý ở nơi gọi hàm
  }
}
