// export const domainApi = 'http://127.0.0.1:8000/';
// export const linkWeb = 'http://127.0.0.1:8000/api/v1/';
// export const linkSocket = 'ws://127.0.0.1:8090/';
// export const domainWeb = 'http://127.0.0.1:8001/';

export const domainWeb = 'https://inanbova.com/';
export const domainApi = 'https://api.inanbova.com/';
export const linkWeb = 'https://api.inanbova.com/api/v1/';
export const linkSocket = 'wss://api.inanbova.com:8090/';


// export const domainWeb = 'https://test.inanbova.com/';
// export const domainApi = 'https://api-test.inanbova.com/';
// export const linkWeb = 'https://api-test.inanbova.com/api/v1/';
// export const linkSocket = 'wss://api-test.inanbova.com:8091/';
