
import React, { useEffect, useState } from 'react'
import { useRef } from "react";
import "./style.css";

import * as LINK from "../../constants/Links";
import {
  Form,
  Input,
  Button,
  InputNumber, Select,
  Space, message,
  Divider,Skeleton,
  Typography, Modal, Radio, Empty, TreeSelect, Card, Checkbox, Drawer,
  Cascader
} from 'antd';
import {
 
  PlusOutlined,
 
  } from '@ant-design/icons';
import * as ActionsWeb from "../../actions/indexWeb";
import { v4 as uuidv4 } from 'uuid';
import Lazada from "./Lazada";
import Titok from "./Titok";
import Tiki from "./Tiki";
import Shopee from "./Shopee";
import Sendo from "./Sendo";
import { useDispatch, useSelector } from 'react-redux';
import { ECOMMERCE_KEY } from '../../constants/DefaultData';
import { META } from '../../utils/constant';


const SyncMultipleProduct = ({ visible, checkSync, SetisVisible, itemSyncMutiple,selectedRowKeys,selectedRows }) => {
  const dispatch = useDispatch()
  const { Web } = useSelector(state => state);

  const [visibleE, setVisibleE] = useState(false);
  const [checkPro, setcheckPro] = useState(false);
  const [checkPros, setcheckPros] = useState([]);
  const [textEcom, setTextEcom] = useState(null);
  const [Titokedit, setTitokedit] = useState(null);
  const [Loading, setLoading] = useState(false);
  const [LoadingAtribute, setLoadingAtribute] = useState(false);
  const [codeTitok, setCodeTitok] = useState(null);

  const [ListAttribute, setListAttribute] = useState([]);
  const [ListCategories, setListCategories] = useState([]);
  const [meta, setMeta] = useState({ ...META });
  const [NameNewAttribute, setNameNewAttribute] = useState({});
  const [TitokBrands, setTitokBrands] = useState([]);

  const formSyncRef = useRef();
  const refAttributeSave = useRef([]);
  const refinputs = useRef([]);
  const [LogisticList, setLogisticList] = useState([]);

  useEffect(() => {
    console.log("checkSync ", checkSync);
    if(checkSync[0]){
      formSyncRef.current.setFieldsValue({checkSync:checkSync[0].id_});
      setCodeTitok(checkSync[0].id_);
    }
    return () => {
      if(formSyncRef.current){
        formSyncRef.current.resetFields();
      }
     
      setListAttribute([]);
      setListCategories([]);
    };
  }, [checkSync]);
  useEffect(() => {
   
    const loadCate = async () => {
      let response;
      switch (itemSyncMutiple?.keyshow) {
        case ECOMMERCE_KEY.LAZADA:
         

          response = await ActionsWeb.SearchCategoryRequestNotDispatch({
            limit: meta.per_page * 10,
            page: meta.current_page, column: meta.column, sort: meta.sort,
          }, { data: { "isparent": true, columntype: "lazada_code" } })
        
          if (response.code == 200) {
            if (response.data) {
              setListCategories(response.data.data);
            }
          }
          break;
          case ECOMMERCE_KEY.TITOK:
         

          response = await ActionsWeb.SearchCategoryRequestNotDispatch({
            limit: meta.per_page * 10,
            page: meta.current_page, column: meta.column, sort: meta.sort,
          }, { data: { "isparent": true, columntype: "tiktok_code" } })
        
          if (response.code == 200) {
            if (response.data) {
              setListCategories(response.data.data);
            }
          }
          break;
          case ECOMMERCE_KEY.SHOPEE:
         

          response = await ActionsWeb.SearchCategoryRequestNotDispatch({
            limit: meta.per_page * 10,
            page: meta.current_page, column: meta.column, sort: meta.sort,
          }, { data: { "isparent": true, columntype: "shopee_code" } })
        
          if (response.code == 200) {
            if (response.data) {
              setListCategories(response.data.data);
            }
          }
          break;
        default:
          break;
      }
    }
    loadCate();
   
    return () => {

    };
  }, [checkSync,itemSyncMutiple]);
  const getLogisticList = async()=>{

    const response = await ActionsWeb.GetlogisticListShopeeNodisPatchRequest({code:codeTitok})
    console.log("responseresponseresponse ",response);
    if (response.code == 200) {
      if (response.data) {
        setLogisticList(response.data);
      }
    }
  }

  useEffect(() => {
    if(codeTitok){
      getLogisticList();

    }
    return () => {
      
    };
  }, [codeTitok]);
  const loadDataCate = async (selectedOptions) => {
    let columntype="";
    switch (itemSyncMutiple?.keyshow) {
      case ECOMMERCE_KEY.LAZADA:
        columntype="lazada_code";

   
        break;
        case ECOMMERCE_KEY.TITOK:
          columntype="tiktok_code";
        break;
      default:
        break;
    }


    const targetOption = selectedOptions[selectedOptions.length - 1];
    targetOption.loading = true;

    const OptionsCategory=ListCategories;
    const response = await ActionsWeb.SearchCategoryRequestNotDispatch({
      limit: meta.per_page * 10,
      page: meta.current_page, column: meta.column, sort: meta.sort,
    }, { data: { "parent_id": targetOption.id,columntype:columntype } })
    console.log("responseresponseresponseresponse ", response);
    if (response.code == 200) {
      if (response.data) {
        if (response.data.data) {
          targetOption.children = response.data.data;
        }
      }
    }
    if (response.data && response.data.length <= 0) {
      targetOption.loading = false;
      return;
    }
    const updateOptionsCategory = (categories, targetOption) => {
      return categories.map(option => {
        if (option.id === targetOption.id) {
          return targetOption;
        }
        if (option.children) {
          return {
            ...option,
            children: updateOptionsCategory(option.children, targetOption)
          };
        }
        return option;
      });
    };

    const updatedOptions = updateOptionsCategory(OptionsCategory, targetOption);
    setListCategories([...updatedOptions])
    // this.setState({ OptionsCategory: updatedOptions });

    targetOption.loading = false;
  };
  const onFinishFormSync = async (values) => {
    let response=null;
    console.log("valuesvaluesvaluesvalues ",values);
    let cateEcom=values.cateEcom;

    const attributeChose = [];
    for (let x in values) {
      if (x.includes("attributeEcom")) {
        if(values[x]){
          attributeChose.push(values[x]);
        }
        
      }
    }
    // let atributes=values;
    switch (itemSyncMutiple?.keyshow) {
      case ECOMMERCE_KEY.LAZADA:
        response = await ActionsWeb.SyncProductsToLazadaNodisPatchRequest({ idstiktok: [codeTitok], cateEcom: cateEcom,idsproduct:selectedRowKeys,attributeChose:attributeChose,TitokAttributes:ListAttribute });
        console.log("responseresponseresponseresponse ",response);
        if(typeof response.message != "string"){
          message.error("Có lỗi xảy ra!");
          return;
        }
        if (response.code == 200) {
          if(response.message){
            message.success(response.message)
          }
        }else{
          if(response.message){
            message.warn(response.message)
          }
        }
        break;
        case ECOMMERCE_KEY.TITOK:
            response = await ActionsWeb.SyncProductsToTiktokNodisPatchRequest({ idstiktok: [codeTitok], cateEcom: cateEcom,idsproduct:selectedRowKeys,attributeChose:attributeChose,TitokAttributes:ListAttribute });
            console.log("responseresponseresponseresponse ",response);
            if(typeof response.message != "string"){
              message.error("Có lỗi xảy ra!");
              return;
            }
            if (response.code == 200) {
              if(response.message){
                message.success(response.message)
              }
            }else{
              if(response.message){
                message.warn(response.message)
              }
            }
        break;
        case ECOMMERCE_KEY.SHOPEE:
          response = await ActionsWeb.SyncProductsToShopeeNodisPatchRequest({ idstiktok: [codeTitok], cateEcom: cateEcom,idsproduct:selectedRowKeys,attributeChose:attributeChose,TitokAttributes:ListAttribute,...values });
          console.log("responseresponseresponseresponse ",response);
          if(typeof response.message != "string"){
            message.error("Có lỗi xảy ra!");
            return;
          }
          if (response.code == 200) {
            if(response.message){
              message.success(response.message)
            }
          }else{
            if(response.message){
              message.warn(response.message)
            }
          }
      break;
      default:
        break;
    }
  }

  const setvalueAttribute = (e, index) => {
    // console.log("eee ",e);
    refAttributeSave.current[index] = e;
  }
  const onChangeCascader =async (value) => {
  
    const formvalues = formSyncRef.current.getFieldsValue();
    console.log(codeTitok);
    setLoadingAtribute(true);
    let response=null;
    if(codeTitok){
      switch (itemSyncMutiple?.keyshow) {
        case ECOMMERCE_KEY.LAZADA:
           response = await ActionsWeb.LazadaAtttributesNoDispatchRequest({ code: codeTitok, cateId: value[value.length - 1], isRefresh: true });
            if (response.code == 200) {
              if (response.data) {
                setListAttribute(response.data)
              }
            }
          break;
          case ECOMMERCE_KEY.TITOK:
              response = await ActionsWeb.TiktokAtttributesNoDispatchRequest({ code: codeTitok, cateId: value[value.length - 1], isRefresh: true  });
              console.log("responseresponseresponseresponse ",response);
              
                if (response.code == 200) {
                  if (response.data) {
                    if (response.data.data) {

                    }
                    setListAttribute(response.data)
                  }
              }
          break;
          case ECOMMERCE_KEY.SHOPEE:

          getBrands(value[value.length - 1]);
            response = await ActionsWeb.ShopeeAtttributesNoDispatchRequest({ code: codeTitok, cateId: value[value.length - 1], isRefresh: true  });
            console.log("responseresponseresponseresponse ",response);
            
              if (response.code == 200) {
                if (response.data) {
                  if (response.data.data) {

                  }
                  setListAttribute(response.data)
                }
            }
        break;
        default:
          break;
      }
     
     
    }
    // setCateTitok(value);
    setLoadingAtribute(false);
   
  }

  
  useEffect(() => {
    if(visible){
      let checkLength=false;
      
      const checkrows=[];
      if(selectedRows){
        selectedRows.map((value, index) => {
        if(value.title.length<25 || value.title.length>255){
          checkrows.push({
            code:value.code,
            title:value.title,
            isLength:true,
          });
          checkLength=true;
        }
         
        })
      }
      setcheckPros(checkrows);
      console.log("selectedRowsselectedRowsselectedRows",checkLength);
      setcheckPro(checkLength)
    }
   
    return () => {  

    };
  }, [visible,selectedRows]);
  const addAttribute = (e, index) => {
    e.preventDefault();

    const refinput = refinputs.current[index];
    const dataAtt = ListAttribute[index];


    let valuesDataAtt = dataAtt.children;
    const newName = refinput.input.value;
    if (newName) {
      if (!valuesDataAtt) {
        valuesDataAtt = [];
      }
      valuesDataAtt.push({ id: "new" + index, name: newName })
    }
    dataAtt.children = valuesDataAtt;
    ListAttribute[index] = dataAtt;

    setListAttribute([...ListAttribute])
    setNameNewAttribute({ ...NameNewAttribute, [index]: "" })

    // dispatch(ActionsWeb.StoreAttributeProductsRequest({
    //     "name": NewName,
    //     "code": "",
    //     "parent_id": dataAtt.id,
    //     "value": 0,
    //     "rank": 1,
    //     "deleted": "0",
    //     "hidden": "0",
    //     "description": ""
    // }));
  };
  const onChangeRadio = (e) => {
    const target = e.target;

    setCodeTitok(target.value)
  }


  
  const getBrands = async(cateId)=>{

    console.log("cateId ", cateId);
    const response=await ActionsWeb.ShopeeBrandsNoDispatchRequest({ code: codeTitok, cateId: cateId, isRefresh: true })
   
    if (response.code == 200) {
      if (response.data) {
        // if (response.data.data) {
        //   targetOption.children = response.data.data;
        // }
      }
    }
   
    if (response.code == 200) {
      if (response.data) {
       

        setTitokBrands(response.data);
        // setIsNotReload(true);
      }
    }
  }
  return (
    <>
      <Modal
                    title="Các sản phẩm không hợp lệ khi đồng bộ"
                    visible={checkPro}
                    onOk={()=>setcheckPro(false)}
                    onCancel={()=>setcheckPro(false)}
                >
                  <p>Vui lòng cập nhật sản phẩm trước khi đồng bộ để tránh lỗi</p>
                  <div style={{ height:"400px",overflowY:"scroll" }}>
                  {checkPros && checkPros.map((value, index) => {
                    return <div className='d-flex card p-2 mb-2'>
                       <span className='fw-bold me-2'>Mã: {value.code} </span>
                       <span className='fw-bold me-2' >Tên: {value.title} </span>
                      {value.isLength &&   <span className='text-danger' style={{ fontSize:"12px" }}>Độ dài tên không hợp lệ (độ dài phải lớn hơn 25 ký tự và nhỏ hơn 255 ký tự)</span> }
                       
                    </div>
                    

                  })}
                  </div>
                 
                    
                </Modal>
      <Drawer
        title={<div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6"><span>{itemSyncMutiple?.name}</span></div>
          <div className="col-lg-6 col-md-6 col-sm-6 flex_" style={{ justifyContent: "flex-end" }}> <div dangerouslySetInnerHTML={{ __html: itemSyncMutiple?.icon }} className='icon-mini' /></div>
        </div>} placement="right"
        onClose={() => { SetisVisible(false) }}
        visible={visible}
        size={"large"}
      >
        <Form name="formSync"   layout={"vertical"} ref={formSyncRef} onFinish={onFinishFormSync} >
          <Form.Item name={"checkSync"}>
          <Radio.Group options={(checkSync || []).map((item, index) => {
            return {
              value: item.id_,
              label: item.name,
            }
          })} onChange={onChangeRadio} value={codeTitok} optionType="button"
            buttonStyle="solid" />
            {/* <Checkbox.Group options={checkSync.map((value, index) => {
              return {
                label: value.name,
                value: value.id_,
                // disabled: isCheckEcom.includes(el.code)
            }
            })} defaultValue={[1]} /> */}
          </Form.Item>

          <Form.Item label="Danh mục" name={"cateEcom"} rules={[
            {
              required: true,
              message: "Danh mục là bắt buộc"
            },
          ]}>
            <Cascader options={ListCategories} loadData={loadDataCate}  onChange={onChangeCascader}  placeholder="Chọn 1 danh mục" />

          </Form.Item>
          <Form.Item label={`Thuộc tính sản phẩm`} >
            <div style={{ display: "grid", gridTemplateColumns: "repeat(2,1fr)" }}>
              {ListAttribute && ListAttribute.length > 0 && ListAttribute.map((value, index) => {


                return <Form.Item label={value.name} name={`attributeEcom-${index}`}
                //   rules={[
                //     {
                //         required: true,
                //         message: "Thuộc tính là bắt buộc"
                //     },
                // ]}
                >

                  <Select

                    showSearch
                    placeholder={value.name}
                    options={(value.children || []).map((d) => ({
                      value: d.id,
                      label: d.name,
                    }))}
                    onChange={(e) => setvalueAttribute(e, index)}
                    dropdownRender={(menu) => (
                      <>
                        {menu}
                        <Divider
                          style={{
                            margin: '8px 0',
                          }}
                        />
                        <Space
                          style={{
                            padding: '0 8px 4px',
                          }}
                        >
                          <Input
                            placeholder="Nhập mới"
                            value={NameNewAttribute[index]}
                            // onChange={(e) => { onNameAttribute(e, index) }}
                            ref={(el) => (refinputs.current[index] = el)}
                          />
                          <Button type="text" icon={<PlusOutlined />} onClick={(e) => addAttribute(e, index)}>
                            Thuộc tính mới
                          </Button>
                        </Space>
                      </>
                    )}
                  />

                </Form.Item>

              })}

              {LoadingAtribute &&  <Skeleton />}
            </div>

            
          </Form.Item>
          <Form.Item label="Thương hiệu" name={"brandEcom"}  >
            <Select

              showSearch
              placeholder="Thương hiệu"
              options={([{ id: "", name: "không" }, ...TitokBrands] || []).map((d) => ({
                value: d.id,
                label: d.name,
              }))}
            
            />

        </Form.Item>
          <Form.Item label="Vận chuyển" name={"logistic"} 
        // initialValue={setEditTitok?.pro?.brands_id ? parseInt(setEditTitok?.pro?.brands_id) : ""}
        >

          
          <Select
            showSearch
            placeholder="Vận chuyển"
            options={(LogisticList).map((d,index) => ({
              value: d.logistics_channel_id,
              label: d.logistics_channel_name,
              key: index,
            }))}
           
          />

        </Form.Item>
          <Form.Item noStyle style={{ margin: 0 }} >
            <Button type="primary" htmlType="submit" loading={Loading}>Đồng bộ</Button>
          </Form.Item>
        </Form>
      </Drawer>

    </>
  )
}
export default SyncMultipleProduct;