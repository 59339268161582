import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import * as ActionsWeb from "../../../../actions/indexWeb";
import "./style.css";
import * as LINK from "../../../../constants/Links";
import { Link } from "react-router-dom";
import { Input, Table, Pagination, Modal, message, Button, Form, Switch as SwitchA, Card, Drawer, Radio, Select } from 'antd';
import { replaceThous, getParamsUrl } from "./../../../../service";
import * as Colors from "../../../../constants/Colors";

import * as Services from "./../../../../service";
import {
    FolderViewOutlined,
    DeleteOutlined, GlobalOutlined, AppstoreOutlined, UnorderedListOutlined,
    EditOutlined, PlusCircleOutlined, SearchOutlined
} from '@ant-design/icons';
import { META } from "../../../../utils/constant";
import SettingEcommerceMobile from "./settingEcommerceMobile";
import LoadingSmile from "./../../../../components/loadingSmile/loadingSmile";
class Index extends Component {

    constructor(props) {
        super(props);
        this.formSearchRef = createRef(null)
        this.formRef = createRef(null)
        this.state = {

            ListEcommerceConfig: [],
            meta: {
                ...META
            },
            data: [],
            rowSelection: {
                onChange: (selectedRowKeys, selectedRows) => {
                    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                },
                getCheckboxProps: (record) => ({
                    disabled: record.name === 'Disabled User',
                    // Column configuration not to be checked
                    name: record.name,
                }),
            },
            columns: [
                {
                    title: 'STT',
                    dataIndex: 'key',


                },
                {
                    title: 'Tên',
                    dataIndex: 'name',
                    filterMode: 'tree',
                    filterSearch: true,

                    onFilter: (value, record) => record.name.startsWith(value),
                    sorter: (a, b) => a.name - b.name,
                },
                {
                    title: 'Code',
                    dataIndex: 'code',
                    sorter: (a, b) => a.code - b.code,

                },


                {
                    title: 'ngày tạo',
                    dataIndex: 'created_at',
                    sorter: (a, b) => a.created_at - b.created_at,


                },
                {
                    title: 'Ngày cập nhật',
                    dataIndex: 'updated_at',
                    sorter: (a, b) => a.updated_at - b.updated_at,

                },
                {
                    title: 'Hành động',
                    dataIndex: 'action',
                    width: "20%",
                    render: (text, record, index) => (
                        <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }} >{text}
                            <span className="__action_">
                                <button className="reset_btn btn_edit btn__" onClick={() => this.editRows(index)}> <EditOutlined /> </button>
                                <button className="reset_btn btn_delete btn__" onClick={() => { this.delete(index) }}><DeleteOutlined /></button>
                                <button className="reset_btn btn_view btn__" onClick={() => { this.editRows(index) }}> <FolderViewOutlined /></button>

                            </span>
                        </div>
                    ),
                },
            ],
            pagination: {
                limit: 2,
                page: 1,
                column: "id",
                sort: "DESC",
            },
            record: {
                deleted: 0
            },
            visible: false,
            confirmLoading: false,
            modalText: "",
            StoreEcommerceConfig: {},
            isloading: false,
            isLoadingSearch: false,
            isLoadingRefresh: false,
            isShowList: false,
            isNewList: false,
            visibledetail: false,
            ListEcommerceType: [],
            listSuplier: []
        }

    }

    setVisibleDetail = (type) => {
        this.setState(state => {
            return {
                visibledetail: type
            }
        })
    }
    handleOkDetail = (e) => {
        this.setState(state => {
            return {
                // record: e,
                isreset: false
            }
        }, () => {
            this.setVisibleDetail(true);
        })


    };

    handleCancelDetail = () => {
        this.setState(state => {
            return {
                isreset: true
            }
        }, () => {
            this.setVisibleDetail(false);
        })
        // console.log('Clicked cancel button');

    };
    SetisShowList = (type) => {
        localStorage.setItem("isShowList", !type ? 0 : 1)
        this.setState(state => {
            return {
                isShowList: type
            }
        })
    }
    setVisible = (type) => {
        this.setState(state => {
            return {
                visible: type
            }
        })
    }
    setConfirmLoading = (type) => {
        this.setState(state => {
            return {
                confirmLoading: type
            }
        })
    }
    setModalText = (type) => {
        this.setState(state => {
            return {
                modalText: type
            }
        })
    }
    showModalDelete = () => {
        this.setVisible(true);
    };

    handleOkDelete = () => {
        let { record } = this.state
        this.setModalText('Đang xóa');
        this.setConfirmLoading(true);
        this.props.DeletedEcommerceConfigRequest(record, record.id ? record.id : "")
        this.setState(state => {
            return {
                isNewList: true,
                meta: { ...state.meta, current_page: 1 }
            }
        });
    };

    handleCancel = () => {
        // console.log('Clicked cancel button');
        this.setVisible(false);
    };
    onChangeTable = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    };

    SetisLoading = (type) => {
        this.setState(state => {
            return {
                isloading: type
            }
        })
    }
    SetisLoadingSeach = (type) => {
        this.setState(state => {
            return {
                isLoadingSearch: type
            }
        })
    }
    componentDidMount = () => {
        this.getSuplier();
        const isshowList = localStorage.getItem("isShowList")

        this.SetisShowList((1 * isshowList) === 0 ? false : true)
        const urlParams = getParamsUrl();
        if (urlParams.s) {
            this.formSearchRef.current.setFieldsValue({ search: urlParams.s })
        }


        this.props.ListEcommerceConfigRequest({
            limit: this.state.meta.per_page * 10,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort, s: urlParams.s
        }, { data: { code: urlParams.s, } });

        this.props.ListEcommerceTypeRequest({
            limit: this.state.meta.per_page * 10,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort, s: urlParams.s
        });

    }
    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.Web.StoreEcommerceConfig && nextProps.Web.StoreEcommerceConfig !== prevState.StoreEcommerceConfig) {
            return {
                StoreEcommerceConfig: nextProps.Web.StoreEcommerceConfig,
                ListEcommerceConfig: nextProps.Web.ListEcommerceConfig,
                ResfeshEcommerceConfig: nextProps.Web.ResfeshEcommerceConfig,
                isLoadingRefresh: false,


            };

        }
        if (nextProps.Web.ListEcommerceConfig && nextProps.Web.ListEcommerceConfig !== prevState.ListEcommerceConfig) {
            return {
                ListEcommerceConfig: nextProps.Web.ListEcommerceConfig,

            };

        }
        if (nextProps.Web.ResfeshEcommerceConfig && nextProps.Web.ResfeshEcommerceConfig?.id !== prevState.ResfeshEcommerceConfig?.id) {

            return {
                ResfeshEcommerceConfig: nextProps.Web.ResfeshEcommerceConfig,
                isLoadingRefresh: false,

            };

        }
        if (nextProps.Web.ListEcommerceType && nextProps.Web.ListEcommerceType.data !== prevState.ListEcommerceType) {

            return {
                ListEcommerceType: nextProps.Web.ListEcommerceType.data,

            };

        }


        return null;


    }

    onChangetext = (e, type, group, isNumber) => {
        let target = e.target;
        if (target) {
            let value = target.value;
            if (isNumber) {
                value = replaceThous(value)
            }
            let name = target.name;

            this.setState(state => {
                return {
                    [group]: { ...state[group], [name]: value }
                }
            })
        }
        else {

            if (isNumber) {
                e = replaceThous(e)
            }
            this.setState(state => {
                return {
                    [group]: { ...state[group], [type]: e }
                }
            })
        }

    }

    componentDidUpdate(prevProps, prevState) {

        if (this.state.StoreEcommerceConfig !== prevState.StoreEcommerceConfig) {
            let { StoreEcommerceConfig } = this.state;
            console.log("StoreEcommerceConfigStoreEcommerceConfig ", StoreEcommerceConfig);

            try {
                if (StoreEcommerceConfig.code === 200) {
                    if (StoreEcommerceConfig.message && typeof StoreEcommerceConfig.message === "string") {
                        message.success(StoreEcommerceConfig.message)
                        this.setState(state => {
                            return {
                                record: {

                                    hidden: 0,
                                    deleted: 0,
                                    visibledetail: false
                                }
                            }
                        })
                    } else {

                        message.error("Có lỗi!")
                    }
                    const urlParams = getParamsUrl();
                    this.formSearchRef.current.setFieldsValue({ search: urlParams.s })

                    this.props.ListEcommerceConfigRequest({
                        limit: this.state.meta.per_page,
                        page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort, s: urlParams.s
                    }, { data: { code: urlParams.s, } });

                }
                else {
                    if (StoreEcommerceConfig.message && typeof StoreEcommerceConfig.message === "string") {

                        message.error(StoreEcommerceConfig.message)
                    } else {
                        for (let x in StoreEcommerceConfig.message) {
                            let mess = StoreEcommerceConfig.message[x];
                            for (let y in mess) {
                                if (mess[y]) {
                                    message.error(mess[y])
                                }

                            }
                        }
                        // message.error("Có lỗi!")
                    }

                }

            } catch (error) {
                message.error("Có lỗi!")
                this.SetisLoading(false)
            }
            this.SetisLoading(false)
            this.setConfirmLoading(false);
            this.props.ResetStoreEcommerceConfig()
            this.handleCancel();
        }
        if (this.state.ListEcommerceConfig !== prevState.ListEcommerceConfig) {
            this.SetisLoadingSeach(false)

            this.data();
        }

        if (this.state.ResfeshEcommerceConfig !== prevState.ResfeshEcommerceConfig) {
            this.props.ListEcommerceConfigRequest({
                limit: this.state.meta.per_page * 10,
                page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort, s: ""
            }, { data: {} });
        }
    }
    setColumn_ = (filters) => {
        this.setState(state => {
            return {
                columns: [
                    {
                        title: 'STT',
                        dataIndex: 'key',


                    },
                    {
                        title: 'Code',
                        dataIndex: 'code',
                        sorter: (a, b) => a.code - b.code,

                    },
                    {
                        title: 'ID',
                        dataIndex: 'id_',
                        sorter: (a, b) => a.code - b.code,

                    },
                    // {
                    //     title: 'auth_code',
                    //     dataIndex: 'auth_code',
                    //     sorter: (a, b) => a.code - b.code,
                    //     render: (text, record, index)=>{
                    //         return Services.CutString(text, 20, false)
                    //     }

                    // },
                    // {
                    //     title: 'app_secret',
                    //     dataIndex: 'app_secret',
                    //     sorter: (a, b) => a.code - b.code,
                    //     render: (text, record, index) =>
                    //     {
                    //         return Services.CutString(text, 20, false)
                    //     }
                    // },
                    // {
                    //     title: 'app_key',
                    //     dataIndex: 'app_key',
                    //     sorter: (a, b) => a.code - b.code,
                    //     render: (text, record, index) =>
                    //     {
                    //         return Services.CutString(text, 20, false)
                    //     }
                    // },
                    {
                        title: 'code_shop',
                        dataIndex: 'code_shop',
                        sorter: (a, b) => a.code - b.code,
                        render: (text, record, index) => {
                            return Services.CutString(text, 20, false)
                        }
                    },
                    {
                        title: 'Tên',
                        dataIndex: 'name',
                        filterMode: 'tree',
                        filterSearch: true,
                        filters: filters,
                        onFilter: (value, record) => {
                            return record.name.startsWith(value)

                        },
                        sorter: (a, b) => a.name - b.name,
                    },


                    {
                        title: 'Active',
                        dataIndex: 'active',
                        width: "10%",
                        onFilter: (value, record) => {

                            return record.active === value

                        },

                        sorter: (a, b) => a.active > b.active,
                        render: (text, record, index) => {

                            return <SwitchA defaultChecked={!Services.convertToBoolean(record.active)} onChange={(e) => { this.updateRecord(e, record) }}
                                loading={this.state.isloading} className={"switch_20"} />
                        },

                    },


                    {
                        title: 'Hành động',
                        dataIndex: 'action',
                        width: "20%",
                        render: (text, record, index) => (
                            <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }} >{text}
                                <span className="">
                                    <button className="reset_btn btn_edit btn__" onClick={() => this.editRows(record)} >
                                        <EditOutlined style={{ color: Colors.colorEdit }} /> </button>
                                    <button className="reset_btn btn_delete btn__" onClick={() => { this.deleteModal(record) }}><DeleteOutlined style={{ color: Colors.colorDelete }} /></button>
                                    <button className="reset_btn btn_view btn__" onClick={() => { this.editRows(record) }}> <FolderViewOutlined style={{ color: Colors.colorView }} /></button>

                                </span>
                            </div>
                        ),
                    },
                ]
            }
        })
    }
    data = () => {
        let { ListEcommerceConfig } = this.state;

        if (ListEcommerceConfig.length <= 0) {
            return;
        }
        let data = [];
        let listfil = [];
        let meta = {
            ...this.state.meta,
            current_page: ListEcommerceConfig.current_page,
            first_page_url: ListEcommerceConfig.first_page_url,
            from: ListEcommerceConfig.from,
            last_page: ListEcommerceConfig.last_page,
            last_page_url: ListEcommerceConfig.last_page_url,
            links: ListEcommerceConfig.links,
            next_page_url: ListEcommerceConfig.next_page_url,
            path: ListEcommerceConfig.path,
            per_page: ListEcommerceConfig.per_page,
            prev_page_url: ListEcommerceConfig.prev_page_url,
            to: ListEcommerceConfig.to,
            total: ListEcommerceConfig.total,
        };
        ListEcommerceConfig.data.map((item, index) => {
            listfil.push({
                text: item.name,
                value: item.name,
            })
            item.key = index + 1
            data.push(item)

        });

        this.setColumn_(listfil);
        this.setState(state => {
            return {
                data: data,
                meta: meta
            }
        }, () => {
            this.setState(state => {
                return {
                    isNewList: false,
                }
            });

        })
    }



    editRows = (record) => {
        console.log('====================================');
        console.log("recordrecord", record);
        console.log('====================================');
        this.setState(state => {
            return {
                record: record,
                visibledetail: true
            }
        }, () => {
            this.formRef.current.setFieldsValue({ ...record })
        })

    }
    editRowsMobile = (record) => {
        console.log('====================================');
        console.log("recordrecord", record);
        console.log('====================================');

        this.setState(state => {
            return {
                record: record,
                visibledetail: true
            }
        }, () => {
            this.formRef.current.setFieldsValue({ ...record })
        })

    }
    addnew = (record = "") => {


        this.setState(state => {
            return {
                record: { name: "", deleted: 0 }
            }
        })
        this.formRef.current.resetFields();
    }
    deleteModal = (record) => {
        this.setState(state => {
            return {
                record: { ...record, deleted: 1 }

            }
        }, () => {
            this.setModalText("Bạn Có chắc muốn xóa!")
            this.setVisible(true)
        })
        // this.props.DeleteCategoryRequest(record, record.id ? record.id : "")
    }
    OnPagination = (page, pageSize) => {
        this.props.ListEcommerceConfigRequest({
            limit: pageSize,
            page: page, column: this.state.meta.column, sort: this.state.meta.sort
        }, { data: {} });
    }
    onSearch = (values) => {
        this.SetisLoadingSeach(true)
        this.props.ListEcommerceConfigRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort, s: values.search
        }, { data: { code: values.search, } });
        if (values.search) {
            const searchParams = new URLSearchParams({ s: values.search }).toString();

            this.props.history.push(`${LINK.WEB_SETTINGS_ECOMMERCE}?${searchParams}`);
            this.setState(state => {
                return {
                    isNewList: true
                }
            })
        }


    }
    updateRecord = (e, record) => {
        // const tmp = { ...record, hidden: !e ? 1 : 0 }

        this.SetisLoading(true)
        this.props.UpdateActiveEcommerceConfigRequest({ id: record.id, active: !Services.convertToBoolean(e) });
        this.setState(state => {
            return {
                isNewList: true
            }
        })
    }

    onFinishForm = (values) => {
        const { record } = this.state;
        const record_ = { ...record, ...values }
        this.SetisLoading(true)
        this.props.StoreEcommerceConfigRequest(record_, record_.id ? record_.id : "")
        this.setState(state => {
            return {
                isNewList: true
            }
        })
    }
    refreshToken = (item) => {
        this.setState(state => {
            return {
                isLoadingRefresh: true,
                isNewList: true
            }
        })
        this.props.refreshTokenEcommerceConfigRequest(item.id);
    }

    getSuplier = async () => {


        const response = await ActionsWeb.SearchCustomersNoDispatchRequest({
            limit: this.state.meta.per_page * 100,
            page: this.state.meta.current_page, column: "name", sort: "ASC"
        });
        console.log(response);
        if (response.code == 200) {
            if (response.data) {

                if (response.data.data) {
                    this.setState(state => {
                        return {
                            listSuplier: response.data.data
                        }
                    })

                }
            }
        }
    }

    changeSuplier = (e) => {

        const customer = this.state.listSuplier.filter((value, index) => {
            return value.id == e;
        })
        if (customer[0]) {
            if (this.formRef.current) {
                this.formRef.current.setFieldsValue({ customer_name: customer[0].name, customer_code: customer[0].code, customer_taxcode: customer[0].taxcode, customer_address: customer[0].address })
                // const formvalue= formRef.current.getFieldsValue();
                if (customer[0].name) {
                    // formRef.current.setFieldsValue({ note: "ba " + suplier[0].name })
                }
            }
        }


    }
    render() {
        let { routers } = this.props;

        return (
            <div className="main_content">

                {this.state.isLoadingRefresh && <div className="ovelay">

                    <div className="loading">
                        <LoadingSmile height={"100%"} width={"100%"} />
                    </div>
                </div>}
                <Modal
                    title="Thông báo"
                    visible={this.state.visible}
                    onOk={this.handleOkDelete}
                    confirmLoading={this.state.confirmLoading}
                    onCancel={this.handleCancel}
                >
                    <p>{this.state.modalText}</p>
                </Modal>
                <Drawer
                    title="Cấu hình" placement="right"
                    onClose={() => this.setVisibleDetail(false)}
                    visible={this.state.visibledetail}
                    size={"large"}
                >
                    <Form name="formadd" onFinish={this.onFinishForm} ref={this.formRef}
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 20 }}
                    >
                        <Form.Item label="Loại" name="ecommerce_type_id" rules={[
                            {
                                required: true,
                                message: "Loại là bắt buộc"
                            },
                        ]}>
                            <Radio.Group>
                                {this.state.ListEcommerceType && this.state.ListEcommerceType.map((item, index) => {
                                    return <Radio value={item.id}>{item.name}</Radio>
                                })}

                            </Radio.Group>
                        </Form.Item>
                        <Form.Item label="id" name="id_">
                            <Input placeholder="id" showCount />
                        </Form.Item>
                        <Form.Item label="region" name="region">
                            <Input placeholder="region" showCount />
                        </Form.Item>
                        <Form.Item label="seller_type" name="seller_type">
                            <Input placeholder="seller_type" showCount />
                        </Form.Item>
                        <Form.Item label="access_token_expire_in" name="access_token_expire_in">
                            <Input placeholder="access_token_expire_in" showCount />
                        </Form.Item>
                        <Form.Item label="refresh_token_expire_in" name="refresh_token_expire_in">
                            <Input placeholder="refresh_token_expire_in" showCount />
                        </Form.Item>
                        <Form.Item label="Tên" name="name" rules={[
                            {
                                required: true,
                                message: "Tên là bắt buộc"
                            },
                        ]}>
                            <Input placeholder="Tên" showCount maxLength={120} onChange={(e) => {
                                this.formRef.current.setFieldsValue({ code: Services.createSlug(e.target.value) })
                            }} />
                        </Form.Item>
                        <Form.Item label="Code" name="code" rules={[
                            {
                                required: true,
                                message: "Code là bắt buộc"
                            },
                        ]}>
                            <Input placeholder="code" showCount maxLength={120} />
                        </Form.Item>
                        <Form.Item label="app_key" name="app_key" rules={[
                            {
                                required: true,
                                message: "app_key là bắt buộc"
                            },
                        ]} >
                            <Input placeholder="app_key" />
                        </Form.Item>
                        <Form.Item label="auth_code" name="auth_code" rules={[
                            {
                                required: true,
                                message: "auth_code là bắt buộc"
                            },
                        ]}>
                            <Input placeholder="auth_code" />
                        </Form.Item>
                        <Form.Item label="app_secret" name="app_secret" rules={[
                            {
                                required: true,
                                message: "app_secret là bắt buộc"
                            },
                        ]}>
                            <Input placeholder="app_secret" />
                        </Form.Item>
                        <Form.Item label="code_shop" name="code_shop" rules={[
                            {
                                required: true,
                                message: "code_shop là bắt buộc"
                            },
                        ]} >
                            <Input placeholder="code_shop" />
                        </Form.Item>
                        <Form.Item label="cipher" name="cipher">
                            <Input placeholder="cipher" />
                        </Form.Item>
                        <Form.Item label="access_token" name="access_token">
                            <Input placeholder="access_token" />
                        </Form.Item>
                        <Form.Item label="refresh_token" name="refresh_token">
                            <Input placeholder="refresh_token" />
                        </Form.Item>
                        <Form.Item label="khách hàng hoạch toán" name="customer_id">
                            <Select
                                showSearch
                                style={{ width: '100%' }}
                                options={(this.state.listSuplier || []).map((value, index) => {
                                    return {
                                        value: value.id,
                                        label: value.code + "-" + value.name
                                    }
                                })}

                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                placeholder="khách hàng hoạch toán"
                                // allowClear
                                // multiple
                                treeDefaultExpandAll
                                onChange={this.changeSuplier}

                            />
                        </Form.Item>
                        <Form.Item label="Khách hàng" className='m-0'>
                            <Input.Group compact>
                                <Form.Item name="customer_code" style={{ width: "50%" }}>
                                    <Input placeholder="Mã khách hàng" />
                                </Form.Item>
                                <Form.Item name="customer_taxcode" style={{ width: "50%" }}>
                                    <Input placeholder="Mã số Thuế" />
                                </Form.Item>

                            </Input.Group>
                        </Form.Item>
                        <Form.Item label="Tên KH" name="customer_name">
                            <Input placeholder="Tên KH" />
                        </Form.Item>
                        <Form.Item label="Địa chỉ KH" name="customer_address">
                            <Input placeholder="Địa chỉ KH" />
                        </Form.Item>
                        <Form.Item>

                            <Button type="primary" loading={this.state.isloading} style={{ marginRight: "10px" }} htmlType="submit">Cập nhật</Button>
                        </Form.Item>
                    </Form>
                </Drawer>

                <div className="table_w" >
                    {/* <h1 style={{ color: Colors.pop2[3] }}>Vùng</h1> */}
                    <Card title={<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <button className="reset_btn btn_edit btn__" onClick={() => { this.SetisShowList(!this.state.isShowList) }}
                                style={{ display: "flex", justifyContent: "center", alignContent: "center" }}
                            >
                                {this.state.isShowList && <AppstoreOutlined style={{ color: Colors.pop2[3] }} />}
                                {!this.state.isShowList && <UnorderedListOutlined style={{ color: Colors.pop2[3] }} />}
                            </button>
                            <span style={{ marginLeft: "10px", color: Colors.pop2[3] }}>Thương mại điện tử</span>
                        </div>
                        <Button type="primary" size="small" onClick={() => this.setVisibleDetail(true)}><PlusCircleOutlined /></Button>
                    </div>} bordered={false}
                        style={{ width: "100%", }}
                        bodyStyle={{
                            padding: "10px",
                        }}
                        headStyle={{
                            backgroundColor: Colors.colorgrey,
                            display: "flex",
                            minHeight: "30px",
                            borderRadius: "3px"
                        }}
                    >
                        <div className="form__half row">
                            <div className={(Services.checkScreenSize().width < 600 || !this.state.isShowList) ? "left_half col-lg-12 col-md-12 col-sm-12 " : "left_half col-lg-12 col-md-12 col-sm-12 "}>
                                <div className="btn_action_list">
                                    <div className="left_">
                                        <Form name="formsearch" ref={this.formSearchRef} onFinish={this.onSearch} style={{
                                            padding: "0px",
                                            width: "100%",
                                            display: "flex"
                                        }}>


                                            <Form.Item name="search" noStyle style={{
                                                width: "80%"
                                            }}>
                                                <Input placeholder="tìm kiếm" name="search" id="search"

                                                    style={{
                                                        width: "80%"
                                                    }}

                                                />
                                            </Form.Item>
                                            <Form.Item noStyle style={{
                                                width: "20%"
                                            }}>
                                                <Button type="primary" htmlType="submit" style={{ width: "20%" }} icon={<SearchOutlined />} loading={this.state.isLoadingSearch} />
                                            </Form.Item>

                                        </Form>
                                    </div>


                                </div>
                                {(Services.checkScreenSize().width < 600 || !this.state.isShowList) && <SettingEcommerceMobile lists={this.state.data} loading={this.state.loading}
                                    updateRecord={this.updateRecord}
                                    editRows={this.editRowsMobile}
                                    deleteModal={this.deleteModal}
                                    duplicateItem={this.duplicateItem}
                                    current_page={this.state.meta.current_page}
                                    per_page={this.state.meta.per_page}
                                    last_page={this.state.meta.last_page}
                                    OnPagination={this.OnPagination}
                                    isNewList={this.state.isNewList}
                                    refreshToken={this.refreshToken}
                                />}
                                {Services.checkScreenSize().width >= 600 && this.state.isShowList && <><Table
                                    rowClassName={(record, index) => {
                                        return "__row_";
                                    }}
                                    rowSelection={{
                                        ...this.state.rowSelection,
                                    }}
                                    columns={this.state.columns}
                                    dataSource={this.state.data}
                                    pagination={false}
                                    onChange={this.onChangeTable}

                                    style={{ paddingBottom: "50px" }}
                                />
                                    <div className="pagination_">
                                        <Pagination total={this.state.meta && this.state.meta.total ? this.state.meta.total : 10} showSizeChanger onChange={this.OnPagination} />

                                    </div>
                                </>
                                }
                            </div>
                            {/* {Services.checkScreenSize().width >= 600 && this.state.isShowList && <div className="right_half col-lg-5 col-md-12 col-sm-12">
                                <Form name="formadd" onFinish={this.onFinishForm} ref={this.formRef}
                                    labelCol={{ span: 4 }}
                                    wrapperCol={{ span: 20 }}
                                >
                                    <Form.Item label="Tên" name="name" rules={[
                                        {
                                            required: true,
                                            message: "Tên tỉnh thành là bắt buộc"
                                        },
                                    ]}>
                                        <Input placeholder="Tên" showCount maxLength={120} onChange={(e) =>
                                        {
                                            this.formRef.current.setFieldsValue({ code: Services.createSlug(e.target.value) })
                                        }} />
                                    </Form.Item>
                                    <Form.Item label="Code" name="code" rules={[
                                        {
                                            required: true,
                                            message: "Codelà bắt buộc"
                                        },
                                    ]}>
                                        <Input placeholder="code" showCount maxLength={120} />
                                    </Form.Item>


                                    <Form.Item >


                                        <Button type="primary" loading={this.state.isloading} style={{ marginRight: "10px" }} htmlType="submit">Cập nhật</Button>
                                    </Form.Item>
                                </Form>

                            </div>
                            } */}
                        </div>
                    </Card>
                </div>
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {

        Web: state.Web
    };
};
const mapDispatchToProps = (dispatch, Props) => {
    return {

        ListEcommerceConfigRequest: (body, data) => {
            dispatch(ActionsWeb.ListEcommerceConfigRequest(body, data));
        },
        ListEcommerceTypeRequest: (body, data) => {
            dispatch(ActionsWeb.ListEcommerceTypeRequest(body, data));
        },
        StoreEcommerceConfigRequest: (data, id) => {
            dispatch(ActionsWeb.StoreEcommerceConfigRequest(data, id));
        },
        ResetStoreEcommerceConfig: () => {
            dispatch(ActionsWeb.ResetStoreEcommerceConfig());
        },
        refreshTokenEcommerceConfigRequest: (id) => {
            dispatch(ActionsWeb.refreshTokenEcommerceConfigRequest(id));
        },
        DeletedEcommerceConfigRequest: (data) => {
            dispatch(ActionsWeb.DeletedEcommerceConfigRequest(data));
        },
        UpdateActiveEcommerceConfigRequest: (data) => {
            dispatch(ActionsWeb.UpdateActiveEcommerceConfigRequest(data));
        },

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);