import React, { useEffect, useRef, useState } from 'react'
import { Button, Card, Checkbox, DatePicker, Form, Input, message, Modal, Popover, Select, Table, Tooltip, TreeSelect } from 'antd';
import * as LINK from "../../../../constants/Links";

import "./../style.css"
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { META } from '../../../../utils/constant';
import { useDispatch, useSelector } from 'react-redux';
import { ListTimeKeepingRequest, CreateDataTimeKeepingRequest, SetTimeKeepingRequest } from '../../../../actions/indexWeb';
import { DATA_GROUP_ACCOUNT, DATA_TYPE_ACCOUNTING } from '../../../../constants/DefaultData';
import {
  FolderViewOutlined, AppstoreOutlined,
  DeleteOutlined, PlusCircleOutlined, ExclamationCircleOutlined,
  EditOutlined, UnorderedListOutlined, SearchOutlined
} from '@ant-design/icons';
import * as Colors from "../../../../constants/Colors";
import moment from 'moment';
import { convertDayOfWeekToVietnamese, formatMN1 } from '../../../../service';

const Index = () => {

  const dispatch = useDispatch()
  const { Web } = useSelector(state => state);
  const [meta, setMeta] = useState({ ...META });
  const [listTimeKeeping, setListTimeKeeping] = useState([]);
  const [listdays, setListdays] = useState([]);
  const [listTotal, setListTotal] = useState([]);
  const [listTotalAll, setListTotalAll] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const [record, setRecord] = useState(null);
  const [Listdate, setdate] = useState([]);
  const [Listday, setday] = useState([]);
  const formSearchRef = useRef(null);
  const tableRef = useRef(null);

  const [currentMonth, setCurrentMonth] = useState(moment().startOf('month'));
  const [currentYear, setCurrentYear] = useState(moment().startOf('year'));

  const history = useHistory();

  const getListTimeKeeping = async (values) => {
    const response = await ListTimeKeepingRequest({
      limit: meta.per_page * 100,
      page: meta.current_page, column: "code", sort: "ASC"
    }, { data: { ...values } });
    if (response.code == 200) {
      if (response.data) {
        setListTimeKeeping(response.data);
        setListdays(response.days);
        setListTotal(response.totals)
        setListTotalAll(response.totalsAll)
      }
    }


  }
  useEffect(() => {
    const filteredvalues = { date: currentMonth.format('YYYY-MM-DD') }
    getListTimeKeeping(filteredvalues);
    filteredvalues.date = moment(filteredvalues.date).format('MM-YYYY');
    const searchParams = new URLSearchParams(filteredvalues).toString();
    history.push(`${LINK.WEB_ACCOUNTANT_TIMEKEEPING}?${searchParams}`);
    return () => {

    };
  }, []);

  useEffect(() => {
    renderDayOfMonth();
    return () => {

    };
  }, [listdays]);
  const onSearch = async (values) => {
    const filteredValues = Object.fromEntries(
      Object.entries(values).filter(([key, value]) => value !== undefined)
    );
    filteredValues.date = filteredValues.date.format('YYYY-MM-DD')
    getListTimeKeeping(filteredValues);
    const month = moment(filteredValues.date).startOf("month");
    const year = moment(filteredValues.date).startOf("year");
    setCurrentMonth(month);
    setCurrentYear(year);
    filteredValues.date = moment(filteredValues.date).format('MM-YYYY');
    const searchParams = new URLSearchParams(filteredValues).toString();
    history.push(`${LINK.WEB_ACCOUNTANT_TIMEKEEPING}?${searchParams}`);
  }
  const confirmCreateData = (e) => {
    Modal.confirm({
      title: 'Xác nhận',
      icon: <ExclamationCircleOutlined />,
      content: 'Tạo dữ liệu ' + e + " Tháng mới nhất?",
      okText: 'Đồng ý',
      cancelText: 'Bỏ qua',
      onOk: () => {
        createTimekeeping(e);

      }
    });
  };
  const createTimekeeping = async (e) => {
    setIsloading(true);
    console.log("eeeee ", e);

    const response = await CreateDataTimeKeepingRequest({ number: e });
    setIsloading(false);
    if (response.code == 200) {
      if (response.message) {
        message.success(response.message);
      } else {
        message.success("Cập nhật thành công");
      }
    } else {
      if (response.message) {
        if (typeof response.message == "string") {
          message.error(response.message);
        } else {
          (Object.values(response.message) || []).map((value, index) => {
            message.error(value);
          })
        }

      } else {
        message.warning("Cập nhật không thành công");
      }
    }
  }

  const renderDayOfMonth = () => {
    const date = [];
    const day = [];
    console.log("listTimeKeepinglistTimeKeepinglistTimeKeeping", listTimeKeeping);
    if (listdays) {
      listdays.map((value, index) => {
        const formattedDate = moment(value.date).format('DD');
        date.push(formattedDate);
        day.push(convertDayOfWeekToVietnamese(value.day_of_week_number));
      })
    }

    setdate(date)
    setday(day)
    // return {date,day}
  }
  const confirmSetTime = (e, item, name) => {
    Modal.confirm({
      title: 'Xác nhận',
      icon: <ExclamationCircleOutlined />,
      content: e.target.checked ? 'Chấm công cho nhân viên ' + name + ' ngày ' + moment(item.date).format("DD/MM/YYYY") + "?" : 'Bỏ chấm công cho nhân viên ' + name + ' ngày ' + moment(item.date).format("DD/MM/YYYY") + "?",
      okText: 'Đồng ý',
      cancelText: 'Bỏ qua',
      onOk: () => {
        setTimeKeeping(e, item);

      }
    });
  };
  const setTimeKeeping = async (e, item) => {
    if (item?.id) {
      setIsloading(true);
      console.log("eeeee ", e);

      const response = await SetTimeKeepingRequest({ id: item?.id, checked: e.target.checked, date: item.date });
      setIsloading(false);
      if (response.code == 200) {
        if (response.message) {
          message.success(response.message);
        } else {
          message.success("Cập nhật thành công");
        }
      } else {
        if (response.message) {
          if (typeof response.message == "string") {
            message.error(response.message);
          } else {
            (Object.values(response.message) || []).map((value, index) => {
              message.error(value);
            })
          }

        } else {
          message.warning("Cập nhật không thành công");
        }
      }
    }

  }
  const resetFormSearch = () => {
    formSearchRef.current.resetFields();
    formSearchRef.current.submit();
  }
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const handleMouseDown = (e) => {


    // if (e.target.tagName != "TH") return;
    setIsDragging(true);
    setStartX(e.pageX - tableRef.current.offsetLeft);
    setScrollLeft(tableRef.current.scrollLeft);

  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - tableRef.current.offsetLeft;
    const walk = (x - startX) * 2; // Tốc độ cuộn (tăng hoặc giảm giá trị này tùy thích)
    tableRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
  };

  const renderTimekeeping = () => {
    const result = [];
    listTimeKeeping.map((depart, iDepart) => {
      result.push(<tr className='fw-bold'>
        <td colSpan={4} style={{ position: "sticky", zIndex: 100, left: 0 }} >{depart.department_name}</td>
        {listdays && listdays.map((item, i) => {

          return <td key={i}></td>
        })}
        <td className='text-center '>{formatMN1(depart?.total?.days)}</td>
        <td className='text-center '>{formatMN1(depart?.total?.hours)}</td>
        <td className='text-center '>{formatMN1(depart?.total?.minutes)}</td>
        <td className='text-center '>{(depart?.total?.daysOff)}</td>
        <td className='text-center '>{(depart?.total?.daysOffSunday)}</td>
        <td className='text-center '>{(depart?.total?.daysWorkSunday)}</td>

      </tr>)
      if (depart.employees) {
        for (const x in depart.employees) {
          const value=depart.employees[x];
          result.push(<tr key={iDepart+x}>
            <td className='text-center bg-white' style={{ position: "sticky", zIndex: 100, left: 0 }}>{iDepart + 1}</td>

            <td className='bg-white' style={{ position: "sticky", zIndex: 100, left: "20px" }}>{value.employee_code}</td>
            <td className='bg-white' style={{ position: "sticky", zIndex: 100, left: "120px" }}>{value.employee_name}</td>
            <td className='bg-white' style={{ position: "sticky", zIndex: 100, left: "318px" }}>{value.position_name}</td>
            {listdays && listdays.map((item, i) => {


              return <td key={iDepart + x+i} className='text-center ' style={{ zIndex: 0, }}>
                <Tooltip placement="bottom" title={value.dates?.[item.date]?.description} arrowPointAtCenter>
                  <Checkbox defaultChecked={value.dates?.[item.date]?.time != 0} onChange={(e) => confirmSetTime(e, value.dates?.[item.date], value.employee_name)}
                    disabled={value.dates?.[item.date]?.status == 0} />
                </Tooltip>
              </td>

            })}

            <td className='text-center ' style={{ zIndex: 0, }}>
              {value?.total?.days}
            </td>
            <td className='text-center ' style={{ zIndex: 0, }}>
              {formatMN1(value?.total?.hours)}
            </td>
            <td className='text-center ' style={{ zIndex: 0, }}>
              {formatMN1(value?.total?.minutes)}
            </td>
            <td className='text-center ' style={{ zIndex: 0, }}>
              {(value?.total?.daysOff)}
            </td>
            <td className='text-center ' style={{ zIndex: 0, }}>
              {(value?.total?.daysOffSunday)}
            </td>
            <td className='text-center ' style={{ zIndex: 0, }}>
              {(value?.total?.daysWorkSunday)}
            </td>
          </tr>)
        }
      }


    })

    return result;
  }
  return (
    <Card title={<div style={{ display: "flex", alignItems: "center" }}>
      <Link to={LINK.WEB_ACCOUNTANT}><Button size='small'><i className="fas fa-chevron-left"></i></Button></Link>
      <FolderViewOutlined style={{ color: Colors.pop2[3] }} />
      <span style={{ marginLeft: "10px", color: Colors.pop2[3] }} >Bảng chấm công</span>
      {/* <span style={{ marginLeft: "10px", color: tab == 2 ? Colors.pop2[3] : "#000", cursor: "pointer" }} onClick={() => setTab(2)}>Hóa đơn</span> */}
    </div>} bordered={false}
      style={{ width: "100%", margin: "0 auto" }}
      bodyStyle={{
        padding: "10px",
        // maxHeight: "90vh",
        // overflowY: "scroll"

      }}
      headStyle={{
        backgroundColor: Colors.colorgrey,
        display: "flex",
        minHeight: "30px",
        borderRadius: "3px"
      }}
    >
      <div className='w-100 row  bg-white'>

        <div className='col-lg-12 col-md-12 col-sm-12 '>
          <Form name="formsearch" onFinish={onSearch} ref={formSearchRef}
          // labelCol={{ span: 4 }}
          // wrapperCol={{ span: 20 }}
          >
            <Form.Item>
              <Input.Group compact>
                <Form.Item name="code" className='m-0'>
                  <Input placeholder="Mã" />
                </Form.Item>
                <Form.Item name="name" className='m-0'>
                  <Input placeholder="Tên" />
                </Form.Item>
                <Form.Item name="date" className='m-0' initialValue={currentMonth}>
                  <DatePicker picker="month" format={"MM/YYYY"} />
                </Form.Item>
                <Form.Item name="description" className='m-0'>
                  <Input placeholder="Mô tả" />
                </Form.Item>
                <Form.Item className='m-0'>
                  <div className="flex_">
                    <Button type="default" className='bg-warning' onClick={() => { resetFormSearch() }}>reset</Button>
                    <Button type="primary" loading={isloading} htmlType="submit"  ><SearchOutlined /></Button>
                    <Popover placement="bottom" title={"Tạo DL chấm công"} content={
                      <div>
                        <p style={{ cursor: "pointer" }} onClick={() => confirmCreateData(1)}>1 tháng</p>
                        <p style={{ cursor: "pointer" }} onClick={() => confirmCreateData(2)}>2 tháng</p>
                        <p style={{ cursor: "pointer" }} onClick={() => confirmCreateData(3)}>3 tháng</p>
                        <p style={{ cursor: "pointer" }} onClick={() => confirmCreateData(6)}>6 tháng</p>
                        <p style={{ cursor: "pointer" }} onClick={() => confirmCreateData(2)}>12 tháng</p>
                      </div>
                    } trigger="click">
                      <Button>Khỏi tạo dữ liệu</Button>
                    </Popover>
                  </div>
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Form>
        </div>
        <div className='col-lg-12 col-md-12 col-sm-12 '>
          <div className="table-responsive" style={{ height: "750px", cursor: "move" }} ref={tableRef}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseLeave}
          >
            <table className="table table-ledger table-sm table-hover" style={{ width: "max-content", minWidth: "100%" }} >
              <thead style={{ position: "sticky", top: 0, zIndex: 1000 }}>
                <tr>

                  <th className='p-1 text-center align-middle bg_main' width="20px" rowSpan={3} style={{ position: "sticky", zIndex: 100, left: 0 }}>
                    STT
                  </th>
                  <th className='p-1 text-center align-middle bg_main' rowSpan={3} width="100px" style={{ position: "sticky", zIndex: 100, left: "20px" }}> Mã NV</th>
                  <th className='p-1 text-center align-middle bg_main' rowSpan={3} width="200px" style={{ position: "sticky", zIndex: 100, left: "120px" }}>Tên NV</th>
                  <th className='p-1 text-center align-middle bg_main' rowSpan={3} width="100px" style={{ position: "sticky", zIndex: 100, left: "318px" }}>Chức vụ</th>
                  <th className='p-1 text-center align-middle' colSpan={listdays.length} style={{ zIndex: 0, }} >{`Tháng ${currentMonth.format("MM")} năm ${currentYear.format("YYYY")}`}</th>
                  <th className='p-1 text-center align-middle' rowSpan={3}>Tổng ngày</th>
                  <th className='p-1 text-center align-middle' rowSpan={3} >Tổng giờ</th>
                  <th className='p-1 text-center align-middle' rowSpan={3}>Tổng phút</th>
                  <th className='p-1 text-center align-middle' rowSpan={3}>Tổng ngày nghỉ</th>
                  <th className='p-1 text-center align-middle' rowSpan={3}>Tổng ngày nghỉ chủ nhật</th>
                  <th className='p-1 text-center align-middle' rowSpan={3}>Tổng đi làm chủ nhật</th>

                </tr>
                <tr>

                  {Listdate && Listdate.map((value, index) => {
                    return <th key={index} className='text-center' style={{ zIndex: 0, }}>
                      {value}
                    </th>
                  })}
                </tr>

                <tr>

                  {Listday && Listday.map((value, index) => {
                    return <th key={index} className={`text-center ${value === "Thứ bảy" ? "bg-info" : value === "Chủ nhật" ? "bg-danger" : ""}`} style={{ zIndex: 0, }}>
                      {value}
                    </th>
                  })}

                </tr>



              </thead>
              <tbody>
               {renderTimekeeping()}
                <tr className='bg_main_2 fw-bold text-white' style={{ position: "sticky", bottom: 0 }}>
                  <td colSpan={4} className='text-center bg_main_2' style={{ position: "sticky", left: 0, zIndex: 100 }}>Tổng</td>
                  {listdays && listdays.map((item, index) => {


                    return <td key={index} className={`text-center ${item.day_of_week_number === 6 ? "bg-info" : item.day_of_week_number === 7 ? "bg-danger" : ""}`} style={{ zIndex: 0, }}>
                      {listTotal[item.date] ? listTotal[item.date] : 0}
                    </td>
                  })}
                  <td className='text-center'>{formatMN1(listTotalAll?.days)}</td>
                  <td className='text-center'>{formatMN1(listTotalAll?.hours)}</td>
                  <td className='text-center'>{formatMN1(listTotalAll?.minutes)}</td>
                  <td className='text-center'>{formatMN1(listTotalAll?.daysOff)}</td>
                  <td className='text-center'>{formatMN1(listTotalAll?.daysOffSunday)}</td>
                  <td className='text-center'>{formatMN1(listTotalAll?.daysWorkSunday)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

      </div>
    </Card>
  )
}

export default Index
