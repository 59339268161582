import React, { useEffect, useRef, useState } from 'react'
import { Button, DatePicker, Drawer, Empty, Form, Input, InputNumber, message, Popover, Select, Skeleton, Spin, Table, Tooltip, TreeSelect } from 'antd';
import "./../style.css"
import { ListAccountingAccountRequest, ListDefaultAccountRequest, ListDepartmentRequest, ListTypeAssetsRequest, SearchProductsWarehouseNoDispatchRequest, SearchSupplierNoDispatchRequest, StoreAccountantRequest, StoreAccountingAccountRequest, StoreIncreasseAssetsRequest, StoreIncreasseToolRequest } from '../../../../actions/indexWeb';
import { META } from '../../../../utils/constant';
import { DATA_GROUP_ACCOUNT, DATA_KEY_CODE_ACCOUNT, DATA_PRECIOUS } from '../../../../constants/DefaultData';
import {
  LoadingOutlined, SearchOutlined
} from '@ant-design/icons';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import * as LINK from "../../../../constants/Links";
import ListPurchase from "./ListPurchase";

import { filAcounts, formatDate, formatDate_fm, formatDateVN, formatNumber, getPar, formatNumberamsUrl, handleNumberInput, parseNumberV_ } from '../../../../service';
import { Link, useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
const { RangePicker } = DatePicker;

const Index = ({ getListGroupAccountant, record, dataDetail1, addRow, deleteRow, formRef, setVisibleListPurchase, purchase, tab, setpurchase }) => {

  const [listDepartment, setListDepartment] = useState([]);

  const [meta, setMeta] = useState({ ...META });
  const [isloading, setIsloading] = useState(false);
  const [isLoadingSearch, SetisLoadingSeach] = useState(false);
  const [listTKno, setListTKno] = useState([]);
  const [listTKco, setListTKco] = useState([]);
  const [listTKcost, setListTKcost] = useState([]);
  const [listSuplier, setListSuplier] = useState([]);

  const [listAccount, setListAccount] = useState([]);
  const [listDefaultAccountFil, setListDefaultAccountFill] = useState(null);
  const [listDefaultAccount, setListDefaultAccount] = useState([]);
  const [listTypeAssets, setListTypeAssets] = useState([]);
  const [typeAsset, setTypeAssets] = useState(null);

  // console.log("typeAssettypeAssettypeAsset ", typeAsset);

  const tableRef = useRef(null);
  const newRowRef = useRef(null);

  const params = useParams();


  const defaultDate = moment().startOf('day');
 


  // console.log(dataDetail1);
  const getSuplier = async () => {


    const response = await SearchSupplierNoDispatchRequest({
      limit: meta.per_page * 100,
      page: meta.current_page, column: "name", sort: "ASC"
    });
    // console.log(response);
    if (response.code == 200) {
      if (response.data) {

        if (response.data.data) {

          setListSuplier(response.data.data);
        }
      }
    }
  }
  const getListTypeAssets = async () => {
    const response = await ListTypeAssetsRequest({
      limit: meta.per_page * 100,
      page: meta.current_page, column: "code", sort: "ASC"
    }, { data: { type: 2 } });
    // console.log("getListTypeAssetsgetListTypeAssetsgetListTypeAssets",response);
    if (response.code == 200) {
      if (response.data) {

        if (response.data.data) {

          setListTypeAssets(response.data.data);
        }
      }
    }


  }
  const getListDefaultAccount = async () => {
    const response = await ListDefaultAccountRequest({
      limit: meta.per_page * 100,
      page: meta.current_page, column: "code", sort: "ASC"
    });
    console.log("responseresponse ", response);

    if (response.code == 200) {
      if (response.data) {

        setListDefaultAccount(response.data);

      }
    }


  }
  const getListAccount = async () => {
    const response = await ListAccountingAccountRequest({
      limit: meta.per_page * 100,
      page: meta.current_page, column: "code", sort: "ASC"
    });
    if (response.code == 200) {
      if (response.data) {
        if (response.data.data) {

          setListAccount(response.data.data);
        }
      }
    }


  }
  const filterTreeNode = (inputValue, treeNode) => {
    const { title, description, code } = treeNode;
    // console.log(treeNode.props.data);

    return (
      title.toLowerCase().includes(inputValue.toLowerCase()) || // Tìm kiếm theo title
      description.toLowerCase().includes(inputValue.toLowerCase()) || // Tìm kiếm theo description
      code.toString().includes(inputValue) // Tìm kiếm theo code
    );
  };
  const findAssetById = (list, id) => {
    for (const value of list) {
      if (value.id == id) {
        return value;
      } else if (value.children) {
        const child = findAssetById(value.children, id); // Đệ quy tìm trong children
        if (child) return child;
      }
    }
    return null; // Không tìm thấy
  };
  const setTypeAssets_ = (e) => {
    const typeass = findAssetById(listTypeAssets, e);
    // console.log(listTypeAssets, e, typeass);

    setTypeAssets(typeass);
    
    if(formRef.current){
      formRef.current.setFieldsValue({tkno:typeass.tkno,tkco:typeass.tkco,})
    }
  }
  useEffect(() => {
    filterAccount();

    return () => {

    };
  }, [listDefaultAccount, listAccount]);
  const filterAccount = () => {
    const fildata = listDefaultAccount.filter((value, index) => {
     
      return value.code == "ghi-tang-tscd";
    })

   
    setListDefaultAccountFill(fildata[0]);

    return;
  }
  useEffect(() => {


    processListAccount();
    return () => {

    };
  }, [listDefaultAccountFil,listAccount]);
  const processListAccount = async () => {
    if (listDefaultAccountFil) {
      const listTKno_ = await filAcounts(listDefaultAccountFil, "tkno", listAccount);
      const listTKco_ = await filAcounts(listDefaultAccountFil, "tkco", listAccount);
      const listTKcost_ = await filAcounts(listDefaultAccountFil, "tk-chi-phi", listAccount);

      setListTKcost(listTKcost_);
      setListTKno(listTKno_);
      setListTKco(listTKco_);
      // setListTKvat(listTKvat_);




    }
  }

  const handleKeyDown = (e, isLastCell = false) => {


    // console.log(e);
    if (isLastCell && (e.key === 'Tab' || e.key === 'Enter')) {
      e.preventDefault();
      addRow();
    }

    if (!isLastCell && (e.key === 'Enter')) {
      e.preventDefault();
      addRow();

    }
  };



  const getListDepartment = async () => {
    const response = await ListDepartmentRequest({
      limit: meta.per_page * 100,
      page: meta.current_page, column: "code", sort: "ASC"
    });
    if (response.code == 200) {
      if (response.data) {
        // console.log(response.data.data);
        if (response.data.data) {

          setListDepartment(response.data.data);
        }
      }
    }


  }

  useEffect(() => {
    getListTypeAssets();
    getSuplier();
    getListAccount();
    getListDepartment();
    getListDefaultAccount();

    return () => {

    };
  }, []);


  const onsubmit = async (values) => {
    setIsloading(true);
    if (record) {
      values.id = record.increase_assets_id;
    }
    values.increase_assets_id = record.id;
    if (values.original_price) {
      values.original_price = parseNumberV_(values.original_price);
    }
    if (values.amount) {
      values.amount =parseNumberV_(values.amount.toString());
    }
    // if (values.depreciation_price) {
    //   values.depreciation_price = values.depreciation_price.toString().replace(/\D/g, '');
    // }
    // if (values.price_of_period) {
    //   values.price_of_period = values.price_of_period.toString().replace(/\D/g, '');
    // }
    // if (values.price_of_period_year) {
    //   values.price_of_period_year = values.price_of_period_year.toString().replace(/\D/g, '');
    // }
    // if (values.price_of_period) {
    //   values.price_of_period = values.price_of_period.toString().replace(/\D/g, '');
    // }
    if (values.accumulated_depreciation) {
      values.accumulated_depreciation = parseNumberV_(values.accumulated_depreciation);
    }
    // if (values.residual_value) {
    //   values.residual_value = values.residual_value.toString().replace(/\D/g, '');
    // }
    values.date= moment(values.date).format('YYYY-MM-DD');
    values.start_date= moment(values.start_date).format('YYYY-MM-DD');

    const depreciation_price = values.depreciation_price ? parseNumberV_(values.depreciation_price) : 0;
      const periods = values.periods;
    
      const depreciation_rate_month=((depreciation_price/periods)/(depreciation_price))*100;
      const depreciation_rate_year=depreciation_rate_month*12;
      const price_of_period=depreciation_price*(depreciation_rate_month/100);
      const price_of_period_year=depreciation_price*(depreciation_rate_year/100);
      const residual_value=depreciation_price;
      values.depreciation_rate_month=depreciation_rate_month;
      values.depreciation_rate_year=depreciation_rate_year;
      values.price_of_period_year=price_of_period_year;
      values.price_of_period=price_of_period;
      values.depreciation_price=depreciation_price;
      values.residual_value=residual_value;
    if (purchase?.code) {
      values.purchase_id = purchase.code;
    }
    const detail = {};
    for (const x in values) {
      const xarr = x.split("-");
      if (xarr[0] == "d") {
        let key = xarr[1];
        let row = xarr[2];
        if (!detail[row]) {
          detail[row] = {};
        }
        detail[row][key] = values[x];

      }

    }
    values.detail = Object.values(detail);

    // return;

    const response = await StoreIncreasseAssetsRequest(values);
    console.log("response ", response);
    setIsloading(false);
    if (response.code == 200) {
      if (response.message) {
        message.success(response.message);
      } else {
        message.success("Cập nhật thành công");
      }
      getListGroupAccountant();
    } else {
      if (response.message) {
        if (typeof response.message == "string") {
          message.error(response.message);
        } else {
          (Object.values(response.message) || []).map((value, index) => {
            message.error(value);
          })
        }

      } else {
        message.warning("Cập nhật không thành công");
      }
    }
  }

  const handleMoneyChange = (e, name) => {
    let inputValue = "";
    if (e.target.value) {
        inputValue = e.target.value // Loại bỏ ký tự không phải số
    } else {
        inputValue = e.toString() // Loại bỏ ký tự không phải số
    }
    const formattedValue= handleNumberInput(inputValue);

    formRef.current.setFieldsValue({
        [`${name}`]: formattedValue,
    });

};

  const proccessPeriods = () => {

    if (formRef.current) {
      // switch (name) {
      //     case "":

      //         break;

      //     default:
      //         break;
      // }
      const values = formRef.current.getFieldsValue();
      const depreciation_price = values.depreciation_price ?  parseNumberV_(values.depreciation_price) : 0;
      const periods = values.periods;
    
      const depreciation_rate_month=((depreciation_price/periods)/(depreciation_price))*100;
      const depreciation_rate_year=depreciation_rate_month*12;
      const price_of_period=depreciation_price*(depreciation_rate_month/100);
      const price_of_period_year=depreciation_price*(depreciation_rate_year/100);
      const residual_value=depreciation_price;
 

      formRef.current.setFieldsValue({ 
        depreciation_rate_month: Number(depreciation_rate_month).toFixed(2),
        depreciation_rate_year: Number(depreciation_rate_year).toFixed(2),
        price_of_period: formatNumber(price_of_period),
        price_of_period_year: formatNumber(price_of_period_year),
        price_of_period_year: formatNumber(price_of_period_year),
        residual_value: formatNumber(residual_value),
        remaining_period:periods
       })

    }
  };
  
  // const proccessPeriods = (e) => {

  //   const values = formRef.current.getFieldsValue();
  //   const total_price = values.total_price ? values.total_price.toString().replace(/\D/g, '') : 0;
  //   const total_price_allocated = values.total_price_allocated ? values.total_price_allocated.toString().replace(/\D/g, '') : 0;
  //   const periods = values.periods ?? 0;

  //   // const remaining_total=total_price-total_price_allocated;


  //   const per_period = Math.round(total_price / periods);
  //   const allocated = Math.round(total_price_allocated / per_period);
  //   const remaining_period = Math.round(periods - allocated);
  //   console.log("per_periodper_periodper_period ", total_price_allocated, per_period);
  //   console.log("allocatedallocated ", allocated);
  //   formRef.current.setFieldsValue({ price_of_period: formatNumber(per_period), remaining_period: remaining_period })


  // }

  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const handleMouseDown = (e) => {


    if (e.target.tagName != "TH") return;
    setIsDragging(true);
    setStartX(e.pageX - tableRef.current.offsetLeft);
    setScrollLeft(tableRef.current.scrollLeft);

  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - tableRef.current.offsetLeft;
    const walk = (x - startX) * 2; // Tốc độ cuộn (tăng hoặc giảm giá trị này tùy thích)
    tableRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
  };
  const changeSuplier = (e) => {

    const suplier = listSuplier.filter((value, index) => {
      return value.code == e;
    })
    if (suplier[0]) {
      if (formRef.current) {
        formRef.current.setFieldsValue({ suplier_name: suplier[0].name })
        // const formvalue= formRef.current.getFieldsValue();
        // if (suplier[0].name) {
        //   formRef.current.setFieldsValue({ note: "Chi tiền mua hàng " + suplier[0].name })
        // }
      }
    }


  }

  const onchangeDepartment=(e)=>{
    console.log("onchangeDepartmentonchangeDepartment ",e);
    const department=listDepartment.filter((value, index) => {
        return value.id==e;
    })
    if (formRef.current) {
      if(dataDetail1){
        dataDetail1.map((value, index) => {
           formRef.current.setFieldsValue({ [`d-department_id-${index}`]: department?.[0]?.id,[`d-department_name-${index}`]: department?.[0]?.name,[`d-percent-${index}`]: 100 })

        })
      }
    
    }
  }
  return (
    <Form name="form" ref={formRef} onFinish={onsubmit} style={{
      padding: "0px",
      width: "100%",
      // display: "flex"
    }}

    >
      <div className={`${tab == 1 ? '' : 'd-none'}`}>
        <Form.Item label="SCT/Ngày GT" labelCol={{ span: 7 }} wrapperCol={{ span: 15 }}>
          <Input.Group compact>
            <Form.Item name="date"
              rules={[
                {
                  required: true,
                  message: "Ngày ghi tăng là bắt buộc"
                },
              ]} initialValue={defaultDate} >
              <DatePicker placeholder='Ngày ghi tăng' />
            </Form.Item>
            <Form.Item name="code_" >
              <Input placeholder='Số chứng từ' disabled />
            </Form.Item>
          </Input.Group>
        </Form.Item>
        <Form.Item label="Mã /Tên CCDC" labelCol={{ span: 7 }} wrapperCol={{ span: 15 }}>
          <Input.Group compact>
            <Form.Item name="products_warehouse_id" initialValue={record?.code}
              rules={[
                {
                  required: true,
                  message: "Mã CCDC là bắt buộc"
                },
              ]} style={{ width: "50%", margin: "0" }} >
              <Input placeholder='Mã' />
            </Form.Item>

            <Form.Item name="products_warehouse_name" initialValue={record?.title}
              rules={[
                {
                  required: true,
                  message: "Tên CCDC là bắt buộc"
                },
              ]}
              style={{ width: "50%", margin: "0" }}
            >
              <Input placeholder='Tên' />
            </Form.Item>
          </Input.Group>
        </Form.Item>
        <Form.Item label="loại TSCĐ" name="accountant_type_assets_id" labelCol={{ span: 7 }} wrapperCol={{ span: 15 }}
          rules={[
            {
              required: true,
              message: "loại TSCĐ là bắt buộc"
            },
          ]} style={{ width: "100%" }} initialValue={typeAsset} >
          <TreeSelect
            showSearch
            style={{ width: '100%' }}
            treeData={listTypeAssets}

            dropdownStyle={{ maxHeight: 400, minWidth: "400px", overflow: 'auto' }}
            placeholder="loại TSCĐ"

            onChange={setTypeAssets_}

          />
        </Form.Item>
        <Form.Item label="Đơn vị sử dụng" name="department_id" labelCol={{ span: 7 }} wrapperCol={{ span: 15 }}
          rules={[
            {
              required: true,
              message: "Đơn vị sử dụng là bắt buộc"
            },
          ]} style={{ width: "100%" }} >
          <Select
            showSearch
            style={{ width: '100%' }}
            options={(listDepartment || []).map((value, i) => {
              return {
                value: value.id,
                label: value.code + "-" + value.name
              }
            })}


            dropdownStyle={{ maxHeight: 400, minWidth: "400px", overflow: 'auto' }}
            placeholder="Đơn vị sử dụng"
            onChange={onchangeDepartment}

          />
        </Form.Item>
        <Form.Item label="Nhà sản xuất" name="manufacturer" labelCol={{ span: 7 }} wrapperCol={{ span: 15 }}
          style={{ width: "100%" }} >
          <Input placeholder='Nhà sản xuất' />
        </Form.Item>
        <Form.Item label="Năm SX/Số hiệu" labelCol={{ span: 7 }} wrapperCol={{ span: 15 }}
          style={{ width: "100%" }} >
          <Input.Group compact>
            <Form.Item name="year_manufacturer" style={{ width: "50%" }} className='m-0'>
              <Input placeholder='Năm sản xuất' />
            </Form.Item>
            <Form.Item name="number" style={{ width: "50%" }} className='m-0'>
              <Input placeholder='Số hiệu' />
            </Form.Item>
          </Input.Group>
        </Form.Item>
        <Form.Item label="Nước sản xuất" name="contry_manufacturer" labelCol={{ span: 7 }} wrapperCol={{ span: 15 }}
          style={{ width: "100%" }} >
          <Input placeholder='Nước sản xuất' />
        </Form.Item>
        <Form.Item label="Công suất" name="capacity" labelCol={{ span: 7 }} wrapperCol={{ span: 15 }}
          style={{ width: "100%" }} >
          <Input placeholder='Công suất' />
        </Form.Item>
        <Form.Item label="Thời gian bảo hành" name="warranty_period" labelCol={{ span: 7 }} wrapperCol={{ span: 15 }}
          style={{ width: "100%" }} >
          <Input placeholder='Thời gian bảo hành' />
        </Form.Item>
        <Form.Item label="Điều kiện bảo hành" name="warranty_terms" labelCol={{ span: 7 }} wrapperCol={{ span: 15 }}
          style={{ width: "100%" }} >
          <Input placeholder='Điều kiện bảo hành' />
        </Form.Item>
        <Form.Item label="Nhà cung cấp" labelCol={{ span: 7 }} wrapperCol={{ span: 15 }}
          style={{ width: "100%" }} >
          <Input.Group compact>
            <Form.Item name="suplier_id" style={{ width: "30%" }} className='m-0'>
              <Select
                showSearch
                style={{ width: '100%' }}
                options={(listSuplier || []).map((value, index) => {
                  return {
                    value: value.code,
                    label: value.name
                  }
                })}

                dropdownStyle={{ maxHeight: 400, overflow: 'auto', minWidth: "200px" }}
                placeholder="Nhà cung cấp"
                // allowClear
                // multiple
                treeDefaultExpandAll
                onChange={changeSuplier}

              />
            </Form.Item>
            <Form.Item name="suplier_name" style={{ width: "70%" }} className='m-0'>
              <Input placeholder='Tên nhà cung cấp' />
            </Form.Item>
          </Input.Group>
        </Form.Item>
        <Form.Item label="Tình trạng/chất lượng" labelCol={{ span: 7 }} wrapperCol={{ span: 15 }}
          style={{ width: "100%" }} >
          <Input.Group compact>
            <Form.Item name="status" style={{ width: "50%" }} className='m-0'>
              <Select
                showSearch
                style={{ width: '100%' }}
                options={[{ value: 1, label: "mới" }, { value: 2, label: "cũ" }]}

                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                placeholder="Tình trạng"
                // allowClear
                // multiple
                treeDefaultExpandAll

              />
            </Form.Item>
            <Form.Item name="quality" style={{ width: "50%" }} className='m-0'>
              <Select
                showSearch
                style={{ width: '100%' }}
                options={[{ value: 1, label: "hoạt động tốt" }, { value: 2, label: "hỏng" }]}

                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                placeholder="Chất lượng"
                // allowClear
                // multiple
                treeDefaultExpandAll

              />
            </Form.Item>
          </Input.Group>
        </Form.Item>
      </div>
      <div className={`${tab == 2 ? '' : 'd-none'}`}>
        <Form.Item label="Tk nguyên giá" name="tkno" initialValue={listTKno.default} labelCol={{ span: 7 }} wrapperCol={{ span: 15 }}
          rules={[
            {
              required: true,
              message: "Tk nguyên giá là bắt buộc"
            },
          ]}

        >
          <TreeSelect
            showSearch
            style={{ width: '100%' }}
            treeData={listTKno?.listAccount}
            dropdownStyle={{ maxHeight: 400, minWidth: "250px", overflow: 'auto' }}
            placeholder="Tk nợ"
            filterTreeNode={filterTreeNode}
            treeDefaultExpandAll
          // onKeyDown={(e) => { handleKeyDown(e, false) }}


          />
        </Form.Item>
        <Form.Item label="Tk khấu hao" name="tkco" labelCol={{ span: 7 }} wrapperCol={{ span: 15 }}
          rules={[
            {
              required: true,
              message: "Tk khấu hao là bắt buộc"
            },
          ]}
          initialValue={listTKco.default}
        >
          <TreeSelect
            showSearch
            style={{ width: '100%' }}
            treeData={listTKco?.listAccount}
            dropdownStyle={{ maxHeight: 400, minWidth: "250px", overflow: 'auto' }}
            placeholder="Tk nợ"
            filterTreeNode={filterTreeNode}
            treeDefaultExpandAll
          // onKeyDown={(e) => { handleKeyDown(e, false) }}


          />
        </Form.Item>
        <Form.Item label="Nguyên giá/khấu hao" labelCol={{ span: 7 }} wrapperCol={{ span: 15 }}>
          <Input.Group compact>
            <Form.Item name="original_price"
              rules={[
                {
                  required: true,
                  message: "Nguyên giá là bắt buộc"
                },
              ]}
              style={{ width: "50%", margin: "0" }}
              initialValue={formatNumber(record?.price)}
            >
              <Input placeholder='Nguyên giá' onChange={(e) => { handleMoneyChange(e, "original_price");}} style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item name="depreciation_price" initialValue={formatNumber(record?.price)}
              rules={[
                {
                  required: true,
                  message: "giá khấu hao là bắt buộc"
                },
              ]}
              style={{ width: "50%", margin: "0" }}

            >
              <Input placeholder='giá khấu hao' onChange={(e) => { handleMoneyChange(e, "depreciation_price"); proccessPeriods(); }} style={{ width: "100%" }} />
            </Form.Item>

          </Input.Group>
        </Form.Item>

        <Form.Item label="Ngày tính khấu hao" name="start_date" initialValue={defaultDate}  labelCol={{ span: 7 }} wrapperCol={{ span: 15 }}>
          <DatePicker placeholder='Ngày tính khấu hao' />
        </Form.Item>

        <Form.Item name="periods" label="Thời hạn sử dụng" rules={[
          {
            required: true,
            message: "Thời hạn sử dụng là bắt buộc"
          },
        ]} initialValue={record?.periods} labelCol={{ span: 7 }} wrapperCol={{ span: 15 }} >
          <InputNumber min={1} placeholder='Thời hạn sử dụng' onChange={(e) => {proccessPeriods(e)}} style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item name={"depreciation_rate_month"} label="Tỉ lệ khấu hao tháng (%)" rules={[
          {
            required: true,
            message: "Tỉ lệ khấu hao tháng là bắt buộc"
          },
        ]} initialValue={record?.depreciation_rate_month} labelCol={{ span: 7 }} wrapperCol={{ span: 15 }} >
          <InputNumber min={1} placeholder='Tỉ lệ khấu hao tháng'  style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item name={"depreciation_rate_year"} label="Tỉ lệ khấu hao năm (%)" rules={[
          {
            required: true,
            message: "Tỉ lệ khấu hao năm là bắt buộc"
          },
        ]} initialValue={record?.depreciation_rate_year} labelCol={{ span: 7 }} wrapperCol={{ span: 15 }} >
          <InputNumber min={1} placeholder='Tỉ lệ khấu hao năm'  style={{ width: "100%" }} />
        </Form.Item>

        <Form.Item name="price_of_period" label="Giá trị khấu hao tháng" initialValue={formatNumber(record?.price_of_period)} labelCol={{ span: 7 }} wrapperCol={{ span: 15 }}>
          <Input placeholder='Giá trị khấu hao tháng' readOnly style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item name="price_of_period_year" label="Giá trị khấu hao năm" initialValue={formatNumber(record?.price_of_period)} labelCol={{ span: 7 }} wrapperCol={{ span: 15 }}>
          <Input placeholder='Giá trị khấu hao năm' readOnly style={{ width: "100%" }} />
        </Form.Item>

        <Form.Item name="accumulated_depreciation" label="Hao mòn lũy kế" initialValue={formatNumber(record?.price_of_period)} labelCol={{ span: 7 }} wrapperCol={{ span: 15 }}>
          <Input placeholder='Hao mòn lũy kế' style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item name="residual_value" label="Giá trị còn lại" initialValue={formatNumber(record?.price_of_period)} labelCol={{ span: 7 }} wrapperCol={{ span: 15 }}>
          <Input placeholder='Giá trị còn lại' style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item name="remaining_period" label="Thời hạn sử dụng còn lại" initialValue={record?.remaining_period} labelCol={{ span: 7 }} wrapperCol={{ span: 15 }} >
          <InputNumber min={1} placeholder='Thời hạn sử dụng còn lại' style={{ width: "100%" }} />
        </Form.Item>
      </div>
      <div className={`${tab == 3 ? '' : 'd-none'}`}>
        <div className="table-responsive" style={{ height: "300px" }} ref={tableRef}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseLeave}
        >
          <table className="table table-ledger table-sm table-hover" style={{ width: "max-content", minWidth: "100%" }} >
            <thead style={{ position: "sticky", top: 0, zIndex: 1000, cursor: "all-scroll" }} >

              <tr className='noscroll'>
                <th className='p-1 text-center align-middle' ></th>
                <th className='p-1 text-center align-middle' id='tab1'  > Mã đơn vị</th>
                <th className='p-1 text-center align-middle'  >Tên đơn vị</th>
                {/* <th className='p-1 text-center align-middle' >Số lượng</th> */}

                <th className='p-1 text-center align-middle'  >Tỉ lệ phân bổ</th>
                <th className='p-1 text-center align-middle' >TK chi phí</th>




              </tr>

            </thead>
            <tbody>
              {dataDetail1 && dataDetail1.map((row, index) => {
                const isLastRow = index === dataDetail1.length - 1;
                return <tr key={index}>
                  <td>
                    <Button size='small' onClick={() => deleteRow(index)}><i className="fas fa-trash-alt text-danger"></i></Button>

                  </td>
                  <td>
                    <Form.Item name={"d-department_id-" + index}
                      rules={[
                        {
                          required: true,
                          message: "Mã bộ phận là bắt buộc"
                        },
                      ]}
                      style={{ width: "100px" }}
                      className='m-0'
                      initialValue={row?.department_id}
                    >
                      <Select
                        showSearch
                        style={{ width: '100%' }}
                        options={(listDepartment || []).map((value, i) => {
                          return {
                            value: value.id,
                            label: value.code + "-" + value.name
                          }
                        })}

                        dropdownStyle={{ maxHeight: 400, minWidth: "400px", overflow: 'auto' }}
                        placeholder="Mã bộ phận"
                        // allowClear
                        // multiple
                        treeDefaultExpandAll
                        onKeyDown={(e) => { handleKeyDown(e, false) }}
                        onChange={(e) => {
                          const product = listDepartment.filter((item, i) => {
                            return item.id == e;

                          })
                          if (product[0]) {
                            if (formRef.current) {
                              formRef.current.setFieldsValue({ ["d-department_name-" + index]: product[0].name });
                            }
                          }


                        }}

                        ref={isLastRow ? newRowRef : null}
                      />

                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item name={"d-department_name-" + index} style={{ width: "100%" }} className='m-0'
                      initialValue={row?.department_name}
                    >

                      <Input onKeyDown={(e) => { handleKeyDown(e, false) }} placeholder='Tên bộ phận' />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item name={"d-percent-" + index} style={{ width: "100%" }} className='m-0'
                      initialValue={row?.percent}
                    >

                      <InputNumber onKeyDown={(e) => { handleKeyDown(e, false) }} placeholder='tỉ lệ phân bổ' />
                    </Form.Item>
                  </td>
                  <td style={{ width: "200px" }}>
                    <Form.Item name={"d-accounting_code-" + index} style={{ width: "100%" }} className='m-0'
                      initialValue={listTKcost?.default}
                    >

                      <TreeSelect
                        showSearch
                        style={{ width: '100%' }}
                        treeData={listTKcost?.listAccount}
                        dropdownStyle={{ maxHeight: 400, minWidth: "250px", overflow: 'auto' }}
                        placeholder="Tk chi phí"
                        filterTreeNode={filterTreeNode}
                        treeDefaultExpandAll
                        onKeyDown={(e) => { handleKeyDown(e, false) }}


                      />
                    </Form.Item>
                  </td>
                </tr>
              })}
            </tbody>
          </table>
        </div>

      </div>
      {record?.cost_allocation && <div className={`row`}>
              <div className='col-lg-12 col-md-12 col-sm-12 mt-2'>
                <span className='text-danger fst-italic '>(*) Đã phân bổ chi phí khấu hao Không thể sửa!</span>
              </div>
            </div>}
      <div className={`${tab == 4 ? '' : 'd-none'}`}>

        <Form.Item name="purchase_id" label="nguồn gốc" initialValue={record?.purchase_id}>
          <Input placeholder='Nguồn gốc' style={{ width: "100%" }} addonAfter={<span onClick={() => setVisibleListPurchase(true)} style={{ cursor: "pointer" }}>Nguồn gốc</span>} />
        </Form.Item>

      </div>


      <Form.Item noStyle style={{
        width: "100%"
      }}>
        <Button type="primary" htmlType="submit" style={{ width: "20%" }} loading={isLoadingSearch} disabled={record?.cost_allocation}>Lưu</Button>

      </Form.Item>

    </Form>
  )
}

export default Index

