import React, { Component, createRef } from "react";
// import { Row, Col, Container } from 'react-bootstrap';
import { Switch, Route, Link, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import * as ActionsWeb from "../../../../../../actions/indexWeb";

import * as ActionsApp from "../../../../../../actions/indexApp";
import "./style.css";
import * as LINK from "../../../../../../constants/Links";
// import type { MenuProps } from 'antd';
import { Divider, Radio, Table, Pagination, Affix, Input, Button, Modal, Image, Tag, Switch as SwitchA, message, DatePicker, Tooltip, Form, Select, TreeSelect, Checkbox, Avatar, Card, Row, Col, InputNumber, Collapse, Tabs, Popover, Alert, Dropdown, Menu, Empty, List, Skeleton, Spin } from 'antd';
import moment from 'moment';

import * as Colors from "../../../../../../constants/Colors";
import { arrColors } from "../../../../../../constants/Colors";
import Draggable from 'react-draggable';
import {
    FolderViewOutlined, UnorderedListOutlined,
    DeleteOutlined, AppstoreOutlined, LoadingOutlined,
    EditOutlined, SearchOutlined, PlusCircleOutlined, SafetyOutlined, DiffOutlined, SaveOutlined, CheckOutlined, CarOutlined, GiftOutlined
} from '@ant-design/icons';
import { formatNumber, CutString, getParamsUrl, checkScreenSize, getCookie, getDateRange, isValidURL } from "../../../../../../service";
import BoxOrder from "./../box/index";
import { META } from "../../../../../../utils/constant";
import IndexMobile from "./indexMobile";

import * as caseSocket from "../../../../../../constants/CaseSocket";
import { Runfirst } from "../../../../../../constants/Runfirst";
import { TotalBottom } from "../../../../../../components/TotalBottom/TotalBottom";
import FormBill from "./formBill";
// import Accountant from "./accountant";
import { DATA_SYNC_ORDER_DAY, ECOMMERCE_KEY } from "../../../../../../constants/DefaultData";

const { Search } = Input;
const { RangePicker } = DatePicker;

class Index extends Component {

    constructor(props) {
        super(props);
        this.draggleRef = new createRef(null);
        this.formRef = new createRef(null);
        this.formRefInfoBill = new createRef(null);
        this.tableRef = new createRef(null);
        this.tableRefc1 = new createRef(null);
        this.tableRefc2 = new createRef(null);


        this.state = {
            meta: {
                ...META
            },
            ListTiktokOrders: [],
            ListStore: [],
            ListUsers: [],
            ListProvinces: [],
            ListDistricts: [],
            ListStatusTransport: [],
            ListWars: [],
            Province_id_: "",
            isSket: true,
            groupReport: {},
            columns: [
                {
                    title: 'STT',
                    dataIndex: 'key',


                },
                {
                    title: 'Tiêu đề',
                    dataIndex: 'title',
                    filterMode: 'tree',
                    filterSearch: true,
                    // filters: [
                    //     {
                    //         text: 'Joe',
                    //         value: 'Joe',
                    //     },
                    //     {
                    //         text: 'Category 1',
                    //         value: 'Category 1',
                    //     },
                    //     {
                    //         text: 'Category 2',
                    //         value: 'Category 2',
                    //     },
                    // ],
                    onFilter: (value, record) => record.title.startsWith(value),
                    sorter: (a, b) => a.title - b.title,
                },
                {
                    title: 'Slug',
                    dataIndex: 'slug',
                    sorter: (a, b) => a.slug - b.slug,

                },
                {
                    title: 'Giá',
                    dataIndex: 'price',
                    sorter: (a, b) => a.price - b.price,

                },
                {
                    title: 'Giảm giá',
                    dataIndex: 'discount',
                    sorter: (a, b) => a.discount - b.discount,

                },
                {
                    title: 'Danh mục ',
                    dataIndex: 'category',
                    // render: (text, record, index) => (

                    //     <span>{text}</span>
                    // )

                },
                {
                    title: 'Ẩn',
                    dataIndex: 'hidden',

                },
                {
                    title: 'ngày tạo',
                    dataIndex: 'created_at',
                    sorter: (a, b) => a.created_at - b.created_at,


                },
                {
                    title: 'Ngày cập nhật',
                    dataIndex: 'updated_at',
                    sorter: (a, b) => a.updated_at - b.updated_at,

                },
                {
                    title: 'Hành động',
                    dataIndex: 'action',
                    width: "20%",
                    render: (text, record, index) => (
                        <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }} >{text}
                            <span className="__action_">
                                <button className="reset_btn btn_edit btn__" onClick={() => this.editRows(index)}> <EditOutlined /> </button>
                                <button className="reset_btn btn_delete btn__" onClick={() => { this.delete(index) }}><DeleteOutlined /></button>
                                <button className="reset_btn btn_view btn__" onClick={() => { this.duplicateItem(record) }}>
                                    <DiffOutlined style={{ color: Colors.colorView }} /></button>

                            </span>
                        </div>
                    ),
                },
            ],
            pagination: {
                limit: 2,
                page: 1,
                column: "id",
                sort: "DESC",
            },
            data: [],
            StoreTiktokOrders: {},
            visible: false,
            confirmLoading: false,
            modalText: "",
            isloading: false,
            ListCategory: [],
            treeData: [],
            search: {},
            ListTags: [],
            isLoadingSearch: false,
            statusSearch: "",
            visibledetail: false,

            bounds: {
                left: 0,
                top: 0,
                bottom: 0,
                right: 0,
            },
            record: "",
            disabled: true,
            isShowList: false,
            code: "",
            isNewList: true,
            selectedRows: [],
            selectedRowKeys: [],
            dataExportSocket: [],
            showExportRes: false,
            visibleInfoBill: false,
            visibleExcel: false,
            visibleExcelCompare: false,
            ListEcommerceType: [],
            ListConnectExportBill: [],
            Listexcel: null,
            isDragging: false,
            startX: 0,
            scrollLeft: 0,
            isGetInvoiceInfo: false,
            typeExcel: 0,
            loadingAcountant: false,
            visibleAccountant: false,
            isCheckInvoceInfo: false,
            visibleCheckInvoice: false,
            dataCheckInvoice: null,
            loadingInvoice: false,
            ListexcelCompare: {
                sheet:[],
                order:[]
            },


        }

    }
    setIsDragging = (type) => {
        this.setState(state => {
            return {
                isDragging: type
            }
        })
    }
    setStartX = (type) => {
        this.setState(state => {
            return {
                startX: type
            }
        })
    }
    setScrollLeft = (type) => {
        this.setState(state => {
            return {
                scrollLeft: type
            }
        })
    }

    SetisShowList = (type) => {
        localStorage.setItem("isShowList", !type ? 0 : 1)
        this.setState(state => {
            return {
                isShowList: type
            }
        })
    }
    setBounds = (bounds) => {
        this.setState(state => {
            return {
                bounds: bounds
            }
        })
    }
    setDisabled = (type) => {
        this.setState(state => {
            return {
                disabled: type
            }
        })
    }
    onStart = (_event, uiData) => {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = this.draggleRef.current?.getBoundingClientRect();

        if (!targetRect) {
            return;
        }

        this.setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    };
    onChangetext = (e, type, group) => {

        let target = e.target;
        if (target) {
            let value = target.value;
            let name = target.name;


            this.setState(state => {
                return {
                    [group]: { ...state[group], [name]: value }
                }
            })
        }
        else {

            this.setState(state => {
                return {
                    [group]: { ...state[group], [type]: e }
                }
            })
        }

    }

    setVisibleDetail = (type) => {
        this.setState(state => {
            return {
                visibledetail: type
            }
        })
    }
    handleOkDetail = (e) => {
        console.log('====================================');
        console.log(e);
        console.log('====================================');
        // return
        this.setState(state => {
            return {
                record: e,
                isreset: false
            }
        }, () => {
            this.setVisibleDetail(true);
        })


    };

    handleCancelDetail = () => {
        this.setState(state => {
            return {
                isreset: true,

            }
        }, () => {
            this.setVisibleDetail(false);
        })
        // console.log('Clicked cancel button');
        this.setVisibleDetail(false);

    };
    firstSearchOrder = () => {
        const { match } = this.props;
        const { params } = match;
        const code = (params.code); // Lấy giá trị của tham số từ URL
        const type = (params.type);
        this.setState(state => {
            return {
                code: code,
                ecomType: type,
            }
        })
        const urlParams = getParamsUrl();
        urlParams.created_at = [];
        if (urlParams.fromdate && urlParams.todate) {
            urlParams.created_at = [
                moment(urlParams.fromdate),
                moment(urlParams.todate)
            ]
        }

        if (urlParams.fromUpdate && urlParams.toUpdate) {
            urlParams.updated_at = [
                moment(urlParams.fromUpdate),
                moment(urlParams.toUpdate)
            ]
        }

        if (urlParams.fromdeliverydate && urlParams.todeliverydate) {
            urlParams.delivery_time = [
                moment(urlParams.fromdeliverydate),
                moment(urlParams.todeliverydate)
            ]
        }
        if (urlParams.status) {
            urlParams.status = urlParams.status.split(",");
        }
        if (urlParams.province_id) {
            this.props.ListDistrictsRequest({
                limit: this.state.meta.per_page,
                page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort, provinceid: urlParams.province_id
            });

            this.setState(state => {
                return {
                    Province_id_: urlParams.province_id,

                }
            })
        }
        if (urlParams.district_id) {
            this.props.ListWarsRequest({
                limit: this.state.meta.per_page,
                page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort,
                provinceid: urlParams.province_id, districtid: urlParams.district_id
            });
        }
        if (urlParams.isExportBill) {
            urlParams.isExportBill = parseInt(urlParams.isExportBill);
        }
        urlParams.ecoId = code;
        if (urlParams.shipping_fee) {
            urlParams.shipping_fee = parseInt(urlParams.shipping_fee)

        }
        if (urlParams.update_phone) {
            urlParams.update_phone = parseInt(urlParams.update_phone)

        }
        if (urlParams.update_delivered) {
            urlParams.update_delivered = parseInt(urlParams.update_delivered)

        }
        if (urlParams.is_cancel) {
            urlParams.is_cancel = parseInt(urlParams.is_cancel)

        }
        this.formRef.current.setFieldsValue(urlParams);

        const { ListEcommerceType } = this.state;
        const ecomType_ = ListEcommerceType.filter((value, index) => {
            return parseInt(value.id) == type;

        })
        if (ecomType_[0]) {
            console.log("ListEcommerceType ", ecomType_[0]);
            switch (ecomType_[0].keyshow) {
                case ECOMMERCE_KEY.TITOK:
                    this.props.SearchTiktokOrdersRequest({
                        limit: this.state.meta.per_page, page_size: this.state.meta.per_page,
                        page: this.state.meta.current_page, column: "update_time", sort: this.state.meta.sort, ecoId: code
                    }, { data: urlParams });
                    break;
                case ECOMMERCE_KEY.LAZADA:
                    this.props.SearchLazadaOrdersRequest({
                        limit: this.state.meta.per_page, page_size: this.state.meta.per_page,
                        page: this.state.meta.current_page, column: "update_time", sort: this.state.meta.sort, ecoId: code
                    }, { data: urlParams });
                    break;
                case ECOMMERCE_KEY.SHOPEE:
                    this.props.SearchShopeeOrdersRequest({
                        limit: this.state.meta.per_page, page_size: this.state.meta.per_page,
                        page: this.state.meta.current_page, column: "update_time", sort: this.state.meta.sort, ecoId: code
                    }, { data: urlParams });
                    break;
                default:
                    break;
            }
        }


    }
    componentDidMount = () => {

        // this.props.ListEcommerceConfigByCodeRequest({ code: code });
        const isshowList = localStorage.getItem("isShowList")

        this.SetisShowList((1 * isshowList) === 0 ? false : true)

        this.props.SearchUserRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
        }, { data: {} });
        this.props.ListProvincesRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
        });

        this.firstSearchOrder();
        this.props.ListStoreRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
        });
        this.props.ListConnectExportBillRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort, s: ""
        });
        this.props.SettingsShowByCodeRequest({ code: "ORDER" })

        let productsData = localStorage.getItem("productsData");

        if (productsData) {

            productsData = JSON.parse(productsData);
        } else {
            productsData = {}
        }

        const runfirstdata = {};
        if (productsData) {

            for (let x in Runfirst) {

                if (x === "ListEcommerceConfig" || x === "ListConnectExportBill") {

                    if (!productsData[x] || !productsData[x].data) {

                        const dataF = []

                        Runfirst[x](this.props, this.state, dataF)


                    } else {
                        runfirstdata[x] = productsData[x].data

                    }
                } else {
                    if (productsData[x] && productsData[x].data) {

                        runfirstdata[x] = productsData[x].data


                    }

                }
            }
        }

        this.setState(state => {
            return { ...state, ...runfirstdata }
        }, () => {

        })
    }
    static getDerivedStateFromProps(nextProps, prevState) {




        if (nextProps.Web.SyncTiktokOrders && nextProps.Web.SyncTiktokOrders !== prevState.SyncTiktokOrders) {


            return {
                SyncTiktokOrders: nextProps.Web.SyncTiktokOrders,

            };

        }
        if (nextProps.match && nextProps.match?.params?.code !== prevState.code) {


            return {
                code: nextProps.match?.params?.code,

            };

        }
        if (nextProps.appReducer && nextProps.appReducer.socket !== prevState.socket) {


            return {
                socket: nextProps.appReducer.socket,
                ListProducts: nextProps.Web.ListProducts,
                isLoadingSearch: false

            };


        }
        if (nextProps.Web.StoreTiktokOrders && nextProps.Web.StoreTiktokOrders !== prevState.StoreTiktokOrders) {


            return {
                StoreTiktokOrders: nextProps.Web.StoreTiktokOrders,

            };

        }
        if (nextProps.Web && nextProps.Web.ListTiktokOrders !== prevState.ListTiktokOrders) {

            return {
                ListTiktokOrders: nextProps.Web.ListTiktokOrders,
                isLoadingSearch: false
            };


        }
        if (nextProps.Web && nextProps.Web.ListEcommerceConfigByCode !== prevState.ListEcommerceConfigByCode) {

            return {
                ListEcommerceConfigByCode: nextProps.Web.ListEcommerceConfigByCode,

            };


        }
        // if (nextProps.Web && nextProps.Web.ListEcommerceConfig.data !== prevState.ListEcommerceConfig) {
        //     return {
        //         ListEcommerceConfig: nextProps.Web.ListEcommerceConfig.data,

        //     };


        // }
        if (nextProps.Web && nextProps.Web.ListUsers.data !== prevState.ListUsers) {
            // console.log(nextProps.Web.ListUsers);


            return {
                ListUsers: nextProps.Web.ListUsers.data,

            };


        }

        if (nextProps.Web && nextProps.Web.ListProvinces.data !== prevState.ListProvinces) {

            return {
                ListProvinces: nextProps.Web.ListProvinces.data,

            };


        }


        if (nextProps.Web && nextProps.Web.ListWars.data !== prevState.ListWars) {


            return {
                ListWars: nextProps.Web.ListWars.data,

            };


        }


        if (nextProps.Web && nextProps.Web.ListDistricts.data !== prevState.ListDistricts) {


            return {
                ListDistricts: nextProps.Web.ListDistricts.data,

            };


        }
        if (nextProps.Web && nextProps.Web.ListStore.data !== prevState.ListStore) {


            return {
                ListStore: nextProps.Web.ListStore.data,

            };


        }
        if (nextProps.Web.ListConnectExportBill && nextProps.Web.ListConnectExportBill !== prevState.ListConnectExportBill.data) {

            console.log('====================================');
            console.log("nextProps.Web.ListConnectExportBill ", nextProps.Web.ListConnectExportBill.data);
            console.log('====================================');
            return {
                ListConnectExportBill: nextProps.Web.ListConnectExportBill.data,

            };

        }
        if (Runfirst) {
            for (let x in Runfirst) {

                if (nextProps.Web[x] && nextProps.Web[x].data && nextProps.Web[x].data !== prevState[x]) {
                    console.log("nextProps.Web[x].datanextProps.Web[x].data ", nextProps.Web[x].data);


                    return {
                        [x]: nextProps.Web[x].data,

                    };

                }

            }

        }
        return null;


    }
    modalInfo = (text, content) => {
        Modal.info({
            title: text,
            content: content,
            onOk() { },
            onCancel() { },
        });
    };
    componentDidUpdate(prevProps, prevState) {
        if (this.state.code !== prevState.code) {
            this.setState(state => {
                return {
                    isSket: true,
                    data: []
                }
            })
            this.firstSearchOrder();
            // this.props.SearchTiktokOrdersRequest({
            //     limit: this.state.meta.per_page,
            //     page: this.state.meta.current_page, column: "update_time", sort: this.state.meta.sort
            // }, { data: {code:this.state.code} });


        }
        if (this.state.socket !== prevState.socket) {
            // let { ListCategory } = this.state

            this.connectSocket();

        }
        if (this.state.SyncTiktokOrders !== prevState.SyncTiktokOrders) {

            this.modalInfo("Thông báo", "Đang xử lý dữ liệu! quá trình có thể mất nhiều thời gian");
            this.setState(state => {
                return {
                    isLoadingSearch: false
                }
            })
            this.props.setIsync(true);

        }
        if (this.state.StoreTiktokOrders !== prevState.StoreTiktokOrders) {
            let { StoreTiktokOrders, isDuplicate } = this.state;

            try {
                if (StoreTiktokOrders.code === 200) {
                    if (StoreTiktokOrders.message && typeof StoreTiktokOrders.message === "string") {
                        message.success(StoreTiktokOrders.message)
                    } else {

                        message.error("Có lỗi!")
                    }
                    // console.log('====================================');
                    // console.log({
                    //     limit: this.state.meta.per_page,
                    //     page: isDuplicate ? 1 : this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
                    // });
                    // console.log('====================================');
                    this.props.ListTiktokOrdersRequest({
                        limit: this.state.meta.per_page,
                        page: isDuplicate ? 1 : this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
                    });
                }
                else {
                    if (StoreTiktokOrders.message && typeof StoreTiktokOrders.message === "string") {

                        message.error(StoreTiktokOrders.message)
                    } else {
                        for (let x in StoreTiktokOrders.message) {
                            let mess = StoreTiktokOrders.message[x];
                            for (let y in mess) {
                                if (mess[y]) {
                                    message.error(mess[y])
                                }

                            }
                        }
                        // message.error("Có lỗi!")
                    }

                }

            } catch (error) {
                message.error("Có lỗi!")
                this.SetisLoading(false)
            }
            this.SetisLoading(false)
            this.setState(state => {
                return {
                    StoreTiktokOrders: "",

                    isDuplicate: false
                }
            })
            this.props.ResetStoreTiktokOrders();

        }
        if (this.state.ListTiktokOrders !== prevState.ListTiktokOrders) {

            let { ListTiktokOrders } = this.state;

            if (ListTiktokOrders.code !== 200 && ListTiktokOrders.isfilter) {
                message.warn("không tìm thấy sản phẩm!");
            }

            this.data()
        }


        if (this.state.ListProvinces !== prevState.ListProvinces) {

            const urlParams = getParamsUrl();

            if (urlParams.province_id) {
                this.formRef.current.setFieldsValue({ province_id: (1 * urlParams.province_id) });
            }

        }
        if (this.state.ListDistricts !== prevState.ListDistricts) {

            const urlParams = getParamsUrl();
            if (urlParams.district_id) {
                this.formRef.current.setFieldsValue({ district_id: (1 * urlParams.district_id) });
            }
        }
        if (this.state.ListWars !== prevState.ListWars) {

            const urlParams = getParamsUrl();
            if (urlParams.war_id) {
                this.formRef.current.setFieldsValue({ war_id: (1 * urlParams.war_id) });
            }


        }
    }
    SetisLoading = (type) => {
        this.setState(state => {
            return {
                isloading: type
            }
        })
    }

    setColumn_ = (filters) => {
        this.setState(state => {
            return {
                columns: [
                    // {
                    //     title: 'STT',
                    //     dataIndex: 'key',
                    //     width: "5%",
                    //     align: 'center',

                    // },
                    // {
                    //     title: 'shop',
                    //     dataIndex: 'tiktokName',
                    //     align: 'left',

                    // },
                    {
                        title: 'shop',
                        dataIndex: 'tiktokName',
                        align: 'left',
                        render: (text, record, index) => {
                            return <Image src={record?.line_items?.[0].sku_image} />
                        }
                    },
                    {
                        title: 'Mã',
                        dataIndex: 'id_',
                        // filterMode: 'tree',
                        // width: "7%",
                        filterSearch: true,
                        filters: filters.title,
                        // onFilter: (value, record) => {

                        //     return record.title.startsWith(value)

                        // },
                        sorter: (a, b) => a.title - b.title,
                        render: (text, record, index) => {
                            return <span style={{ color: Colors.pop2[2], fontWeight: "bold", cursor: "pointer" }} onClick={() => this.redirectLink(text)}>{text}</span>
                        }
                    },

                    {
                        title: 'Trạng thái',
                        dataIndex: 'status',
                        filters: filters.status,
                        // onFilter: (value, record) => {

                        //     return record.status.startsWith(value)

                        // },
                        sorter: (a, b) => a.status - b.status,
                        filterSearch: true,
                        render: (text, record, index) => {
                            return <span style={{ fontWeight: "bold" }}>{text}</span>
                        }

                    },
                    {
                        title: 'Tên',
                        dataIndex: '',
                        align: 'center',
                        render: (text, record, index) => {
                            return <div className="position-relative">
                                <div id={`loading_name_${record.id}`} style={{ position: "absolute", width: "100%", height: "100%", display: "none" }} className=" justify-content-center align-items-center"> <Spin indicator={<LoadingOutlined
                                    style={{
                                        fontSize: 24,
                                    }}
                                    spin
                                />} /></div>
                                <span id={`name_${record.id}`} style={{ cursor: "pointer" }} onDoubleClick={(e) => this.showEditPhone(e, `input_name_${record.id}`)}>{record.recipient_address.name}</span>
                                <Input id={`input_name_${record.id}`} style={{ display: "none" }} onBlur={(e) => this.setPhoneNumber(e, record.id, `name_${record.id}`, 3)} data-phone={record.recipient_address.name} />


                            </div>
                        }


                    },
                    {
                        title: 'Phone',
                        dataIndex: '',
                        align: 'center',
                        render: (text, record, index) => {
                            return <div className="position-relative">
                                <div id={`loading_phone_${record.id}`} style={{ position: "absolute", width: "100%", height: "100%", display: "none" }} className=" justify-content-center align-items-center"> <Spin indicator={<LoadingOutlined
                                    style={{
                                        fontSize: 24,
                                    }}
                                    spin
                                />} /></div>
                                <span id={`phone_${record.id}`} style={{ cursor: "pointer" }} onDoubleClick={(e) => this.showEditPhone(e, `input_phone_${record.id}`)}>{record.recipient_address.phone_number}</span>
                                <Input id={`input_phone_${record.id}`} style={{ display: "none" }} onBlur={(e) => this.setPhoneNumber(e, record.id, `phone_${record.id}`, 1)} data-phone={record.recipient_address.phone_number} />


                            </div>
                        }


                    },
                    {
                        title: 'Địa chỉ',
                        dataIndex: 'address',
                        render: (text, record, index) => {
                            return <div className="position-relative">
                                <div id={`loading_address_${record.id}`} style={{ position: "absolute", width: "100%", height: "100%", display: "none" }} className=" justify-content-center align-items-center"> <Spin indicator={<LoadingOutlined
                                    style={{
                                        fontSize: 24,
                                    }}
                                    spin
                                />} /></div>

                                <span id={`address_${record.id}`} style={{ cursor: "pointer" }} onDoubleClick={(e) => this.showEditPhone(e, `input_address_${record.id}`)}>
                                    <Popover content={<span>{record.recipient_address.full_address}</span>} title="Title">
                                        {CutString(record.recipient_address.full_address, 20, false)}
                                    </Popover>
                                </span>
                                <Input id={`input_address_${record.id}`} style={{ display: "none" }} onBlur={(e) => this.setPhoneNumber(e, record.id, `address_${record.id}`, 2)} data-phone={record.recipient_address.full_address} />


                            </div>
                        }
                        // render: (text, record, index) => {
                        //     return <Popover content={<span>{record.recipient_address.full_address}</span>} title="Title">
                        //         {CutString(record.recipient_address.full_address, 20, false)}
                        //     </Popover>
                        // }

                    },

                    {
                        title: 'Sản phẩm',
                        dataIndex: 'ttsp',
                        align: 'center',

                        render: (text, record, index) => {

                            return <button className="reset_btn" style={{ color: Colors.color1 }} onClick={() => this.handleOkDetail(record)}>xem</button>
                        }

                    },
                    {
                        title: 'Mã VD',
                        dataIndex: 'tracking_number',
                        align: 'left',

                        render: (text, record, index) => {

                            return CutString(text, 20, false)
                        }

                    },

                    // {
                    //     title: 'TT trước CK',
                    //     dataIndex: '',
                    //     align: 'right',
                    //     render: (text, record, index) => {

                    //         return <span>{formatNumber(record.payment.original_total_product_price)}</span>
                    //     }

                    // },
                    // {
                    //     title: 'TT sau CK',
                    //     dataIndex: '',
                    //     align: 'right',
                    //     render: (text, record, index) => {

                    //         return <span>{formatNumber(record.payment.sub_total)}</span>
                    //     }

                    // },
                    // {
                    //     title: 'Tổng ship',
                    //     dataIndex: '',
                    //     align: 'right',
                    //     render: (text, record, index) => {

                    //         return <span>{formatNumber(record.payment.shipping_fee)}</span>
                    //     }

                    // },
                    {
                        title: 'Tổng tiền',
                        dataIndex: '',
                        align: 'right',
                        render: (text, record, index) => {

                            return <span>{formatNumber(record.payment.total_amount)}</span>
                        }

                    },
                    {
                        title: 'Hóa đơn',
                        dataIndex: '',
                        align: 'right',
                        render: (text, record, index) => {
                            if (record.invCode) {
                                if (record.link_invoice) {
                                    return <Tag color={`success`} style={{ cursor: "pointer" }} ><a target="blank_" href={record.link_invoice}>{record.invCode}</a> </Tag>
                                } else {
                                    return <Tag color={`success`} style={{ cursor: "pointer" }} >{record.invCode}</Tag>

                                }
                                // return <Tag color={`success`} onClick={() => { this.showBillVat(record) }} style={{ cursor: "pointer" }} >{record.invCode}</Tag>
                            }
                            return <Tag color={`warning`} >Chưa xuất</Tag>
                        }

                    },
                    {
                        title: 'ngày GH thành công',
                        dataIndex: 'delivery_time',
                        // width: "7%",
                        filterSearch: true,
                        sorter: (a, b) => a.delivery_time - b.delivery_time,
                        render: (text, record, index) => {
                            return moment(text).format('DD/MM/YYYY H:m:s');;
                        }

                    },
                    {
                        title: 'ngày update TT',
                        dataIndex: 'update_time',
                        // width: "7%",
                        filterSearch: true,
                        sorter: (a, b) => a.update_time - b.update_time,
                        render: (text, record, index) => {
                            return moment(text).format('DD/MM/YYYY H:m:s');;
                        }

                    },
                    {
                        title: 'ngày tạo',
                        dataIndex: 'create_time',
                        // width: "7%",
                        filterSearch: true,
                        filters: filters.created_at,
                        onFilter: (value, record) => {

                            return moment(record.created_at).format('DD/MM/YYYY') === moment(value).format('DD/MM/YYYY')

                        },
                        sorter: (a, b) => a.create_time - b.create_time,
                        render: (text, record, index) => {
                            return moment(text).format('DD/MM/YYYY H:m:s');;
                        }

                    },
                    {
                        title: 'Cập nhật',
                        dataIndex: '',
                        // width: "7%",
                        render: (text, record, index) => {
                            return <Button onClick={() => { this.updateOnlyOrderTiktok(record) }} type="primary" >Cập nhật</Button>
                        }

                    },
                    {
                        title: 'TT HĐ',
                        dataIndex: '',
                        // width: "7%",
                        render: (text, record, index) => {
                            return <Button onClick={() => {
                                this.setState(state => {
                                    return {
                                        isGetInvoiceInfo: record.id_,
                                        visible: true,
                                    }
                                })
                            }} className="bg-warning" disabled={record.oid == null || record.oid == ""}>TTHĐ</Button>
                        }

                    },
                    {
                        title: 'Hoạch toán',
                        dataIndex: '',
                        // width: "7%",
                        render: (text, record, index) => {
                            return !record.accountant_code_revenue ? <Button onClick={() => { this.acountant(record, 1) }} className="bg-danger" loading={this.state.loadingAcountant} disabled={record.accountant_code_revenue}>HT</Button> : record.accountant_code_revenue
                        }

                    },
                    {
                        title: 'Hoạch toán CP',
                        dataIndex: '',
                        // width: "7%",
                        render: (text, record, index) => {
                            return !record.accountant_expense ? <Button onClick={() => { this.acountant(record, 2) }} className="bg-info" loading={this.state.loadingAcountant} disabled={record.accountant_expense}>HT CP</Button> : record.accountant_expense
                        }

                    },

                    {
                        title: 'KT HD',
                        dataIndex: '',
                        // width: "7%",
                        render: (text, record, index) => {
                            return <Button onClick={() => {
                                this.setState(state => {
                                    return {
                                        isCheckInvoceInfo: record.id_,
                                        visible: true,
                                    }
                                })
                            }} className="bg-warning" disabled={false}>KT HĐ</Button>
                        }

                    },
                    {
                        title: 'Đơn thay thế',
                        dataIndex: 'replace_invCode',
                        render: (text, record, index) => {
                            return <div className="position-relative">
                                <div id={`loading_replace_invCode_${record.id}`} style={{ position: "absolute", width: "100%", height: "100%", display: "none" }} className=" justify-content-center align-items-center"> <Spin indicator={<LoadingOutlined
                                    style={{
                                        fontSize: 24,
                                    }}
                                    spin
                                />} /></div>

                                <span id={`replace_invCode_${record.id}`} style={{ cursor: "pointer" }} onDoubleClick={(e) => this.showEditPhone(e, `input_replace_invCode_${record.id}`)}>
                                    {record.replace_invCode?record.replace_invCode:"Cập nhật"}
                                </span>
                                <Input id={`input_replace_invCode_${record.id}`} style={{ display: "none" }} onBlur={(e) => this.setPhoneNumber(e, record.id, `replace_invCode_${record.id}`, 4)} data-phone={record.recipient_address.full_address} />


                            </div>
                        }
                        // render: (text, record, index) => {
                        //     return <Popover content={<span>{record.recipient_address.full_address}</span>} title="Title">
                        //         {CutString(record.recipient_address.full_address, 20, false)}
                        //     </Popover>
                        // }

                    },
                    {
                        title: 'Đơn hủy',
                        dataIndex: 'is_cancel',
                        render: (text, record, index) => {
                            return <div className="position-relative">
                                <div id={`loading_is_cancel_${record.id}`} style={{ position: "absolute", width: "100%", height: "100%", display: "none" }} className=" justify-content-center align-items-center"> <Spin indicator={<LoadingOutlined
                                    style={{
                                        fontSize: 24,
                                    }}
                                    spin
                                />} /></div>

                                <span id={`is_cancel_${record.id}`} style={{ cursor: "pointer" }} onDoubleClick={(e) => this.showEditPhone(e, `input_is_cancel_${record.id}`)}>
                                   {parseInt(text)==1?<span className="text-danger">Đã hủy</span>:"Cập nhật"}
                                </span>
                                <Input id={`input_is_cancel_${record.id}`} style={{ display: "none" }} onBlur={(e) => this.setPhoneNumber(e, record.id, `is_cancel_${record.id}`, 5)} data-phone={record.recipient_address.full_address} />


                            </div>
                        }
                        // render: (text, record, index) => {
                        //     return <Popover content={<span>{record.recipient_address.full_address}</span>} title="Title">
                        //         {CutString(record.recipient_address.full_address, 20, false)}
                        //     </Popover>
                        // }

                    },
                    // {
                    //     title: 'Xác nhận',
                    //     dataIndex: 'status_text',
                    //     align: 'center',
                    //     width: "15%",
                    //     render: (text, record, index) =>
                    //     {

                    //         return <Popover placement="top" content={<div style={{ display: "flex", flexDirection: "column", padding: "10px" }}>
                    //             <button className="reset_btn" style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", padding: "5px 0px", alignItems: "center", cursor: "pointer" }}
                    //                 onClick={() =>
                    //                 {
                    //                     this.update_(1, record);
                    //                 }}
                    //             >
                    //                 <SafetyOutlined /> <span style={{ marginLeft: "10px" }}>Xác nhận đơn hàng</span>
                    //             </button>
                    //             <Divider dashed={true} style={{ borderColor: Colors.colorblue, margin: "5px 0" }} />
                    //             <button className="reset_btn" style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", padding: "5px 0px", alignItems: "center", cursor: "pointer" }}
                    //                 onClick={() =>
                    //                 {
                    //                     this.update_(2, record);
                    //                 }}
                    //             >
                    //                 <GiftOutlined /> <span style={{ marginLeft: "10px" }}>Đang đóng hàng</span>
                    //             </button>
                    //             <Divider dashed={true} style={{ borderColor: Colors.colorblue, margin: "5px 0" }} />
                    //             <button className="reset_btn" style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", padding: "5px 0px", alignItems: "center", cursor: "pointer" }}
                    //                 onClick={() =>
                    //                 {
                    //                     this.update_(3, record);
                    //                 }}
                    //             >
                    //                 <CheckOutlined /> <span style={{ marginLeft: "10px" }}>Chờ chuyển hàng</span>
                    //             </button>
                    //             <Divider dashed={true} style={{ borderColor: Colors.colorblue, margin: "5px 0" }} />
                    //             <button className="reset_btn" style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", padding: "5px 0px", alignItems: "center", cursor: "pointer" }}
                    //                 onClick={() =>
                    //                 {
                    //                     this.update_(4, record);
                    //                 }}
                    //             >
                    //                 <CarOutlined /> <span style={{ marginLeft: "10px" }}>Gửi hàng di</span>
                    //             </button>

                    //         </div>} title="Lưu" >

                    //             <Tag color={arrColors[record.status]} style={{ cursor: "pointer" }}>{CutString(text, 10, false)}</Tag>
                    //         </Popover>
                    //     },
                    // },
                    // {
                    //     title: 'Hành động',
                    //     dataIndex: 'action',
                    //     width: "7%",
                    //     render: (text, record, index) => (
                    //         <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }} >{text}
                    //             <span className="">
                    //                 <button className="reset_btn btn_edit btn__" onClick={() => this.editRows(record)} >
                    //                     <EditOutlined style={{ color: Colors.colorEdit }} /> </button>
                    //                 <button className="reset_btn btn_delete btn__" onClick={() => { this.deleteModal(record) }}><DeleteOutlined style={{ color: Colors.colorDelete }} /></button>
                    //                 <button className="reset_btn btn_view btn__" onClick={() => { this.handleOkDetail(record) }}> <FolderViewOutlined style={{ color: Colors.colorView }} /></button>
                    //                 <button className="reset_btn btn_view btn__" onClick={() => { this.duplicateItem(record) }}>
                    //                     <DiffOutlined style={{ color: Colors.colorView }} /></button>
                    //             </span>
                    //         </div>
                    //     ),
                    // },
                ]
            }
        })
    }

    acountant = async (record, type = 1) => {
        // this.setState(state => {
        //     return {
        //         visibleAccountant: true
        //     }
        // })

        // return;
        const { ListEcommerceType, ecomType } = this.state;
        const ecomType_ = ListEcommerceType.filter((item, index) => {
            return parseInt(item.id) == ecomType;

        })
        let response = null;
        this.setState(state => {
            return {
                loadingAcountant: true
            }
        })
        console.log("ecomType_ecomType_ ", ecomType_);

        if (ecomType_[0]) {
            switch (ecomType_[0].keyshow) {
                case ECOMMERCE_KEY.TITOK:

                    response = await ActionsWeb.acountantTiktok({ order_id: record.id_, idType: 1, config_code: this.state.code, type: type });
                    break;
                case ECOMMERCE_KEY.LAZADA:

                    break;
                case ECOMMERCE_KEY.SHOPEE:
                    response = await ActionsWeb.acountantShopee({ order_id: record.id_, idType: 1, config_code: this.state.code, type: type });
                    break;
                default:
                    break;
            }
        }
        this.setState(state => {
            return {
                loadingAcountant: false
            }
        })
        if (response.code == 200) {
            if (response.message) {
                message.success(response.message);
            } else {
                message.success("Cập nhật thành công");
            }
            // phone_.innerHTML = value;
            // if (this.formRef.current) {
            //     this.formRef.current.submit();
            // }
        } else {
            if (response.message) {
                if (typeof response.message == "string") {
                    message.error(response.message);
                } else {
                    (Object.values(response.message) || []).map((value, index) => {
                        message.error(value);
                    })
                }

            } else {
                message.warning("Cập nhật không thành công");
            }
        }
    }

    acountantMultiple = async (type = 1) => {
        const { selectedRows } = this.state;

        const { ListEcommerceType, ecomType } = this.state;
        const ecomType_ = ListEcommerceType.filter((item, index) => {
            return parseInt(item.id) == ecomType;

        })
        let response = null;
        this.setState(state => {
            return {
                loadingAcountant: true
            }
        })
        const orderIds = selectedRows.map((value, index) => {
            return value.id_;
        })
        if (ecomType_[0]) {
            switch (ecomType_[0].keyshow) {
                case ECOMMERCE_KEY.TITOK:

                    response = await ActionsWeb.acountantTiktokMultiple({ order_ids: orderIds, idType: 1, config_code: this.state.code, type: type });
                    break;
                case ECOMMERCE_KEY.LAZADA:

                    break;
                case ECOMMERCE_KEY.SHOPEE:
                    response = await ActionsWeb.acountantShopeeMultiple({ order_ids: orderIds, idType: 1, config_code: this.state.code, type: type });
                    break;
                default:
                    break;
            }
        }
        this.setState(state => {
            return {
                loadingAcountant: false
            }
        })
        if (response.code == 200) {
            if (response.message) {
                message.success(response.message);
            } else {
                message.success("Cập nhật thành công");
            }
            // this.setState(state=>{
            //     return {
            //         selectedRows:[]
            //     }
            // })
            // phone_.innerHTML = value;
            // if (this.formRef.current) {
            //     this.formRef.current.submit();

            // }
        } else {
            if (response.message) {
                if (typeof response.message == "string") {
                    message.error(response.message);
                } else {
                    (Object.values(response.message) || []).map((value, index) => {
                        message.error(value);
                    })
                }

            } else {
                message.warning("Cập nhật không thành công");
            }
        }
    }
    checkInvoice = async (idType, ids_) => {
        const { ListEcommerceType, ecomType } = this.state;
        const ecomType_ = ListEcommerceType.filter((item, index) => {
            return parseInt(item.id) == ecomType;

        })
        this.setState(state=>{
            return{
                loadingInvoice:true
            }
        })
        let response = null;
        if (ecomType_[0]) {
            switch (ecomType_[0].keyshow) {
                case ECOMMERCE_KEY.TITOK:

                        response = await ActionsWeb.checkInvoiceTiktok({ idType: idType, ids_: ids_ });
                    break;
                case ECOMMERCE_KEY.LAZADA:

                    break;
                case ECOMMERCE_KEY.SHOPEE:
                    response = await ActionsWeb.checkInvoiceShopee({ idType: idType, ids_: ids_ });
                    break;
                default:
                    break;
            }
        }
       
      

        if (response.code == 200) {
            if (response.data) {


            }
            this.setState(state => {
                return {
                    isCheckInvoceInfo: false,
                    visible: false,
                    visibleCheckInvoice: true,
                    dataCheckInvoice: response.data ? response.data : null
                }
            })
            // if (this.formRef.current) {
            //     this.formRef.current.submit();
            // }
            message.success("Cập nhật thành công");
        } else {
            message.warning("Cập nhật không thành công");

        }
        this.setState(state=>{
            return{
                loadingInvoice:false
            }
        })
    }
    updateInvoice = async (idType, ids_) => {
        const { ListEcommerceType, ecomType } = this.state;
        const ecomType_ = ListEcommerceType.filter((item, index) => {
            return parseInt(item.id) == ecomType;

        })
        this.setState(state=>{
            return{
                loadingInvoice:true
            }
        })
        let response = null;
        if (ecomType_[0]) {
            switch (ecomType_[0].keyshow) {
                case ECOMMERCE_KEY.TITOK:

                        response = await ActionsWeb.updateInvoiceTiktok({ idType: idType, ids_: ids_ });
                    break;
                case ECOMMERCE_KEY.LAZADA:

                    break;
                case ECOMMERCE_KEY.SHOPEE:
                    response = await ActionsWeb.updateInvoiceShopee({ idType: idType, ids_: ids_ });
                    break;
                default:
                    break;
            }
        }
       
        // console.log("responseresponse ", response);

        if (response.code == 200) {
            if (response.data) {


            }
            this.setState(state => {
                return {
                    isGetInvoiceInfo: false,
                    visible: false,
                }
            })
            if (this.formRef.current) {
                this.formRef.current.submit();
            }
            message.success("Cập nhật thành công");
        } else {
            message.warning("Cập nhật không thành công");

        }
        this.setState(state=>{
            return{
                loadingInvoice:false
            }
        })
    }

    updateOnlyOrderTiktok = async (record) => {
        const { ListEcommerceType, ecomType } = this.state;
        const ecomType_ = ListEcommerceType.filter((item, index) => {
            return parseInt(item.id) == ecomType;

        })
        let response = null;
        if (ecomType_[0]) {
            switch (ecomType_[0].keyshow) {
                case ECOMMERCE_KEY.TITOK:

                        response = await ActionsWeb.updateOnlyOrderTiktok({ code: this.state.code, ids: [record.id_] });
                    break;
                case ECOMMERCE_KEY.LAZADA:

                    break;
                case ECOMMERCE_KEY.SHOPEE:
                    response = await ActionsWeb.updateOnlyOrderShopee({ code: this.state.code, ids: [record.id_] });
                    break;
                default:
                    break;
            }
        }
       
      
        if (response.code == 200) {
            if (response.data) {


            }
            if (this.formRef.current) {
                this.formRef.current.submit();
            }
            message.success("Cập nhật thành công");
        } else {
            message.warning("Cập nhật không thành công");

        }
    }

    duplicateItem = (record) => {
        this.setState(state => {
            return {
                isNewList: true,
                isDuplicate: true
            }
        })
        this.SetisLoading(true)
        this.props.duplicateTiktokOrdersRequest({ id: record.id })
    }
    update_ = (e, record) => {
        this.setState(state => {
            return {
                isNewList: true
            }
        })

        record.status = e
        this.SetisLoading(true)
        this.props.StoreTiktokOrdersRequest(record, record.id);

    }
    data = () => {
        try {

            let { ListTiktokOrders } = this.state;

            if (ListTiktokOrders.length <= 0) {
                return;
            }
            // console.log('====================================');
            // console.log(ListTiktokOrders);
            // console.log('====================================');
            let data = [];
            let listfil = {};
            let meta = {
                ...this.state.meta,
                current_page: ListTiktokOrders?.data.current_page,
                first_page_url: ListTiktokOrders?.data.first_page_url,
                from: ListTiktokOrders?.data.from,
                last_page: ListTiktokOrders?.data.last_page,
                last_page_url: ListTiktokOrders?.data.last_page_url,
                links: ListTiktokOrders?.data.links,
                next_page_url: ListTiktokOrders?.data.next_page_url,
                path: ListTiktokOrders?.data.path,
                per_page: ListTiktokOrders?.data.per_page,
                prev_page_url: ListTiktokOrders?.data.prev_page_url,
                to: ListTiktokOrders?.data.to,
                total: ListTiktokOrders?.data.total,
            };
            let filtilte = [];
            let filslug = [];
            let filprice = [];
            let status = [];
            let filCreatedate = [];
            let filUpdatedate = [];
            ListTiktokOrders?.data.data.map((item, index) => {
                filtilte = [...filtilte, {
                    text: item.title,
                    value: item.title,
                }]
                filslug = [...filslug, {
                    text: item.slug,
                    value: item.slug,
                }]
                filprice = [...filprice, {
                    text: item.price,
                    value: item.price,
                }]
                if (!status.some(s => s.value === item.status)) {
                    status = [...status, {
                        text: item.status,
                        value: item.status,
                    }];
                }

                filCreatedate = [...filCreatedate, {
                    text: moment(item.created_at).format('DD/MM/YYYY'),
                    value: moment(item.created_at).format('DD/MM/YYYY'),
                }]

                filUpdatedate = [...filUpdatedate, {
                    text: moment(item.updated_at).format('DD/MM/YYYY'),
                    value: moment(item.updated_at).format('DD/MM/YYYY'),
                }]

                item.key = item.id
                data.push(item)

            });

            const filhidden = [{
                text: "Hiển thị",
                value: 0,
            }, {
                text: "Ẩn",
                value: 1,
            }]

            listfil = { ...listfil, title: filtilte, hidden: filhidden, slug: filslug, price: filprice, created_at: filCreatedate, updated_at: filUpdatedate, status: status }
            this.setColumn_(listfil);
            this.setState(state => {
                return {
                    data: data,
                    meta: meta,
                    isSket: false,
                    groupReport: ListTiktokOrders?.groupReport
                }
            }, () => {
                this.setState(state => {
                    return {
                        isNewList: false,
                    }
                });

            })
        } catch (error) {
            console.error(error);
        }
    }

    editRows = (record) => {

        this.props.history.push({
            pathname: LINK.WEB_ORDERS_TIKTOK + "/" + record.id,
        });

    }
    setPhoneNumber = async (e, id, idHmtl, type = 1) => {
        const target = e.target;
        let value = target.value;


        const oldPhone = target.getAttribute("data-phone")

        if (type == 1 && (value && value.charAt(0) !== '0')) {
            value = '0' + value;
        }

        const phone_ = document.getElementById(idHmtl);
        target.style.display = "none";
        phone_.style.display = "block";
        if (value && value != oldPhone) {
            const loading_phone_ = document.getElementById(`loading_phone_${id}`);
            loading_phone_.style.display = "flex"
            // console.log("cập nhật ", oldPhone);
            let response = await this.updateInfobuyer(id, value, type);

            // const response = await ActionsWeb.updatePhoneNumberTiktok({ id: id, phone: value });
            // console.log("responseresponseresponseresponse ",response);

            loading_phone_.style.display = "none"
            if (response.code == 200) {
                if (response.message) {
                    message.success(response.message);
                } else {
                    message.success("Cập nhật thành công");
                }
                phone_.innerHTML = value;

            } else {
                if (response.message) {
                    if (typeof response.message == "string") {
                        message.error(response.message);
                    } else {
                        (Object.values(response.message) || []).map((value, index) => {
                            message.error(value);
                        })
                    }

                } else {
                    message.warning("Cập nhật không thành công");
                }
            }
        }


    }
    updateInfobuyer = async (id, value, type) => {

        const { ListEcommerceType, ecomType } = this.state;
        const ecomType_ = ListEcommerceType.filter((item, index) => {
            return parseInt(item.id) == ecomType;

        })
        let response = null;
        if (ecomType_[0]) {
            switch (ecomType_[0].keyshow) {
                case ECOMMERCE_KEY.TITOK:

                    if (type == 1) {
                        response = await ActionsWeb.updatePhoneNumberTiktok({ id: id, phone: value });
                    }
                    if (type == 2) {
                        response = await ActionsWeb.updateAddressTiktok({ id: id, address: value });
                    }
                    if (type == 3) {
                        response = await ActionsWeb.updateNameTiktok({ id: id, name: value });
                    }
                    if (type == 4) {
                        response = await ActionsWeb.updateInvocieReplaceTiktok({ id: id, invoice: value });
                    }
                    if (type == 5) {
                        response = await ActionsWeb.updateIsCancelTiktok({ id: id, is_cancel: value });
                    }
                    break;
                case ECOMMERCE_KEY.LAZADA:

                    break;
                case ECOMMERCE_KEY.SHOPEE:
                    if (type == 1) {
                        response = await ActionsWeb.updatePhoneNumberShopee({ id: id, phone: value });
                    }
                    if (type == 2) {
                        response = await ActionsWeb.updateAddressShopee({ id: id, address: value });
                    }
                    if (type == 3) {
                        response = await ActionsWeb.updateNameShopee({ id: id, name: value });
                    }
                    if (type == 4) {
                        response = await ActionsWeb.updateInvocieReplaceShopee({ id: id, invoice: value });

                    }
                    if (type == 5) {
                        response = await ActionsWeb.updateIsCancelShopee({ id: id, is_cancel: value });

                    }
                    break;
                default:
                    break;
            }
        }

        return response;
    }
    showEditPhone = (e, id) => {
        const phone_ = document.getElementById(id);
        const target = e.target;
        target.style.display = "none";
        phone_.style.display = "block";
        phone_.focus();
    }
    handleClickOpenTab = (id) => {
        // Mở tab mới với URL cụ thể
        const newTab = window.open(`https://seller-vn.tiktok.com/order/detail?order_no=${id}&shop_region=VN`, '_blank');

        if (newTab) {
            // Lắng nghe sự kiện từ tab mới
            const messageListener = (event) => {
                if (event.source === newTab && event.data === 'ready') {
                    // Gửi script để thực thi
                    newTab.postMessage('executeScript', '*');
                }
            };

            window.addEventListener('message', messageListener);

            // Tạo nội dung cho tab mới
            const checkIfTabIsLoaded = setInterval(() => {
                if (newTab.closed) {
                    clearInterval(checkIfTabIsLoaded);
                    window.removeEventListener('message', messageListener);
                } else {
                    newTab.postMessage('checkReady', '*');
                }
            }, 1000);
        }
    };
    redirectLink = (id) => {

        // this.handleClickOpenTab(id);
        // return;
        const { ListEcommerceType, ecomType } = this.state;
        const ecomType_ = ListEcommerceType.filter((value, index) => {
            return parseInt(value.id) == ecomType;

        })
        if (ecomType_[0]) {
            // console.log("ListEcommerceType ", ecomType_[0]);
            switch (ecomType_[0].keyshow) {
                case ECOMMERCE_KEY.TITOK:
                    window.open(`https://seller-vn.tiktok.com/order/detail?order_no=${id}&shop_region=VN`, '_blank');
                    break;
                case ECOMMERCE_KEY.LAZADA:

                    break;
                case ECOMMERCE_KEY.SHOPEE:
                    window.open(`https://banhang.shopee.vn/portal/sale/order/${id}`, '_blank');
                    break;
                default:
                    break;
            }
        }
    }
    onFinishForm = (values) => {


        const { code } = this.state;
        const filteredvalues = Object.fromEntries(
            Object.entries(values).filter(([_, value]) => { return value !== undefined && value !== "" })
        );

        if (filteredvalues.created_at && filteredvalues.created_at.length > 0) {
            filteredvalues.fromdate = filteredvalues.created_at[0].format("YYYY-MM-DD")
            filteredvalues.todate = filteredvalues.created_at[1].format("YYYY-MM-DD")
        }
        if (filteredvalues.updated_at && filteredvalues.updated_at.length > 0) {
            filteredvalues.fromUpdate = filteredvalues.updated_at[0].format("YYYY-MM-DD")
            filteredvalues.toUpdate = filteredvalues.updated_at[1].format("YYYY-MM-DD")
        }

        if (filteredvalues.delivery_time && filteredvalues.delivery_time.length > 0) {
            filteredvalues.fromdeliverydate = filteredvalues.delivery_time[0].format("YYYY-MM-DD")
            filteredvalues.todeliverydate = filteredvalues.delivery_time[1].format("YYYY-MM-DD")
        }
        // delete filteredvalues.created_at
        // console.log('====================================');
        // console.log("filteredvalues", filteredvalues);
        // console.log('====================================');
        filteredvalues.ecoId = code;
        const { ListEcommerceType, ecomType } = this.state;
        const ecomType_ = ListEcommerceType.filter((value, index) => {
            return parseInt(value.id) == ecomType;

        })
        if (ecomType_[0]) {
            // console.log("ListEcommerceType ", ecomType_[0]);
            switch (ecomType_[0].keyshow) {
                case ECOMMERCE_KEY.TITOK:
                    this.props.SearchTiktokOrdersRequest({
                        limit: 100,
                        page: this.state.meta.current_page, column: "update_time", sort: this.state.meta.sort, page_size: this.state.meta.per_page
                    }, { data: filteredvalues });
                    break;
                case ECOMMERCE_KEY.LAZADA:
                    this.props.SearchLazadaOrdersRequest({
                        limit: 100, page_size: this.state.meta.per_page,
                        page: this.state.meta.current_page, column: "update_time", sort: this.state.meta.sort, ecoId: code
                    }, { data: filteredvalues });
                    break;
                case ECOMMERCE_KEY.SHOPEE:
                    this.props.SearchShopeeOrdersRequest({
                        limit: 100, page_size: this.state.meta.per_page,
                        page: this.state.meta.current_page, column: "update_time", sort: this.state.meta.sort, ecoId: code
                    }, { data: filteredvalues });
                    break;
                default:
                    break;
            }
        }

        const params_ = filteredvalues;
        delete params_.created_at
        delete params_.updated_at
        if(filteredvalues?.v_number?.length>100){
            filteredvalues.v_number=""
            delete filteredvalues.v_number
        }
        const searchParams = new URLSearchParams(filteredvalues).toString();
        this.props.history.push(`${LINK.WEB_ORDERS_TIKTOK}/${this.state.ecomType}/${this.state.code}?${searchParams}`);
        this.setState(state => {
            return {
                isNewList: true,
                isLoadingSearch: true
            }
        })
    }
    onResetFrom = () => {

        this.formRef.current.resetFields();
        this.formRef.current.submit();
        this.setState(state => {
            return {
                selectedRows: [],
                isNewList: false
            }
        })
    }
    deleteModal = (record) => {
        this.setState(state => {
            return {
                record: { ...record, deleted: 1 }

            }
        }, () => {
            this.setModalText(<span style={{ color: "red" }}>Bạn Có chắc muốn xóa! Sản phẩm sẽ được đưa vào thùng rác!</span>)
            this.setVisible(true)
        })
        // this.props.DeleteCategoryRequest(record, record.id ? record.id : "")
    }
    setModalText = (type) => {
        this.setState(state => {
            return {
                modalText: type
            }
        })
    }
    setVisible = (type) => {
        this.setState(state => {
            return {
                visible: type
            }
        })
    }
    setConfirmLoading = (type) => {
        this.setState(state => {
            return {
                confirmLoading: type
            }
        })
    }


    handleCancel = () => {
        // console.log('Clicked cancel button');
        this.setVisible(false);
    };
    OnPagination = (page, pageSize) => {
        // this.props.ListTiktokOrdersRequest({
        //     limit: pageSize,
        //     page: page, column: this.state.meta.column, sort: this.state.meta.sort
        // });
        const { code } = this.state;
        const urlParams = getParamsUrl();
        urlParams.ecoId = code;
        if (urlParams.status) {
            urlParams.status = urlParams.status.split(",");
        }
        this.props.SearchTiktokOrdersRequest({
            limit: this.state.meta.per_page,
            page: page, column: "update_time", sort: this.state.meta.sort, ecoId: code, page_size: this.state.meta.per_page
        }, { data: urlParams });
    }
    updateRecord = (e, record) => {
        record.status = e
        this.SetisLoading(true)
        this.props.StoreTiktokOrdersRequest(record, record.id);

    }

    onchangeProvinces = (e) => {
        // console.log('====================================');
        // console.log(e);
        // console.log('====================================');
        this.setState(state => {
            return {
                Province_id_: e
            }
        })
        this.props.ListDistrictsRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort, provinceid: e
        });
    }
    onchangeDistricts = (e) => {
        this.props.ListWarsRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort,
            provinceid: this.state.Province_id_, districtid: e
        });
    }
    changeTabOrder = (type) => {
        this.setState(state => {
            return {
                tabOrder: type
            }
        })
    }

    getTiktokOrders = (value) => {

        const dateRang = getDateRange(value);
        const boby = {};
        const formvalues = this.formRef.current.getFieldsValue();

        if (formvalues.created_at && formvalues.created_at.length > 0) {
            boby.create_time_ge = formvalues.created_at[0].toISOString();
            boby.create_time_lt = formvalues.created_at[1].toISOString();

        } else {
            boby.create_time_ge = dateRang.fromDate;
            boby.create_time_lt = dateRang.toDate;
        }
        if (formvalues.updated_at && formvalues.updated_at.length > 0) {
            boby.update_time_ge = formvalues.updated_at[0].toISOString();
            boby.update_time_lt = formvalues.updated_at[1].toISOString();
        }
        // console.log(dateRang);
        // console.log(formvalues);

        // return;
        this.setState(state => {
            return {
                isLoadingSearch: true
            }
        })

        const { ListEcommerceType, ecomType } = this.state;
        const ecomType_ = ListEcommerceType.filter((value, index) => {
            return parseInt(value.id) == ecomType;

        })
        if (ecomType_[0]) {
            // console.log("ListEcommerceType ", ecomType_[0]);
            switch (ecomType_[0].keyshow) {
                case ECOMMERCE_KEY.TITOK:
                    this.props.SyncTiktokOrdersRequest({
                        limit: this.state.meta.per_page,
                        page: this.state.meta.current_page, column: "create_time", sort: this.state.meta.sort, code: this.state.code, page_size: 100
                    }, { data: { ...boby } });
                    break;
                case ECOMMERCE_KEY.LAZADA:
                    this.props.SyncLazadaOrdersRequest({
                        limit: this.state.meta.per_page,
                        page: this.state.meta.current_page, column: "create_time", sort: this.state.meta.sort, code: this.state.code, page_size: 100
                    }, { data: { ...boby } });
                    break;
                case ECOMMERCE_KEY.SHOPEE:
                    this.props.SyncShopeeOrdersRequest({
                        limit: this.state.meta.per_page,
                        page: this.state.meta.current_page, column: "create_time", sort: this.state.meta.sort, code: this.state.code, page_size: 100
                    }, { data: { ...boby } });
                    break;
                default:
                    break;
            }
        }



        this.setState(state => {
            return {
                isNewList: true
            }
        })
    }
    connectSocket = () => {

        // const newSocket = new WebSocket(linkSocket);

        // newSocket.addEventListener('open', () =>
        // {
        //     console.log('Connected to WebSocket server');
        // });
        if (this.state.socket) {

            this.state.socket.addEventListener('message', (event) => {

                let json = JSON.parse(event.data);

                // switch (json.type) {
                //     case caseSocket.SYNC_EXPORT_BILL_MULTIPLE:
                //         let user = getCookie("user");
                //         user = JSON.parse(user);
                //         this.props.setIsync(false);

                //         if (user.id === json.user_id) {
                //             try {
                //                 let syncProducts = JSON.parse(json.data)
                //                 this.setState(state=>{
                //                     return {
                //                         dataExportSocket:syncProducts,
                //                         showExportRes:true
                //                     }
                //                 })
                //                 console.log('====================================');
                //                 console.log("json.msgjson.msg", syncProducts);
                //                 console.log('====================================');
                //             } catch (error) {

                //                 console.error("errorerror", error);
                //             }

                //         }
                //         break;

                //     default:
                //         break;
                // }

            });
        }



    }
    processSyncPrData = (syncProducts) => {

        let { code, isShowList, isSyncEdit } = this.state;
        if (isSyncEdit) {
            const syncId = Object.keys(syncProducts)[0];
            this.props.history.push({
                pathname: LINK.WEB_PRODUCTS_EDIT + "/" + syncId,

            });
            return;
        }
        if (isShowList) {
            this.props.SearchProductsTiktokRequest({
                limit: this.state.meta.per_page * 100,
                page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort, code: code, next_page_token: ""
            }, { data: {} });
        }

        this.setState(state => {
            return {
                syncProductsSet: [...state.syncProductsSet, ...Object.values(syncProducts)]
            }
        })
    }
    setRecord = (record) => {

        // console.log(record);

        this.setState(state => {
            return {
                record: record
            }
        })
    }
    showBillVat = async (record) => {
        if (record.id) {
            const response = await ActionsWeb.GetLinkBillVatMultipleRequest({ id: record.id });
            if (response) {
                const result = await response.json();
                if (result.code !== 200) {
                    message.warning(result.message)

                } else {
                    message.success(result.message)

                    //    if(this.formRef.current){
                    //     this.formRef.current.submit();
                    //    }
                }
                // console.log('result:', result);
            }
        }
    }

    exportBillGroup = async (values) => {
        const { selectedRowKeys } = this.state;
        // this.setState(state=>{
        //     return {
        //         showExportRes:true
        //     }
        // });
        //     return ;
        try {
            let response = null;
            response = await ActionsWeb.ExportTiktokBillVatMultipleRequest({ ids: selectedRowKeys, type: 2 });

            //   }
            if (response) {
                const result = await response.json();
                if (result.code !== 200) {
                    message.warning(result.message)

                } else {
                    message.success(result.message)

                    //    if(this.formRef.current){
                    //     this.formRef.current.submit();
                    //    }
                }
                // console.log('result:', result);
            }

        } catch (error) {
            console.error('Error:', error);
        }
    }
    exportBill = async (type, idType) => {
        const { selectedRowKeys } = this.state;
        let response = null;
        try {
            const { ListEcommerceType, ecomType } = this.state;
            const ecomType_ = ListEcommerceType.filter((value, index) => {
                return parseInt(value.id) == ecomType;

            })
            if (ecomType_[0]) {
                // console.log("ListEcommerceType ", ecomType_[0]);
                switch (ecomType_[0].keyshow) {
                    case ECOMMERCE_KEY.TITOK:
                        if (type == 1) {
                            response = await ActionsWeb.ExportTiktokBillVatMultipleRequest({ ids: selectedRowKeys, type: type, idType: idType });
                        }
                        break;
                    case ECOMMERCE_KEY.LAZADA:
                        if (type == 1) {
                            response = await ActionsWeb.ExportLazadaBillVatMultipleRequest({ ids: selectedRowKeys, type: type, idType: idType });
                        }
                        break;
                    case ECOMMERCE_KEY.SHOPEE:
                        // console.log("SHOPEESHOPEESHOPEE ");
                        if (type == 1) {
                            response = await ActionsWeb.ExportShopeeBillVatMultipleRequest({ ids: selectedRowKeys, type: type, idType: idType });
                        }
                        break;
                    default:
                        break;
                }
            }




            if (response) {
                const result = await response.json();
                if (result.code !== 200) {
                    message.warning(result.message)

                } else {
                    message.success(result.message)

                    //    if(this.formRef.current){
                    //     this.formRef.current.submit();
                    //    }
                }
                // console.log('result:', result);
            }

        } catch (error) {
            console.error('Error:', error);
        }
    }

    handleOkExportBill = (item) => {

        if (this.state.isGetInvoiceInfo) {
            if (this.state.isGetInvoiceInfo != "ALL") {
               
                this.updateInvoice(item.id, [this.state.isGetInvoiceInfo]);
            } else {
                const { selectedRows } = this.state;
                // console.log(selectedRows);
                const ids_ = selectedRows.map((value, index) => {
                    return value.id_;
                })
                this.updateInvoice(item.id, ids_);
            }

        } else if (this.state.isCheckInvoceInfo) {

            if (this.state.isCheckInvoceInfo != "ALL") {
                this.checkInvoice(item.id, [this.state.isCheckInvoceInfo]);
            } else {
                const { selectedRows } = this.state;
                // console.log(selectedRows);
                const ids_ = selectedRows.map((value, index) => {
                    return value.id_;
                })
                this.checkInvoice(item.id, ids_);
            }

        } else {
            this.exportBill(1, item.id);

        }
    };

    uploadBill = async (e) => {
        const file = e.target.files[0];
   

        let response = null;
        try {
            const { ListEcommerceType, ecomType } = this.state;
            const ecomType_ = ListEcommerceType.filter((value, index) => {
                return parseInt(value.id) == ecomType;

            })
            if (ecomType_[0]) {

                switch (ecomType_[0].keyshow) {
                    case ECOMMERCE_KEY.TITOK:

                         response = await ActionsWeb.updateBillTiktok(file);

                        break;
                    case ECOMMERCE_KEY.LAZADA:

                        break;
                    case ECOMMERCE_KEY.SHOPEE:
                        response = await ActionsWeb.updateBillShopee(file);

                        break;
                    default:
                        break;
                }
            }


            // return response;
          
            if (response) {

                if (response.code !== 200) {
                    message.warning(response.message)
                    this.formRef.current.submit();
                } else {
                    message.success(response.message)
    
                    //    if(this.formRef.current){
                    //     this.formRef.current.submit();
                    //    }
                }
                // console.log('result:', response);
            }
           
        } catch (error) {
            console.error('Error:', error);
        }

    }
    getFromSearch = () => {
        const values = this.formRef.current.getFieldsValue();
        const { code } = this.state;
        const filteredvalues = Object.fromEntries(
            Object.entries(values).filter(([_, value]) => { return value !== undefined && value !== "" })
        );

        if (filteredvalues.created_at && filteredvalues.created_at.length > 0) {
            filteredvalues.fromdate = filteredvalues.created_at[0].format("YYYY-MM-DD")
            filteredvalues.todate = filteredvalues.created_at[1].format("YYYY-MM-DD")
        }
        if (filteredvalues.updated_at && filteredvalues.updated_at.length > 0) {
            filteredvalues.fromUpdate = filteredvalues.updated_at[0].format("YYYY-MM-DD")
            filteredvalues.toUpdate = filteredvalues.updated_at[1].format("YYYY-MM-DD")
        }

        if (filteredvalues.delivery_time && filteredvalues.delivery_time.length > 0) {
            filteredvalues.fromdeliverydate = filteredvalues.delivery_time[0].format("YYYY-MM-DD")
            filteredvalues.todeliverydate = filteredvalues.delivery_time[1].format("YYYY-MM-DD")
        }
        // delete filteredvalues.created_at
        // console.log('====================================');
        // console.log("filteredvalues", filteredvalues);
        // console.log('====================================');
        filteredvalues.ecoId = code;
        return {
            body: {
                limit: this.state.meta.per_page,
                page: this.state.meta.current_page, column: "update_time", sort: this.state.meta.sort, page_size: this.state.meta.per_page
            }, filteredvalues: filteredvalues
        };
    }
    uploadExcelIncome = async (e) => {
        this.setState(state => {
            return {
                typeExcel: 4
            }
        })
        const datasearch = this.getFromSearch();

        const file = e.target.files[0];
        const { selectedRowKeys } = this.state;
        let response = null;
        try {
            const { ListEcommerceType, ecomType } = this.state;
            const ecomType_ = ListEcommerceType.filter((value, index) => {
                return parseInt(value.id) == ecomType;

            })
            if (ecomType_[0]) {

                switch (ecomType_[0].keyshow) {
                    case ECOMMERCE_KEY.TITOK:

                        response = await ActionsWeb.updateExcelTiktok(file, { type: 4 }, datasearch);

                        break;
                    case ECOMMERCE_KEY.LAZADA:

                        break;
                    case ECOMMERCE_KEY.SHOPEE:

                        break;
                    default:
                        break;
                }
            }




            if (response) {

                if (response.code !== 200) {
                    message.warning(response.message)

                } else {
                    message.success(response.message)
                    // this.props.SearchTiktokOrders(response);
                }
                // console.log('result:', response);
            }
            e.target.value = ""
        } catch (error) {
            console.error('Error:', error);
        }

        // console.log(response);



    }
    uploadExcelSearch = async (e) => {
        const datasearch = this.getFromSearch();

        const file = e.target.files[0];
      
        let response = null;
        try {
            const { ListEcommerceType, ecomType } = this.state;
            const ecomType_ = ListEcommerceType.filter((value, index) => {
                return parseInt(value.id) == ecomType;

            })
            if (ecomType_[0]) {

                switch (ecomType_[0].keyshow) {
                    case ECOMMERCE_KEY.TITOK:
                         response = await ActionsWeb.updateExcelTiktok(file, { type: 3 }, datasearch);
                        break;
                    case ECOMMERCE_KEY.LAZADA:

                        break;
                    case ECOMMERCE_KEY.SHOPEE:
                        response = await ActionsWeb.updateExcelShopee(file, { type: 3 }, datasearch);

                        break;
                    default:
                        break;
                }
            }



            this.setState(state => {
                return {
                    typeExcel: 3
                }
            })
            if (response) {

                if (response.code !== 200) {
                    message.warning(response.message)
    
                } else {
                    message.success(response.message)
                    this.props.SearchTiktokOrders(response);
                    if(response.data.ids){
                        if(this.formRef){
                            this.formRef.current.setFieldsValue({v_number:response.data.ids})
                        }
                    }
                }
                // console.log('result:', response);
            }
            e.target.value = ""
        } catch (error) {
            console.error('Error:', error);
        }

       
    }
    uploadExcelCompare = async (e) => {
        const datasearch = this.getFromSearch();

        const file = e.target.files[0];
        let response = null;
        try {
            const { ListEcommerceType, ecomType } = this.state;
            const ecomType_ = ListEcommerceType.filter((value, index) => {
                return parseInt(value.id) == ecomType;

            })
            if (ecomType_[0]) {

                switch (ecomType_[0].keyshow) {
                    case ECOMMERCE_KEY.TITOK:

                         response = await ActionsWeb.updateExcelTiktok(file, { type: 2 }, datasearch);

                        break;
                    case ECOMMERCE_KEY.LAZADA:

                        break;
                    case ECOMMERCE_KEY.SHOPEE:
                        response = await ActionsWeb.updateExcelShopee(file, { type: 2 }, datasearch);

                        break;
                    default:
                        break;
                }
            }



            this.setState(state => {
                return {
                    typeExcel: 2
                }
            })
            if (response) {
    
                if (response.code !== 200) {
                    message.warning(response.message)
    
                } else {
                    message.success(response.message)
                    if(response.data){
                        this.setState(state => {
                            return {
                                visibleExcelCompare: true,
                                ListexcelCompare: response.data
                            }
                        })
                    }
                    
                }
                // console.log('result:', response);
            }
            e.target.value = ""
        } catch (error) {
            console.error('Error:', error);
        }

       
       
    }
    uploadExcelReconciliation = async (e) => {
        const file = e.target.files[0];
        const response = await ActionsWeb.updateExcelTiktok(file, { type: 1 });

        if (response) {

            if (response.code !== 200) {
                message.warning(response.message)

            } else {
                message.success(response.message)

                this.setState(state => {
                    return {
                        visibleExcel: true,
                        Listexcel: response.data
                    }
                })
            }
            // console.log('result:', response);
        }
        e.target.value = ""
    }

    handleMouseDown = (e) => {


        if (e.target.tagName != "TH") return;
        this.setIsDragging(true);
        this.setStartX(e.pageX - this.tableRef.current.offsetLeft);
        this.setScrollLeft(this.tableRef.current.scrollLeft);

    };

    handleMouseMove = (e) => {
        if (!this.state.isDragging) return;
        e.preventDefault();
        const x = e.pageX - this.tableRef.current.offsetLeft;
        const walk = (x - this.state.startX) * 2; // Tốc độ cuộn (tăng hoặc giảm giá trị này tùy thích)
        this.tableRef.current.scrollLeft = this.state.scrollLeft - walk;
    };

    handleMouseUp = () => {
        this.setIsDragging(false);
    };

    handleMouseLeave = () => {
        this.setIsDragging(false);
    };

    render() {
        let { routers } = this.props;
        // console.log(this.state.visibleCheckInvoice);

        // console.log("runfirstdata[x] ",this.state.ListEcommerceConfig);
        return (
            <div className="main_content order_ tiktok" style={{ overflow: "unset" }}>

                {this.state.selectedRowKeys.length > 0 && <div style={{ position: "fixed", bottom: "300px", zIndex: 100 }}>
                    <Button type="primary" onClick={() => {
                        this.setState(state => {
                            return {
                                visible: true,
                                modalText: "Tạo hóa đơn hàng loạt?"
                            }
                        })
                    }}>
                        Tạo hóa đơn hàng loạt


                    </Button>
                    <Button onClick={() => {
                        this.setState(state => {
                            return {
                                visible: true,
                                modalText: "Cập nhật TT hóa đơn",
                                isGetInvoiceInfo: "ALL"
                            }
                        })
                    }} className="mt-2 bg-warning">
                        Cập nhật TT hóa đơn loạt


                    </Button>

                    <Button onClick={() => {
                        this.acountantMultiple(1);
                    }} className="mt-2 bg-danger">
                        Hoạch toán hàng loại


                    </Button>
                    <Button onClick={() => {
                        this.acountantMultiple(2);
                    }} className="mt-2 bg-info">
                        Hoạch toán chi phí hàng loại


                    </Button>

                    <Button onClick={() => {
                        this.setState(state => {
                            return {
                                isCheckInvoceInfo: "ALL",
                                visible: true,
                            }
                        })
                    }} className="mt-2 bg-info">
                        Kiểm tra thông tin hoa đơn hàng loạt


                    </Button>
                    {/* <Button type="primary" className="mt-2 bg-warning" onClick={() => {
                        this.setState(state => {
                            return {
                                visibleInfoBill: true
                            }
                        })
                    }}>
                        Gộp hóa đơn
                    </Button> */}
                </div>
                }
                <Modal
                    title="Thông tin"
                    visible={this.state.visibleCheckInvoice}
                    // onOk={this.handleOkExportBill}
                    // confirmLoading={this.state.confirmLoading}
                    onCancel={() => {
                        this.setState(state => {
                            return {
                                visibleCheckInvoice: false
                            }
                        })
                    }}
                    footer=""
                >

                    {(this.state.dataCheckInvoice?.length > 0) && "Phiếu sai"}
                    {(this.state.dataCheckInvoice?.length > 0) && this.state.dataCheckInvoice.map((value, index) => {
                        return <p key={index}>{value}</p>
                    })}
                    {(this.state.dataCheckInvoice?.length == 0) && "Tất cả phiếu đúng"}

                </Modal>

                <Modal
                    title="Thông báo"
                    visible={this.state.visible}
                    // onOk={this.handleOkExportBill}
                    confirmLoading={this.state.confirmLoading}
                    onCancel={this.handleCancel}
                    footer=""
                >
                  {this.state.loadingInvoice && <div style={{ position: "absolute", width: "100%", height: "100%", left: 0, top: 0 }} className="d-flex justify-content-center align-items-center">

                        <Spin indicator={<LoadingOutlined
                            style={{
                                fontSize: 24,
                            }}
                            spin
                        />} />
                    </div>} 
                    <p className="mb-4">{this.state.modalText}</p>

                    {this.state.ListConnectExportBill && this.state.ListConnectExportBill.map((value, index) => {
                        return <Button onClick={() => this.handleOkExportBill(value)}>
                            {value?.icon && <div>

                                {isValidURL(value?.icon) ? (
                                    <img src={value.icon} alt="icon" className='icon-mini' style={{ height: "20px" }} />
                                ) : (
                                    <div dangerouslySetInnerHTML={{ __html: value?.icon }} className='icon-mini' />
                                )}

                            </div>}
                            {!value?.icon && <div>  <span>{value.name}</span> </div>}

                        </Button>
                    })}
                </Modal>
                <Modal
                    title={
                        <div
                            style={{
                                width: '100%',
                                cursor: 'move',
                            }}
                            onMouseOver={() => {
                                if (this.state.disabled) {
                                    this.setDisabled(false);
                                }
                            }}
                            onMouseOut={() => {
                                this.setDisabled(true);
                            }}
                            onFocus={() => { }}
                            onBlur={() => { }} // end
                        >
                            Thông tin hóa đơn
                        </div>
                    }
                    modalRender={(modal) => (
                        <Draggable
                            disabled={this.state.disabled}
                            bounds={this.state.boundsTrans}
                            onStart={(event, uiData) => this.onStart(event, uiData)}
                        >
                            <div ref={this.draggleRef}>{modal}</div>
                        </Draggable>
                    )}
                    visible={this.state.visibleInfoBill}
                    onOk={() => {
                        this.setState(state => {
                            return {
                                visibleInfoBill: true
                            }
                        })
                    }}
                    onCancel={() => {
                        this.setState(state => {
                            return {
                                visibleInfoBill: false
                            }
                        })
                    }}

                    footer={[

                    ]}
                    width={"95%"}
                    bodyStyle={{
                        height: "80vh",
                        overflowY: "scroll"
                    }}
                    style={{ top: "60px", position: "relative" }}
                >
                    <FormBill selectedRows={this.state.selectedRows} selectedRowKeys={this.state.selectedRowKeys} ListConnectExportBill={this.state.ListConnectExportBill} ListEcommerceType={this.state.ListEcommerceType} ecomType={this.state.ecomType} />
                </Modal>
                <Modal
                    title={
                        <div
                            style={{
                                width: '100%',
                                cursor: 'move',
                            }}
                            onMouseOver={() => {
                                if (this.state.disabled) {
                                    this.setDisabled(false);
                                }
                            }}
                            onMouseOut={() => {
                                this.setDisabled(true);
                            }}
                            onFocus={() => { }}
                            onBlur={() => { }} // end
                        >
                            Thông tin file excel
                        </div>
                    }
                    modalRender={(modal) => (
                        <Draggable
                            disabled={this.state.disabled}
                            bounds={this.state.boundsTrans}
                            onStart={(event, uiData) => this.onStart(event, uiData)}
                        >
                            <div ref={this.draggleRef}>{modal}</div>
                        </Draggable>
                    )}
                    visible={this.state.visibleExcel}
                    onOk={() => {
                        this.setState(state => {
                            return {
                                visibleExcel: true
                            }
                        })
                    }}
                    onCancel={() => {
                        this.setState(state => {
                            return {
                                visibleExcel: false
                            }
                        })
                    }}

                    footer={[

                    ]}
                    width={"95%"}
                    bodyStyle={{
                        height: "80vh",
                        overflowY: "scroll"
                    }}
                    style={{ top: "60px", position: "relative" }}
                >
                    <div className="table-responsive" style={{ height: "100%" }}
                        ref={this.tableRef}
                        onMouseDown={this.handleMouseDown}
                        onMouseMove={this.handleMouseMove}
                        onMouseUp={this.handleMouseUp}
                        onMouseLeave={this.handleMouseLeave}
                    >
                        <table className="table table-ledger table-sm table-hover" style={{ width: "max-content", minWidth: "100%" }} >
                            <thead style={{ position: "sticky", top: 0, zIndex: 1000, cursor: "all-scroll" }} >
                                <tr className='noscroll'>
                                    {this.state.Listexcel && Object.keys(this.state.Listexcel[0]).map((value, index) => {
                                        return <th key={index} className='p-1 text-center align-middle'  >{value}</th>
                                    })}



                                </tr>
                                {/* <tr className='noscroll'>
                                    {this.state.Listexcel && Object.values(this.state.Listexcel[0]).map((value, index) => {
                                        return <th key={index} className='p-1 text-center align-middle' >{value}</th>
                                    })}



                                </tr> */}

                            </thead>
                            <tbody>
                                {this.state.Listexcel && this.state.Listexcel.map((value, index) => {
                                    let classColor = "bg-success";
                                    if (value.marked == 2) {
                                        classColor = "bg-warning";
                                    }
                                    if (value.marked == 3) {
                                        classColor = "bg-danger";
                                    }
                                    // if(this.state.typeExcel==2){
                                    //     classColor="bg-white"
                                    // }
                                    return <tr key={index} className={`${classColor}`}>
                                        {index > 0 && Object.values(value).map((item, i) => {
                                            return <td key={index + i} className='p-1 text-center align-middle' >{item}</td>
                                        })}


                                    </tr>
                                })}

                            </tbody>
                        </table>
                    </div>
                </Modal>
                <Modal
                    title={
                        <div
                            style={{
                                width: '100%',
                                cursor: 'move',
                            }}
                            onMouseOver={() => {
                                if (this.state.disabled) {
                                    this.setDisabled(false);
                                }
                            }}
                            onMouseOut={() => {
                                this.setDisabled(true);
                            }}
                            onFocus={() => { }}
                            onBlur={() => { }} // end
                        >
                            Thông tin file excel
                        </div>
                    }
                    modalRender={(modal) => (
                        <Draggable
                            disabled={this.state.disabled}
                            bounds={this.state.boundsTrans}
                            onStart={(event, uiData) => this.onStart(event, uiData)}
                        >
                            <div ref={this.draggleRef}>{modal}</div>
                        </Draggable>
                    )}
                    visible={this.state.visibleExcelCompare}
                    onOk={() => {
                        this.setState(state => {
                            return {
                                visibleExcelCompare: true
                            }
                        })
                    }}
                    onCancel={() => {
                        this.setState(state => {
                            return {
                                visibleExcelCompare: false
                            }
                        })
                    }}

                    footer={[

                    ]}
                    width={"95%"}
                    bodyStyle={{
                        height: "80vh",
                        overflowY: "scroll"
                    }}
                    style={{ top: "60px", position: "relative" }}
                >
                    <div className="row">
                        <div className="col-6">
                    <div className="table-responsive" style={{ height: "100%" }}
                        ref={this.tableRefc1}
                        // onMouseDown={this.handleMouseDown}
                        // onMouseMove={this.handleMouseMove}
                        // onMouseUp={this.handleMouseUp}
                        // onMouseLeave={this.handleMouseLeave}
                    >
                        <p className="fw-bold">Đơn hàng</p>

                        <table className="table table-ledger table-sm table-hover" style={{ width: "max-content", minWidth: "100%" }} >
                            <thead style={{ position: "sticky", top: 0, zIndex: 1000, cursor: "all-scroll" }} >
                                <tr className='noscroll'>
                                    {this.state.ListexcelCompare.sheet[0] && (Object.keys(this.state.ListexcelCompare.sheet[0])||[]).map((value, index) => {
                                        return <th key={index} className='p-1 text-center align-middle'  >{value} đơn hàng</th>
                                    })}



                                </tr>
                                {/* <tr className='noscroll'>
                                    {this.state.Listexcel && Object.values(this.state.Listexcel[0]).map((value, index) => {
                                        return <th key={index} className='p-1 text-center align-middle' >{value}</th>
                                    })}



                                </tr> */}

                            </thead>
                            <tbody>
                                {this.state.ListexcelCompare.sheet && this.state.ListexcelCompare.sheet.map((value, index) => {
                                    let classColor = "bg-success";
                                    if (value.marked == 2) {
                                        classColor = "bg-warning";
                                    }
                                    if (value.marked == 3) {
                                        classColor = "bg-danger";
                                    }
                                    // if(this.state.typeExcel==2){
                                    //     classColor="bg-white"
                                    // }
                                    return <tr key={index} className={`${classColor}`}>
                                        {index > 0 && Object.values(value).map((item, i) => {
                                            return <td key={index + i} className='p-1 text-center align-middle' >{item}</td>
                                        })}


                                    </tr>
                                })}

                            </tbody>
                        </table>
                    </div>
                    </div>
                    <div className="col-6">
                    <div className="table-responsive" style={{ height: "100%" }}
                        ref={this.tableRefc2}
                        // onMouseDown={this.handleMouseDown}
                        // onMouseMove={this.handleMouseMove}
                        // onMouseUp={this.handleMouseUp}
                        // onMouseLeave={this.handleMouseLeave}
                    >
                        <p className="fw-bold">Sheet data</p>
                        <table className="table table-ledger table-sm table-hover" style={{ width: "max-content", minWidth: "100%" }} >
                            <thead style={{ position: "sticky", top: 0, zIndex: 1000, cursor: "all-scroll" }} >
                                <tr className='noscroll'>
                                    {this.state.ListexcelCompare.order[0] && (Object.keys(this.state.ListexcelCompare.order[0])||[]).map((value, index) => {
                                        return <th key={index} className='p-1 text-center align-middle'  >{value} </th>
                                    })}



                                </tr>
                                {/* <tr className='noscroll'>
                                    {this.state.Listexcel && Object.values(this.state.Listexcel[0]).map((value, index) => {
                                        return <th key={index} className='p-1 text-center align-middle' >{value}</th>
                                    })}



                                </tr> */}

                            </thead>
                            <tbody>
                                {this.state.ListexcelCompare.order && this.state.ListexcelCompare.order.map((value, index) => {
                                    let classColor = "bg-success";
                                    if (value.marked == 2) {
                                        classColor = "bg-warning";
                                    }
                                    if (value.marked == 3) {
                                        classColor = "bg-danger";
                                    }
                                    // if(this.state.typeExcel==2){
                                    //     classColor="bg-white"
                                    // }
                                    return <tr key={index} className={`${classColor}`}>
                                        {index > 0 && Object.values(value).map((item, i) => {
                                            return <td key={index + i} className='p-1 text-center align-middle' >{item}</td>
                                        })}


                                    </tr>
                                })}

                            </tbody>
                        </table>
                    </div>
                    </div>
                    </div>
                </Modal>
                <Modal
                    title={
                        <div
                            style={{
                                width: '100%',
                                cursor: 'move',
                            }}
                            onMouseOver={() => {
                                if (this.state.disabled) {
                                    this.setDisabled(false);
                                }
                            }}
                            onMouseOut={() => {
                                this.setDisabled(true);
                            }}
                            onFocus={() => { }}
                            onBlur={() => { }} // end
                        >
                            Chi tiết:  <span style={{}}>id - {this.state.record.id_}</span>  <span style={{}}> mã vận đơn - {this.state.record.tracking_number}</span>
                            <span style={{}}> Số hóa đơn - {this.state.record.invCode}</span> <span style={{}}> OID - {this.state.record.OID}</span>
                            <span style={{}}> mã tra cứu - {this.state.record.invRef}</span>
                        </div>
                    }
                    modalRender={(modal) => (
                        <Draggable
                            disabled={this.state.disabled}
                            bounds={this.state.boundsTrans}
                            onStart={(event, uiData) => this.onStart(event, uiData)}
                        >
                            <div ref={this.draggleRef}>{modal}</div>
                        </Draggable>
                    )}
                    visible={this.state.visibledetail}
                    onOk={this.handleOkDetail}
                    onCancel={this.handleCancelDetail}

                    footer={[

                    ]}
                    width={"95%"}
                    bodyStyle={{
                        height: "80vh",
                        overflowY: "scroll"
                    }}
                    style={{ top: "60px", position: "relative" }}
                >
                    <div className="modal_content_ h-100">
                        <BoxOrder isView={true} record={this.state.record} setRecord={this.setRecord} idrecord={this.state.record.id} isreset={this.state.isreset} handleCancelDetail={this.handleCancelDetail}
                            formRef={this.formRef} ListConnectExportBill={this.state.ListConnectExportBill} ListEcommerceType={this.state.ListEcommerceType} ecomType={this.state.ecomType} code={this.state.code}
                        />
                    </div>
                </Modal>
                <div className="table_w" >
                    <Card title={<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>

                        <div style={{ display: "flex", alignItems: "center" }}>
                            <button className="reset_btn btn_edit btn__" onClick={() => { this.SetisShowList(!this.state.isShowList) }}
                                style={{ display: "flex", justifyContent: "center", alignContent: "center" }}
                            >
                                {this.state.isShowList && <AppstoreOutlined style={{ color: Colors.pop2[3] }} />}
                                {!this.state.isShowList && <UnorderedListOutlined style={{ color: Colors.pop2[3] }} />}
                            </button>
                            <span style={{ marginLeft: "10px", color: Colors.pop2[3] }}>Đơn hàng</span>

                            {this.state.ListEcommerceType && this.state.ListEcommerceType.map((value) => {

                                return <Popover placement="bottom" content={
                                    <List

                                        itemLayout="horizontal"

                                        dataSource={this.state.ListEcommerceConfig?.filter((item) => { return item.ecommerce_type_id == value.id })}
                                        renderItem={(item) => (
                                            <List.Item>
                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                    <Link to={LINK.WEB_ORDERS_TIKTOK + "/" + value.id + "/" + item?.id_}>{item?.name}</Link>

                                                </div>


                                            </List.Item>
                                        )}
                                    />

                                } trigger="click">
                                    <Tag icon={<div dangerouslySetInnerHTML={{ __html: value.icon }} />} color={value.color} className={this.state.tabOrder === "tiktok" ? "tabs_ecommerce active d-flex" : "tabs_ecommerce  d-flex"} >{value.name}</Tag>
                                </Popover>
                            })}
                            {/* <Popover placement="bottom" content={
                                <List

                                    itemLayout="horizontal"

                                    dataSource={this.state.ListEcommerceConfig}
                                    renderItem={(item) => (
                                        <List.Item>
                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                <Link to={LINK.WEB_ORDERS_TIKTOK + "/" + item?.code}>{item?.name}</Link>
                                            </div>
                                        </List.Item>
                                    )}
                                />

                            } trigger="click">
                                <Tag icon={<i class="fab fa-tiktok"></i>} color="purple" className={this.state.tabOrder === "tiktok" ? "tabs_ecommerce active" : "tabs_ecommerce"} >Tiktok</Tag>
                            </Popover> */}
                            {/* <Tag  color="#f50" className={"tabs_ecommerce"} onClick={() => this.changeTabOrder("shopee")}>Shopee</Tag> */}
                        </div>
                        <Link to={LINK.WEB_ORDERS_EDIT_TIKTOK + "/add"}> <Button size="small" type="primary"><PlusCircleOutlined /></Button></Link>
                    </div>} bordered={false}
                        style={{ width: "100%", }}
                        bodyStyle={{
                            padding: "10px 10px",
                            // maxHeight: "90vh",
                            // overflowY: "scroll"
                        }}
                        headStyle={{
                            backgroundColor: Colors.colorgrey,
                            display: "flex",
                            minHeight: "35px",
                            borderRadius: "3px",

                        }}
                    >
                        <div className="btn_action_list">
                            <div className="left_">
                                <Form name="TiktokOrdersearch" onFinish={this.onFinishForm} ref={this.formRef}

                                    style={{ padding: 0 }}
                                >
                                    <Form.Item style={{ margin: 0 }}>
                                        <Input.Group compact>
                                            <div className="row d-flex">

                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">

                                                    <Form.Item name="v_number" noStyle style={{
                                                        width: "100%"
                                                    }}>
                                                        <Input placeholder="Mã đơn" name="v_number" id="v_number" style={{ width: "100%" }} />
                                                    </Form.Item>

                                                </div>
                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">

                                                    <Form.Item name="phone_number" noStyle style={{
                                                        width: "100%"
                                                    }}>
                                                        <Input placeholder="Số điện thoại" name="phone_number" id="phone_number" style={{ width: "100%" }} />
                                                    </Form.Item>

                                                </div>
                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">

                                                    <Form.Item name="lading_code" noStyle style={{
                                                        width: "100%"
                                                    }}>
                                                        <Input placeholder="Mã vận đơn" name="lading_code" id="lading_code" style={{ width: "100%" }} />
                                                    </Form.Item>

                                                </div>
                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">

                                                    <Form.Item name="customer" noStyle style={{
                                                        width: "100%"
                                                    }}>
                                                        <Input placeholder="tên,phone,mã kh..." style={{ width: "100%" }} />
                                                    </Form.Item>

                                                </div>
                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">

                                                    <Form.Item name="note" noStyle style={{
                                                        width: "100%"
                                                    }}>
                                                        <Input placeholder="Ghi chú..." style={{ width: "100%" }} />
                                                    </Form.Item>

                                                </div>
                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">

                                                    <Form.Item name="products" noStyle style={{
                                                        width: "100%"
                                                    }}>
                                                        <Input placeholder="tiêu đề,slug,mã sản phẩm..." style={{ width: "100%" }} />
                                                    </Form.Item>

                                                </div>
                                            </div>
                                            <div className="row d-flex">

                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                                    <Form.Item name="address" noStyle style={{
                                                        width: "100%"
                                                    }}>
                                                        <Input placeholder="Địa chỉ" style={{ width: "100%" }} />
                                                    </Form.Item>
                                                </div>

                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                                    <Form.Item name={"store_id"} noStyle style={{
                                                        width: "100%"
                                                    }}>
                                                        <Select
                                                            showSearch
                                                            style={{ width: '100%' }}
                                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                            placeholder="Cửa hàng"
                                                            treeDefaultExpandAll
                                                            options={(this.state.ListStore || []).map((d) => ({
                                                                value: d.id,
                                                                label: d.name,
                                                            }))}

                                                        />
                                                    </Form.Item>
                                                </div>

                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                                    <Form.Item name={"user_id"} noStyle style={{
                                                        width: "100%"
                                                    }}>
                                                        <Select
                                                            showSearch
                                                            style={{ width: '100%' }}
                                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                            placeholder="Người tạo"

                                                            options={(this.state.ListUsers || []).map((d) => ({
                                                                value: d.id,
                                                                label: d.name,
                                                            }))}

                                                        /></Form.Item>
                                                </div>
                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">

                                                    <Form.Item name={"user_id_customer_care"} noStyle style={{
                                                        width: "100%"
                                                    }}>
                                                        <Select
                                                            showSearch
                                                            style={{ width: '100%' }}
                                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                            placeholder="Nhân viên chăm sóc"

                                                            options={(this.state.ListUsers || []).map((d) => ({
                                                                value: d.id,
                                                                label: d.name,
                                                            }))}

                                                        /></Form.Item>
                                                </div>
                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">

                                                    <Form.Item name={"user_id_marketer"} noStyle style={{
                                                        width: "100%"
                                                    }}>
                                                        <Select
                                                            showSearch
                                                            style={{ width: '100%' }}
                                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                            placeholder="Nhân viên marketing"

                                                            options={(this.state.ListUsers || []).map((d) => ({
                                                                value: d.id,
                                                                label: d.name,
                                                            }))}

                                                        /></Form.Item>
                                                </div>
                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">

                                                    <Form.Item name={"province_id"} noStyle style={{
                                                        width: "100%"
                                                    }}>
                                                        <Select
                                                            showSearch
                                                            style={{ width: '100%' }}
                                                            // treeData={this.state.treeData}

                                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                            placeholder="Tỉnh/thành"

                                                            options={(this.state.ListProvinces || []).map((d) => ({
                                                                value: d.id,
                                                                label: d.name,
                                                            }))}
                                                            onChange={this.onchangeProvinces}
                                                        /></Form.Item>
                                                </div>
                                            </div>
                                            <div className="row d-flex">

                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                                    <Form.Item name={"district_id"} noStyle style={{
                                                        width: "100%"
                                                    }}>
                                                        <Select
                                                            showSearch
                                                            style={{ width: '100%' }}
                                                            // treeData={this.state.treeData}

                                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                            placeholder="Quận huyện"

                                                            options={(this.state.ListDistricts || []).map((d) => ({
                                                                value: d.id,
                                                                label: d.name,
                                                            }))}
                                                            onChange={this.onchangeDistricts}

                                                        /></Form.Item>
                                                </div>
                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                                    <Form.Item name={"war_id"} noStyle style={{
                                                        width: "100%"
                                                    }}>
                                                        <Select
                                                            showSearch
                                                            style={{ width: '100%' }}
                                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                            placeholder="Phường/xã"

                                                            options={(this.state.ListWars || []).map((d) => ({
                                                                value: d.id,
                                                                label: d.name,
                                                            }))} /></Form.Item>
                                                </div>
                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                                    <Form.Item name={"info_transport_id"} noStyle style={{
                                                        width: "100%"
                                                    }}>
                                                        <Select
                                                            showSearch
                                                            style={{ width: '100%' }}
                                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                            placeholder="Nhà vận chuyển" />
                                                    </Form.Item>
                                                </div>
                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                                    <Form.Item name="updated_at" label="cập nhật" style={{
                                                        width: "100%"
                                                    }} >
                                                        <RangePicker style={{
                                                            width: "100%",
                                                        }} />
                                                    </Form.Item>
                                                </div>

                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                                    <Form.Item name="created_at" label="tạo" style={{
                                                        width: "100%"
                                                    }} >
                                                        <RangePicker style={{
                                                            width: "100%",
                                                        }} />
                                                    </Form.Item>
                                                </div>
                                                <div className="col_ col-lg-3 col-md-3 col-sm-6">
                                                    <Form.Item name="delivery_time" label="giao thành công" style={{
                                                        width: "100%"
                                                    }} >
                                                        <RangePicker style={{
                                                            width: "100%",
                                                        }} />
                                                    </Form.Item>
                                                </div>
                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                                    <Form.Item name={"isExportBill"} noStyle style={{
                                                        width: "100%"
                                                    }}>
                                                        <Select
                                                            showSearch
                                                            // mode={"multiple"}
                                                            style={{ width: '100%' }}
                                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                            placeholder="Xuất bill"
                                                            treeDefaultExpandAll
                                                            options={[{ value: 1, label: "đã xuất" }, { value: 2, label: "chưa xuất" }]}

                                                        />
                                                    </Form.Item>
                                                </div>
                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                                    <Form.Item name={"status"} noStyle style={{
                                                        width: "100%"
                                                    }}>
                                                        <Select
                                                            showSearch
                                                            mode={"multiple"}
                                                            style={{ width: '100%' }}
                                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                            placeholder="Trạng thái đơn"
                                                            treeDefaultExpandAll
                                                            options={(this.state.ListStatusTransport || []).map((d) => ({
                                                                value: d.name,
                                                                label: d.name,
                                                            }))}

                                                        />
                                                    </Form.Item>
                                                </div>
                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                                    <Form.Item name={"shipping_fee"} noStyle style={{
                                                        width: "100%"
                                                    }}>
                                                        <Select
                                                            showSearch

                                                            style={{ width: '100%' }}
                                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                            placeholder="Phí vận chuyển"
                                                            treeDefaultExpandAll
                                                            options={[{ value: 1, label: "Tất cả" }, { value: 2, label: "Phí VC = 0" }, { value: 3, label: "Phí VC> 0" }]}

                                                        />
                                                    </Form.Item>
                                                </div>
                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                                    <Form.Item name={"update_phone"} noStyle style={{
                                                        width: "100%"
                                                    }}>
                                                        <Select
                                                            showSearch

                                                            style={{ width: '100%' }}
                                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                            placeholder="Đã cập nhật SDT"
                                                            treeDefaultExpandAll
                                                            options={[{ value: 1, label: "Tất cả" }, { value: 2, label: "đã cập nhật" }, { value: 3, label: "chưa cập nhật" }]}

                                                        />
                                                    </Form.Item>
                                                </div>
                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                                    <Form.Item name={"update_delivered"} noStyle style={{
                                                        width: "100%"
                                                    }}>
                                                        <Select
                                                            showSearch

                                                            style={{ width: '100%' }}
                                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                            placeholder="Đã cập nhật ngày giao hàng"
                                                            treeDefaultExpandAll
                                                            options={[{ value: 1, label: "Tất cả" }, { value: 2, label: "đã cập nhật" }, { value: 3, label: "chưa cập nhật" }]}

                                                        />
                                                    </Form.Item>
                                                </div>

                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                                    <Form.Item name={"update_invoice_info"} noStyle style={{
                                                        width: "100%"
                                                    }}>
                                                        <Select
                                                            showSearch

                                                            style={{ width: '100%' }}
                                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                            placeholder="Đã cập nhật TT HĐ"
                                                            treeDefaultExpandAll
                                                            options={[{ value: 1, label: "Tất cả" }, { value: 2, label: "đã cập nhật" }, { value: 3, label: "chưa cập nhật" }]}

                                                        />
                                                    </Form.Item>
                                                </div>
                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">

                                                    <Form.Item name="value" noStyle style={{
                                                        width: "100%"
                                                    }}>
                                                        <Input placeholder="Giá trị" name="value" id="value" style={{ width: "100%" }} />
                                                    </Form.Item>

                                                </div>

                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                                    <Form.Item name={"is_cancel"} noStyle style={{
                                                        width: "100%"
                                                    }}>
                                                        <Select
                                                            showSearch

                                                            style={{ width: '100%' }}
                                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                            placeholder="Bỏ đơn hủy"
                                                            treeDefaultExpandAll
                                                            options={[{ value: 1, label: "Tất cả" }, { value: 2, label: "Bỏ đơn hủy" }, { value: 3, label: "Chỉ đơn hủy" }]}

                                                        />
                                                    </Form.Item>
                                                </div>
                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                                    <Form.Item name={"is_acountant_revenue"} noStyle style={{
                                                        width: "100%"
                                                    }}>
                                                        <Select
                                                            showSearch
                                                            // mode={"multiple"}
                                                            style={{ width: '100%' }}
                                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                            placeholder="Đã hoạch toán DT"
                                                            treeDefaultExpandAll
                                                            options={[{ value: 2, label: "Đã hoạch toán DT" }, { value: 3, label: "Chưa hoạch toán DT" }]}

                                                        />
                                                    </Form.Item>
                                                </div>
                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                                    <Form.Item name={"is_acountant_expense"} noStyle style={{
                                                        width: "100%"
                                                    }}>
                                                        <Select
                                                            showSearch
                                                            // mode={"multiple"}
                                                            style={{ width: '100%' }}
                                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                            placeholder="Đã hoạch toán CP"
                                                            treeDefaultExpandAll
                                                            options={[{ value: 2, label: "Đã hoạch toán CP" }, { value: 3, label: "Chưa hoạch toán CP" }]}

                                                        />
                                                    </Form.Item>
                                                </div>
                                                <div className="col_ col-lg-1 col-md-3 col-sm-6">
                                                    <Tooltip title="search">
                                                        <Button type="primary" id="btn-search" htmlType="submit" style={{ width: "100%" }} icon={<SearchOutlined />} loading={this.state.isLoadingSearch} />
                                                    </Tooltip>


                                                </div>
                                                <div className="col_ col-lg-1 col-md-3 col-sm-6">
                                                    <Tooltip title="Reset">
                                                        <Button style={{ width: "100%", backgroundColor: Colors.colorWarning, color: Colors.white }} htmlType="button" onClick={this.onResetFrom}>Reset</Button>
                                                    </Tooltip>

                                                </div>
                                                <div className="col_ col-lg-1 col-md-3 col-sm-6">
                                                    <Tooltip title="Đồng bộ">
                                                        <Popover placement="bottom" content={
                                                            <List

                                                                itemLayout="horizontal"

                                                                dataSource={DATA_SYNC_ORDER_DAY}
                                                                renderItem={(item) => (
                                                                    <List.Item>
                                                                        <button className="reset_btn" type="button" onClick={() => { this.getTiktokOrders(item.value) }}>
                                                                            {item.label}
                                                                        </button>
                                                                    </List.Item>
                                                                )}
                                                            />

                                                        } trigger="click">
                                                            <Button style={{ width: "100%", backgroundColor: Colors.pop3[3], color: Colors.white }} loading={this.state.isLoadingSearch} htmlType="button">Đồng bộ</Button>
                                                        </Popover>

                                                    </Tooltip>

                                                </div>

                                                <div className="col_ col-lg-1 col-md-3 col-sm-6">
                                                    <Tooltip title="Tải lên vận đơn">

                                                        <input type="file" id="upload-pdf" accept="application/pdf" onChange={this.uploadBill} style={{ display: "none" }} />
                                                        <Button type="primary" htmlType="button" style={{ width: "100%" }} icon={<i class="fas fa-file-upload"></i>} loading={this.state.isLoadingSearch} onClick={() => { document.getElementById("upload-pdf").click() }} />
                                                    </Tooltip>



                                                </div>
                                                {/* <div className="col_ col-lg-1 col-md-3 col-sm-6">


                                                    <Tooltip title="Tải File excel đối soát">

                                                        <input type="file" id="upload-exel" accept=".xlsx, .xls" onChange={this.uploadExcelReconciliation} style={{ display: "none" }} />
                                                        <Button type="primary" htmlType="button" style={{ width: "100%" }} icon={<i class="fas fa-file-excel"></i>} loading={this.state.isLoadingSearch} onClick={() => { document.getElementById("upload-exel").click() }} />
                                                    </Tooltip>

                                                </div> */}
                                                <div className="col_ col-lg-1 col-md-3 col-sm-6">


                                                    <Tooltip title="Tải File excel so sánh">

                                                        <input type="file" id="upload-exel-compare" accept=".xlsx, .xls" onChange={this.uploadExcelCompare} style={{ display: "none" }} />
                                                        <Button type="primary" htmlType="button" style={{ width: "100%" }} icon={<i class="fas fa-file-excel"></i>} loading={this.state.isLoadingSearch} onClick={() => { document.getElementById("upload-exel-compare").click() }} />
                                                    </Tooltip>

                                                </div>
                                                <div className="col_ col-lg-1 col-md-3 col-sm-6">


                                                    <Tooltip title="Tải File excel tìm kiếm danh sách">

                                                        <input type="file" id="upload-exel-search" accept=".xlsx, .xls" onChange={this.uploadExcelSearch} style={{ display: "none" }} />
                                                        <Button type="primary" htmlType="button" style={{ width: "100%" }} icon={<i class="fas fa-file-excel"></i>} loading={this.state.isLoadingSearch} onClick={() => { document.getElementById("upload-exel-search").click() }} />
                                                    </Tooltip>

                                                </div>

                                                <div className="col_ col-lg-1 col-md-3 col-sm-6">


                                                    <Tooltip title="Tải File excel update quyết toán">

                                                        <input type="file" id="upload-exel-income" accept=".xlsx, .xls" onChange={this.uploadExcelIncome} style={{ display: "none" }} />
                                                        <Button type="primary" htmlType="button" style={{ width: "100%" }} icon={<i class="fas fa-file-excel"></i>} loading={this.state.isLoadingSearch} onClick={() => { document.getElementById("upload-exel-income").click() }} />
                                                    </Tooltip>

                                                </div>
                                            </div>

                                        </Input.Group>
                                    </Form.Item>
                                </Form>
                            </div>

                        </div>
                        {this.state.isSket && <Skeleton active />}
                        {!this.state.isSket && this.state.data.length <= 0 && <Empty />}
                        {this.state.data.length > 0 && <>    {(checkScreenSize().width < 600 || !this.state.isShowList) && <IndexMobile lists={this.state.data} loading={this.state.loading}
                            updateRecord={this.updateRecord}
                            editRows={this.editRows}
                            deleteModal={this.deleteModal}
                            duplicateItem={this.duplicateItem}
                            current_page={this.state.meta.current_page}
                            per_page={this.state.meta.per_page}
                            last_page={this.state.meta.last_page}
                            OnPagination={this.OnPagination}
                            isNewList={this.state.isNewList}
                        />}
                            {checkScreenSize().width >= 600 && this.state.isShowList && <>
                                <Table
                                    rowClassName={(record, index) => {
                                        return "__row_";
                                    }}
                                    columns={this.state.columns}
                                    dataSource={this.state.data}
                                    pagination={false}
                                    rowSelection={{
                                        onChange: (selectedRowKeys, selectedRows) => {
                                            this.setState(state => {
                                                return {
                                                    selectedRowKeys: selectedRowKeys,
                                                    selectedRows: selectedRows
                                                }
                                            })
                                            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                                        },
                                        getCheckboxProps: (record) => ({
                                            // disabled: record.name === 'Disabled User',
                                            // // Column configuration not to be checked
                                            name: record.name,
                                        }),
                                    }}
                                    scroll={{ y: 440 }}
                                    style={{ paddingBottom: "30px", marginBottom: "100px" }}
                                />
                                <div className="pagination_" style={{ marginBottom: "100px" }}>
                                    <Pagination showTotal={(total, range) => `${range[0]}-${range[1]} của ${total} dòng`} pageSize={this.state.meta && this.state.meta.per_page ? this.state.meta.per_page : 10} total={this.state.meta && this.state.meta.total ? this.state.meta.total : 10} showSizeChanger onChange={this.OnPagination} />
                                </div>
                            </>
                            }
                        </>
                        }

                        <TotalBottom groupReport={this.state.groupReport} />
                    </Card>


                </div>





            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {

        Web: state.Web,
        appReducer: state.appReducer
    };
};
const mapDispatchToProps = (dispatch, Props) => {
    return {

        ListTiktokOrdersRequest: (data) => {
            dispatch(ActionsWeb.ListTiktokOrdersRequest(data));
        },
        ListStoreRequest: (data) => {
            dispatch(ActionsWeb.ListStoreRequest(data));
        },
        SearchUserRequest: (body, data) => {
            dispatch(ActionsWeb.SearchUserRequest(body, data));
        },
        ListProvincesRequest: (data) => {
            dispatch(ActionsWeb.ListProvincesRequest(data));
        },
        ListDistrictsRequest: (data) => {
            dispatch(ActionsWeb.ListDistrictsRequest(data));
        },
        ListWarsRequest: (data) => {
            dispatch(ActionsWeb.ListWarsRequest(data));
        },
        SearchTiktokOrdersRequest: (body, data) => {
            dispatch(ActionsWeb.SearchTiktokOrdersRequest(body, data));
        },
        SearchLazadaOrdersRequest: (body, data) => {
            dispatch(ActionsWeb.SearchLazadaOrdersRequest(body, data));
        },
        SearchShopeeOrdersRequest: (body, data) => {
            dispatch(ActionsWeb.SearchShopeeOrdersRequest(body, data));
        },
        StoreTiktokOrdersRequest: (data, id) => {
            dispatch(ActionsWeb.StoreTiktokOrdersRequest(data, id));
        },
        ResetStoreTiktokOrders: () => {
            dispatch(ActionsWeb.ResetStoreTiktokOrders());
        },
        duplicateTiktokOrdersRequest: (body) => {
            dispatch(ActionsWeb.duplicateTiktokOrdersRequest(body));
        },
        ListEcommerceConfigByCodeRequest: (body) => {
            dispatch(ActionsWeb.ListEcommerceConfigByCodeRequest(body));
        },
        SyncTiktokOrdersRequest: (body, data) => {
            dispatch(ActionsWeb.SyncTiktokOrdersRequest(body, data));
        },
        SyncLazadaOrdersRequest: (body, data) => {
            dispatch(ActionsWeb.SyncLazadaOrdersRequest(body, data));
        },
        SyncShopeeOrdersRequest: (body, data) => {
            dispatch(ActionsWeb.SyncShopeeOrdersRequest(body, data));
        },
        setIsync: (body) => {
            dispatch(ActionsApp.setIsync(body));
        },
        SettingsShowByCodeRequest: (body) => {
            dispatch(ActionsWeb.SettingsShowByCodeRequest(body));
        },
        ListStatusTransportRequest: (body) => {
            dispatch(ActionsWeb.ListStatusTransportRequest(body));
        },
        ListEcommerceTypeRequest: (body, data) => {
            dispatch(ActionsWeb.ListEcommerceTypeRequest(body, data));
        },
        ListConnectExportBillRequest: (body) => {
            dispatch(ActionsWeb.ListConnectExportBillRequest(body));
        },
        ListEcommerceConfigRequest: (body, data) => {
            dispatch(ActionsWeb.ListEcommerceConfigRequest(body, data));
        },
        SearchTiktokOrders: (data) => {
            dispatch(ActionsWeb.SearchTiktokOrders(data));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);