import React from 'react'
import { Link } from "react-router-dom";

import { withRouter } from "react-router-dom";
import * as Colors from '../../constants/Colors';
import { linkWeb } from '../../constants/LinkApi';
import * as LINK from "../../constants/Links";
import {
CaretLeftOutlined, MenuUnfoldOutlined, MenuFoldOutlined,
ShopOutlined,
UserOutlined,
PieChartOutlined,
DesktopOutlined, LogoutOutlined,
MailOutlined,
AppstoreAddOutlined, SmileOutlined,
LoadingOutlined, RetweetOutlined
} from '@ant-design/icons';
import { Spin } from 'antd';
import { useEffect } from 'react';
import { formatNformatNumber, umber, getCookie, formatNumber } from '../../service';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LogoutRequest, SetUserRequest } from '../../actions/login';
import { SettingsShowByCodeRequest } from '../../actions/indexWeb';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { isloader, toggleColapNav } from '../../actions/indexApp';
import * as Types from "../../constants/TypesactionApp";

export const TotalBottom = ({ groupReport }) => {

  const { Web,appReducer } = useSelector(state => state);
  // console.log("groupReportgroupReportgroupReport ", groupReport);

  return (
    <div className={`main-grid  total-bottom  bg-white p-2 ${appReducer.active ? " hide" : ""} w-100`}>
      <div></div>
      <div className=" d-flex justify-content-between  ">
        <div className='me-2 text-secondary fw-bold d-flex flex-column' style={{ fontSize:"12px" }}><span>Tổng đơn: </span> <span>{(groupReport?.totalAmount)} </span></div>
        <div className='me-2 text-info fw-bold d-flex flex-column' style={{ fontSize:"12px" }}><span>TT gốc:</span> <span>{formatNumber(groupReport?.totalPriceOrigin)}</span></div>
        <div className='me-2 text-info fw-bold d-flex flex-column' style={{ fontSize:"12px" }}><span>Giảm giá sàn:</span> <span>{formatNumber(groupReport?.totalPlatformDiscount)}</span></div>
        <div className='me-2 text-info fw-bold d-flex flex-column' style={{ fontSize:"12px" }}><span>Xu:</span> <span>{formatNumber(groupReport?.totalCoins)}</span></div>
        <div className='me-2 text-info fw-bold d-flex flex-column' style={{ fontSize:"12px" }}><span>Giảm giá shop:</span> <span>{formatNumber(groupReport?.totalSellerDiscount)}</span></div>
        <div className='me-2 text-info fw-bold d-flex flex-column' style={{ fontSize:"12px" }}><span>TT Hoàn: </span><span>{formatNumber(groupReport?.seller_return_refund)}</span></div>
        <div className='me-2 text-info fw-bold d-flex flex-column' style={{ fontSize:"12px" }}><span>DT ước tính:</span> <span>{formatNumber(groupReport?.totalAfterDiscount)}</span></div>


        <div className='me-2 text-primary fw-bold d-flex flex-column' style={{ fontSize:"12px" }}><span>TT ship gốc: </span><span>{formatNumber(groupReport?.totalFreeShipOrigin)}</span></div>
        <div className='me-2 text-primary fw-bold d-flex flex-column' style={{ fontSize:"12px" }}><span>Giảm giá  sàn: </span><span>{formatNumber(groupReport?.totalFreeShipPlatformDiscount)}</span></div>
        <div className='me-2 text-primary fw-bold d-flex flex-column' style={{ fontSize:"12px" }}><span>Giảm giá shop: </span><span>{formatNumber(groupReport?.totalFreeShipSellerDiscount)}</span></div>
        <div className='me-2 text-primary fw-bold d-flex flex-column' style={{ fontSize:"12px" }}><span>TT ship: </span><span>{formatNumber(groupReport?.totalFreeShip)}</span></div>

        <div className='me-2 text-success fw-bold d-flex flex-column' style={{ fontSize:"12px" }}><span>DT+VC-phí: </span><span>{formatNumber(parseFloat(groupReport?.totalPrice)+parseFloat(groupReport?.totalPlatformDiscount))}</span></div>


        <div className='me-2 text-danger fw-bold d-flex flex-column' style={{ fontSize:"12px" }}><span>TT phí GD: </span><span>{formatNumber(groupReport?.transaction_fee)}</span></div>
        <div className='me-2 text-danger fw-bold d-flex flex-column' style={{ fontSize:"12px" }}><span>TT Phí SFP: </span><span>{formatNumber(groupReport?.sfp_service_fee)}</span></div>
        <div className='me-2 text-danger fw-bold d-flex flex-column' style={{ fontSize:"12px" }}><span>TT Phí DV: </span><span>{formatNumber(groupReport?.service_fee)}</span></div>
        <div className='me-2 text-danger fw-bold d-flex flex-column' style={{ fontSize:"12px" }}><span>TT Phí HH: </span><span>{formatNumber(groupReport?.commission_fee)}</span></div>
       
        <div className='me-2 text-danger fw-bold d-flex flex-column' style={{ fontSize:"12px" }}><span>TT phí: </span><span>{formatNumber(groupReport?.total_fees)}</span></div>
        <div className='me-2 text-secondary fw-bold d-flex flex-column' style={{ fontSize:"12px" }}><span>TT VC TT: </span><span>{formatNumber(groupReport?.actual_shipping_fee)}</span></div>
        <div className='me-2 text-secondary fw-bold d-flex flex-column' style={{ fontSize:"12px" }}><span>TT VC Phải trả TT: </span><span>{formatNumber(groupReport?.actual_shipping_fee_after_discount)}</span></div>
        <div className='me-2 text-secondary fw-bold d-flex flex-column' style={{ fontSize:"12px" }}><span>TT VC thừa: </span><span>{formatNumber(groupReport?.seller_shipping_fee)}</span></div>

      
        <div className='me-2 text-success fw-bold d-flex flex-column' style={{ fontSize:"12px" }}><span>DT thực: </span><span>{formatNumber(groupReport?.actual_totals)}</span></div>


      </div>
    </div>

  )
}
