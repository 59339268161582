import React, { useEffect, useRef, useState } from 'react'
import { Button, Card, DatePicker, Drawer, Form, Input, InputNumber, message, Modal, Select, Switch as SwitchA, Table, TreeSelect } from 'antd';
import * as LINK from "../../../constants/Links";

import "./style.css"
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { META } from '../../../utils/constant';
import { useDispatch, useSelector } from 'react-redux';
import { ChangeStatusEmployeesRequest, DeleteEmployeesRequest, ListAllowanceRequest, ListDepartmentRequest, ListEmployeesRequest, ListPositionsRequest, StoreEmployeesRequest } from '../../../actions/indexWeb';
import { DATA_GROUP_ACCOUNT, DATA_KEY_CODE_ACCOUNT, DATA_SEX, DATA_TYPE_ACCOUNTING, DATA_TYPE_CONTRACT } from '../../../constants/DefaultData';
import {
    FolderViewOutlined, AppstoreOutlined,
    DeleteOutlined, PlusCircleOutlined, ExclamationCircleOutlined,
    EditOutlined, UnorderedListOutlined, SearchOutlined
} from '@ant-design/icons';
import * as Colors from "../../../constants/Colors";
import { formatMN1 } from '../../../service';
import moment from 'moment';
import Pdfview from "./../../../components/pdfview/index";
import * as TypePrint from "./../../../constants/TypePrint";

const Index = () => {

    const dispatch = useDispatch()
    const { Web } = useSelector(state => state);
    const [meta, setMeta] = useState({ ...META });
    const [listEmployees, setListEmployees] = useState([]);
    const [listPositions, setListPositions] = useState([]);
    const [listDeparment, setListDepartment] = useState([]);
    const [listPart, setListPart] = useState([]);
    const [listAllowance, setListAllowance] = useState([]);
    const [listAllowanceChose, setListAllowanceChose] = useState([]);
    const [isloading, setIsloading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [record, setRecord] = useState(null);
    const [visiblePdf, setvisiblePdf] = useState(false);
    const defaultDate = moment();

    const formRef = useRef(null);

    const getListAllowance = async () => {
        const response = await ListAllowanceRequest({
            limit: meta.per_page * 100,
            page: meta.current_page, column: "code", sort: "ASC"
        });
        if (response.code == 200) {
            if (response.data) {
                console.log(response.data.data);
                if (response.data.data) {

                    setListAllowance(response.data.data);
                }
            }
        }


    }
    const getListDepartment = async () => {
        const response = await ListDepartmentRequest({
            limit: meta.per_page * 100,
            page: meta.current_page, column: "code", sort: "ASC"
        });
        if (response.code == 200) {
            if (response.data) {
                console.log(response.data.data);
                if (response.data.data) {
                    const dataDepartment = response.data.data;
                    const dataDepart = dataDepartment.filter((value, index) => {
                        return value.type == 1;
                    })
                    const dataPart = dataDepartment.filter((value, index) => {
                        return value.type == 2;
                    })
                    setListDepartment(dataDepart);
                    setListPart(dataPart);
                }
            }
        }


    }
    const getListPositions = async () => {
        const response = await ListPositionsRequest({
            limit: meta.per_page * 100,
            page: meta.current_page, column: "code", sort: "ASC"
        });
        if (response.code == 200) {
            if (response.data) {
                console.log(response.data.data);
                if (response.data.data) {

                    setListPositions(response.data.data);
                }
            }
        }


    }
    const getListEmployees = async () => {
        const response = await ListEmployeesRequest({
            limit: meta.per_page * 100,
            page: meta.current_page, column: "code", sort: "ASC"
        });
        if (response.code == 200) {
            if (response.data) {
                console.log(response.data.data);
                if (response.data.data) {

                    setListEmployees(response.data.data);
                }
            }
        }


    }
    useEffect(() => {
        getListEmployees();
        getListPositions();
        getListDepartment();
        getListAllowance();
        return () => {

        };
    }, []);
    const columns = [

        {
            title: 'Mã',
            dataIndex: 'code',
            key: 'code',
            sorter: (a, b) => a.code - b.code,


        },
        {
            title: 'Họ tên',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name - b.name,
            // width: "20%"

        },
        {
            title: 'Giới tính',
            dataIndex: 'sex',
            key: 'sex',
            sorter: (a, b) => a.sex - b.sex,
            render: (text, record, index) => {
                return text == 1 ? "Nam" : "Nữ"
            }
        },

        {
            title: 'Ngày sinh',
            dataIndex: 'birthday',
            key: 'birthday',
            sorter: (a, b) => a.birthday - b.birthday,
        },
        {
            title: 'CCCD',
            dataIndex: 'CCCD',
            key: 'CCCD',
            sorter: (a, b) => a.CCCD - b.CCCD,
        },
        //   {
        //     title: 'Ngày cấp',
        //     dataIndex: 'issue_date',
        //     key: 'issue_date',
        //     sorter: (a, b) => a.issue_date - b.issue_date,
        //   }, 
        //   {
        //     title: 'Nơi cấp',
        //     dataIndex: 'issue_place',
        //     key: 'issue_place',
        //     sorter: (a, b) => a.issue_place - b.issue_place,
        //   },  
        {
            title: 'taxcode',
            dataIndex: 'taxcode',
            key: 'taxcode',
            sorter: (a, b) => a.taxcode - b.taxcode,
        },

        {
            title: 'Ngày bắt đầu',
            dataIndex: 'start_date',
            key: 'start_date',
            sorter: (a, b) => a.start_date - b.start_date,
        },
        {
            title: 'Chức vụ',
            dataIndex: 'position_name',
            key: 'position_name',
            sorter: (a, b) => a.position_name - b.position_name,
            // render: (text, record, index) => {
            //     const position=listPositions.filter((value, index) => {
            //         return value.id==text;
            //     })
            //     if(position[0]){
            //         return position[0].name;
            //     }
            // }
        },
        {
            title: 'Phòng ban',
            dataIndex: 'department_name',
            key: 'department_name',
            sorter: (a, b) => a.department_name - b.department_name,
            // render: (text, record, index) => {
            //     return record.name;
            //     // console.log("listDeparmentlistDeparment",listDeparment);

            //     // const department=listDeparment.filter((value, index) => {
            //     //     return value.id==text;
            //     // })
            //     // console.log("department",department);
            //     // if(department[0]){
            //     //     return department[0].name;
            //     // }
            // }
        },
        {
            title: 'Mức lương',
            dataIndex: 'salary',
            key: 'salary',
            sorter: (a, b) => a.salary - b.salary,
            render: (text, record, index) => {
                return formatMN1(text)
            }
        },
        // {
        //     title: 'Số HĐ',
        //     dataIndex: 'contract_number',
        //     key: 'contract_number',
        //     sorter: (a, b) => a.contract_number - b.contract_number,
        // },
        // {
        //     title: 'Loại HĐ',
        //     dataIndex: 'contract_type',
        //     key: 'contract_type',
        //     sorter: (a, b) => a.contract_type - b.contract_type,
        // },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'status',
            sorter: (a, b) => a.status - b.status,
            render: (text, record, index) => {
                return <SwitchA defaultChecked={text} onChange={() => changeStatusRow(record)} />
            }
        },
        {
            title: 'Chỉnh sửa',
            dataIndex: '',
            render: (text, record, index) => (
                <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }} >
                    <span className="__action_">
                        <button className="reset_btn btn_edit btn__" onClick={() => editRow(record)}> <EditOutlined style={{ color: Colors.colorView }} /> </button>
                        <button className="reset_btn btn_delete btn__" onClick={() => { confirmDeleted(index, record) }}><DeleteOutlined style={{ color: Colors.colorDelete }} /></button>
                        {/* <button className="reset_btn btn_view btn__" onClick={() => { editRow(record) }}> <FolderViewOutlined   style={{ color: Colors.colorView }} /></button> */}

                    </span>
                </div>
            ),
            key: 'group',
        },

        {
            title: 'HĐLĐ',
            dataIndex: '',
            render: (text, record, index) => (
                <Button size='small' className='bg-warning' onClick={() => { setvisiblePdf(true); setRecord(record) }}><i class="fas fa-print"></i></Button>
            ),
            key: 'group',
        },
    ];
    const confirmDeleted = (row, record) => {
        Modal.confirm({
            title: 'Xác nhận',
            icon: <ExclamationCircleOutlined />,
            content: 'Bạn có chắc muốn xóa',
            okText: 'Xóa',
            cancelText: 'Bỏ qua',
            onOk: () => {
                deleteRow(row, record);

            }
        });
    };
    const deleteRow = async (row, record) => {
        setIsloading(true);
        const response = await DeleteEmployeesRequest({ id: record.id });
        setIsloading(false);
        if (response.code == 200) {
            if (response.message) {
                message.success(response.message);
            } else {
                message.success("Cập nhật thành công");
            }

            getListEmployees();

        } else {
            if (response.message) {
                if (typeof response.message == "string") {
                    message.error(response.message);
                } else {
                    (Object.values(response.message) || []).map((value, index) => {
                        message.error(value);
                    })
                }

            } else {
                message.warning("Cập nhật không thành công");
            }
        }
    }

    const changeStatusRow = async (record) => {
        setIsloading(true);

        const response = await ChangeStatusEmployeesRequest({ id: record.id });
        setIsloading(false);
        if (response.code == 200) {
            if (response.message) {
                message.success(response.message);
            } else {
                message.success("Cập nhật thành công");
            }

            getListEmployees();

        } else {
            if (response.message) {
                if (typeof response.message == "string") {
                    message.error(response.message);
                } else {
                    (Object.values(response.message) || []).map((value, index) => {
                        message.error(value);
                    })
                }

            } else {
                message.warning("Cập nhật không thành công");
            }
        }
    }
    const editRow = (record) => {
        setRecord(record)
        setVisible(true)



    }
    useEffect(() => {
        if (visible && record?.id) {
            if (formRef.current) {
                record.department_id = parseInt(record.department_id);
                record.part_id = parseInt(record.part_id);
                record.sex = parseInt(record.sex);
                record.position_id = parseInt(record.position_id);
                record.contract_type = parseInt(record.contract_type);
                record.start_date = moment(record.start_date, "YYYY-MM-DD");
                record.issue_date = moment(record.issue_date, "YYYY-MM-DD");
                record.birthday = moment(record.birthday, "YYYY-MM-DD");
                record.contract_from = moment(record.contract_from, "YYYY-MM-DD");
                record.contract_to= moment(record.contract_to, "YYYY-MM-DD");
                formRef.current.setFieldsValue({ ...record, salary: formatMN1(record.salary), salary_day: formatMN1(record.salary_day), total_salary: formatMN1(record.total_salary) })
                if (record.allowance_ids) {
                    setListAllowanceChose(JSON.parse(record.allowance_ids))
                    formRef.current.setFieldsValue({ allowance_ids: JSON.parse(record.allowance_ids) })
                }

            }

        }
        return () => {

        };
    }, [record, visible]);
    const submitForm = async (values) => {
        // setIsloading(true);
        if (record) {
            values.id = record.id;
        }
        values.salary = Number(values.salary.toString().replace(/\D/g, ''))
        const allowance_ids = values.allowance_ids;

        let totalAllowance = 0;
        listAllowance.map((value, index) => {
            if (allowance_ids.includes(value.id)) {
                totalAllowance += Number(value.money);
            }
        })

        const total_salary = totalAllowance + values.salary;
        const salary_day = total_salary / 26;
        values.total_salary = total_salary;
        values.salary_day = salary_day;
        values.birthday = moment(values.birthday).format('YYYY-MM-DD');
        values.start_date = moment(values.start_date).format('YYYY-MM-DD');
        values.issue_date = moment(values.issue_date).format('YYYY-MM-DD');
        values.contract_from = moment(values.contract_from).format('YYYY-MM-DD');
        values.contract_to = moment(values.contract_to).format('YYYY-MM-DD');
        values.allowance_ids = JSON.stringify(values.allowance_ids)
        const response = await StoreEmployeesRequest(values);
        setIsloading(false);
        if (response.code == 200) {
            if (response.message) {
                message.success(response.message);
            } else {
                message.success("Cập nhật thành công");
            }


            getListEmployees()
        } else {
            if (response.message) {
                if (typeof response.message == "string") {
                    message.error(response.message);
                } else {
                    (Object.values(response.message) || []).map((value, index) => {
                        message.error(value);
                    })
                }

            } else {
                message.warning("Cập nhật không thành công");
            }
        }
    }

    const addnewRecord = () => {
        if (formRef.current) {
            formRef.current.resetFields();
            formRef.current.setFieldsValue({ from_time: "Sáng từ 8 giờ đến 12 giờ trưa", to_time: ", chiều từ 13 giờ đến 17 giờ", start_date: defaultDate, address_work: "CÔNG TY TNHH DỊCH VỤ IN ẤN BOVA\nk31/30 Nguyễn Như Đãi, Hòa Thọ Tây, Quận Cẩm Lệ, Thành Phố Đà Nẵng" })
        }
        setRecord(null)
        setListAllowanceChose([])
    }
    const handleMoneyChange = (e, name) => {
        let inputValue = "";
        if (e.target.value) {
            inputValue = e.target.value.replace(/\D/g, ''); // Loại bỏ ký tự không phải số
        } else {
            inputValue = e.toString().replace(/\D/g, ''); // Loại bỏ ký tự không phải số
        }

        const formattedValue = formatMN1(inputValue);
        formRef.current.setFieldsValue({
            [`${name}`]: formattedValue,
        });

    };

    const onchageSalary = () => {
        const formvalue = formRef.current.getFieldsValue();
        if (formvalue.salary) {
            const salary = Number(formvalue.salary.toString().replace(/\D/g, ''));
            const allowance_ids = formvalue.allowance_ids;

            let totalAllowance = 0;
            listAllowance.map((value, index) => {
                if (allowance_ids.includes(value.id)) {
                    totalAllowance += Number(value.money);
                }
            })

            console.log("totalAllowance ", totalAllowance);
            const total_salary = totalAllowance + salary;
            const salary_day = total_salary / 26;
            formRef.current.setFieldsValue({
                salary_day: formatMN1(salary_day),
                total_salary: formatMN1(total_salary),
            });

        }

    }

    const proccessTerm=()=>{
        let future_date=0;
        const formvalue = formRef.current.getFieldsValue();
        const start_date=formvalue.start_date;
        const contract_from=formvalue.contract_from;
        const months=formvalue.months;
        if(contract_from && months){
            console.log("start_datestart_datestart_date ",contract_from);
            future_date = contract_from.clone().add(months, 'months').subtract(1, 'days');
            formRef.current.setFieldsValue({contract_to:moment(future_date, "DD/MM/YYY")})
        }
    }
    return (
        <>
            <Drawer
                title={<div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6"><span>Hợp đồng</span></div>
                    <div className="col-lg-6 col-md-6 col-sm-6 flex_" style={{ justifyContent: "flex-end" }}></div>
                </div>} placement="right"
                onClose={() => { setvisiblePdf(false) }}
                visible={visiblePdf}
                size={"large"}

            >
                <Pdfview type={1} typePrint={TypePrint.CONTRACT} data={{ id: record?.id }} visible={visiblePdf} />
            </Drawer>
            <Drawer
                title={<div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6"><span>Nhân viên</span></div>
                    <div className="col-lg-6 col-md-6 col-sm-6 flex_" style={{ justifyContent: "flex-end" }}></div>
                </div>} placement="right"
                onClose={() => { setVisible(false) }}
                visible={visible}
                size={"large"}

            >
                <div className='row'>
                    <div className='col-lg-12 col-md-12 col-sm-12 bg-white'>
                        <Form name="formadd" onFinish={submitForm} ref={formRef}
                            labelCol={{ span: 6 }}
                            wrapperCol={{ span: 20 }}

                        >
                            <Form.Item label="Mã / Tên">
                                <Input.Group compact>
                                    <Form.Item
                                        name="code"
                                        // rules={[
                                        //     {
                                        //         required: true,
                                        //         message: "Mã là bắt buộc"
                                        //     },
                                        // ]}
                                        style={{ width: "20%" }}
                                    >

                                        <Input placeholder="Mã" disabled />
                                    </Form.Item>
                                    <Form.Item
                                        name="name"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Tên là bắt buộc"
                                            },
                                        ]}
                                        style={{ width: "80%" }}
                                    >

                                        <Input placeholder="Tên" />
                                    </Form.Item>

                                </Input.Group>
                            </Form.Item>

                            <Form.Item label="Giới tính / Ngày sinh" >
                                <Input.Group compact>
                                    <Form.Item
                                        name="sex"

                                        style={{ width: "50%" }}
                                        initialValue={1}
                                    >

                                        <Select
                                            showSearch
                                            style={{ width: "100%" }}
                                            showArrow={true}
                                            defaultActiveFirstOption={true}
                                            options={DATA_SEX}
                                            placeholder="Giới tính"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="birthday"
                                        style={{ width: "50%" }}
                                    >

                                        <DatePicker placeholder='Ngày sinh' style={{ width: "100%" }} format="DD/MM/YYYY" />
                                    </Form.Item>
                                   
                                </Input.Group>
                            </Form.Item>
                            
                            <Form.Item label="MSST / MSBHXH" >
                                <Input.Group compact>
                                <Form.Item
                                        name="taxcode"
                                        style={{ width: "50%" }}
                                    >

                                        <Input placeholder="Mã số thuế" />
                                    </Form.Item>
                                    <Form.Item
                                        name="insurance_code"
                                        style={{ width: "50%" }}
                                    >

                                        <Input placeholder="Mã số BHXH" />
                                    </Form.Item>
                                </Input.Group>
                            </Form.Item>
                            <Form.Item label="Tại"
                                name="address"
                                rules={[
                                    {
                                        required: true,
                                        message: "Sinh tại là bắt buộc"
                                    },
                                ]}
                            >

                                <Input placeholder="Địa chỉ" />
                            </Form.Item>
                            <Form.Item label="CCCD" >
                                <Input.Group compact>
                                    <Form.Item
                                        name="CCCD"

                                        style={{ width: "33%" }}

                                        rules={[
                                            {
                                                required: true,
                                                message: "CCCD là bắt buộc"
                                            },
                                        ]}
                                    >

                                        <Input placeholder="CCCD" />
                                    </Form.Item>
                                    <Form.Item
                                        name="issue_date"
                                        style={{ width: "33%" }}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Ngày cấp là bắt buộc"
                                            },
                                        ]}
                                    >

                                        <DatePicker placeholder='Ngày cấp' style={{ width: "100%" }} format="DD/MM/YYYY" />
                                    </Form.Item>
                                    <Form.Item
                                        name="issue_place"
                                        style={{ width: "33%" }}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Nơi cấp là bắt buộc"
                                            },
                                        ]}
                                    >

                                        <Input placeholder="Nơi cấp" />
                                    </Form.Item>
                                </Input.Group>
                            </Form.Item>


                            <Form.Item label="Địa chỉ thường trú"
                                name="address_tmp"
                                rules={[
                                    {
                                        required: true,
                                        message: "Địa chỉ thường trú là bắt buộc"
                                    },
                                ]}
                            >

                                <Input placeholder="Địa chỉ thường trú" />
                            </Form.Item>
                            <Form.Item
                                label="Ngày bắt đầu"
                                name="start_date"

                                style={{ width: "100%" }}
                                rules={[
                                    {
                                        required: true,
                                        message: "Ngày bắt đầu là bắt buộc"
                                    },
                                ]}
                                initialValue={defaultDate}
                            >

                                <DatePicker placeholder='Ngày bắt đầu' style={{ width: "100%" }} format="DD/MM/YYYY" onChange={(e)=>{
                                    if(formRef.current){
                                        formRef.current.setFieldsValue({contract_from:e})
                                    }
                                }} />
                            </Form.Item>
                            <Form.Item label="CV / PB / BP" >
                                <Input.Group compact>

                                    <Form.Item
                                        name="position_id"
                                        style={{ width: "33%" }}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Chức vụ là bắt buộc"
                                            },
                                        ]}
                                    >

                                        <Select
                                            showSearch
                                            style={{ width: "100%" }}
                                            showArrow={true}
                                            defaultActiveFirstOption={true}
                                            options={(listPositions || []).map((value, index) => {
                                                return {
                                                    value: value.id,
                                                    label: value.name
                                                }
                                            })}
                                            placeholder="Chức vụ"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="department_id"
                                        style={{ width: "33%" }}
                                    // rules={[
                                    //     {
                                    //         required: true,
                                    //         message: "Phòng ban là bắt buộc"
                                    //     },
                                    // ]}
                                    >


                                        <Select
                                            showSearch
                                            style={{ width: "100%" }}
                                            showArrow={true}
                                            defaultActiveFirstOption={true}
                                            options={(listDeparment || []).map((value, index) => {
                                                return {
                                                    value: value.id,
                                                    label: value.name
                                                }
                                            })}
                                            placeholder="Phòng ban"
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        name="part_id"
                                        style={{ width: "33%" }}
                                    // rules={[
                                    //     {
                                    //         required: true,
                                    //         message: "Bộ phận là bắt buộc"
                                    //     },
                                    // ]}
                                    >

                                        <Select
                                            showSearch
                                            style={{ width: "100%" }}
                                            showArrow={true}
                                            defaultActiveFirstOption={true}
                                            options={(listPart || []).map((value, index) => {
                                                return {
                                                    value: value.id,
                                                    label: value.name
                                                }
                                            })}
                                            placeholder="Bộ phận"
                                        />
                                    </Form.Item>
                                </Input.Group>
                            </Form.Item>
                            <Form.Item label="CDCM"
                                name="professional_title">

                                <Input placeholder="Chức danh chuyên môn" />
                            </Form.Item>
                            <Form.Item label="Mô tả CV"
                                name="description"

                            >

                                <Input.TextArea placeholder="Mô tả CV" />
                            </Form.Item>
                            <Form.Item label="Thời gian làm" >
                                <Input.Group compact>

                                    <Form.Item
                                        name="total_time"
                                        style={{ width: "33%" }}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Số giờ trên 1 ngày là bắt buộc"
                                            },
                                        ]}
                                        initialValue={8}
                                    >
                                        <InputNumber placeholder="Số giờ trên 1 ngày là bắt buộc" style={{ width: "100%" }} />
                                    </Form.Item>
                                    <Form.Item
                                        name="from_time"
                                        style={{ width: "33%" }}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Thời gian từ"
                                            },
                                        ]}
                                        initialValue={"Sáng từ 8 giờ đến 12 giờ trưa"}
                                    >
                                        <Input placeholder="Thời gian từ" style={{ width: "100%" }} />
                                    </Form.Item>
                                    <Form.Item
                                        name="to_time"
                                        style={{ width: "33%" }}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Thời gian đến"
                                            },
                                        ]}
                                        initialValue={", chiều từ 13 giờ đến 17 giờ"}
                                    >
                                        <Input placeholder="Thời gian đến" style={{ width: "100%" }} />
                                    </Form.Item>
                                </Input.Group>
                            </Form.Item>
                            <Form.Item label="Địa điểm làm việc"
                                name="address_work"
                                initialValue={"CÔNG TY TNHH DỊCH VỤ IN ẤN BOVA\nk31/30 Nguyễn Như Đãi, Hòa Thọ Tây, Quận Cẩm Lệ, Thành Phố Đà Nẵng"}
                            >

                                <Input.TextArea placeholder="Địa điểm làm việc" />
                            </Form.Item>
                            <Form.Item label="Công cụ "
                                name="allocation_tool"

                            >

                                <Input.TextArea placeholder="Công cụ cấp phát" />
                            </Form.Item>
                            <Form.Item label="Số HĐ / Loại HĐ" >
                                <Input.Group compact>
                                    <Form.Item
                                        name="contract_number"

                                        style={{ width: "50%" }}
                                    // rules={[
                                    //     {
                                    //         required: true,
                                    //         message: "Số HĐ là bắt buộc"
                                    //     },
                                    // ]}
                                    >

                                        <Input placeholder="Số HĐ" style={{ width: "100%" }} readOnly />
                                    </Form.Item>
                                    <Form.Item
                                        name="contract_type"
                                        style={{ width: "50%" }}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Số HĐ là bắt buộc"
                                            },
                                        ]}
                                    >

                                        <Select
                                            showSearch
                                            style={{ width: "100%" }}
                                            showArrow={true}
                                            defaultActiveFirstOption={true}
                                            options={DATA_TYPE_CONTRACT}
                                            placeholder="Loại HĐ"
                                        />
                                    </Form.Item>

                                </Input.Group>
                            </Form.Item>
                            <Form.Item label="Thời hạn HĐ"
                                name="months"
                            >
                                <Input placeholder="Thời hạn HĐ" onBlur={(e)=>proccessTerm(e)} />
                            </Form.Item>
                            <Form.Item
                            label="Từ ngày"
                                name="contract_from"
                                // style={{ width: "33%" }}
                            >

                                <DatePicker placeholder='Từ ngày' style={{ width: "100%" }} format="DD/MM/YYYY" onBlur={(e)=>proccessTerm(e)} />
                            </Form.Item>

                            <Form.Item
                            label="Đến ngày"

                                name="contract_to"
                                // style={{ width: "33%" }}
                            >

                                <DatePicker placeholder='Đến ngày' style={{ width: "100%" }} format="DD/MM/YYYY" />
                            </Form.Item>
                            <Form.Item label="Mức lương"
                                name="salary"
                                rules={[
                                    {
                                        required: true,
                                        message: "Mức lương là bắt buộc"
                                    },
                                ]}
                            >
                                <Input placeholder="Mức lương" onChange={(e) => { handleMoneyChange(e, "salary"); onchageSalary(); }} />
                            </Form.Item>


                            {/* <span className='text-danger fst-italic'>(*) Tiền công theo = mức lương / 26</span> */}
                            <Form.Item label="Phụ cấp"
                                name="allowance_ids"

                            >
                                <Select
                                    mode="multiple"

                                    showSearch
                                    style={{ width: "100%" }}
                                    showArrow={true}
                                    defaultActiveFirstOption={true}
                                    options={(listAllowance || []).map((value, index) => {
                                        return {
                                            value: value.id,
                                            label: value.name
                                        }
                                    })}
                                    placeholder="Phụ cấp"
                                    onChange={(e) => {
                                        onchageSalary();
                                        setListAllowanceChose(e)
                                    }}
                                />

                            </Form.Item>

                            <div className='mb-2'>
                                {listAllowanceChose && listAllowanceChose.map((item, i) => {
                                    const allowance_ = listAllowance.filter((value, index) => {
                                        return value.id == item;
                                    })
                                    if (allowance_[0]) {
                                        return <div><span>{allowance_[0]?.name}: </span> <span>{formatMN1(allowance_[0]?.money)}</span></div>
                                    }
                                })}
                            </div>
                            <Form.Item label="Tổng thu nhập"
                                name="total_salary"
                                rules={[
                                    {
                                        required: true,
                                        message: "Tổng thu nhập là bắt buộc"
                                    },
                                ]}
                                extra="Ghi chú: Tổng thu nhập = mức lương + các khoản trợ cấp"
                            >
                                <Input placeholder="Tổng thu nhập" onChange={(e) => handleMoneyChange(e, "total_salary")} />
                            </Form.Item>
                            <Form.Item label="Mức lương ngày"
                                name="salary_day"
                                rules={[
                                    {
                                        required: true,
                                        message: "Mức lương ngày là bắt buộc"
                                    },
                                ]}
                                extra="Ghi chú: Mức lương ngày = Tổng thu nhập / 26, giá trị này để tham khảo, không dùng tính lương"
                            >
                                <Input placeholder="Mức lương ngày" onChange={(e) => handleMoneyChange(e, "salary_day")} />
                            </Form.Item>
                            <Form.Item label="Trạng thái"
                                name="status"
                                initialValue={true}
                            >
                                <SwitchA checked={record?.status} onChange={(e) => {
                                    setRecord({ ...record, status: e })
                                }} />

                            </Form.Item>
                            <Form.Item >
                                <div className="flex_">
                                    <Button type="default" style={{ marginRight: "10px" }} danger onClick={() => { }}>Hủy</Button>

                                    <Button type="primary" loading={isloading} style={{ marginRight: "10px" }} htmlType="submit"  >Cập nhật</Button>
                                </div>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </Drawer>
            <Card title={<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <div><FolderViewOutlined style={{ color: Colors.pop2[3] }} />
                    <span style={{ marginLeft: "10px", color: Colors.pop2[3] }}>Nhân viên</span></div>

                <Button type='primary' size='small' onClick={() => { setVisible(true); addnewRecord() }}><PlusCircleOutlined /></Button>
            </div>
            } bordered={false}
                style={{ width: "100%", margin: "0 auto" }}
                bodyStyle={{
                    padding: "10px",
                    // maxHeight: "90vh",
                    // overflowY: "scroll"

                }}
                headStyle={{
                    backgroundColor: Colors.colorgrey,
                    display: "flex",
                    minHeight: "30px",
                    borderRadius: "3px"
                }}
            >
                <div className='w-100 row p-0'>
                    <div className='col-lg-12 col-md-12 col-sm-12 p-0 '>
                        <Table columns={columns} dataSource={listEmployees} pagination={false} scroll={{
                            y: 800,
                        }}>

                        </Table>
                    </div>

                </div>

            </Card>
        </>
    )
}

export default Index
