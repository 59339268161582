import React, { useEffect, useRef, useState } from 'react'
import { Button, Card, Checkbox, DatePicker, Form, Input, message, Radio, Select, Table, TreeSelect } from 'antd';
import * as LINK from "../../../../constants/Links";

import "./../style.css"
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { META } from '../../../../utils/constant';
import { useDispatch, useSelector } from 'react-redux';
import { ListAccountantBankRequest, ListAccountantInvoiceTemplateRequest, ListAccountingAccountRequest, ListDefaultAccountRequest, ListDetermineCostAssetRequest, ListEmployeesRequest, ListUsersNodispatchRequest, SearchCustomersNoDispatchRequest, SearchSupplierNoDispatchRequest, StoreAccountantPurchaseRequest, StoreAccountingAccountRequest, StoreCostAllocationRequest } from '../../../../actions/indexWeb';
import { DATA_GROUP_ACCOUNT, DATA_REASON_PAYMENT, DATA_REASON_RECEIPT, DATA_STOCK_EXPORT, DATA_STOCK_IMPORT, DATA_TYPE_ACCOUNTING, DATA_TYPE_PURCHASE_RECEIPT, DATA_TYPE_PURCHASE_RECEIPT_ADD_BILL, DATA_TYPE_PURCHASE_RECEIPT_PAYMENT, DATA_TYPE_PURCHASE_RECEIPT_PAYMENT_ACCOUNT, DATA_TYPE_SELL, DATA_TYPE_SELL_ACCOUNT } from '../../../../constants/DefaultData';
import {
  FolderViewOutlined, AppstoreOutlined,
  DeleteOutlined, PlusCircleOutlined,
  EditOutlined, UnorderedListOutlined, SearchOutlined
} from '@ant-design/icons';
import * as Colors from "../../../../constants/Colors";
import Planning from "./planning";
import moment from 'moment';
import { createSlug, formatNumformatNumber, ber, splitAndRoundMultiple, formatNumber, filterTreeNode, filAcounts, isFirstDayOfMonth, daysUntilEndOfMonth, getDaysInMonth } from '../../../../service';

const Index = () => {

  const dispatch = useDispatch()
  const { Web } = useSelector(state => state);
  const params = useParams();
  const { typeV } = params;
  // console.log(typeV);

  const [meta, setMeta] = useState({ ...META });

  const [document, setDocument] = useState(null);
  const [ListDetermineCost, setListDetermineCost] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const [tab, setTab] = useState(1);
  const [isWrite, setisWrite] = useState(false);
  const [record, setRecord] = useState(null);
  const [typeV_, setTypeV_] = useState("Khấu hao TSCĐ");

  const [listAccount, setListAccount] = useState([]);
  const [listDefaultAccount, setListDefaultAccount] = useState([]);
  const [listDefaultAccountFil, setListDefaultAccountFill] = useState(null);
  const [listTKno, setListTKno] = useState([]);
  const [listTKco, setListTKco] = useState([]);
  const [listTKvat, setListTKvat] = useState([]);
  const [listAcountant, setlistAcountant] = useState([]);
  const [changeAcounting, setChangeAcounting] = useState(false);

  const [total_, setTotal_] = useState({
    totalPrice: 0,
    totalVat: 0,
    totalDiscount: 0,
    total: 0,

  });

  const total = {
    totalExpen: 0,
    totalExpenUse: 0,
    totalAllocation: 0,
    totalPercent: 0,
  }
  const formRef = useRef(null);

  const defaultDate = moment();

  const getListAccount = async () => {
    const response = await ListAccountingAccountRequest({
      limit: meta.per_page * 100,
      page: meta.current_page, column: "code", sort: "ASC"
    });
    if (response.code == 200) {
      if (response.data) {
        console.log(response.data.data);
        if (response.data.data) {

          setListAccount(response.data.data);
        }
      }
    }


  }
  const getListDefaultAccount = async () => {
    const response = await ListDefaultAccountRequest({
      limit: meta.per_page * 100,
      page: meta.current_page, column: "code", sort: "ASC"
    });
    console.log("responseresponse ", response);

    if (response.code == 200) {
      if (response.data) {

        setListDefaultAccount(response.data);

      }
    }


  }

  const getListDetermineCost = async (month, year) => {

    const response = await ListDetermineCostAssetRequest({ data: { month: month, year: year } });
    if (response.code == 200) {
      if (response.data) {

        setListDetermineCost(response.data);
        let isWrite = false;

        response.data.map((value, index) => {
          // console.log("value ",value);
          if (parseInt(value.iswrite) == 1) {
            isWrite = true
          }
        })
        // console.log("isWriteisWriteisWrite ",isWrite);

        setisWrite(isWrite)
      }
      if (response.document?.[0]) {
        setDocument(response.document[0])

      } else {
        setDocument(null)
      }
    }


  }
  useEffect(() => {

    if (document) {
      if (formRef.current) {
        console.log(document.code);

        formRef.current.setFieldsValue({ code: document.code, accounting_date: moment(document.accounting_date, "YYYY-MM-DD"), document_date: moment(document.document_date, "YYYY-MM-DD"), })
      }
    } else {
      formRef.current.setFieldsValue({ code: "", accounting_date: moment(defaultDate, "YYYY-MM-DD"), document_date: moment(defaultDate, "YYYY-MM-DD"), })
    }

    return () => {

    };
  }, [document]);
  useEffect(() => {
    getListAccount();
    getListDefaultAccount();
    if (formRef.current) {
      const month = defaultDate.format('MM'); // Tháng (2 chữ số)
      const year = defaultDate.format('YYYY'); // Năm (4 chữ số)
      formRef.current.setFieldsValue({ note: `Khấu hao TSCĐ tháng ${month} năm ${year}` })
      getListDetermineCost(month, year);
    }

    return () => {

    };
  }, []);

  const submitForm = async (values) => {
    setIsloading(true);
    if (record) {
      values.id = record.id;
    }
    values.type = 2;
    ListDetermineCost.map((value, index) => {
      value.accounting_code = value.tkco;
    })
    values.detail = ListDetermineCost;
    const response = await StoreCostAllocationRequest(values);
    console.log("responseresponseresponse ", response);

    setIsloading(false);
    if (response.code == 200) {
      if (response.message) {
        message.success(response.message);
      } else {
        message.success("Cập nhật thành công");
      }
      setDocument(response.data)
      // setRecord(null);
    } else {
      if (response.message) {
        if (typeof response.message == "string") {
          message.error(response.message);
        } else {
          (Object.values(response.message) || []).map((value, index) => {
            message.error(value);
          })
        }

      } else {
        message.warning("Cập nhật không thành công");
      }
    }
  }

  const changeDate = (e) => {

    // const suplier = listSuplier.filter((value, index) => {
    //   return value.code == e;
    // })
    // if (suplier[0]) {
    //   if (formRef.current) {
    //     formRef.current.setFieldsValue({ suplier_name: suplier[0].name, suplier_address: suplier[0].address, suplier_taxcode: suplier[0].taxcode })
    //     // const formvalue= formRef.current.getFieldsValue();
    //     if (suplier[0].name) {
    //       // formRef.current.setFieldsValue({ note: "ba " + suplier[0].name })
    //     }
    //   }
    // }


  }

  // const changeTypeSeason = (e) => {

  //   setType(e)
  // }
  // useEffect(() => {
  //   const data_ = typeV == 1 ? DATA_REASON_RECEIPT : DATA_REASON_PAYMENT;
  //   const suplier = data_.filter((value, index) => {
  //     return value.value == type;
  //   })
  //   // console.log(data_,e);

  //   if (suplier[0]) {
  //     if (formRef.current) {
  //       formRef.current.setFieldsValue({ note: suplier[0].label })

  //     }
  //   }
  //   return () => {

  //   };
  // }, [type]);


  const setnote = (e) => {
    if (formRef.current) {
      const month = e.format('MM'); // Tháng (2 chữ số) 
      const year = e.format('YYYY'); // Năm (4 chữ số)
      formRef.current.setFieldsValue({ note: `Khấu hao TSCĐ tháng ${month} năm ${year}` })
      getListDetermineCost(month, year);
    }


  }
  const processListAccount = async () => {
    if (listDefaultAccountFil) {

      const listTKno_ = await filAcounts(listDefaultAccountFil, "tkno", listAccount);
      const listTKco_ = await filAcounts(listDefaultAccountFil, "tkco", listAccount);

      console.log("listTKno_ listTKco_listTKco_listTKco_", listTKno_);

      setListTKno(listTKno_);
      setListTKco(listTKco_);
      // setListTKvat(listTKvat_);

      if (formRef.current) {
        formRef.current.setFieldsValue({ accounting_code: listTKno_.default });
      }


    }
  }
  useEffect(() => {
    filterAccount();

    return () => {

    };
  }, [listDefaultAccount]);

  const filterAccount = () => {

    if (listDefaultAccount.length > 0) {
      const fildata = listDefaultAccount.filter((value, index) => {
        return value.code == "phan-bo-ccdc";
      })

      setListDefaultAccountFill(fildata[0]);

    }
    return;
  }

  useEffect(() => {

    if (listDefaultAccountFil && listAccount?.length > 0) {
      processListAccount();
    }

    return () => {

    };
  }, [listDefaultAccountFil, listAccount]);
  useEffect(() => {
    if (ListDetermineCost.length > 0) {
      renderAccountant()

      // ListDetermineCost.map((row, index) => {
      //   if (row.children && row.children.length > 0) {
      //     row.children.map((item, i) => {
      //       formRef.current.setFieldsValue({ [`d-accounting_code-${item.department_code}-${index}-${i}`]: item.accounting_code })
      //     })
      //   }
      // })
    }

    return () => {

    };
  }, [ListDetermineCost, changeAcounting]);
  const changeAcountingCode = (e, index, i, departmentcode) => {

    const newListDetermineCost = ListDetermineCost.map((row, index) => {
      let child = [];
      if (row.children && row.children.length > 0) {
        child = row.children.map((item, i) => {
          if (departmentcode === item.department_code) {
            return { ...item, accounting_code: e }
          } else {
            return { ...item }
          }

        })
      }
      return { ...row, children: child }
    })

    // console.log(e);
    // newListDetermineCost[index].children[i].accounting_code = e;
    setListDetermineCost(newListDetermineCost);

    newListDetermineCost.map((row, index) => {
      if (row.children && row.children.length > 0) {
        row.children.map((item, i) => {
          formRef.current.setFieldsValue({ [`d-accounting_code-${departmentcode}-${index}-${i}`]: e })
        })
      }
    })
    setChangeAcounting(!changeAcounting)


  }
  const processPriceOfperiod=(row)=>{
    const remaining_period=row.remaining_period;
      const periods=row.periods;
      let price_of_period=row.price_of_period;
      if(remaining_period==periods){
        if(!isFirstDayOfMonth(row.start_date)){
          const untildays=daysUntilEndOfMonth(row.start_date);
          // console.log("untildaysuntildays ",untildays);
          const daysOfmonth=30
          price_of_period=(row.price_of_period/daysOfmonth)*untildays;
        }
      }
      return price_of_period;
  }
  const renderAllocation = () => {
    const result = [];
    ListDetermineCost.map((row, index) => {
    
      let price_of_period=processPriceOfperiod(row);
      

      result.push(<tr key={index} className='fw-bold'>
        <td>
          {row.products_warehouse_id}
        </td>
        <td>
          {row.products_warehouse_name}
        </td>
        <td className='text-right'>
          {formatNumber(price_of_period)}
        </td>
        <td className='text-right'></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>)
      if (row.children && row.children.length > 0) {
        const ratios = [];
        let numberPrice = 0;
        numberPrice = price_of_period;
        row.children.map((item, i) => {
          ratios.push(item.percent);

        });
        const resutPricePercent = splitAndRoundMultiple(numberPrice, ratios);
        row.children.map((item, i) => {
          total.totalPercent += item.percent;
          total.totalAllocation += (resutPricePercent[i]);
          result.push(<tr key={index + "-" + i}>
            <td>

            </td>
            <td>

            </td>
            <td className='text-right'>

            </td>
            <td className='text-center'>

              {item.department_code}
            </td>
            <td> {item.department_name}</td>
            <td className='text-center'>{item.percent}</td>
            <td className='text-right'>{formatNumber(resutPricePercent[i])}</td>
            <td className='text-center' style={{ width: "150px" }}>  {!document && <Form.Item noStyle name={`d-accounting_code-${item.department_code}-${index}-${i}`} className='m-0' initialValue={item.accounting_code}>
              <TreeSelect
                showSearch
                style={{ width: '120px' }}
                treeData={listTKno?.listAccount}
                dropdownStyle={{ maxHeight: 400, minWidth: "250px", overflow: 'auto' }}
                placeholder="Tài khoản"
                filterTreeNode={filterTreeNode}
                treeDefaultExpandAll
                onChange={(e) => changeAcountingCode(e, index, i, item.department_code)}
              />
            </Form.Item>}
              {document && item.accounting_code}</td>
          </tr>)

        })

      }

    })
    result.push(<tr className='bg_main_2 fw-bold text-white'  >
      <td colSpan={5} className='text-center'>
        Tổng
      </td>

      <td className='text-center'> {total.totalPercent}</td>
      <td className='text-right'> {formatNumber(total.totalAllocation)}</td>
      <td className='text-right'></td>
      <td className='text-center'></td>
    </tr>)


    return result;
  }

  const renderAccountant = () => {
    const result = [];
    let data = {};
    let note = "";
    if (formRef.current) {
      const formvalue = formRef.current.getFieldsValue();
      note = formvalue.note;
    }
    ListDetermineCost.map((row, index) => {
      let price_of_period=processPriceOfperiod(row);
      if (row.children && row.children.length > 0) {
        const ratios = [];
        let numberPrice = 0;
        numberPrice = price_of_period;
        row.children.map((item, i) => {
          ratios.push(item.percent);

        });
        const resutPricePercent = splitAndRoundMultiple(numberPrice, ratios);
        row.children.map((item, i) => {
          const mn = resutPricePercent[i];
          if (!data[item.department_code]) {

            data[item.department_code] = {
              note: note,
              department_code: item.department_code,
              tkno: item.accounting_code,
              tkco: row.tkco,
              money: mn,
            }
          } else {
            data[item.department_code].money += mn
          }

        })

      }

    })
    data = Object.values(data);
    let total = 0;
    data.map((row, index) => {
      total += row.money;
      result.push(<tr >
        <td>{row.note}</td>
        <td className='text-center'>{row.tkno}</td>
        <td className='text-center'>{row.tkco}</td>
        <td className='text-right'>{formatNumber(row.money)}</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td className='text-center'>{row.department_code}</td>
      </tr>)
    })
   
    
    result.push(<tr className='bg_main_2 fw-bold text-white' >
      <td colSpan={3} className='text-center'>Tổng </td>
      <td className='text-right'>{formatNumber(total)}</td>

      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td className='text-center'></td>
    </tr>)


    setlistAcountant(result);
    return result;
  }
  return (
    <Card title={<div style={{ display: "flex", alignItems: "center" }}>
      <Link to={LINK.WEB_ACCOUNTANT}><Button size='small'><i className="fas fa-chevron-left"></i></Button></Link>
      <FolderViewOutlined style={{ color: Colors.pop2[3] }} />
      <span style={{ marginLeft: "10px", color: Colors.pop2[3] }} >{typeV_}</span>
      {/* <span style={{ marginLeft: "10px", color: tab == 2 ? Colors.pop2[3] : "#000", cursor: "pointer" }} onClick={() => setTab(2)}>Hóa đơn</span> */}
    </div>} bordered={false}
      style={{ width: "100%", margin: "0 auto" }}
      bodyStyle={{
        padding: "10px",
        // maxHeight: "90vh",
        // overflowY: "scroll"

      }}
      headStyle={{
        backgroundColor: Colors.colorgrey,
        display: "flex",
        minHeight: "30px",
        borderRadius: "3px"
      }}
    >
      <div className='w-100 m-auto row  p-0'>
        <div className='col-lg-12 col-md-12 col-sm-12 p-0 '>
          <Form name="formadd" onFinish={submitForm} ref={formRef}
            labelCol={{ span: 2 }}
            wrapperCol={{ span: 24 }}
          >


            <div className={`row `}>
              <div className='col-lg-8 col-md-8 col-sm-8 '>
                <div className=' bg-white p-2'>
                  <Form.Item label="Diễn giải" name="note" className='m-0'>

                    <Input />
                  </Form.Item>
                  <Form.Item label="Tháng/năm"
                    name={"date"}
                    className='m-0'
                    initialValue={moment.utc(defaultDate, "YYYY-MM-DD")}>
                    <DatePicker placeholder='Tháng/năm' picker="month" style={{ width: "100%" }} format="MM/YYYY" onChange={(e) => setnote(e)} />
                  </Form.Item>

                </div>
              </div>
              <div className='col-lg-4 col-md-4 col-sm-4 '>
                <div className=' bg-white p-2'>
                  <Form.Item label="Ngày hoạch toán"
                    name="accounting_date"
                    rules={[
                      {
                        required: true,
                        message: "Ngày hoạch toán là bắt buộc"
                      },
                    ]}
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 14 }}
                    initialValue={document?.accounting_date ? moment.utc(document.accounting_date, 'YYYY-MM-DD') : moment.utc(defaultDate, 'YYYY-MM-DD')} className='m-0'
                  >

                    <DatePicker placeholder='ngày hoạch toán' style={{ width: "100%" }} />
                  </Form.Item>
                  <Form.Item label="Ngày chứng từ"
                    name="document_date"
                    rules={[
                      {
                        required: true,
                        message: "Ngày chứng từ là bắt buộc"
                      },
                    ]}
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 14 }} className='m-0'
                    initialValue={document?.document_date ? moment.utc(document.document_date, 'YYYY-MM-DD') : moment.utc(defaultDate, 'YYYY-MM-DD')}

                  >

                    <DatePicker placeholder='ngày chứng từ' style={{ width: "100%" }} />
                  </Form.Item>

                  <Form.Item label="Số chứng từ"
                    name="code"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Số chứng từ là bắt buộc"
                    //   },
                    // ]}
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 14 }} className='m-0'
                    initialValue={document?.code}
                  >
                    <Input placeholder="Số chứng từ" style={{ width: "100%" }} disabled />
                  </Form.Item>
                </div>
              </div>



            </div>
            <div className={`row p-2`} >
              <Radio.Group
                options={[{ value: 1, label: "Xác định chi phí" }, { value: 2, label: "Phân bổ" }, { value: 3, label: "Hoạch toán" }]}
                onChange={(e) => {
                  setTab(e.target.value)
                }}
                value={tab}
                optionType="button"
                buttonStyle="solid"
                checked
              />
            </div>

            <div className={`row ${parseInt(tab) == 1 ? "" : "d-none"}`} >
              <div className="table-responsive" style={{ height: "300px" }}
              // ref={tableRef}
              // onMouseDown={handleMouseDown}
              // onMouseMove={handleMouseMove}
              // onMouseUp={handleMouseUp}
              // onMouseLeave={handleMouseLeave}
              >
                <table className="table table-ledger table-sm table-hover" style={{ width: "max-content", minWidth: "100%" }} >
                  <thead style={{ position: "sticky", top: 0, zIndex: 1000, cursor: "all-scroll" }} >

                    <tr className='noscroll'>

                      <th className='p-1 text-center align-middle'  > Mã TS</th>
                      <th className='p-1 text-center align-middle'  >Tên TS</th>
                      <th className='p-1 text-center align-middle'  >Loại TS</th>
                      <th className='p-1 text-center align-middle'  >Đơn vị sử dụng</th>
                      <th className='p-1 text-center align-middle '  >Giá trị KH tháng</th>
                      <th className='p-1 text-center align-middle '  >Tổng tiền PB</th>
                      <th className='p-1 text-center align-middle ' >Số tiền PB đang dùng</th>
                      <th className='p-1 text-center align-middle ' >Giá trị còn lại của CCDC giảm</th>
                    </tr>

                  </thead>
                  <tbody>
                    {ListDetermineCost && ListDetermineCost.map((row, index) => {
                       let price_of_period=processPriceOfperiod(row);
                       const ratios = [];
                       let numberPrice = 0;
                       numberPrice = price_of_period;
                       row.children.map((item, i) => {
                         ratios.push(item.percent);
               
                       });
                       const resutPricePercent = splitAndRoundMultiple(numberPrice, ratios);
                     
                      total.totalExpen +=  resutPricePercent.reduce((total, value) => total + value, 0);
                      total.totalExpenUse +=  resutPricePercent.reduce((total, value) => total + value, 0);;
                      return <tr key={index}>
                        <td>
                          {row.products_warehouse_id}

                        </td>
                        <td>
                          {row.products_warehouse_name}

                        </td>
                        <td>
                          {row.accountant_type_assets_name}
                        </td>
                        <td>
                          {row.department_name}
                        </td>

                        <td className='text-right'>
                          {formatNumber(price_of_period)}

                        </td>

                        <td className='text-right'>
                          {formatNumber(price_of_period)}

                        </td>
                        <td>

                        </td>
                        <td>

                        </td>
                      </tr>
                    })}

                    <tr className='bg_main_2 fw-bold text-white'>
                      <td colSpan={4} className='text-center'>
                        Tổng

                      </td>

                      <td className='text-right'>
                        {formatNumber(total.totalExpen)}

                      </td>
                      <td className='text-right'>

                        {formatNumber(total.totalExpenUse)}
                      </td>
                      <td>

                      </td>
                      <td>

                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

            </div>


            <div className={`row ${parseInt(tab) == 2 ? "" : "d-none"}`} >
              <div className="table-responsive" style={{ height: "300px" }}
              // ref={tableRef}
              // onMouseDown={handleMouseDown}
              // onMouseMove={handleMouseMove}
              // onMouseUp={handleMouseUp}
              // onMouseLeave={handleMouseLeave}
              >
                <table className="table table-ledger table-sm table-hover" style={{ width: "max-content", minWidth: "100%" }} >
                  <thead style={{ position: "sticky", top: 0, zIndex: 1000, cursor: "all-scroll" }} >

                    <tr className='noscroll'>

                      <th className='p-1 text-center align-middle'  > Mã TS</th>
                      <th className='p-1 text-center align-middle'  >Tên TS</th>
                      <th className='p-1 text-center align-middle '  >Giá trị KH tháng</th>
                      <th className='p-1 text-center align-middle ' >Đối tượng PB</th>
                      <th className='p-1 text-center align-middle ' >Tên đối tượng PB</th>
                      <th className='p-1 text-center align-middle ' >Tỉ lệ (%)</th>
                      <th className='p-1 text-center align-middle ' >Số tiền</th>
                      <th className='p-1 text-center align-middle ' >Tk chi phí</th>

                    </tr>

                  </thead>
                  <tbody>
                    {renderAllocation()}
                  </tbody>
                </table>
              </div>

            </div>

            <div className={`row ${parseInt(tab) == 3 ? "" : "d-none"}`} >
              <div className="table-responsive" style={{ height: "300px" }}
              // ref={tableRef}
              // onMouseDown={handleMouseDown}
              // onMouseMove={handleMouseMove}
              // onMouseUp={handleMouseUp}
              // onMouseLeave={handleMouseLeave}
              >
                <table className="table table-ledger table-sm table-hover" style={{ width: "max-content", minWidth: "100%" }} >
                  <thead style={{ position: "sticky", top: 0, zIndex: 1000, cursor: "all-scroll" }} >

                    <tr className='noscroll'>
                      <th className='p-1 text-center align-middle'  > Diễn giải</th>
                      <th className='p-1 text-center align-middle'  >Tk nợ</th>
                      <th className='p-1 text-center align-middle '  >Tk có</th>
                      <th className='p-1 text-center align-middle ' >Số tiền</th>
                      <th className='p-1 text-center align-middle ' >Đối tượng nợ</th>
                      <th className='p-1 text-center align-middle ' >Tên đối tượng nợ</th>
                      <th className='p-1 text-center align-middle ' >Đối tượng có</th>
                      <th className='p-1 text-center align-middle ' >Tên đối tượng có</th>
                      <th className='p-1 text-center align-middle ' >Đơn vị</th>

                    </tr>

                  </thead>
                  <tbody>
                    {listAcountant}
                  </tbody>
                </table>
              </div>

            </div>
            {document && <div className={`row`}>
              <div className='col-lg-12 col-md-12 col-sm-12 mt-2'>
                <span className='text-danger fst-italic '>(*) Đã khấu hao TSCĐ tháng {document?.date != null ? moment(document?.date).format('MM') : ""} năm {document?.date != null ? moment(document?.date).format('YYYY') : ""}</span>
              </div>
            </div>}
            {/* <div className={`row`}>
              <div className='col-lg-6 col-md-6 col-sm-6'>
                <div className={`row`}>
                  <div className='col-lg-6 col-md-6 col-sm-6'>
                    Tổng tiền hàng: {formatNumber(total_.totalPrice)}
                  </div>
                  <div className='col-lg-6 col-md-6 col-sm-6'>
                    Tổng tiền chiết khấu: {formatNumber(total_.totalDiscount)}
                  </div>
                </div>
              </div>
              <div className='col-lg-6 col-md-6 col-sm-6'>
                <div className={`row`}>
                  <div className='col-lg-6 col-md-6 col-sm-6'>
                    Tiền thuế: {formatNumber(total_.totalVat)}
                  </div>
                  <div className='col-lg-6 col-md-6 col-sm-6'>
                    Tiền tiền thanh toán: {formatNumber(total_.total)}
                  </div>
                </div>
              </div>
            </div> */}
            <div className={`row`}>
              <div className='col-lg-12 col-md-12 col-sm-12 mt-2'>
                <div className=' bg-white p-2'>
                  <Form.Item >
                    <div className="flex_">
                      {/* <Button type="default" style={{ marginRight: "10px" }} danger onClick={() => { }}>Hủy</Button> */}

                      <Button type="primary" loading={isloading} style={{ marginRight: "10px" }} htmlType="submit" disabled={document}  >Cập nhật</Button>
                    </div>
                  </Form.Item>
                </div>
              </div>
            </div>
          </Form>
        </div>
        <div className='col-lg-12 col-md-12 col-sm-12 bg-white'>


        </div>
      </div>
    </Card>
  )
}

export default Index
