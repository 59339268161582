
export const Runfirst = {
    // ListCategory: (props, state, data = []) => props.SearchCategoryRequest({
    //     limit: state.meta.per_page*100,
    //     page: state.meta.current_page, column: state.meta.column, sort: state.meta.sort
    // }, data),
    ListMenuHome: (props, state, data = []) => props.SearchMenuHomeRequest({
        limit: state.meta.per_page*100,
        page: state.meta.current_page, column: state.meta.column, sort: state.meta.sort
    }, {...data}),
    ListInfoTransport: (props, state, data = []) => props.ListInfoTransportRequest({
        limit: state.meta.per_page * 100,
        page: state.meta.current_page, column: state.meta.column, sort: state.meta.sort
    }),

    ListTags: (props, state, data = []) => props.ListTagsRequest({
        limit: state.meta.per_page * 100,
        page: state.meta.current_page, column: state.meta.column, sort: state.meta.sort
    }),

    ListStore: (props, state, data = []) => props.ListStoreRequest({
        limit: state.meta.per_page * 100,
        page: state.meta.current_page, column: state.meta.column, sort: state.meta.sort
    }),



    ListClassifys: (props, state, data = []) => props.ListClassifysRequest({
        limit: state.meta.per_page * 100,
        page: state.meta.current_page, column: state.meta.column, sort: state.meta.sort
    }),


    InfoPackageActive: (props, state, data = []) => props.InfoPackageActiveRequest({
        limit: state.meta.per_page * 100,
        page: state.meta.current_page, column: state.meta.column, sort: state.meta.sort
    }),
    ListAttributeProducts: (props, state, data = []) => props.ListAttributeProductsRequest({
        limit: state.meta.per_page * 100,
        page: state.meta.current_page, column: state.meta.column, sort: state.meta.sort
    }),
    ListEcommerceType: (props, state, data = []) => props.ListEcommerceTypeRequest({
        limit: state.meta.per_page * 100,
        page: state.meta.current_page, column: state.meta.column, sort: state.meta.sort
    },{data:{active:0}}),
    ListEcommerceConfig: (props, state, data = []) => props.ListEcommerceConfigRequest({
        limit: state.meta.per_page * 100,
        page: state.meta.current_page, column: state.meta.column, sort: state.meta.sort
    },{data:{active:0}}),
    ListUnit: (props, state, data = []) => props.ListUnitRequest({
        limit: state.meta.per_page * 100,
        page: state.meta.current_page, column: state.meta.column, sort: state.meta.sort
    }),
    ListStatusTransport: (props, state, data = []) => props.ListStatusTransportRequest({
        limit: state.meta.per_page * 100,
        page: state.meta.current_page, column: state.meta.column, sort: state.meta.sort
    }),
    ListConnectExportBill: (props, state, data = []) => props.ListConnectExportBillRequest({
        limit: state.meta.per_page * 100,
        page: state.meta.current_page, column: state.meta.column, sort: state.meta.sort
    }),
}