import React, { useEffect, useRef, useState } from 'react'
import { Button, Form, Input, message, Modal, Select, Table, TreeSelect } from 'antd';
import * as LINK from "../../../../constants/Links";

import "./../style.css"
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { META } from '../../../../utils/constant';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteAccountantBankRequest, DeleteDefaultAccountRequest, ListAccountantBankRequest, ListAccountingAccountRequest, ListDefaultAccountRequest, StoreAccountantBankRequest, StoreAccountingAccountRequest, StoreDefaultAccountRequest } from '../../../../actions/indexWeb';
import { DATA_GROUP_ACCOUNT, DATA_TYPE_ACCOUNTING } from '../../../../constants/DefaultData';
import {
  FolderViewOutlined, AppstoreOutlined,
  DeleteOutlined, PlusCircleOutlined, ExclamationCircleOutlined,
  EditOutlined, UnorderedListOutlined, SearchOutlined
} from '@ant-design/icons';
import * as Colors from "../../../../constants/Colors";
import { createSlug } from '../../../../service';
const Index = () => {

  const dispatch = useDispatch()
  const { Web } = useSelector(state => state);
  const [meta, setMeta] = useState({ ...META });
  const [listDefaultAccount, setListDefaultAccount] = useState([]);
  const [listAccount, setListAccount] = useState([]);
  const [listAccountChose, setListAccountChose] = useState([]);
  const [listAccountFil, setListAccountFil] = useState([
    { code_account: "tkno", account_name: "TK nợ", accounts: "", default_account: "" },
    { code_account: "tkco", account_name: "TK có", accounts: "", default_account: "" }
  ]);
  const [isloading, setIsloading] = useState(false);
  const [record, setRecord] = useState(null);
  const formRef = useRef(null);
  const getListAccount = async () => {
    const response = await ListAccountingAccountRequest({
      limit: meta.per_page * 100,
      page: meta.current_page, column: "code", sort: "ASC"
    });
    if (response.code == 200) {
      if (response.data) {
        console.log(response.data.data);
        if (response.data.data) {

          setListAccount(response.data.data);
        }
      }
    }


  }
  const getListDefaultAccount = async () => {
    const response = await ListDefaultAccountRequest({
      limit: meta.per_page * 100,
      page: meta.current_page, column: "code", sort: "ASC"
    });
    if (response.code == 200) {
      if (response.data) {
        setListDefaultAccount(response.data);
      }
    }


  }
  useEffect(() => {
    getListDefaultAccount();
    getListAccount();
    return () => {

    };
  }, []);
  const columns = [

    // {
    //   title: 'Mã',
    //   dataIndex: 'code',
    //   key: 'code',
    //   sorter: (a, b) => a.code - b.code,
    //   width: "20%"

    // },
    {
      title: 'Tên',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name - b.name,
    },

    // {
    //   title: 'Mô tả',
    //   dataIndex: 'description',
    //   key: 'description',
    //   sorter: (a, b) => a.description - b.description,
    // },

    {
      title: 'Chỉnh sửa',
      dataIndex: '',
      width:"25%",
      render: (text, record, index) => (
        <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }} >
          <span className="__action_">
            {/* <button className="reset_btn btn_edit btn__" onClick={() => editRow(record)}> <EditOutlined style={{ color: Colors.colorView }} /> </button> */}
            <button className="reset_btn btn_delete btn__" onClick={() => { confirmDeleted(index, record) }}><DeleteOutlined style={{ color: Colors.colorDelete }} /></button>
            {/* <button className="reset_btn btn_view btn__" onClick={() => { editRow(record) }}> <FolderViewOutlined   style={{ color: Colors.colorView }} /></button> */}

          </span>
        </div>
      ),
      key: 'group',
    },
  ];
  const confirmDeleted = (row, record) => {
    Modal.confirm({
      title: 'Xác nhận',
      icon: <ExclamationCircleOutlined />,
      content: 'Bạn có chắc muốn xóa',
      okText: 'Xóa',
      cancelText: 'Bỏ qua',
      onOk: () => {
        deleteRow(row, record);

      }
    });
  };
  const deleteRow = async (row, record) => {
    const response = await DeleteDefaultAccountRequest({ id: record.id });
    setIsloading(false);
    if (response.code == 200) {
      if (response.message) {
        message.success(response.message);
      } else {
        message.success("Cập nhật thành công");
      }

      setListDefaultAccount();

    } else {
      if (response.message) {
        if (typeof response.message == "string") {
          message.error(response.message);
        } else {
          (Object.values(response.message) || []).map((value, index) => {
            message.error(value);
          })
        }

      } else {
        message.warning("Cập nhật không thành công");
      }
    }
  }
  const editRow = (record) => {
    setRecord(record)
    if(record.accounts){
        console.log("Object.values(record.accounts) ",Object.values(record.accounts));
          const accounts=Object.values(record.accounts);
          if(accounts.length>0){
            setListAccountFil(Object.values(record.accounts))
            Object.values(record.accounts).map((value, index) => {
              for(const x in value){
                formRef.current.setFieldsValue({ [`d-${x}-${index}`]:value[x]})
    
              }
            })
          }else{
            const resetData=[
              { code_account: "tkno", account_name: "TK nợ", accounts: "", default_account: "" },
              { code_account: "tkco", account_name: "TK có", accounts: "", default_account: "" }
            ];
            setListAccountFil(resetData)
            resetData.map((value, index) => {
              for(const x in value){
                formRef.current.setFieldsValue({ [`d-${x}-${index}`]:value[x]})
    
              }
            })
          }
        
    }
    formRef.current.setFieldsValue({ ...record })
  }
  const submitForm = async (values) => {
    // setIsloading(true);
    if (record) {
      values.id = record.id;
    }

    const detail = {};
    for (const x in values) {
      const xarr = x.split("-");
      if (xarr[0] == "d") {
        let key = xarr[1];
        let row = xarr[2];
        if (!detail[row]) {
          detail[row] = {};
        }
        detail[row][key] = values[x];

      }

    }
    values.detail = Object.values(detail);
    // console.log("valuesvaluesvalues ", values);
    // return;
    const response = await StoreDefaultAccountRequest(values);
    setIsloading(false);
    if (response.code == 200) {
      if (response.message) {
        message.success(response.message);
      } else {
        message.success("Cập nhật thành công");
      }


      getListDefaultAccount();
    } else {
      if (response.message) {
        if (typeof response.message == "string") {
          message.error(response.message);
        } else {
          (Object.values(response.message) || []).map((value, index) => {
            message.error(value);
          })
        }

      } else {
        message.warning("Cập nhật không thành công");
      }
    }
  }

  const addnewRecord = () => {
    setRecord(null)
    formRef.current.resetFields();
    const resetData=[
      { code_account: "tkno", account_name: "TK nợ", accounts: "", default_account: "" },
      { code_account: "tkco", account_name: "TK có", accounts: "", default_account: "" }
    ];
    setListAccountFil(resetData)

    resetData.map((value, index) => {
      for(const x in value){
        formRef.current.setFieldsValue({ [`d-${x}-${index}`]:value[x]})

      }
    })
  }
  const filterTreeNode = (inputValue, treeNode) => {
    const { title, description, code } = treeNode;
    // console.log(treeNode.props.data);
    const codes = inputValue.split(';').map(code => code.trim());
    return (
      title.toLowerCase().includes(inputValue.toLowerCase()) || // Tìm kiếm theo title
      description.toLowerCase().includes(inputValue.toLowerCase()) || // Tìm kiếm theo description
      codes.includes(code.toString()) // Tìm kiếm theo code
    );
  };

  const deleteAcountFil = (item, i) => {
    if (listAccountFil.length <= 1) {
      return;
    }
    const newList = listAccountFil.filter((value, index) => {
      return index != i;
    })
    setListAccountFil(newList)
  }

  const addAcountFil = () => {


    setListAccountFil([...listAccountFil, { code_account: "", account_name: "", accounts:"", default_account:"" },])
  }
  return (
    <div className='w-100 row '>
      <div className='col-lg-4 col-md-4 col-sm-12 '>
        <Table columns={columns} dataSource={listDefaultAccount} pagination={false} 
           onRow={(record, rowIndex) => {
            return {
              onClick: () => editRow(record, rowIndex), // Gán sự kiện click vào dòng
            };
          }}
        scroll={{
          y: 800,
        }}>

        </Table>
      </div>
      <div className='col-lg-8 col-md-8 col-sm-12 bg-white' >
        <div className='w-100 d-flex justify-content-end m-2'>
          <Button onClick={addnewRecord}><i class="fas fa-plus"></i></Button>
        </div>
        <Form name="formadd"  onFinish={submitForm} ref={formRef}
          labelCol={{ span: 1 }}
          wrapperCol={{ span: 23 }}
        >


          <Form.Item label="Tên"
        
            name="name"
            rules={[
              {
                required: true,
                message: "Tên là bắt buộc"
              },
            ]}
          >

            <Input placeholder="Tên" onChange={(e) => {
              const value = e.target.value;
              if (formRef.current) {
                formRef.current.setFieldsValue({ code: createSlug(value) })
              }
            }} />
          </Form.Item>
          <Form.Item label="Mã"
            name="code"
            rules={[
              {
                required: true,
                message: "code là bắt buộc"
              },
            ]}
          >

            <Input placeholder="code" />
          </Form.Item>

          <Form.Item label="Mô tả"
            name="description"

          >

            <Input placeholder="Mô tả" />
          </Form.Item>


          <div>
            {listAccountFil && listAccountFil.map((item, i) => {
              let lastRow = false;
              if (i == listAccountFil.length - 1) {
                lastRow = true;
              }
              return <Form.Item label="Tk" >
                <Input.Group compact >
                  <Form.Item
                    name={`d-account_name-${i}`}
                    style={{ width: '30%' }} className='m-0'
                    initialValue={item.account_name}
                  >
                    <Input placeholder="Tên" onChange={(e) => {
                      const value = e.target.value;
                      if (formRef.current) {
                        formRef.current.setFieldsValue({ [`d-code_account-${i}`]: createSlug(value) })
                      }
                    }} addonBefore={<div>
                      {lastRow && <span style={{ cursor: "pointer", fontSize: "14px", color: "green" }} onClick={() => addAcountFil(item, i)}><i class="fas fa-plus"></i></span>}
                      <span style={{ cursor: "pointer", fontSize: "14px", color: "red" }} className='ms-3' onClick={() => deleteAcountFil(item, i)}><i class="fas fa-trash-alt"></i></span>

                    </div>} />
                  </Form.Item>
                  <Form.Item
                    name={`d-code_account-${i}`}
                    style={{ width: '20%' }} className='m-0'
                    initialValue={item.code_account}

                  >
                    <Input placeholder="Mã " readOnly />
                  </Form.Item>
                  <Form.Item
                    name={`d-accounts-${i}`}
                    style={{ width: '30%' }} className='m-0'
                    initialValue={item.accounts}

                  >
                      <Input placeholder="lọc TK " />
                    {/* <TreeSelect
                      node="multiple"
                      multiple
                      showSearch
                      style={{ width: '100%' }}
                      treeData={listAccount}
                      dropdownStyle={{ maxHeight: 400, minWidth: "250px", overflow: 'auto' }}
                      placeholder="tài khoản"
                      filterTreeNode={filterTreeNode}
                      treeDefaultExpandAll
                      onChange={setListAccountChose}

                    /> */}

                  </Form.Item>
                  <Form.Item
                    name={`d-default_account-${i}`}
                    style={{ width: '20%' }} className='m-0'
                  >
                      <Input placeholder="TK ngầm định " />

                    {/* <TreeSelect
                      showSearch
                      style={{ width: '100%' }}

                      treeData={listAccount}

                      dropdownStyle={{ maxHeight: 400, minWidth: "250px", overflow: 'auto' }}
                      placeholder="TK ngầm định"
                      filterTreeNode={filterTreeNode}
                      treeDefaultExpandAll

                    /> */}

                  </Form.Item>
                </Input.Group>
              </Form.Item>
            })}
          </div>

          <Form.Item >
            <div className="flex_">
              <Button type="default" style={{ marginRight: "10px" }} danger onClick={() => { }}>Hủy</Button>

              <Button type="primary" loading={isloading} style={{ marginRight: "10px" }} htmlType="submit"  >Cập nhật</Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export default Index
