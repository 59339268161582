import React, { useEffect, useRef, useState } from 'react';

import * as LINK from "../../../../constants/Links";
import './style.css';
import { Divider, Radio, Table, Space, List, Skeleton, Drawer, Button, Input, Pagination, Modal, message, Switch as SwitchA, Form, Card, TreeSelect, Select, Tag, Avatar, Dropdown, Menu, Popover, DatePicker, Image, Checkbox } from 'antd';
import {
    SearchOutlined,
    CaretDownOutlined, FileImageOutlined, PlusOutlined,
    MinusOutlined
} from '@ant-design/icons';

import { useDispatch, useSelector } from 'react-redux';
import { META } from "../../../../utils/constant";
import * as ActionsWeb from "../../../../actions/indexWeb";
import Upload from "./Upload";
import { DATA_PERCENT_VAT } from '../../../../constants/DefaultData';
const { TextArea } = Input;
const Index = ({ hiddenKey }) => {
    const dispatch = useDispatch()
    const { Web } = useSelector(state => state);
    const [meta, setMeta] = useState({ ...META });
    const [imgesInfo, setimgesInfo] = useState({});
    const [imgesInfoRemove, setimgesInfoRemove] = useState([]);
    const [isloading, setIsloading] = useState(false);
    const [isupload, setisupload] = useState(0);
    const [values, setValues] = useState(null);
    const [record, setRecord] = useState({ deleted: 0, type: 2, active: 1 });
    const [listBanks, setListBanks] = useState([]);

    const [typeResUpload, setypeResUpload] = useState(null);

    const formRef = useRef(null)
    const [listInvoiceTem, setListInvoiceTem] = useState([]);
    const [listUser, setlistUser] = useState([]);

    const onFinishForm = (values) => {

        const record_ = { ...record, code: "DEFAULT_ACCOUNTANT", title: "Cài đặt hoạch toán mặc định", type: 2, active: 1, deleted: 0, data: JSON.stringify(values) }

        setIsloading(true)
        dispatch(ActionsWeb.StoreSettingsRequest(record_, record_.id ? record_.id : ""));

    }
    useEffect(() => {
        getUser();
        getListBankTemplate();
        getListInvoiceTemplate();
        dispatch(ActionsWeb.SettingsShowByCodeRequest({ code: "DEFAULT_ACCOUNTANT" }))
        return () => {

        };
    }, []);
    const getListInvoiceTemplate = async () => {
        const response = await ActionsWeb.ListAccountantInvoiceTemplateRequest({
            limit: meta.per_page * 100,
            page: meta.current_page, column: "code", sort: "ASC"
        });
        if (response.code == 200) {
            if (response.data) {

                if (response.data.data) {

                    setListInvoiceTem(response.data.data);
                }
            }
        }


    }
    const getUser = async () => {


        const response = await ActionsWeb.ListEmployeesRequest({
            limit: meta.per_page * 100,
            page: meta.current_page, column: "name", sort: "ASC"
        });
        console.log(response);
        if (response.data) {

            if (response.data.data) {

                setlistUser(response.data.data);
            }
        }
    }
    const getListBankTemplate = async () => {
        const response = await ActionsWeb.ListAccountantBankRequest({
            limit: meta.per_page * 100,
            page: meta.current_page, column: "code", sort: "ASC"
        });
        if (response.code == 200) {
            if (response.data) {

                if (response.data.data) {

                    setListBanks(response.data.data);
                }
            }
        }


    }
    useEffect(() => {
        if (Web.StoreSettings?.code) {

            try {
                if (Web.StoreSettings.code === 200) {
                    if (Web.StoreSettings.message && typeof Web.StoreSettings.message === "string") {
                        message.success(Web.StoreSettings.message)
                    } else {

                        message.error("Có lỗi!")
                    }


                }
                else {
                    if (Web.StoreSettings.message && typeof Web.StoreSettings.message === "string") {

                        message.error(Web.StoreSettings.message)
                    } else {
                        for (let x in Web.StoreSettings.message) {
                            let mess = Web.StoreSettings.message[x];
                            for (let y in mess) {
                                if (mess[y]) {
                                    message.error(mess[y])
                                }

                            }
                        }

                    }

                }

            } catch (error) {
                message.error("Có lỗi!")

            }
            setIsloading(false)
            dispatch(ActionsWeb.ResetStoreSettings());
        }


        return () => {

        };
    }, [Web.StoreSettings]);


    useEffect(() => {

        if (Web.SettingsShowByCode.data) {
            if (Web.SettingsShowByCode.data.code === "DEFAULT_ACCOUNTANT") {
                setRecord(Web.SettingsShowByCode.data)
                if (Web.SettingsShowByCode.data.data) {
                    const formdata = JSON.parse(Web.SettingsShowByCode.data.data)
                    console.log(formdata);

                    formRef.current.setFieldsValue(formdata)

                }

            }
        }
        return () => {

        };
    }, [Web.SettingsShowByCode]);
    useEffect(() => {
        if (isupload === typeResUpload) {
            onFinishForm(values);
        }
        return () => {

        };
    }, [typeResUpload]);
    useEffect(() => {

        if (Web.UploadFileLa) {

            let type = Web.UploadFileLa.type;

            if (Web.UploadFileLa.code === 200) {
                setRecord({ ...record, [type]: Web.UploadFileLa.data })
                setypeResUpload(type)

            } else {
                message.warn("Upload file lỗi!")
            }


            dispatch(ActionsWeb.ResetUploadFileLa);

        }
        return () => {

        };
    }, [Web.UploadFileLa]);

    const changeUser = (e) => {

        const user = listUser.filter((value, index) => {
            return value.code == e;
        })
        if (user[0]) {
            if (formRef.current) {
                formRef.current.setFieldsValue({ employee_name: user[0].name, employee_position: user[0].position_name })
            }
        }
    }
    const changeBanks = (e, type) => {

        const bank = listBanks.filter((value, index) => {
            return value.id == e;
        })
        if (bank[0]) {
            if (formRef.current) {
                if (type == 1) {
                    formRef.current.setFieldsValue({ bank_name: bank[0].name })

                } else {
                    formRef.current.setFieldsValue({ bank_name_receiving: bank[0].name })

                }
            }
        }
    }
    return (
        <div className='dropdown_rim'>
            <Form name="form" className="form" ref={formRef} onFinish={onFinishForm}
                layout={"horizontal"}
                style={{
                    padding: "0px !important",
                    width: "100%",
                    display: "flex",
                    margin: 0
                }}>
                <div className='row'>

                    <div className={`col-lg-12`}>
                        <Form.Item name="invoice_template" label="Mẫu hóa đơn" layout={"horizontal"}>
                            <Select
                                showSearch
                                style={{ width: '100%' }}
                                options={(listInvoiceTem || []).map((value, index) => {
                                    return {
                                        value: value.id,
                                        label: value.code + "-" + value.name
                                    }
                                })}

                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                placeholder="Mẫu hóa đơn"
                                // allowClear
                                // multiple
                                treeDefaultExpandAll


                            />
                        </Form.Item>
                    </div>
                    <div className={`col-lg-12 `}>
                        <Form.Item label="Nhân viên" >
                            <Input.Group compact>
                                <Form.Item name={"employee_id"}

                                    rules={[
                                        {
                                            required: true,
                                            message: "Nhân viên là bắt buộc"
                                        },
                                    ]}
                                    style={{ width: "30%" }} className='m-0'
                                >
                                    <Select
                                        showSearch
                                        style={{ width: '100%' }}
                                        options={(listUser || []).map((value, index) => {
                                            return {
                                                value: value.code,
                                                label: value.code
                                            }
                                        })}

                                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                        placeholder="Nhân viên"
                                        // allowClear
                                        // multiple
                                        treeDefaultExpandAll
                                        onChange={changeUser}

                                    />

                                </Form.Item>
                                <Form.Item name="employee_name" style={{ width: "50%" }} className='m-0'>
                                    <Input />
                                </Form.Item>
                                <Form.Item name="employee_position" style={{ width: "20%" }} className='m-0'>
                                    <Input />
                                </Form.Item>
                            </Input.Group>
                        </Form.Item>

                    </div>


                    <div className={`col-lg-12 `}>
                        <Form.Item label="Nộp vào TK" className={`m-0`}>
                            <Input.Group compact>
                                <Form.Item name={"bank_id"}
                                    // rules={[
                                    //   {
                                    //     required: true,
                                    //     message: "tài khoản chi là bắt buộc"
                                    //   },
                                    // ]}
                                    style={{ width: "40%" }} className='m-0'
                                >
                                    <Select
                                        showSearch
                                        style={{ width: '100%' }}
                                        options={(listBanks || []).map((value, index) => {
                                            return {
                                                value: value.id,
                                                label: value.bank_number
                                            }
                                        })}

                                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                        placeholder="nộp vào tài khoản "
                                        // allowClear
                                        // multiple
                                        treeDefaultExpandAll
                                        onChange={(e) => changeBanks(e, 1)}

                                    />

                                </Form.Item>
                                <Form.Item name="bank_name" style={{ width: "60%" }} className='m-0'>

                                    <Input />
                                </Form.Item>

                            </Input.Group>
                        </Form.Item>

                    </div>
                    <div className={`col-lg-12`}>
                        <Form.Item label="Thuế suất mặc định" name={"percent_vat"}
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: "Thuế GTGT là bắt buộc"
                            //     },
                            // ]}
                            style={{ width: "100%" }}
                          
                        >
                            <Select
                                showSearch
                                style={{ width: '100%' }}
                                options={DATA_PERCENT_VAT}

                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                placeholder="Thuế GTGT"
                                // allowClear
                                // multiple
                                treeDefaultExpandAll
                               

                            />

                        </Form.Item>
                    </div>
                    <div className={`col-lg-12`}>
                        <Form.Item>

                            <Button type="primary" loading={isloading} style={{ marginRight: "10px" }} htmlType="submit">Cập nhật</Button>
                        </Form.Item>
                    </div>


                </div>
            </Form>
        </div>
    );
};

export default Index;
