
import React, { useRef, useEffect, useState } from 'react'

import "./style.css";
import { useDispatch, useSelector } from 'react-redux';
import * as ActionsWeb from "./../../actions/indexWeb";
import * as LinkApi from "./../../constants/LinkApi";
import * as LINK from "./../../constants/Links";
import {
  Form,
  Input,
  Button,
  InputNumber, Select,
  Space, message, Cascader,
  Divider, Image,
  Typography, Modal, Radio, Empty, TreeSelect, Card, Checkbox, Drawer
} from 'antd';
import LoadingSmile from "./../../components/loadingSmile/loadingSmile";
import {

  PlusOutlined,

} from '@ant-design/icons';
import { META } from '../../utils/constant';
import Editor from "./../editor/index";
import TableClassify from "./../../pages/web/Products/edit/RecursiveDisplay";
import { fallbackImg } from '../../constants/DefaultData';
import Classifys from "./Classifys.js";
import { v4 as uuidv4 } from 'uuid';
import { VideoViewer } from '../VideoViewer/VideoViewer';
import { UploadFile as UploadFileA } from "./../../utils/apiCaller";


const Index = ({ imgesInfo, checkSync, ListStore, dataClassifyStoreIds, record, ListCategory, setEditTitok, ListClassifys, keyNew }) => {
  const dispatch = useDispatch()
  const { Web } = useSelector(state => state);
  const [meta, setMeta] = useState({ ...META });
  const [codeTitok, setCodeTitok] = useState(null);
  const [cateTitok, setCateTitok] = useState(null);
  const [TitokCategories, setTitokCategories] = useState([]);
  const [TitokBrands, setTitokBrands] = useState([]);
  const [TitokAttributes, setTitokAttributes] = useState([]);
  const [NameNewAttribute, setNameNewAttribute] = useState({});
  const [isNotReload, setIsNotReload] = useState(false);
  const [newBrand, setNewBrand] = useState("");
  const [NewImageInfo, setNewImageInfo] = useState(imgesInfo);
  const [imgesInfoRemove, setimgesInfoRemove_] = useState([]);
  const [folder, setfolder] = useState("products");
  const [video, setVideo] = useState("");
  const [tikTokCodes, setTikTokCodes] = useState([]);
  const editor__ = useRef();
  const fromreff = useRef();
  const [isLoading, setIsLoading] = useState([]);
  const [ListAttributeByProducts, setListAttributeByProducts] = useState([]);
  const [ListCategoriesByProducts, setListCategoriesByProducts] = useState([]);
  const [Titokedit, setTitokedit] = useState(null);
  const [record_, setrecord_] = useState(record);
  const refinputs = useRef([]);
  const refAttributeSave = useRef([]);
  const [classifyx, setClassifyx] = useState([
    { groups: { groupname: "Tên biến thể", name: "Tên biến thể" }, data: [], items: [{ key: uuidv4(), name: "Biến thể", price: 0, amount: 0, store_id: [], code: "", discount_percent: 0, discount: 0, discount_price: 0 }] }
  ]);
  const [dataClassifyx, setDataClassifyx] = useState(null);

  useEffect(() => {
    fromreff.current.resetFields();

    console.log("setEditTitoksetEditTitok ",setEditTitok);
    
    if (setEditTitok?.pro?.id) {
      setCodeTitok(setEditTitok?.pro?.id_);
      // setTitokedit(setEditTitok);
      dispatch(ActionsWeb.ListAttributeByProductsRequest({ data: { "group_products.product_id": setEditTitok?.pro?.id } }));
      editor__.current.value = setEditTitok?.pro?.content_tiktok;
    }
    return () => {
      dispatch(ActionsWeb.resetListAttributeByProductsRequest());
      setListAttributeByProducts([]);
      setListCategoriesByProducts([]);
      setTitokAttributes([]);
      setClassifyx([
        { groups: { groupname: "Tên biến thể", name: "Tên biến thể" }, data: [], items: [{ key: uuidv4(), name: "Biến thể", price: 0, amount: 0, store_id: [], code: "", discount_percent: 0, discount: 0, discount_price: 0 }] }
      ]);
      // editor__.current.value="";
      setrecord_(null)
    };
  }, [setEditTitok]);
  useEffect(() => {
    getCategories();
    return () => {
      
    };
  }, []);
  const getCategories = async()=>{

    console.log("getCategoriesgetCategoriesgetCategories ");
    const response = await ActionsWeb.SearchCategoryRequestNotDispatch({
      limit: meta.per_page * 10,
      page: meta.current_page, column: meta.column, sort: meta.sort,
    }, { data: { "isparent": true, columntype: "lazada_code" } })
    console.log("responseresponseresponse ",response);
    setIsLoading([...isLoading, "TitokCategories"]);
    
    if (response.code == 200) {
      if (response.data) {
        setTitokCategories(response.data.data);
      }
    }
  }

  useEffect(() => {
    fromreff.current.setFieldsValue({ cateEcom: ListCategoriesByProducts });
    return () => {
      
    };
  }, [TitokCategories]);
  useEffect(() => {
    if (Web.ListAttributeByProducts) {
      console.log("Web.ListAttributeByProducts ",Web.ListAttributeByProducts );
      if (Web.ListAttributeByProducts?.[0]?.attribute_products_id) {

        setListAttributeByProducts(Web.ListAttributeByProducts?.[0]?.attribute_products_id);
      }
      if (Web.ListAttributeByProducts?.[0]?.category_arr_code) {
        const cate = [];
        for (let x in Web.ListAttributeByProducts?.[0]?.category_arr_code) {
          cate.push(Web.ListAttributeByProducts?.[0]?.category_arr_code?.[x].id);
        }

        setListCategoriesByProducts(cate);
        fromreff.current.setFieldsValue({ cateEcom: cate });
      }
      // console.log("Web.ListAttributeByProductsWeb.ListAttributeByProducts",Web.ListAttributeByProducts);
      if (Web.ListAttributeByProducts?.[0]?.classifyxOr) {
        const clss = Web.ListAttributeByProducts?.[0]?.classifyxOr;
        clss.map((item, index) => {
          ListClassifys.map((el, ind) => {
            if (parseInt(item.id) === parseInt(el.id)) {
              clss[index].data = el.children
            }
          })


          setClassifyx(clss);

        });

        if (Web.ListAttributeByProducts?.[0]?.classifys) {

          (async () => {
            const imageClassify = await collectImagesClassify(Web.ListAttributeByProducts?.[0]?.classifys);
            const newimgesInfo_ = { ...NewImageInfo, ...imageClassify }
            setNewImageInfo(newimgesInfo_);
          })();
          setDataClassifyx(Web.ListAttributeByProducts?.[0]?.classifys);
        }
      }
      //
    }
    return () => {

    };
  }, [Web.ListAttributeByProducts]);

  const collectImagesClassify = async (array) => {
    const images = {};

    async function recurse(items, parentKeys = '') {
      for (const item of items) {
        const currentKey = parentKeys + item.key;
        if (item.image) {
          // Giả sử bạn cần tải thông tin ảnh từ server
          const imageName = item.image.split("/")[1];
          images["classify" + currentKey] = [{
            uid: -1 * item.key,
            name: imageName,
            status: 'done',
            url: `${LINK.myHostimgServerImg}/${item.image}`,
          }];
          // Nếu có thao tác bất đồng bộ khác, bạn có thể sử dụng await ở đây
          // Ví dụ:
          // const response = await fetch(someUrl);
          // const data = await response.json();
        }
        if (item.children && item.children.length > 0) {
          await recurse(item.children, currentKey);
        }
      }
    }

    await recurse(array);
    return images;
  }

  useEffect(() => {
    if (record_) {

      fromreff.current.setFieldsValue(record_);

      if (setEditTitok && setEditTitok?.pro?.content_tiktok) {

        editor__.current.value = setEditTitok?.pro?.content_tiktok;
      } else {

        if (record_.detail) {

         
          editor__.current.value = record_.detail;
        }
      }

      if (record_.category_id && record_.category_id?.[0]) {
        if (ListCategory.length > 0) {


          const tikTokCodes = findTikTokCodes(ListCategory, record_.category_id[0]);

          setTikTokCodes(tikTokCodes);


        }

      }


      if (!setEditTitok?.pro) {
        setClassifyx(record_.classifyxOr);
        setDataClassifyx(record_.classifys);
      }
    }



    return () => {
      // editor__.current.value="";
      setrecord_(null)
    };
  }, [record_, keyNew])


  useEffect(() => {

    if (record) {

      setrecord_(record);



    }

    return () => {
      // editor__.current.value="";
    };
  }, [keyNew]);

  // useEffect(() => {
  //   if (record) {


  //     const classifyxOr_set = {};
  //     for (let x in record.classifyxOr) {
  //         const item = record.classifyxOr[x]
  //         classifyxOr_set[`group_classify_${item.key}`] = parseInt(item.id);
  //         if (item.items){
  //             for (let y in item.items) {
  //                 const el = item.items[y]
  //                 classifyxOr_set[`item_classify_${el.key}`] = parseInt(el.id);
  //             }
  //         }

  //     }
  //     fromreff.current.setFieldsValue({ ...classifyxOr_set });
  //     if (record.brands_id) {
  //       editor.current.value = record.detail;
  //     }
  //   }

  //   return () => {

  //   };
  // }, [classifyx,dataClassifyx]);
  // const setCategory=()=>{

  //     fromreff.current.setFieldsValue({cateEcom:ListCategoriesByProducts});
  // }
  const findTikTokCodes = (data, targetId) => {
    let path = [];

    const findPath = (node, currentPath = []) => {
      currentPath.push(node.id);
      if (node.id === targetId) {
        path = [...currentPath];
        return true;
      }
      if (node.children) {
        for (let child of node.children) {
          if (findPath(child, currentPath)) return true;
        }
      }
      currentPath.pop();
      return false;
    };

    for (let item of data) {
      if (findPath(item)) break;
    }

    return path;
  };
  useEffect(() => {
    if (imgesInfo) {
    
    }

    return () => {

    };
  }, [imgesInfo]);
  useEffect(() => {
    if (!setEditTitok?.pro?.id_) {


      setCodeTitok(checkSync[0].id_);
    }
   
    return () => {

    };
  }, [checkSync]);
  useEffect(() => {

  
    // dispatch(ActionsWeb.LazadaCategoriesRequest({ code: codeTitok }));


    // dispatch(ActionsWeb.LazadaBrandsRequest({ code: codeTitok }));
    fromreff.current.setFieldsValue({ checkSync: codeTitok });

    return () => {

    };
  }, [codeTitok]);
  const loadDataCate = async (selectedOptions) => {
    const targetOption = selectedOptions[selectedOptions.length - 1];
    targetOption.loading = true;

    const OptionsCategory = TitokCategories;
    const response = await ActionsWeb.SearchCategoryRequestNotDispatch({
      limit: meta.per_page * 10,
      page: meta.current_page, column: meta.column, sort: meta.sort,
    }, { data: { "parent_id": targetOption.id } })
  
    if (response.code == 200) {
      if (response.data) {
        if (response.data.data) {
          targetOption.children = response.data.data;
        }
      }
    }
    if (response.data && response.data.length <= 0) {
      targetOption.loading = false;
      return;
    }
    const updateOptionsCategory = (categories, targetOption) => {
      return categories.map(option => {
        if (option.id === targetOption.id) {
          return targetOption;
        }
        if (option.children) {
          return {
            ...option,
            children: updateOptionsCategory(option.children, targetOption)
          };
        }
        return option;
      });
    };

    const updatedOptions = updateOptionsCategory(OptionsCategory, targetOption);

    setTitokCategories(updatedOptions);

    targetOption.loading = false;
  };
  // useEffect(() => {

  //   if (Web.ListCategory) {
     
  //     setTitokCategories(Web.ListCategory?.data);

  //     // setCategory();
  //     // fromreff.current.setFieldsValue({ ["cateEcom"]: tikTokCodes });
  //     // tikTokCodes
  //     setIsLoading([...isLoading, "TitokCategories"]);
  //   }
  
  //   fromreff.current.setFieldsValue({ cateEcom: ListCategoriesByProducts });
  //   return () => {

  //   };
  // }, [Web.ListCategory]);

  useEffect(() => {

    if (ListCategoriesByProducts?.length > 0) {
     
      dispatch(ActionsWeb.SearchCategoryRequest({
        limit: meta.per_page * 10,
        page: meta.current_page, column: meta.column, sort: meta.sort,
      }, { data: { "isparent": true, id: ListCategoriesByProducts[0], istree: 1 } }))
    }else{
      dispatch(ActionsWeb.SearchCategoryRequest({
        limit: meta.per_page * 10,
        page: meta.current_page, column: meta.column, sort: meta.sort,
    },{data:{"isparent":true}}));
    }
  
    
    if (tikTokCodes && tikTokCodes.length > 0 && ListCategoriesByProducts.length>0) {
     
      dispatch(ActionsWeb.LazadaAtttributesRequest({ code: codeTitok, cateId: ListCategoriesByProducts[ListCategoriesByProducts.length - 1] ,"isRefresh":true}));
    }



    return () => {

    };
  }, [ ListCategoriesByProducts]);
  useEffect(() => {

    if (Web.TitokBrands && !isNotReload) {

      setIsLoading([...isLoading, "TitokBrands"]);

      setTitokBrands(Web.TitokBrands);
      setIsNotReload(true);
    }
    return () => {

    };
  }, [Web.LazadaBrands]);
  useEffect(() => {

    if (Web.createCustomBrandTitok) {


      setTimeout(() => {
        setNewBrand("");
        setIsNotReload(false);
        dispatch(ActionsWeb.LazadaBrandsRequest({ code: codeTitok, isRefresh: true }));

      }, 1000);

    }
    return () => {

    };
  }, [Web.createCustomBrandTitok]);

  const renderAttribute = (value) => {

    if (setEditTitok?.pro?.id) {

      if (ListAttributeByProducts) {

        return ListAttributeByProducts?.[value.id.toString()]?.[0];
      }
      return null;
    } else {
      return record_?.attribute_products_id?.[value.id.toString()]?.[0];
    }


  }

  const setAttributeForm = () => {
    const dataset = {};

    if (TitokAttributes) {
      TitokAttributes.map((value, index) => {
        let val = renderAttribute(value);
        if (val) {
          dataset[`attributeEcom-${index}`] = val;
        } else {
          dataset[`attributeEcom-${index}`] = "";
        }

      })
    }
  
    fromreff.current.setFieldsValue(dataset);
  }

  useEffect(() => {
    setAttributeForm();
    return () => {

    };
  }, [TitokAttributes, ListAttributeByProducts]);
  // useEffect(() => {

  //   if (Web.StoreAttributeProducts) {


  //     if (Web.StoreAttributeProducts.code === 200) {
  //       if (Web.StoreAttributeProducts.message) {
  //             message.success(Web.StoreAttributeProducts.message)
  //         }
  //         dispatch(ActionsWeb.TiktokAtttributesRequest({ code: codeTitok, cateId: tikTokCodes[tikTokCodes.length - 1] }));
  //     }
  //     else {
  //         if (Web.StoreAttributeProducts.message) {
  //             message.error(Web.StoreAttributeProducts.message)
  //         }

  //     }

  //     dispatch(ActionsWeb.ResetStoreAttributeProducts());

  //   }
  //   return () => {

  //   };
  // }, [Web.StoreAttributeProducts]);

  // useEffect(() => {
  //   if(TitokCategories && codeTitok){
  //     console.log("TitokCategoriesTitokCategoriesTitokCategories",TitokCategories);
      
  //     dispatch(ActionsWeb.LazadaAtttributesRequest({ code: codeTitok, cateId: TitokCategories[TitokCategories.length - 1], isRefresh: true }));
  //   }
  
  //   return () => {
      
  //   };
  // }, [codeTitok,TitokCategories]);
  useEffect(() => {
    console.log("Web.LazadaAtttributesWeb.LazadaAtttributes ",Web.LazadaAtttributes);
    if (Web.LazadaAtttributes) {
      setIsLoading([...isLoading, "LazadaAtttributes"]);
      setTitokAttributes(Web.LazadaAtttributes);
    }
    return () => {

    };
  }, [Web.LazadaAtttributes]);
  const onChangeCascader = (value) => {
    console.log(value);
    setCateTitok(value);
    dispatch(ActionsWeb.LazadaAtttributesRequest({ code: codeTitok, cateId: value[value.length - 1], isRefresh: true }));
  }
  const onChangeRadio = (e) => {
    const target = e.target;

    setCodeTitok(target.value)
  }

  const onNameAttribute = (e, index) => {
    console.log(e);
    const target = e.target;

    setNameNewAttribute({ ...NameNewAttribute, [index]: target.value })
  }
  const setvalueAttribute = (e, index) => {
    // console.log("eee ",e);
    refAttributeSave.current[index] = e;
  }
  const addAttribute = (e, index) => {
    e.preventDefault();

    const refinput = refinputs.current[index];
    const dataAtt = TitokAttributes[index];


    let valuesDataAtt = dataAtt.children;
    const newName = refinput.input.value;
    if (newName) {
      if (!valuesDataAtt) {
        valuesDataAtt = [];
      }
      valuesDataAtt.push({ id: "new" + index, name: newName })
    }
    dataAtt.children = valuesDataAtt;
    TitokAttributes[index] = dataAtt;

    setTitokAttributes([...TitokAttributes])
    setNameNewAttribute({ ...NameNewAttribute, [index]: "" })

    // dispatch(ActionsWeb.StoreAttributeProductsRequest({
    //     "name": NewName,
    //     "code": "",
    //     "parent_id": dataAtt.id,
    //     "value": 0,
    //     "rank": 1,
    //     "deleted": "0",
    //     "hidden": "0",
    //     "description": ""
    // }));
  };
  useEffect(() => {
    console.log(refAttributeSave.current);
    const dataSet = {};
    if (refAttributeSave.current) {
      for (var x in refAttributeSave.current) {
        const el = refAttributeSave.current[x];
        dataSet["attributeEcom-" + x] = el

      }
      fromreff.current.setFieldsValue(dataSet);
    }

    return () => {

    };
  }, [TitokAttributes]);
  const addNewBrand = (e, index) => {
    e.preventDefault();
    if (newBrand) {
      dispatch(ActionsWeb.createCustomBrandTitokRequest({ code: codeTitok, name: newBrand }));
    }


  };
  const filterItemsClassImage = (NewImageInfo_) => {
    // Lấy các key của đối tượng và lọc những key chứa 'classify'
    const filtered = Object.keys(NewImageInfo_).filter(key =>
      key.includes('classify')
    );

    // Tạo một đối tượng mới chỉ với các key đã lọc
    const filteredObject = filtered.reduce((obj, key) => {
      obj[key] = NewImageInfo_[key];
      return obj;
    }, {});

    return filteredObject;
  };

  const removePrefix = (url, prefix) => {
    if (url.startsWith(prefix)) {
      return url.substring(prefix.length);
    }
    return url;
  }

  const uploadFile_ = async (values) => {
 
    // const { imgesInfo, record, dataClassify, classify1, classify2 } = this.state;

    // this.setState({
    //     required: {},
    //     values: values
    // });

    // if (!this.CheckDataRequired()) {
    //     return;
    // }
    const NewImageInfo_ = NewImageInfo;
    // this.SetisLoading(true);
    let isupload = 0;

    const upload = async (files, folder, type, key = null) => {
      const response = await UploadFileA(files, folder, type, key);

     
      if (response.ok) {
        const res = await response.json();

        if (res.code === 200) {
          return res.data;
        }
      }
      return null;
    };

    // Upload avatar
    if (NewImageInfo.avata.length > 0) {
      const arravata = imgesInfo.avata.filter(item => item.originFileObj);
      if (arravata.length > 0) {
        const thumbnail = await upload(arravata, this.state.folder, 1);
       
        if (thumbnail) {
          NewImageInfo_.thumbnail = thumbnail;
          // this.setState({ record });
        }
      }
    }

    // Upload detail images
    if (NewImageInfo.detail.length > 0) {
      const arrdetail = imgesInfo.detail.filter(item => item.originFileObj);
      if (arrdetail.length > 0) {
        const images = await upload(arrdetail, this.state.folder, 2);
        console.log(images);
        if (images) {
          NewImageInfo_.images = [...record.images, ...images];



        }
      }
    }

    // Upload video
    if (NewImageInfo.video.length > 0) {
      const arrvideo = imgesInfo.video.filter(item => item.originFileObj);
      if (arrvideo.length > 0) {
        const video = await upload(arrvideo, "video", 3);

        if (video) {
          NewImageInfo_.video = video;

          // console.log("videovideovideovideo",record.video);

        }
      }
    }



    const imageClass = filterItemsClassImage(NewImageInfo);


    for (let x in imageClass) {
      const arrClassify = imageClass[x].filter(item => item.originFileObj);
      if (arrClassify.length > 0) {
        const classifiedImages = await upload(arrClassify, "classify", 4, x);
        console.log(classifiedImages);
        if (classifiedImages) {
          NewImageInfo_[x] = classifiedImages;
        }
      }
    }
    submitForm(values, NewImageInfo_);
    // this.setState(state =>
    //     {
    //         return {
    //             record: record,
    //             imgesInfo: imgesInfo
    //         }
    //     },()=>{
    //         this.onFinishForm(values);
    //     })

  }
  const submitForm = async (values, NewImageInfo_) => {

    const attributeChose = [];
    let ii = 0
    for (let x in values) {
      if (x.includes("attributeEcom")) {
        if (TitokAttributes[ii]?.children) {

          const atttribute = TitokAttributes[ii]?.children.filter((value, index) => {
            return value.id == values[x];
          })

          if (atttribute.length > 0) {
            atttribute[0]["parent_id"] = TitokAttributes[ii]["id"];
            atttribute[0]["parent_name"] = TitokAttributes[ii]["name"];
            attributeChose.push(atttribute[0]);
          }

        }
        ii++;
      }
    }


    // const attributeChose = [];
    // for (let x in values) {
    //   if (x.includes("attributeEcom")) {
    //     attributeChose.push(values[x]);
    //   }
    // }
    const imagesClassify = filterItemsClassImage(NewImageInfo_);


    
    let arrParent = {}

    const updateItemValue = async (data, level = 0, parent_item = null) => {

      return Promise.all(data.map(async (item, indi) => {
        let newItem = { ...item };

        if (item.children && item.children.length > 0) {
          if (parent_item) {
            arrParent = { ...arrParent, [level]: { ...parent_item, children: [] } };
          }

          let newItemc = await updateItemValue(item.children, level + 1, item);
          let newItem = { ...item, children: newItemc };
          return newItem;
        } else {
          const tmp = Object.values({ ...arrParent });
          if (parent_item) {
            tmp.push({ ...parent_item, children: [] });
          }

          tmp.push({ ...item, children: [] });
          let key_ = "";
          tmp.map((d_, ide_) => {
            key_ += d_.key
          })

          const imgcl = imagesClassify["classify" + key_];

          console.log("key_ ", key_);
          let imageTmp = "";
          if (imgcl?.[0]) {
            if (imgcl?.[0]?.url) {
              imageTmp = imgcl?.[0]?.url;
              imageTmp = removePrefix(imageTmp, `${LINK.myHostimgServerImg}/`);
            } else {
              imageTmp = imgcl?.[0];
            }
          }

          let newItem = {
            ...item,
            price: values[`price_${key_}`],
            store_id: values[`house_${key_}`],
            amount: values[`amount_price_${key_}`],
            discount: values[`discount_${key_}`],
            discount_percent: values[`discount_percent_${key_}`],
            discount_price: values[`discount_price_${key_}`],
            sku: values[`sku_${key_}`],
            image: imageTmp
          }
          newItem.store_ids = newItem.store_ids.map((elstore, istore) => {

            elstore = { ...elstore, amount: values[`amount_price_item_d_${key_}_${istore}`] }
            return elstore;
          });
          newItem.inventory = newItem.store_ids.map((elstore, istore) => {

            elstore = { ...elstore, quantity: values[`amount_price_item_d_${key_}_${istore}`] }
            return elstore;
          });
          return newItem;
        }


      }));
    }
    const newDataClassifyx = await updateItemValue(dataClassifyx, 0);


    const classifyxOr = classifyx.map((item, index) => {
      const newitem = { ...item, children: [], data: [] }
      return newitem;
    });


    const data = { ids: [codeTitok], idsproduct: [record_.id], ...values, imgesInfo: NewImageInfo_, attributeChose: attributeChose, TitokAttributes: [...TitokAttributes], setEditTitok: setEditTitok?.pro, classifys: newDataClassifyx, classifyxOr: classifyxOr, content: editor__.current.value };


    console.log("datadatadata ",data);
    
    //  return;
    dispatch(ActionsWeb.SyncProductsToLazadaOneRequest(data));
  }


  useEffect(() => {

    return () => {

    };
  }, [imgesInfoRemove]);
  const setimgesInfo = (type, data) => {
    const imgesInfo = NewImageInfo;
   
    if (type === "avata" || type === "video" || type.includes("classify")) {

      const imageDelete = imgesInfo[type];
      if (imageDelete?.[0]) {
        imageDelete[0]["folder"] = folder;

      }
    
      setimgesInfoRemove_(imageDelete ? [...imgesInfoRemove, ...imageDelete] : [...imgesInfoRemove]);

      setNewImageInfo({ ...imgesInfo, [type]: data });



      // imageDelete["folder"] = type;

    } else {
      setNewImageInfo({ ...imgesInfo, [type]: data });
    }

  }

  const setimgesInfoRemove = (type, data) => {
    const imgesInfo = NewImageInfo;
    data["folder"] = folder;
    let images = "";
    let imagesrecord = "";
    let rmdata = [...imgesInfoRemove, data];


    if (data && data.length > 0) {
      rmdata = [...data, ...rmdata];
    }

    if (type === "detail") {
      images = imgesInfo.detail.filter(item => {
        return item !== data.name;
      })
      imagesrecord = record.images.filter(item => {
        return item !== data.name;
      })

      setimgesInfoRemove_(rmdata);
      setNewImageInfo({ ...imgesInfo, detail: images });
      setrecord_({ ...record_, images: imagesrecord })

    } else {
      setimgesInfoRemove_(rmdata);
    }


  }

  return (
    <>
      {isLoading < 3 && <LoadingSmile />}
      <Form

        layout={"vertical"}
        ref={fromreff}

        onFinish={uploadFile_}
      >
        <Form.Item name={"checkSync"} initialValue={codeTitok}>
          <Radio.Group options={(checkSync || []).map((item, index) => {
            return {
              value: item.id_,
              label: item.name,
            }
          })} onChange={onChangeRadio} value={codeTitok} optionType="button"
            buttonStyle="solid" />
        </Form.Item>
        <div className='row'>
          <div className='col-9'> <div className='imgs'>
            <div class="grid-container-3-row">
              <div class="grid-item large">
                <Image
                  width={"100%"}
                  height={"100%"}
                  src={NewImageInfo?.avata[0]?.url ? NewImageInfo?.avata[0]?.url : ""}
                  fallback={fallbackImg}

                />
              </div>
              {Array.from({ length: 8 }, (_, i) => i + 1).map((diss, idis) => {
                return <div class="grid-item">

                  {NewImageInfo?.detail && NewImageInfo?.detail?.[idis] && <Image
                    width={"100%"}
                    height={"100%"}

                    src={NewImageInfo?.detail?.[idis]?.url}
                    fallback={fallbackImg}

                  />}
                  {!NewImageInfo?.detail || !NewImageInfo?.detail?.[idis] && <Image
                    width={"100%"}
                    height={"100%"}

                    src={""}
                    fallback={fallbackImg}

                  />}
                </div>
              })}


            </div>
          </div></div>
          <div className='col-3'></div>
          <div className='col-12'>
            <Form.Item label="Video">
              <VideoViewer width="150px" height="150px" src={LINK.myHostimgServerImg + "/" + record_?.video} />
            </Form.Item>
          </div>
        </div>
        <Form.Item label="Tên sản phẩm" name={"nameEcom"} initialValue={setEditTitok?.pro?.title_tiktok ? setEditTitok?.pro?.title_tiktok : record_?.title} >
          <Input placeholder="Tên sản phẩm" />

        </Form.Item>
        <Form.Item label="Danh mục" name={"cateEcom"} rules={[
          {
            required: true,
            message: "Danh mục là bắt buộc"
          },
        ]}>
          <Cascader options={TitokCategories} loadData={loadDataCate} onChange={onChangeCascader} placeholder="Chọn 1 danh mục" />

        </Form.Item>

        <Form.Item label="Thương hiệu" name={"brandEcom"} initialValue={setEditTitok?.pro?.brands_id ? parseInt(setEditTitok?.pro?.brands_id) : ""} >
          <Select

            showSearch
            placeholder="Thương hiệu"
            options={([{ id: "", name: "không" }, ...TitokBrands] || []).map((d) => ({
              value: d.id,
              label: d.name,
            }))}
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider
                  style={{
                    margin: '8px 0',
                  }}
                />
                <Space
                  style={{
                    padding: '0 8px 4px',
                  }}
                >
                  <Input
                    placeholder="Nhập mới"
                    value={newBrand}
                    onChange={(e) => { setNewBrand(e?.target?.value) }}

                  />
                  <Button type="text" icon={<PlusOutlined />} onClick={(e) => addNewBrand(e)}>
                    Thương hiệu mới
                  </Button>
                </Space>
              </>
            )}
          />

        </Form.Item>
        <Form.Item label={`Thuộc tính sản phẩm`} >
          <div style={{ display: "grid", gridTemplateColumns: "repeat(2,1fr)" }}>
            {TitokAttributes && TitokAttributes.length > 0 && TitokAttributes.map((value, index) => {


              return <Form.Item label={value.name} name={`attributeEcom-${index}`}
              //   rules={[
              //     {
              //         required: true,
              //         message: "Thuộc tính là bắt buộc"
              //     },
              // ]}
              >

                <Select

                  showSearch
                  placeholder={value.name}
                  options={(value.children || []).map((d) => ({
                    value: d.id,
                    label: d.name,
                  }))}
                  onChange={(e) => setvalueAttribute(e, index)}
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Divider
                        style={{
                          margin: '8px 0',
                        }}
                      />
                      <Space
                        style={{
                          padding: '0 8px 4px',
                        }}
                      >
                        <Input
                          placeholder="Nhập mới"
                          value={NameNewAttribute[index]}
                          // onChange={(e) => { onNameAttribute(e, index) }}
                          ref={(el) => (refinputs.current[index] = el)}
                        />
                        <Button type="text" icon={<PlusOutlined />} onClick={(e) => addAttribute(e, index)}>
                          Thuộc tính mới
                        </Button>
                      </Space>
                    </>
                  )}
                />

              </Form.Item>

            })}
          </div>
        </Form.Item>
        <Form.Item label="Mô tả chi tiết" >
          <Editor editor={editor__} linkUpload={`${LinkApi.linkWeb}upload-tiktok?code=${codeTitok}`} />

        </Form.Item>
        <Form.Item label="Phân loại" >

          <div className="table-responsive">

            <Classifys dataClassifyx={dataClassifyx} classifyx={classifyx} setClassifyx={setClassifyx} setDataClassifyx={setDataClassifyx} ListStore={ListStore} dataClassifyStoreIds={dataClassifyStoreIds}
              record={record_} ListCategory={ListCategory} setEditTitok={setEditTitok} ListClassifys={ListClassifys} formRef={fromreff} imgesInfo={NewImageInfo} setimgesInfo={setimgesInfo} setimgesInfoRemove={setimgesInfoRemove} />
            {/* <TableClassify data={dataClassifyx}
              // ColorCell={this.ColorCell} 
              ListStore={ListStore}
              //  onChangeDiscountItem={this.onChangeDiscountItem} onChangeAmountItem={this.onChangeAmountItem}

              // onChangeAmountItemSub={this.onChangeAmountItemSub}
              // onChangeStoreItem={this.onChangeStoreItem}
              dataClassifyStoreIds={dataClassifyStoreIds}
            /> */}

          </div>
        </Form.Item>

        <Form.Item label="Vận chuyển" name={"weightEcom"} initialValue={record_?.weight}>
          <Input
            addonBefore={`Kilogram (kg)`}
            style={{

            }}

          />
        </Form.Item>
        <Form.Item label="Kích thước Sản phẩm" >
          <Input.Group compact>
            <Form.Item name={"lengthEcom"} noStyle initialValue={record_?.long}>
              <Input
                addonAfter={`Cm`}
                style={{
                  width: '30%',
                }}
                placeholder='Chiều dài'
              />
            </Form.Item>
            <Form.Item name={"wideEcom"} noStyle initialValue={record_?.wide}>
              <Input
                addonAfter={`Cm`}
                style={{
                  width: '30%',
                }}
                placeholder='Chiều rộng'

              /></Form.Item>
            <Form.Item name={"heightEcom"} noStyle initialValue={record_?.height}>
              <Input
                addonAfter={`Cm`}
                style={{
                  width: '30%',
                }}
                placeholder='Chiều cao'

              /></Form.Item>
          </Input.Group>

        </Form.Item>

        <Form.Item label="Tùy chọn giao hàng" name={"options_transport_ecom"} initialValue={1}>
          <Radio.Group options={[{ value: 1, label: "Mặc định" }]} value={1} />
        </Form.Item>
        <Form.Item >
          <Button htmlType='submit' disabled={setEditTitok?.pro?.id} > Gửi</Button>
        </Form.Item>

      </Form>
    </>
  )
}
export default Index;