import React, { useEffect, useRef, useState } from 'react'
import { Button, DatePicker, Drawer, Empty, Form, Input, InputNumber, message, Popover, Select, Skeleton, Spin, Table, Tooltip, TreeSelect } from 'antd';
import "./../style.css"
import { ListAccountingAccountRequest, ListDefaultAccountRequest, ListDepartmentRequest, SearchProductsWarehouseNoDispatchRequest, StoreAccountantRequest, StoreAccountingAccountRequest, StoreIncreasseToolRequest } from '../../../../actions/indexWeb';
import { META } from '../../../../utils/constant';
import { DATA_GROUP_ACCOUNT, DATA_KEY_CODE_ACCOUNT, DATA_PRECIOUS } from '../../../../constants/DefaultData';
import {
  LoadingOutlined, SearchOutlined
} from '@ant-design/icons';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import * as LINK from "../../../../constants/Links";

import { filAcounts, formatDate, formatDate_fm, formatDateVN, formatNumformatNumber, ber, getParamsUrl, formatNumber } from '../../../../service';
import { Link, useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import SetIncrease from "./SetIncrease";
import ListPurchase from "./ListPurchase";
const { RangePicker } = DatePicker;

const Index = () => {

  const [data, setData] = useState(null);
  const [listDepartment, setListDepartment] = useState([]);
  const [record, setRecord] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleListPurchase, setVisibleListPurchase] = useState(false);
  const [meta, setMeta] = useState({ ...META });
  const [isloading, setIsloading] = useState(false);
  const [isLoadingSearch, SetisLoadingSeach] = useState(false);
  const [listTKno, setListTKno] = useState([]);
  const [listTKco, setListTKco] = useState([]);

  const [listAccount, setListAccount] = useState([]);
  const [listDefaultAccountFil, setListDefaultAccountFill] = useState(null);
  const [listDefaultAccount, setListDefaultAccount] = useState([]);
  const [purchase, setpurchase] = useState([]);

  const formRef = useRef(null);
  const tableRef = useRef(null);
  const newRowRef = useRef(null);
  const formSearchRef = useRef(null);
  const history = useHistory();
  const params = useParams();

  const { typeV } = params;
  const columns = [
    {
      title: 'Mã',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Tên',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Mô tả',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Ghi tăng',
      dataIndex: '',
      align: "center",
      width: "25%",
      render: (text, record, index) => (
        <div style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }} >
          <span className="__action_">
            {/* <button className="reset_btn btn_edit btn__" onClick={() => editRow(record)}> <EditOutlined style={{ color: Colors.colorView }} /> </button> */}
            <Button className=" " onClick={() => { setIncrease(record) }}>Ghi tăng</Button>
            {/* <button className="reset_btn btn_view btn__" onClick={() => { editRow(record) }}> <FolderViewOutlined   style={{ color: Colors.colorView }} /></button> */}

          </span>
        </div>
      ),
      key: 'group',
    },
  ];
  const defaultDate = moment().startOf('day');

  const [dataDetail1, setdataDetail1] = useState([
    { department_id: "", percent: "", description: "", amount: "", department_name: "", accounting_code: "" },
  ]);
  // console.log(dataDetail1);
  
  const addRow = () => {
    setdataDetail1([...dataDetail1, { department_id: "", percent: "", description: "", amount: "", department_name: "", accounting_code: "" },])

  }
  const deleteRow = (i) => {
    if (dataDetail1.length <= 1) {
      return;
    }
    const newrow = dataDetail1.filter((value, index) => {
      return index != i;
    })
    setdataDetail1(newrow);
  }
  const getListDefaultAccount = async () => {
    const response = await ListDefaultAccountRequest({
      limit: meta.per_page * 100,
      page: meta.current_page, column: "code", sort: "ASC"
    });
    console.log("responseresponse ", response);

    if (response.code == 200) {
      if (response.data) {

        setListDefaultAccount(response.data);

      }
    }


  }
  const getListAccount = async () => {
    const response = await ListAccountingAccountRequest({
      limit: meta.per_page * 100,
      page: meta.current_page, column: "code", sort: "ASC"
    });
    if (response.code == 200) {
      if (response.data) {
        if (response.data.data) {

          setListAccount(response.data.data);
        }
      }
    }


  }
  const filterTreeNode = (inputValue, treeNode) => {
    const { title, description, code } = treeNode;
    // console.log(treeNode.props.data);

    return (
      title.toLowerCase().includes(inputValue.toLowerCase()) || // Tìm kiếm theo title
      description.toLowerCase().includes(inputValue.toLowerCase()) || // Tìm kiếm theo description
      code.toString().includes(inputValue) // Tìm kiếm theo code
    );
  };
  useEffect(() => {
    filterAccount();

    return () => {

    };
  }, [listDefaultAccount, listAccount]);
  const filterAccount = () => {
    const fildata = listDefaultAccount.filter((value, index) => {
      return value.code == "phan-bo-ccdc";
    })
    setListDefaultAccountFill(fildata[0]);

    return;
  }
  useEffect(() => {


    processListAccount();
    return () => {

    };
  }, [listDefaultAccountFil]);
  const processListAccount = async () => {
    if (listDefaultAccountFil) {
      const listTKno_ = await filAcounts(listDefaultAccountFil, "tkno", listAccount);
      const listTKco_ = await filAcounts(listDefaultAccountFil, "tkco", listAccount);


      setListTKno(listTKno_);
      setListTKco(listTKco_);
      // setListTKvat(listTKvat_);




    }
  }

  const handleKeyDown = (e, isLastCell = false) => {


    // console.log(e);
    if (isLastCell && (e.key === 'Tab' || e.key === 'Enter')) {
      e.preventDefault();
      addRow();
    }

    if (!isLastCell && (e.key === 'Enter')) {
      e.preventDefault();
      addRow();

    }
  };

  const setIncrease = (record) => {
    setVisible(true)
    setRecord({ ...record })

    if (record.increase_set_allocation && record.increase_set_allocation.length > 0) {
      setdataDetail1([...record.increase_set_allocation])

    } else {
      setdataDetail1([
        { department_id: "", percent: "", description: "", amount: "", department_name: "", accounting_code: "" },
      ])
    }




  }
  useEffect(() => {


    setTimeout(() => {

      console.log("recordrecordrecordrecordrecord",record);
      if (formRef.current) {
        formRef.current.resetFields();
        record.date= moment(record.date);
        record.amount=record.increase_tool_amount
        formRef.current.setFieldsValue({ ...record, products_warehouse_id: record.code, products_warehouse_name: record.title,
          price:formatNumber(record.price), total_price:formatNumber(record.total_price), price_of_period:formatNumber(record.price_of_period), total_price_allocated:formatNumber(record.total_price_allocated),
         });
        
        if (record.increase_set_allocation && record.increase_set_allocation.length > 0) {
        
          record.increase_set_allocation.map((value, index) => {
            for (let x in value) {
              console.log(x, value[x]);

              formRef.current.setFieldsValue({
                [`d-${x}-${index}`]: value[x]
              });

            }
          })



        } else {
          dataDetail1.map((value, index) => {
            for (let x in value) {
            

              formRef.current.setFieldsValue({
                [`d-${x}-${index}`]: value[x]
              });

            }
          })
        }

      }
    }, 1000);
    return () => {

    };
  }, [visible, record]);
  const getListDepartment = async () => {
    const response = await ListDepartmentRequest({
      limit: meta.per_page * 100,
      page: meta.current_page, column: "code", sort: "ASC"
    });
    if (response.code == 200) {
      if (response.data) {
        // console.log(response.data.data);
        if (response.data.data) {

          setListDepartment(response.data.data);
        }
      }
    }


  }
  const getListGroupAccountant = async () => {

    const urlParams = getParamsUrl();
    const filteredvalues = Object.fromEntries(
      Object.entries(urlParams).filter(([_, value]) => { return value !== undefined && value !== "" })
    );


    const searchParams = new URLSearchParams(filteredvalues).toString();
    history.push(`${LINK.WEB_ACCOUNTANT_INCREASE_TOOL}?${searchParams}`);

    const response = await SearchProductsWarehouseNoDispatchRequest({
      limit: meta.per_page * 100,
      page: meta.current_page, column: "name", sort: "ASC", is_asset:1
    }, { data: { type: "CCDC", ...filteredvalues } });


    if (response.code == 200) {

      // console.log("responseresponseresponse ", response.data.data);

      if (response.data) {
        if (response.data.data) {
          setData([...response.data.data]);

        }
      }

    }
    // if (filteredvalues.month) {
    //   filteredvalues.month = moment.utc(filteredvalues.month, "YYYY-MM");
    // }

    // if (quarterMoment) {
    //   filteredvalues.precious = quarterMoment;
    // }
    if (formSearchRef.current) {
      formSearchRef.current.setFieldsValue(filteredvalues)
    }


  }
  useEffect(() => {
    getListAccount();
    getListGroupAccountant();
    getListDepartment();
    getListDefaultAccount();

    return () => {

    };
  }, []);
  useEffect(() => {
    if(visible==false){
      // setRecord(null)
      setpurchase(null)
    }

    return () => {

    };
  }, [visible]);

  const onSearch = async (values) => {
    const filteredvalues = Object.fromEntries(
      Object.entries(values).filter(([_, value]) => { return value !== undefined && value !== "" && value !== null })
    );




    SetisLoadingSeach(true);
    const response = await SearchProductsWarehouseNoDispatchRequest({
      limit: meta.per_page * 100,
      page: meta.current_page, column: "name", sort: "ASC",is_asset:1
    }, { data: { type: "CCDC", ...filteredvalues } });

    SetisLoadingSeach(false);

    if (response.code == 200) {

      console.log("responseresponseresponse ", response);

      if (response.data) {
        if (response.data.data) {
          setData([...response.data.data]);

        }
      }

    }

    const searchParams = new URLSearchParams(filteredvalues).toString();

    history.push(`${LINK.WEB_ACCOUNTANT_INCREASE_TOOL}?${searchParams}`);


  }

  const resetFormSearch = () => {
    if (formSearchRef.current) {
      formSearchRef.current.resetFields();
      formSearchRef.current.submit();
    }
  }
  
  const setpurchase_=(record)=>{
    setpurchase(record);
    setVisibleListPurchase(false)

    if (formRef.current) {
      console.log("recordrecordrecord ",record);
      
      formRef.current.setFieldsValue({purchase_id:record.code});
      

      }
  }
  return (
    <div className='row w-100 bg-white p-2'
    // onClick={()=>setIsEdit(-1)}

    >
        <Drawer
        title={<div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6"><span>Danh sách chứng từ</span></div>
          <div className="col-lg-6 col-md-6 col-sm-6 flex_" style={{ justifyContent: "flex-end" }}></div>
        </div>} placement="right"
        onClose={() => {


          setVisibleListPurchase(false);

        }}
        visible={visibleListPurchase}
        size={"large"}

      >
        <ListPurchase setpurchase={setpurchase_} record={record}/>
      </Drawer>
      <Drawer
        title={<div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6"><span>Ghi tăng</span></div>
          <div className="col-lg-6 col-md-6 col-sm-6 flex_" style={{ justifyContent: "flex-end" }}></div>
        </div>} placement="right"
        onClose={() => {


          setVisible(false);

        }}
        visible={visible}
        size={"large"}

      >
       <SetIncrease getListGroupAccountant={getListGroupAccountant} record={record} dataDetail1={dataDetail1} addRow={addRow} deleteRow={deleteRow} formRef={formRef} setVisibleListPurchase={setVisibleListPurchase} purchase={purchase}/>
      </Drawer>
      <div className='col-lg-12 col-md-12 col-sm-12 d-flex'>
        <Link to={LINK.WEB_ACCOUNTANT}><Button><i className="fas fa-chevron-left"></i></Button></Link>
        <h4> Công cụ dụng cụ</h4>
      </div>
      <div className='col-lg-12 col-md-12 col-sm-12'>
        <Form name="formsearch" ref={formSearchRef} onFinish={onSearch} style={{
          padding: "0px",
          width: "100%",
          display: "flex"
        }}>
          <Form.Item name="code" noStyle >
            <Input placeholder='Mã' />
          </Form.Item>

          <Form.Item name="title" noStyle >
            <Input placeholder='Tên' />
          </Form.Item>

          <Form.Item noStyle style={{
            width: "20%"
          }}>
            <Button type="primary" htmlType="submit" style={{ width: "20%" }} icon={<SearchOutlined />} loading={isLoadingSearch} />
            <Button className='bg-warning' htmlType="button" style={{ width: "20%" }} loading={isLoadingSearch} onClick={resetFormSearch}>reset</Button>
          </Form.Item>

        </Form>
      </div>
      <div className='col-lg-12 col-md-12 col-sm-12'>
        <div className="table-responsive" style={{ height: "700px" }}>
          <Table dataSource={data} columns={columns} />
        </div>
      </div>



    </div>
  )
}

export default Index

