import React, { useEffect, useRef, useState } from 'react'
import { Button, Form, Input, message, Select, Table, TreeSelect } from 'antd';
import * as LINK from "../../../constants/Links";

import "./style.css"
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import SettingAdmin from "./../settings/list/settingAdmin";
const Index = () => {

  return (
    <div className='w-100 row   bg-white p-2  position-relative'>
       <div className='col-lg-12 col-md-12 col-sm-12  d-flex'>
        <Link to={LINK.WEB_ACCOUNTANT}><Button><i className="fas fa-chevron-left"></i></Button></Link>
        <h4> Thông tin doanh nghiệp</h4>
      </div>
      <div className='col-lg-12 col-md-612 col-sm-12 '>
        <SettingAdmin hiddenKey={["title","description",]}/>
      </div>
    
    </div>
  )
}

export default Index
