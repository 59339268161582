import React, { Component, createRef } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import { Switch, Route, Link, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
// import * as Actions from "./../../actions/index";

import * as ActionsLogin from "./../../actions/login";
import * as ActionsWeb from "./../../actions/indexWeb";
import * as ActionsApp from "./../../actions/indexApp";

import { Icons } from "./../../constants/ListIcons";
import "./style.css";
import * as LINK from "./../../constants/Links";
import { getCookie, setCookie } from "./../../service";
import * as Colors from "./../../constants/Colors";
import io from 'socket.io-client';
import OverlayLoading from "./../../components/overlayLoading/index";

import Loading from "./../../components/loading/Loading";
import { v4 as uuidv4 } from 'uuid';
import * as TypePrint from './../../constants/TypePrint';
import {
  CaretLeftOutlined, MenuUnfoldOutlined, MenuFoldOutlined,
  ShopOutlined,
  UserOutlined,
  PieChartOutlined,
  DesktopOutlined, LogoutOutlined,
  MailOutlined,
  AppstoreAddOutlined, SmileOutlined,
  LoadingOutlined, RetweetOutlined
} from '@ant-design/icons';
import { Button, Menu, Spin } from 'antd';
import { META } from "../../utils/constant";
import { Runfirst } from "../../constants/Runfirst";
import { saveAs } from 'file-saver';
import { notification } from 'antd';
import Notifications from "./../../components/Notifications/index";
import * as  CaseNotification from "../../constants/CaseNotification";
import LoadingSmile from "../../components/loadingSmile/loadingSmile";
import { Header } from "../../components/Header/Header";
import { Sidebar } from "../../components/Sidebar/Sidebar";

class Web extends Component {

  constructor(props) {
    super(props);

    this.state = {
      ListCategory: "",
      ListClassifys: "",
      ListInfoTransport: "",
      ListNumberOfPage: "",
      ListStore: "",
      ListTags: "",
      ListTradeMark: "",
      ListTypeProducts: "",
      // "ListWarehouse:"",
      ListWarrantyType: "",
      ListOrganizationProduction: "",
      ListOrganizationProductionAddress: "",
      ListPaperTexture: "",
      ListInfoPackage: "",
      InfoPackageActive: "",
      productsDatafunc_: {},
      meta: {
        ...META,
        per_page: META.per_page * 100
      },
      productsData_: "",
      ListMenus: [],
      itemMenus: [],
      ListSettings: [],

      login: "",
      Logout: "",
      user: {},
      isloading: false,
      isNotification: {},
      active: false,
      isloader: false,



    }

  }
  SetisLoading = (type) => {
    this.setState(state => {
      return {
        isloading: type
      }
    })
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (Runfirst) {
      for (var x in Runfirst) {
        if (nextProps.Web[x] && nextProps.Web[x] !== prevState[x]) {
          let productsData = localStorage.getItem("productsData");

          if (productsData) {
            productsData = JSON.parse(productsData);
            if (!productsData[x]?.data) {
              productsData[x] = nextProps.Web[x];
              localStorage.setItem("productsData", JSON.stringify(productsData));
            }

          } else {
            localStorage.setItem("productsData", JSON.stringify({ [x]: nextProps.Web[x] }));
          }


        }

      }

    }
    if (nextProps.App && nextProps.App.active !== prevState.active) {

      return {
        active: nextProps.App.active,
        isloader: nextProps.App.isloader,
        isNotification: nextProps.App.isNotification,
      }

    }

    if (nextProps.App && nextProps.App.isloader !== prevState.isloader) {

      return {
        isNotification: nextProps.App.isNotification,
        isloader: nextProps.App.isloader
      }

    }


    if (nextProps.App && nextProps.App.isNotification.show !== prevState.isNotification.show) {

      return {
        isNotification: nextProps.App.isNotification,

      }

    }

    if (nextProps.Web.Logout && nextProps.Web.Logout !== prevState.Logout) {
      return {
        Logout: nextProps.Web.Logout
      }

    }


    if (nextProps.Web.SettingsShowByCode && nextProps.Web.SettingsShowByCode?.code !== prevState.SettingsShowByCode?.code) {

      if (nextProps.Web.SettingsShowByCode?.data?.code === "ADMIN") {
        const datasetting = JSON.parse(nextProps.Web.SettingsShowByCode?.data?.data);


        localStorage.setItem("settings", JSON.stringify(datasetting));
        return {
          SettingsShowByCode: nextProps.Web.SettingsShowByCode,
          set_: datasetting,
          settings: datasetting

        };
      }


    }

    return null;

  }


  componentDidUpdate(prevProps, prevState) {

    if (this.state.isNotification !== prevState.isNotification) {

      const { isNotification } = this.state

      if (isNotification.show) {
        this.openNotificationFinishSync(isNotification.title, isNotification.text);
        this.props.setNotification({ show: false, title: "", text: "" });

      }
    }



  }
  componentDidMount = () => {





    let productsData = localStorage.getItem("productsData");

    if (productsData) {
      productsData = JSON.parse(productsData)
      if (Runfirst) {
        for (var x in Runfirst) {
          
          if (!productsData[x] || !productsData[x].data) {
            const filterDataCate = {
              data: {
                name: "",
                slug: "",
                ischild: "",
                isparent: "",
                type: "",
                position: "",
                created_at: "",
                istree: "1"
              }
            }
            let dataF = [];
            if (x === "ListCategory") {
              dataF = filterDataCate;
            }
           
            Runfirst[x](this.props, this.state, filterDataCate)
          }else{
            console.log("xxxxxxxxxxxxxxxxxxxx ",x,productsData[x].data);
          }

        }
      }
    } else {
      if (Runfirst) {
        for (var x in Runfirst) {
          console.log("xxxxxxxxxxxxxxxxxxxx ",x);
          
          const filterDataCate = {
            data: {
              name: "",
              slug: "",
              ischild: "",
              isparent: "",
              type: "",
              position: "",
              created_at: "",
              istree: "1"
            }
          }
          let dataF = [];
          if (x === "ListCategory") {
            dataF = filterDataCate;
          }
          Runfirst[x](this.props, this.state, filterDataCate)
        }
      }
    }

  }

  toggleCollapsed = () => {
    this.setState(state => {
      return {
        collapsed: !state.collapsed
      }
    })
  }




  openNotificationFinishSync = (title, text) => {
    notification.open({
      message: title,
      description: text,
      icon: (
        <SmileOutlined
          style={{
            color: '#108ee9',
          }}
        />
      ),
    });
  };



  render() {

    let { routers } = this.props;

    return (
      <div className=" web_page" style={{ backgroundColor: Colors.pop2[0] }}>

        {this.state.isloader && <div className="ovelay">
          <LoadingSmile height={"100%"} width={"100%"} />
        </div>}





        <Header />
        {/* <div className="max-height-80"> </div>
        <div className="max-height-10"></div> */}
        <div className={`home main-grid  ${this.state.active ? " hide" : ""}`} >

          <div className={`left-main`} style={{}}>
            <Sidebar />

          </div>
          <div className={`right-main`}

          >
            <Switch>
              {routers &&
                routers.length > 0 &&
                routers.map((route, index) => {

                  return <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}

                    render={props => (
                      route.main({ ...props, routers: route.routers, test: "okokk" })
                    )}
                  />
                })
              }
            </Switch>
          </div>
        </div>


      </div>


    );
  }

}

const mapStateToProps = (state) => {
  return {

    Web: state.Web,
    App: state.appReducer
  };
};
const mapDispatchToProps = (dispatch, Props) => {
  return {
    InfoPackageActiveRequest: (body) => {
      dispatch(ActionsWeb.InfoPackageActiveRequest(body));
    },
    ListCategoryRequest: (body) => {
      dispatch(ActionsWeb.ListCategoryRequest(body));
    },
    // ////////////////

    ListInfoTransportRequest: (body) => {
      dispatch(ActionsWeb.ListInfoTransportRequest(body));
    },

    // ////////////////

    ListTagsRequest: (body) => {
      dispatch(ActionsWeb.ListTagsRequest(body));
    },
    ////////////////////////

    ListStoreRequest: (body) => {
      dispatch(ActionsWeb.ListStoreRequest(body));
    },


    ////////////////////////
    ListSettingsRequest: (body) => {
      dispatch(ActionsWeb.ListSettingsRequest(body));
    },
    ListClassifysRequest: (body) => {
      dispatch(ActionsWeb.ListClassifysRequest(body));
    },
    /////////////////////////


    // //////////////

    ListInfoPackageRequest: (body) => {
      dispatch(ActionsWeb.ListInfoPackageRequest(body));
    },

    // ///////////////////////
    ListAttributeProductsRequest: (body) => {
      dispatch(ActionsWeb.ListAttributeProductsRequest(body));
    },
    StoreAttributeProductsRequest: (data, id) => {
      dispatch(ActionsWeb.StoreAttributeProductsRequest(data, id));
    },
    ResetStoreAttributeProducts: () => {
      dispatch(ActionsWeb.ResetStoreAttributeProducts());
    },
    SetAttributeProducts: (data) => {
      dispatch(ActionsWeb.SetAttributeProducts(data));
    },
    ListMenusRequest: (body) => {
      dispatch(ActionsWeb.ListMenusRequest(body));
    },
    SearchCategoryRequest: (body, data) => {
      dispatch(ActionsWeb.SearchCategoryRequest(body, data));
    },
    // login
    SetUserRequest: (data) => {
      dispatch(ActionsLogin.SetUserRequest(data));
    },
    LogoutRequest: (data) => {
      dispatch(ActionsLogin.LogoutRequest(data));
    },
    getPdfDownloadRequest: (data) => {
      dispatch(ActionsWeb.getPdfDownloadRequest(data));
    },
    SettingsShowByCodeRequest: (data) => {
      dispatch(ActionsWeb.SettingsShowByCodeRequest(data));
    },
    ListEcommerceConfigRequest: (body, data) => {
      dispatch(ActionsWeb.ListEcommerceConfigRequest(body, data));
    },
    ListEcommerceTypeRequest: (body, data) => {
      dispatch(ActionsWeb.ListEcommerceTypeRequest(body, data));
    },
    setNotification: (body) => {
      dispatch(ActionsApp.setNotification(body));
    },
    SearchMenuHomeRequest: (body, data) => {
      dispatch(ActionsWeb.SearchMenuHomeRequest(body, data));
    },
    ListUnitRequest: (body) => {
      dispatch(ActionsWeb.ListUnitRequest(body));
    },
    ListStatusTransportRequest: (body) => {
      dispatch(ActionsWeb.ListStatusTransportRequest(body));
    },
    ListConnectExportBillRequest: (body) => {
      dispatch(ActionsWeb.ListConnectExportBillRequest(body));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Web);