import React, { useEffect, useRef, useState } from 'react';

import './style.css';



import * as LINK from "./../../constants/Links";
import { useDispatch, useSelector } from 'react-redux';


import * as ActionsWeb from "./../../actions/indexWeb";

import { useHistory } from 'react-router-dom';
import PDFReader from './PDFReader';
import * as TypePrint from "./../../constants/TypePrint";
import { message } from 'antd';
const Index = (props) => {
    const history = useHistory();
    const dispatch = useDispatch()
    const { Web } = useSelector(state => state);
    const [pdfPath, setpdfPath] = useState(null);
    const [type, settype] = useState(null);
    const [typePrint, setTypePrint] = useState(null);
    const [data, setdata] = useState(null);
    useEffect(() => {


        if (Web.getPdfStream) {

            setpdfPath(Web.getPdfStream);
            // if (Web.getPdf.code === 200) {
            //     console.log('====================================');
            //     console.log("LINK.myHostLINK.myHost",Web.getPdf.data);
            //     console.log('====================================');
            //     // // if (LINK.myHost ==="http://localhost:3000"){
            //     // //     setpdfPath("/pdf/Notfound1.pdf");
            //     // // }else{
            //     // //     setpdfPath(Web.getPdf.data);
            //     // // }
            //     // setpdfPath(Web.getPdf.data);
            //     // setpdfPath("http://127.0.0.1:8000/api/v1/pdf-show/ORDER1.pdf");
            //     fetchPDF(Web.getPdf.data).then((response) => response.text())
            //     .then((result) => {
            //         console.log("resultresultresult",result);
            //         // setpdfPath(result);
            //     })
            //     // .catch((error) => console.error(error));;

            // } else {
            //     showMessage(Web.getPdfStream);
            //     // message.warn("Lỗi tải file!")
            // }
        }



        return () => {

        };
    }, [Web.getPdfStream]);

    const showMessage = (result) => {
        try {
            if (result.code === 200) {
                if (result.message && typeof result.message === "string") {
                    message.success(result.message)
                } else {

                    message.error("Có lỗi!")
                }

            }
            else {
                if (result.message && typeof result.message === "string") {

                    message.error(result.message)
                } else {
                    for (let x in result.message) {
                        let mess = result.message[x];
                        for (let y in mess) {
                            if (mess[y]) {
                                message.error(mess[y])
                            }

                        }
                    }
                    // message.error("Có lỗi!")
                }

            }

        } catch (error) {
            message.error("Có lỗi!")

        }
    }

    // useEffect(() => {
    //     const myHeaders = new Headers();
    //     myHeaders.append("Accept", "application/json");
    //     myHeaders.append("Authorization", "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiZjFjMjBmYjRlZTU4NmY4OGVmYWNhNDU1ZDM0MzgzODY3OGQ4MzE2ZDE1M2UzMDI4ODFlY2QzOWJmNzk2NDcwMjkwZjI0ZTU3NzRjNTEzNzEiLCJpYXQiOjE3MTUxODY2ODEuMDM5MjE2LCJuYmYiOjE3MTUxODY2ODEuMDM5MjIsImV4cCI6MTc0NjcyMjY4MC45NTMxMDIsInN1YiI6IjEiLCJzY29wZXMiOltdfQ.qykvIHkGNFwqDDtCiEB0zduWVDlCKixhhpLCbHNKBQZyrtowUVxFhaEvirDa9ARTdR7i-xA9dpc9FVW_mWIdIHYITqXPateMOmuFmkFSMSqyNuduL__CFPEf8nu5BI5p5gT9ITHKkaT8qg3LjtnV0WbVJIR8I8r9TiEEsEd6r3M50De4oASoXiDR70fVdJDJ_goGG-LUDOhv2Ym0nmHAHeLnn9k0qEXZmIm_obuaqCRubxjnTXmGl0b4k3F5mXE69m5_u6pYP80c3Lh-8fQR9A49JU_eLVl7JcMYuo8x1lymrOYQfOt_HsZlAI-quT2tXtLaQPRIW3fqqAXDRj7GmrdTt3IdR27t2t8hrhOEK7uGpIlgdZJMSNe8zt_jq1FiRjlAqb3AbH0rY_IU6x_Z3faFeWYRQrRVKGcuytgWkmO9Te1Ra-Q8zc_PWinjQQqzK8NwcEDviUt5ccsOvy4yIdIX0feqOViMqjnSpeF53SDej_s-OCJ0VD73HwFvkrS3D91CUJNWSCpve8q-NQ8ofFQjlFY57c1XnS5I-2fjURySv7tqH0WLuEmK1ckMosImZounjBNe3W8Kg59jlG3_AXx9ALyY0QrbuulQk-vqQ7j8sa_6lzfsmdvsayrtYgjiso1ymtYl9p4i3ifgX2sM4v6EY3VdVf0dGCxXMZxqAl4");
    //     myHeaders.append("responseType", "blob");
    //     myHeaders.append("Cookie", "XSRF-TOKEN=eyJpdiI6Ijk5OWdGc2JTY0V5eC9xVW5WcUtid3c9PSIsInZhbHVlIjoiWHRtTG1XRzAzVWF6ZEpOMDlteHEwc0g1TWVIRVd3WVlHM2Vzc2Y3NFNWMkRRSnh4cTBncnRBTVU5THlhV2kzeTNjUEtsbzF4QzZ5SVBCV2Y2ZnNsSjNSUzBKVkF3UXc2TEhMZmVTb21Ld1BHekNQTVA5Z1ZwQ2tDOG9TV0FpcEIiLCJtYWMiOiJhMzMxM2RiNzI5ZjliZGMwN2EyYjM3MGM0NTkyYjY0NjZiOGJiYzg2YjIxZmI2YWM5OTY4OWYyN2JkNmU2ZDQwIiwidGFnIjoiIn0%3D; rimielsticker_session=57jSeQCFONP52dTnRfsFOI2bvhmvZi74dKiqVLXK");

    //     const requestOptions = {
    //       method: "GET",
    //       headers: myHeaders,
    //       redirect: "follow"
    //     };

    //     fetch("http://127.0.0.1:8000/api/v1/pdf-show/ORDER1.pdf", requestOptions)
    //     .then(response => response.blob())
    //     .then(blob => {
    //         setpdfPath(blob);
    //     })
    //       .catch((error) => console.error(error));
    // }, []);
    // useEffect(() =>
    // {

    //     // settype(props.typePrint);
    //     getPdfRes();
    //     return () =>
    //     {

    //     };
    // }, [props.typePrint]);
    // useEffect(() =>
    // {

    //     // settype(props.data);
    //     getPdfRes();
    //     return () =>
    //     {

    //     };
    // }, [props.data]);
    // useEffect(() =>
    // {

    //     // settype(props.type);
    //     getPdfRes();
    //     return () =>
    //     {

    //     };
    // }, [props.type]);
    const getPdfRes = () => {
        if (props.type && props.data && props.typePrint) {

            try {
                switch (props.typePrint) {
                    case TypePrint.ORDER:

                        dispatch(ActionsWeb.getPdfStreamRequest({ TypePrint: props.typePrint, type: props.type, data: props.data }))
                        // dispatch(ActionsWeb.getPdfRequest({ TypePrint: props.typePrint, type: props.type, data: props.data }))
                        break;
                    case TypePrint.CONTRACT:

                        dispatch(ActionsWeb.getPdfStreamRequest({ TypePrint: props.typePrint, type: props.type, data: props.data }))
                        // dispatch(ActionsWeb.getPdfRequest({ TypePrint: props.typePrint, type: props.type, data: props.data }))
                        break;
                    default:
                        break;
                }



            } catch (error) {

            }
        }

    }
    useEffect(() => {
        if (props.visible) {

            getPdfRes();
        } else {
            dispatch(ActionsWeb.resetPdfStreamRequest());
        }


    }, [props.visible]);

    return (
        <div className='dropdown_rim pdf'>
            <PDFReader url={pdfPath} />
        </div>
    );
};

export default Index;
