import React, { useEffect, useState } from 'react'
import { Button, message, Modal, } from 'antd';

import * as ActionWeb from "./../../../../../../actions/indexWeb";
import { formatNuformatNumber, mber, isValidURL, formatNumber } from '../../../../../../service';
import { ECOMMERCE_KEY } from '../../../../../../constants/DefaultData';
const Index = ({ record, formRef, setRecord, ListConnectExportBill, ListEcommerceType, ecomType, handleCancelDetail,code }) => {

  const [loading, setloading] = useState(false);
  const [record_, setrecord_] = useState(false);
  const [visible, setVisible] = useState(false);


  const exportBill = async (type, item) => {
    console.log('recordrecord:', record);
    setloading(true)
    try {
      let response = null;
      record.idType = item.id;

      const ecomType_ = ListEcommerceType.filter((value, index) => {
        return parseInt(value.id) == ecomType;

      })
      if (ecomType_[0]) {
        console.log("ListEcommerceType ", ecomType_[0]);
        switch (ecomType_[0].keyshow) {
          case ECOMMERCE_KEY.TITOK:
            if (type == 1) {

              response = await ActionWeb.ExportTiktokBillVatRequest(record);


            }
            if (type == 2) {
              response = await ActionWeb.SignBillVatRequest(record);


            }

            if (type == 3) {
              record.sign = 1;
              response = await ActionWeb.ExportTiktokBillVatRequest(record);


            }
            break;
          case ECOMMERCE_KEY.LAZADA:
            if (type == 1) {
              response = await ActionWeb.ExportLazadaBillVatRequest(record);
            }
            break;
          case ECOMMERCE_KEY.SHOPEE:
            if (type == 1) {
              response = await ActionWeb.ExportShopeeBillVatRequest(record);
            }
            break;
          default:
            break;
        }
      }


      if (response) {
        const result = await response.json();
        if (result.code !== 200) {
          message.warning(result.message)

        } else {
          message.success(result.message)
          setRecord(result.data)
          if (formRef.current) {
            formRef.current.submit();
          }
        }
        console.log('result:', result);
      }
      setloading(false)
      // handleCancelDetail();
    } catch (error) {
      console.error('Error:', error);
    }
  }

  useEffect(() => {
    setrecord_(record);
    return () => {

    };
  }, [record.id]);

  const acountant = async (type=1) => {
    setloading(true)
    const ecomType_ = ListEcommerceType.filter((value, index) => {
      return parseInt(value.id) == ecomType;

    })
    let response = null;
  
    console.log("ecomType_ecomType_ ", ecomType_);

    if (ecomType_[0]) {
        switch (ecomType_[0].keyshow) {
            case ECOMMERCE_KEY.TITOK:

                response = await ActionWeb.acountantTiktok({ order_id: record.id_, idType: 1, config_code: code,type:type });
                break;
            case ECOMMERCE_KEY.LAZADA:

                break;
            case ECOMMERCE_KEY.SHOPEE:
                // response = await ActionsWeb.updatePhoneNumberTiktok({ id: id, phone: value });
                break;
            default:
                break;
        }
    }
    setloading(false)
    if (response.code == 200) {
        if (response.message) {
            message.success(response.message);
        } else {
            message.success("Cập nhật thành công");
        }
        record_.accountant_code_revenue=response.code;
        setRecord({...record_})
    } else {
        if (response.message) {
            if (typeof response.message == "string") {
                message.error(response.message);
            } else {
                (Object.values(response.message) || []).map((value, index) => {
                    message.error(value);
                })
            }

        } else {
            message.warning("Cập nhật không thành công");
        }
    }
}
  return (
    <><Modal
      title="Thông báo"
      visible={visible}
      // onOk={this.handleOkExportBill}
      // confirmLoading={this.state.confirmLoading}
      onCancel={() => setVisible(false)}
      footer=""
    >
      <p className="mb-4">{"Tạo hóa đơn VAT"}</p>

      {ListConnectExportBill && ListConnectExportBill.map((value, index) => {
        return <Button onClick={() => exportBill(1, value)}>
          {value?.icon && <div>

            {isValidURL(value?.icon) ? (
              <img src={value.icon} alt="icon" className='icon-mini' style={{ height: "20px" }} />
            ) : (
              <div dangerouslySetInnerHTML={{ __html: value?.icon }} className='icon-mini' />
            )}

          </div>}
          {!value?.icon && <div>  <span>{value.name}</span> </div>}

        </Button>
      })}
    </Modal>
      <div className='position-relative  h-100'>
        <div className='row'>
          <div className='col-lg-7 col-md-7 col-sm-12'>
            <div className=''>
              <h5>{record?.status}</h5>
              <div className='row'>
                <div className='col-lg-4 col-md-4 col-sm-12'>

                </div>
                <div className='col-lg-4 col-md-4 col-sm-12'>

                </div>
                <div className='col-lg-4 col-md-4 col-sm-12'>

                </div>
              </div>
            </div>

            <div className=''>
              <h5>sản phẩm</h5>
              <div className='pro-show'>
                {record_?.line_items && record_?.line_items.map((value, index) => {
                  return <div className='item_ p-2 mb-2'  >
                    <div className='w-100 p-1'>
                      <span>{value.sku_id}</span>
                    </div>
                    <div className='w-100 d-flex p-1 justify-content-start align-items-center'>
                      <div className='img_  p-1  ' style={{ width: "70px" }}>
                        <img src={value.sku_image} style={{ width: "50px", height: "50px" }} />
                      </div>
                      <div className='info p-1 ms-2 ps-4' style={{ width: "60%" }}>
                        <span>{value.product_name}</span>
                      </div>
                      <div className='price p-1 justify-content-end align-items-center' style={{ width: "150px" }}>
                        <span>Giá bán: {formatNumber(value.sale_price)}</span><br />
                        <span>CK sàn: {formatNumber(value.san_discount)}</span><br />
                        <span>CK shop: {formatNumber(value.seller_discount)}</span><br />
                      </div>
                      <div className='price p-1 justify-content-end align-items-center' style={{ width: "200px" }}>
                        <span>mã của sàn: {formatNumber(value.discount_from_voucher_san)}</span><br />
                        <span>mã của shop: {formatNumber(value.discount_from_voucher_shop)}</span><br />

                        <span className='fw-bold'>HT: {formatNumber(value.sale_price - value.discount_from_voucher_shop)}</span><br />
                      </div>
                    </div>
                  </div>

                })}
              </div>
            </div>
          </div>
          <div className='col-lg-1 col-md-1 col-sm-0'>

          </div>
          <div className='col-lg-4 col-md-4 col-sm-12'>
            <div className=''>
              <h5>Thanh toán</h5>
              <p>{record_?.payment?.payment_method_name}</p>
              <div>
                <div className='d-flex  justify-content-between'>
                  <div className='fw-bold me-2'>Tổng (các) mặt hàng sau khi chiết khấu </div>
                  <div className=''>{formatNumber(record_?.payment?.sub_total)} </div>
                </div>
                {/* <div className='d-flex  justify-content-between'>
                  <div className='fw-bold me-2'>Tổng (các) mặt hàng chưa trừ phí </div>
                  <div className=''>{formatNumber(parseFloat(record_?.payment?.sub_total) + parseFloat((record_?.payment?.sv_fee ? record_?.payment?.sv_fee : 0)))} </div>
                </div> */}
                <div className='d-flex justify-content-between'>
                  <div className='me-2'>Tổng (các) mặt hàng trước khi chiết khấu </div>
                  <div className=''>{formatNumber(record_?.payment?.original_total_product_price)} </div>
                </div>
                <div className='d-flex justify-content-between'>
                  <div className='me-2'>Chiết khấu của người bán cho các mặt hàng </div>
                  <div className=''>{formatNumber(record_?.payment?.seller_discount)} </div>
                </div>
                <div className='d-flex justify-content-between'>
                  <div className='me-2'>Chiết khấu của sàn cho các mặt hàng </div>
                  <div className=''>{formatNumber(record_?.payment?.platform_discount)} </div>
                </div>
                <div className='d-flex justify-content-between'>
                  <div className='me-2'>Coins </div>
                  <div className=''>{formatNumber(record_?.payment?.coins)} </div>
                </div>
                {/* <div className='d-flex justify-content-between'>
                  <div className='fw-bold me-2'>Phí Sàn </div>
                  <div className=''>{formatNumber(record_?.payment?.total_fees)} </div>
                </div> */}
                <div className='d-flex justify-content-between'>
                  <div className='fw-bold me-2'>Phí vận chuyển sau khi chiết khấu </div>
                  <div className=''>{formatNumber(record_?.payment?.shipping_fee)} </div>
                </div>
                <div className='d-flex justify-content-between'>
                  <div className='me-2'>Phí vận chuyển trước khi chiết khấu </div>
                  <div className=''>{formatNumber(record_?.payment?.original_shipping_fee)} </div>
                </div>
                <div className='d-flex justify-content-between'>
                  <div className='me-2'>Chiết khấu phí vận chuyển của người bán </div>
                  <div className='align-end'>{formatNumber(record_?.payment?.shipping_fee_seller_discount)} </div>
                </div>
                <div className='d-flex justify-content-between'>
                  <div className=''>Chiết khấu phí vận chuyển của TikTok Shop </div>
                  <div className=''>{formatNumber(record_?.payment?.shipping_fee_platform_discount)} </div>
                </div>
                {/* <div className='d-flex justify-content-between'>
                  <div className=''>Phí vận chuyển thực tế </div>
                  <div className=''>{formatNumber(record_?.payment?.actual_shipping_fee)} </div>
                </div> */}
                <div className='d-flex justify-content-between'>
                  <div className='fw-bold me-2'>Thuế </div>
                  <div className=''>{formatNumber(record_?.payment?.tax)} </div>
                </div>

                <div className='d-flex justify-content-between'>
                  <div className='fw-bold me-2'>Tổng cộng </div>
                  <div className=''>{formatNumber(record_?.payment?.total_amount)} </div>
                </div>

                {/* <div className='d-flex justify-content-between'>
                  <div className='fw-bold me-2'>Tổng mã giảm giá của sàn </div>
                  <div className=''>{formatNumber(record_?.discount_from_san)} </div>
                </div>
                <div className='d-flex justify-content-between'>
                  <div className='fw-bold me-2'>Tổng mã giảm giá của shop </div>
                  <div className=''>{formatNumber(record_?.discount_from_shop)} </div>
                </div> */}

                <div className='d-flex justify-content-between'>
                  <div className='fw-bold me-2'>Giá trị hoạch toán </div>
                  <div className='fw-bold'>{formatNumber((parseFloat(record_?.payment?.total_amount) + parseFloat(record_?.discount_from_san)+ parseFloat(record_?.payment?.platform_discount)+ parseFloat(record_?.payment?.coins)))} </div>
                </div>
                <Button type='primary' size='small' loading={loading} onClick={() => {acountant()}} disabled={(record_?.accountant_code_revenue != null && record_?.accountant_code_revenue != "")}>Hoạch toán doanh thu</Button>
              </div>
            </div>

            <div className='mt-4'>
              <h5>Thông tin khách hàng</h5>
              <p>Tên: {record_?.recipient_address?.name}</p>
              <div>
                <div className='d-flex  justify-content-between'>
                  <div className='fw-bold me-2'>Địa chỉ vận chuyển </div>

                </div>
                <div className='d-flex justify-content-between'>

                  <div className='wrap-text'>{record_?.recipient_address?.full_address} </div>
                </div>
                <div className='d-flex justify-content-between'>

                  <div className='wrap-text'>{record_?.recipient_address?.phone_number} </div>
                </div>
                {record_?.recipient_address?.district_info && record_?.recipient_address?.district_info.map((value, index) => {

                  return <div className='d-flex justify-content-between'>

                    <div className='wrap-text'>{value?.address_name} </div>
                  </div>
                })}




              </div>
            </div>

            <div className=''>
              <div className='d-flex justify-content-between'> <h5>Quyết toán</h5>
             
              </div>
             
              {/* <p>{record_?.payment?.payment_method_name}</p> */}
              <div>
                <div className='d-flex  justify-content-between'>
                  <div className='fw-bold me-2'>Tiền quyết toán </div>
                  <div className=''>{formatNumber(record_?.payment?.total_settlement_amount)} </div>
                </div>
                <div className='d-flex  justify-content-between'>
                  <div className='fw-bold me-2'>Doanh thu</div>
                  <div className=''>{formatNumber(record_?.payment?.total_revenue)} </div>
                </div>
                <div className='d-flex  justify-content-between'>
                  <div className=' me-2'>Doanh thu trước chiết khấu</div>
                  <div className=''>{formatNumber(record_?.payment?.subtotal_before_discounts)} </div>
                </div>
                <div className='d-flex  justify-content-between'>
                  <div className=' me-2'>Chiết khấu người bán</div>
                  <div className=''>{formatNumber(record_?.payment?.seller_discounts)} </div>
                </div>
                <div className='d-flex  justify-content-between'>
                  <div className=' me-2'>Chiết khấu sàn</div>
                  <div className=''>{formatNumber(record_?.payment?.platform_discounts)} </div>
                </div>
                <div className='d-flex  justify-content-between'>
                  <div className='fw-bold me-2'>Tổng phí</div>
                  <div className=''>{formatNumber(record_?.payment?.total_fees)} </div>
                </div>
                <div className='d-flex  justify-content-between'>
                  <div className=' me-2'>Phí giao dịch</div>
                  <div className=''>{formatNumber(record_?.payment?.transaction_fee)} </div>
                </div>
                <div className='d-flex  justify-content-between'>
                  <div className=' me-2'>Phí dịch vụ</div>
                  <div className=''>{formatNumber(record_?.payment?.service_fee)} </div>
                </div>
                <div className='d-flex  justify-content-between'>
                  <div className=' me-2'>Phí hoa hồng</div>
                  <div className=''>{formatNumber(record_?.payment?.tikTok_shop_commission_fee)} </div>
                </div>
                <div className='d-flex  justify-content-between'>
                  <div className='fw-bold me-2'>Phí vận chuyển</div>
                  <div className=''>{formatNumber(record_?.payment?.seller_shipping_fee)} </div>
                </div>
                <div className='d-flex  justify-content-between'>
                  <div className=' me-2'>Phí vận chuyển thực tế</div>
                  <div className=''>{formatNumber(record_?.payment?.actual_shipping_fee)} </div>
                </div>
                <div className='d-flex  justify-content-between'>
                  <div className=' me-2'>Phí vận chuyển giảm từ sàn</div>
                  <div className=''>{formatNumber(record_?.payment?.platform_shipping_fee_discount)} </div>
                </div>
                <div className='d-flex  justify-content-between'>
                  <div className=' me-2'>Phí vận chuyển khách hàng trả</div>
                  <div className=''>{formatNumber(record_?.payment?.customer_shipping_fee)} </div>
                </div>
                <div className='d-flex  justify-content-between'>
                  <div className='fw-bold me-2'>Phí SFP</div>
                  <div className=''>{formatNumber(record_?.payment?.sfp_service_fee)} </div>
                </div>
                <Button type='primary' size='small' loading={loading} onClick={() => {acountant(2)}} disabled={((record_?.accountant_expense != null && record_?.accountant_expense != "" ) || !record_?.payment?.total_settlement_amount)}>Hoạch toán chi phí</Button>
              </div>
            </div>
          </div>
        </div>

        <div className='d-flex justify-content-end w-100 mt-4' style={{ bottom: 0 }}>
          {/* {ListConnectExportBill && ListConnectExportBill.map((value, index) => {
            return <Button onClick={() => this.exportBill(1, value)} disabled={record_?.invCode}>
              {value?.icon && <div>

                {isValidURL(value?.icon) ? (
                  <img src={value.icon} alt="icon" className='icon-mini' style={{ height: "20px" }} />
                ) : (
                  <div dangerouslySetInnerHTML={{ __html: value?.icon }} className='icon-mini' />
                )}

              </div>}
              {!value?.icon && <div>  <span>{value.name}</span> </div>}

            </Button>
          })} */}
          <Button type='primary' loading={loading} onClick={() => setVisible(true)} disabled={(record_?.invCode != null && record_?.invCode != "" && record_?.invCode != "0000000")}>Xuất hóa đơn vat</Button>
          
          {/* <Button type='primary' loading={loading} onClick={() => exportBill(2)} disabled={!record_?.invCode || (record_?.invCode && record_.isSign==1 )  }>Ký hóa đơn vat</Button>
      <Button type='primary' loading={loading} onClick={() => exportBill(3)} disabled={record_?.invCode}>Xuất + Ký hóa đơn vat</Button> */}
        </div>
      </div>
    </>
  )
}

export default Index
