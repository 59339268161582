import React, { useEffect, useRef, useState } from 'react'
import { Button, Checkbox, DatePicker, Empty, Form, Input, message, Modal, Popover, Select, Skeleton, Spin, Table, Tooltip, TreeSelect } from 'antd';
import "./style.css"
import { CopyAccountantRequest, DeleteAccountantRequest, ListAccountantRequest, ListAccountingAccountRequest, LockAccountantMultipleRequest, LockAccountantRequest, StoreAccountantRequest, StoreAccountingAccountRequest, UnLockAccountantMultipleRequest, UnLockAccountantRequest } from '../../../actions/indexWeb';
import { META } from '../../../utils/constant';
import { DATA_KEY_CODE_ACCOUNT, DATA_PRECIOUS } from '../../../constants/DefaultData';
import {
  LoadingOutlined, SearchOutlined, ExclamationCircleOutlined, LockOutlined
} from '@ant-design/icons';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { formatDate, formatDate_fm, formatDateVN, formatNumber, getParamsUrl } from '../../../service';
import * as LINK from "../../../constants/Links";
import { useParams, useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
// import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
const { RangePicker } = DatePicker;

const InputCell = ({ value, onAddRow, isLastCell, focusRef, isHidden, row, cell, name, listAccount, defaultValue, isloading, isEdit, formRef, rowData, editRow, deleteRow, isFirtscell, onCheck, checkedValue, isLastRow, lockRow, copyRow, unlockRow }) => {

  const handleKeyDown = (e) => {

    // console.log(e);
    if (isLastCell && (e.key === 'Tab' || e.key === 'Enter')) {
      e.preventDefault();
      onAddRow(row, cell);
    }

    if (!isLastCell && (e.key === 'Enter')) {
      e.preventDefault();
      onAddRow(row, cell);
    }
  };
  // Hàm tìm kiếm dữ liệu
  const filterTreeNode = (inputValue, treeNode) => {
    const { title, description, code } = treeNode;
    // console.log(treeNode.props.data);

    return (
      title.toLowerCase().includes(inputValue.toLowerCase()) || // Tìm kiếm theo title
      description.toLowerCase().includes(inputValue.toLowerCase()) || // Tìm kiếm theo description
      code.toString().includes(inputValue) // Tìm kiếm theo code
    );
  };
  const handleMoneyChange = (e) => {
    const inputValue = e.target.value.replace(/\D/g, ''); // Loại bỏ ký tự không phải số
    const formattedValue = formatNumber(inputValue);
    formRef.current.setFieldsValue({
      [`${name}-${row}-${cell}`]: formattedValue,
    });
  };
  // console.log("isFirtscell ",focusRef);
  const renderInput = () => {
    switch (name) {
      case "date_cre_deb":
        return <DatePicker onKeyDown={handleKeyDown}
          ref={focusRef} />
      case "description":
        return <Input
          onKeyDown={handleKeyDown}
          ref={focusRef}
        />
      case "money":
        return <Input
          onKeyDown={handleKeyDown}
          ref={focusRef}
          onChange={handleMoneyChange}
        />
        case "money_co":
          return <Input
            onKeyDown={handleKeyDown}
            ref={focusRef}
            onChange={handleMoneyChange}
          />
      case "code":
        return <Select
          showSearch
          style={{ width: "100px", maxWidth: "100px" }}
          showArrow={true}
          defaultActiveFirstOption={true}
          options={DATA_KEY_CODE_ACCOUNT}

          onKeyDown={handleKeyDown}
          ref={focusRef}
        />
      case "tkco":

        return <TreeSelect
          // defaultOpen
          showSearch
          style={{ width: '150px', maxWidth: "150px" }}
          treeData={[...listAccount]}
          showArrow
          dropdownStyle={{ maxHeight: 400, overflow: 'auto', minWidth: "250px", width: "auto" }}
          placeholder="Tk"
          allowClear
          filterTreeNode={filterTreeNode}
          treeDefaultExpandAll
          onKeyDown={handleKeyDown}
          ref={focusRef}
        />
      case "tkno":

        return <TreeSelect
          showSearch
          style={{ width: '150px', maxWidth: "150px" }}
          treeData={[...listAccount]}
          showArrow
          dropdownStyle={{ maxHeight: 400, overflow: 'auto', minWidth: "250px", width: "auto" }}
          placeholder="Tk"
          allowClear
          filterTreeNode={filterTreeNode}
          treeDefaultExpandAll
          onKeyDown={handleKeyDown}
          ref={focusRef}
        />
      default:
        return <Input
          onKeyDown={handleKeyDown}
          ref={focusRef}
        />
    }
  }
  const renderValue = (value) => {
    switch (name) {
      case "date_cre_deb":
        return formatDateVN(value, false)
      case "description":
        return value
      case "money":
        return formatNumber(value)
      case "code":
        return value
      case "tkco":

        return <Tooltip title={rowData?.["tkco_des"]}>{value}</Tooltip>
      case "tkno":

        return <Tooltip title={rowData?.["tkno_des"]}>{value}</Tooltip>
      default:
        return value
    }
  }

  useEffect(() => {
    if ((isEdit == row)) {
      if (formRef.current) {

        formRef.current.setFieldsValue({
          [`date-${row}-${cell}`]: moment(rowData.date, 'YYYY-MM-DD HH:mm:ss'),
          [`code-${row}-${cell}`]: rowData.code,
          [`description-${row}-${cell}`]: rowData.description,
          [`tkno-${row}-${cell}`]: rowData.tkno_id,
          [`tkco-${row}-${cell}`]: rowData.tkco_id,
          [`money-${row}-${cell}`]: rowData.money,
        });
      }
    }
    return () => {


    };
  }, [isEdit]);

  const renderAlign = () => {
    switch (name) {
      case "date_cre_deb":
        return "text-center"
      case "description":
        return "text-left"
      case "money":
        return "text-right"
           case "money_co":
        return "text-right"
      case "code":
        return "text-center"
      case "tkco":

        return "text-center"
      case "tkno":

        return "text-center"
      default:
        return "text-center"
    }
  }
  return (
    <td className={`${isHidden ? "d-none" : ""} position-relative ${renderAlign()}`}  >
      {/* <div className={`d-flex align-items-center w-100  ${renderAlign()}`}> */}
      {/* <div className={`d-flex me-1 align-items-center `}>
          {isFirtscell && !isLastRow && <Checkbox onChange={onCheck} value={rowData.id} checked={checkedValue.includes(rowData.id)} />}
          {(isFirtscell && rowData.is_close == 1) && <i className="fas fa-lock text-danger"></i>}
          {(isFirtscell && rowData.is_close == 0) && <i className="fas fa-unlock text-success"></i>}
        </div> */}
      {(isloading != false && isloading == row) && <div style={{ position: "absolute", width: "100%", height: "100%", left: 0, top: 0, zIndex: 1000 }}><Spin indicator={<LoadingOutlined
        style={{
          fontSize: 24,
        }}
        spin
      />} /> </div>}
      {(isEdit == row) || !value ? (<Form.Item name={`${name}-${row}-${cell}`} noStyle initialValue={defaultValue} >
        {renderInput()}
      </Form.Item>) : renderValue(value)
      }

      {isLastCell && rowData.is_close == 0 && <div className='popover-custom d-flex p-2'>
        <Button size='small' htmlType='button' onClick={() => editRow(row, rowData)} ><i className="fas fa-edit text-info"></i></Button>
        <Button size='small' htmlType='button' onClick={() => deleteRow(row, rowData)}><i className="fas fa-trash-alt text-danger" ></i></Button>
        <Button size='small' htmlType='button' onClick={() => lockRow(row, rowData)}><i className="fas fa-lock"></i></Button>
        <Button size='small' htmlType='button' onClick={() => copyRow(row, rowData)}><i className="fas fa-copy"></i></Button>
      </div>}
      {(isLastCell && rowData.is_close == 1 && rowData.is_close_lv2 == 0) && <div className='popover-custom d-flex p-2'>
        <Button size='small' htmlType='button' onClick={() => unlockRow(row, rowData)}><i className="fas fa-unlock text-success"></i></Button>
      </div>}
      {/* </div> */}
    </td>
  );

}
const Index = () => {

  const [data, setData] = useState(null);
  const [meta, setMeta] = useState({ ...META });
  const [isloading, setIsloading] = useState(false);
  const [isLoadingSearch, SetisLoadingSeach] = useState(false);
  const [isEdit, setIsEdit] = useState(-1);
  const [listAccount, setListAccount] = useState([]);
  const [record, setRecord] = useState(null);
  const [checkedValue, setCheckedValue] = useState([]);
  const [listAccountant, setListAccountant] = useState([]);
  const formRef = useRef(null);
  const newRowRef = useRef(null);
  const formSearchRef = useRef(null);
  const checkAllRef = useRef(null);
  const history = useHistory();
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll_, setCheckAll_] = useState(false);
  const keys = ["date_cre_deb", "code", "description", "tkno", "tkco", "money","money_co"];

  const getListAccount = async () => {
    const response = await ListAccountingAccountRequest({
      limit: meta.per_page * 100,
      page: meta.current_page, column: "code", sort: "ASC"
    });
    if (response.code == 200) {
      if (response.data) {
        console.log(response.data.data);
        if (response.data.data) {

          setListAccount(response.data.data);
        }
      }
    }


  }

  const getListAccountant = async () => {

    const urlParams = getParamsUrl();
    const filteredvalues = Object.fromEntries(
      Object.entries(urlParams).filter(([_, value]) => { return value !== undefined && value !== "" })
    );
    // created_at
    if (filteredvalues.fromdate) {
      filteredvalues.created_at = [];
      filteredvalues.created_at[0] = moment.utc(filteredvalues.fromdate, "YYYY-MM-DD");
      filteredvalues.created_at[1] = moment.utc(filteredvalues.todate, "YYYY-MM-DD");
    }
    let quarterMoment=null;
    if (filteredvalues.precious) {
    
          const quarter = filteredvalues.precious;
           const year = filteredvalues.year?filteredvalues.year:moment().year();
            quarterMoment = moment().year(year).quarter(quarter);
    
   }else{
       // Lấy tháng hiện tại
      const currentMonth = moment().month() + 1; // moment().month() trả về giá trị từ 0-11, cần +1 để từ 1-12
      let quarter =1;
      
      const year = moment().year();
      // Tính toán quý hiện tại
      if (currentMonth <= 3) {
        quarter = 1; // Quý 1
      } else if (currentMonth <= 6) {
        quarter = 2;
      } else if (currentMonth <= 9) {
        quarter = 3;
      } else {
        quarter = 4;
      }
      filteredvalues.precious=quarter;
      filteredvalues.year=year;
       quarterMoment = moment().year(year).quarter(quarter);


   }
    // if (filteredvalues.precious) {
    //   filteredvalues.precious = filteredvalues.precious.split(",").map((value) => {
    //     return parseInt(value)
    //   })
    //   // filteredvalues.precious=parseInt(filteredvalues.precious)
    // } else {
    //   // Lấy tháng hiện tại
    //   const currentMonth = moment().month() + 1; // moment().month() trả về giá trị từ 0-11, cần +1 để từ 1-12

    //   // Tính toán quý hiện tại
    //   if (currentMonth <= 3) {
    //     filteredvalues.precious = [1]; // Quý 1
    //   } else if (currentMonth <= 6) {
    //     filteredvalues.precious = [2]; // Quý 2
    //   } else if (currentMonth <= 9) {
    //     filteredvalues.precious = [3]; // Quý 3
    //   } else {
    //     filteredvalues.precious = [4]; // Quý 4
    //   }
    // }
    if (filteredvalues.account) {
      filteredvalues.account = filteredvalues.account.split(",").map((value) => {
        return parseInt(value)
      })
    }

    // if (filteredvalues.year) {
     
    // }else{
    //   const currentYear = moment().year();
    //   filteredvalues.year=currentYear;
    //   filteredvalues.year = currentYear.toString();
    // }
    console.log("filteredvaluesfilteredvalues", filteredvalues);

    const searchParams = new URLSearchParams(filteredvalues).toString();

    history.push(`${LINK.WEB_ACCOUNTANT_LEDGER}?${searchParams}`);
    
    const response = await ListAccountantRequest({
      limit: meta.per_page * 100,
      page: meta.current_page, column: "date_cre_deb", sort: "ASC"
    }, { data: filteredvalues });
    if (response.code == 200) {
      if (response.data) {
        console.log(response.data.data);
        if (response.data.data) {

          setData([...response.data.data, [
            { date: "", code: "", description: "", tkno: "", tkco: "", money: "" },
          ]]);
        }
      }
    }

    if (quarterMoment) {
      filteredvalues.precious=quarterMoment;
    }
    if(formSearchRef.current){
      formSearchRef.current.setFieldsValue(filteredvalues)

    }
  }


  useEffect(() => {
    getListAccount();
    getListAccountant();
    return () => {

    };
  }, []);


  const addRowRequest = async (values, row) => {
    setIsEdit(-1)
    setIsloading(row);
    const response = await StoreAccountantRequest(values);
    setIsloading(false);
    if (response.code == 200) {
      if (response.message) {
        message.success(response.message);
      } else {
        message.success("Cập nhật thành công");
      }
      setData([...data, { date: '', code: '', description: '', tkno: '', tkco: '', money: '' }]);
      getListAccountant();

    } else {
      if (response.message) {
        if (typeof response.message == "string") {
          message.error(response.message);
        } else {
          (Object.values(response.message) || []).map((value, index) => {
            message.error(value);
          })
        }

      } else {
        message.warning("Cập nhật không thành công");
      }
    }
  }
  const addRow = (row, cell) => {

    const values = formRef.current.getFieldsValue();
    // console.log(row, cell, values);
    const date = values[`date_cre_deb-${row}-${0}`];
    const code = values[`code-${row}-${1}`];
    const description = values[`description-${row}-${2}`];
    const tkno = values[`tkno-${row}-${3}`];
    const tkco = values[`tkco-${row}-${4}`];
    const money = values[`money-${row}-${5}`];

    // console.log(code, description, tkno, tkco, money);
    // const inputValue = e.target.value.replace(/\D/g, ''); // Loại bỏ ký tự không phải số
    if (date && code && description && tkno && tkco && money) {
      const datapush = {
        date_cre_deb: date.format("YYYY-MM-DD"),
        key_code: code,
        description: description,
        tkno: tkno,
        tkco: tkco,
        money: money.replace(/\D/g, ''),
      }

      console.log("datapush ",datapush);

      if (record) {
        datapush.id = record.id;
      }
      // console.log(datapush);
      addRowRequest(datapush, row);


    }

    // return;
    //  // Lấy dòng cuối cùng
    //  const lastRow = data[data.length - 1];

    //  // Kiểm tra nếu tất cả các ô trong dòng đã điền dữ liệu
    //  const isRowFilled = Object.values(lastRow).every((value, idx) => {
    //    // Dùng ref để kiểm tra giá trị trong ô
    //    const cellValue = rowRefs.current[idx].current.state.value;
    //    return cellValue && cellValue.trim() !== '';
    //  });

    //  // Nếu tất cả ô trong dòng đã được điền dữ liệu thì thêm dòng mới
    //  if (isRowFilled) {
    //    setData([...data, { date: '', code: '', note: '', tkno: '', tkco: '', money: '' }]);
    //  }
  };


  useEffect(() => {


    if (newRowRef.current) {
      console.log(newRowRef.current);
      newRowRef.current.focus();
    }
  }, [data && data.length]);
  const editRow = (row, record_) => {
    if (record_.is_close) {
      message.warning("Phiếu khóa, Không được phép chỉnh sửa!")
      return;
    }
    if (!record_.id) {
      return;
    }
    if (isEdit == -1) {
      setIsEdit(row);
      setRecord(record_);
    } else {
      if (isEdit == row) {
        setRecord(null);
        setIsEdit(-1);
      } else {
        setRecord(record_);

        setIsEdit(row);
      }


    }
  }
  const deleteRow = async (row, record) => {

    if (record.is_close) {
      message.warning("Phiếu khóa, Không được phép xóa!")
      return;
    }
    setIsloading(row);

    const response = await DeleteAccountantRequest({ id: record.id });
    setIsloading(false);
    if (response.code == 200) {
      if (response.message) {
        message.success(response.message);
      } else {
        message.success("Cập nhật thành công");
      }

      getListAccountant();

    } else {
      if (response.message) {
        if (typeof response.message == "string") {
          message.error(response.message);
        } else {
          (Object.values(response.message) || []).map((value, index) => {
            message.error(value);
          })
        }

      } else {
        message.warning("Cập nhật không thành công");
      }
    }
  }
  const copyRow = async (row, record) => {
    if (record.is_close) {
      message.warning("Phiếu đã khóa!")
      return;
    }
    setIsloading(row);

    const response = await CopyAccountantRequest({ id: record.id });
    setIsloading(false);
    if (response.code == 200) {
      if (response.message) {
        message.success(response.message);
      } else {
        message.success("Cập nhật thành công");
      }

      getListAccountant();

    } else {
      if (response.message) {
        if (typeof response.message == "string") {
          message.error(response.message);
        } else {
          (Object.values(response.message) || []).map((value, index) => {
            message.error(value);
          })
        }

      } else {
        message.warning("Cập nhật không thành công");
      }
    }
  }
  const lockRow = async (row, record) => {
    if (record.is_close) {
      message.warning("Phiếu đã khóa!")
      return;
    }
    setIsloading(row);

    const response = await LockAccountantRequest({ id: record.id });
    setIsloading(false);
    if (response.code == 200) {
      if (response.message) {
        message.success(response.message);
      } else {
        message.success("Cập nhật thành công");
      }

      getListAccountant();

    } else {
      if (response.message) {
        if (typeof response.message == "string") {
          message.error(response.message);
        } else {
          (Object.values(response.message) || []).map((value, index) => {
            message.error(value);
          })
        }

      } else {
        message.warning("Cập nhật không thành công");
      }
    }
  }
  const unlockRow = async (row, record) => {
    // if (record.is_close==) {
    //   message.warning("Phiếu đã khóa!")
    //   return;
    // }
    setIsloading(row);

    const response = await UnLockAccountantRequest({ id: record.id });
    setIsloading(false);
    if (response.code == 200) {
      if (response.message) {
        message.success(response.message);
      } else {
        message.success("Cập nhật thành công");
      }

      getListAccountant();

    } else {
      if (response.message) {
        if (typeof response.message == "string") {
          message.error(response.message);
        } else {
          (Object.values(response.message) || []).map((value, index) => {
            message.error(value);
          })
        }

      } else {
        message.warning("Cập nhật không thành công");
      }
    }
  }

  const unlockMultipleRow = async () => {
    if (checkedValue.length > 0) {

      setIsloading(-2);

      const response = await UnLockAccountantMultipleRequest({ ids: checkedValue });
      setIsloading(false);
      if (response.code == 200) {
        if (response.message) {
          message.success(response.message);
        } else {
          message.success("Cập nhật thành công");
        }
        setCheckedValue([])
        setCheckAll_(false)
        getListAccountant();

      } else {
        if (response.message) {
          if (typeof response.message == "string") {
            message.error(response.message);
          } else {
            (Object.values(response.message) || []).map((value, index) => {
              message.error(value);
            })
          }

        } else {
          message.warning("Cập nhật không thành công");
        }
      }
    }
  }
  const onSearch = async (values) => {
    const filteredvalues = Object.fromEntries(
      Object.entries(values).filter(([_, value]) => { return value !== undefined && value !== "" })
    );

    if (filteredvalues.created_at) {
      filteredvalues.fromdate = filteredvalues.created_at[0].format("YYYY-MM-DD")
      filteredvalues.todate = filteredvalues.created_at[1].format("YYYY-MM-DD")
    }
    if (filteredvalues.precious) {
       const quarter = moment(filteredvalues.precious).quarter();
            const year = moment(filteredvalues.precious).year();
            filteredvalues.precious=quarter;
            filteredvalues.year=year;
     
    }
    if(!values.precious || values.month){
      message.warning("Vui lòng chọn khoảng thời gian!");
      return;
   }
    SetisLoadingSeach(true);
    const response = await ListAccountantRequest({
      limit: meta.per_page * 100,
      page: meta.current_page, column: "date_cre_deb", sort: "ASC"
    }, { data: { ...filteredvalues } });

    SetisLoadingSeach(false);

    if (response.code == 200) {
      if (response.data) {
        console.log(response.data.data);
        if (response.data.data) {

          setData([...response.data.data, [
            { date: "", code: "", description: "", tkno: "", tkco: "", money: "" },
          ]]);
        }
      }
    }
    delete filteredvalues["created_at"]
    const searchParams = new URLSearchParams(filteredvalues).toString();

    history.push(`${LINK.WEB_ACCOUNTANT_LEDGER}?${searchParams}`);


  }

  const resetFormSearch = () => {
    if (formSearchRef.current) {
      formSearchRef.current.resetFields();
      formSearchRef.current.submit();
    }
  }
  const onCheckAll = (e) => {
    const target = e.target;
    const value = target.value;
    if (target.checked) {
      if (data) {
        const newcheckeds = data.map((item, index) => {
          return item.id;
        })
        setCheckedValue([...newcheckeds])
      }


    } else {
      setCheckedValue([])


    }

  }
  const onCheck = (e) => {
    const target = e.target;
    const value = target.value;

    console.log("targettarget ", target);

    if (target.checked) {
      if (!checkedValue.includes(value)) {
        setCheckedValue([...checkedValue, value])

      }

    } else {
      const newcheckeds = checkedValue.filter((item, index) => {
        return item != value;
      })
      setCheckedValue([...newcheckeds])

    }

  }
  useEffect(() => {
    if (checkedValue.length > 0 && checkedValue.length < data.length) {
      setIndeterminate(true)
      setCheckAll_(false)
    }
    if (checkedValue.length > 0 && checkedValue.length >= data.length - 1) {
      setIndeterminate(false)
      setCheckAll_(true)
    }
    if (checkedValue.length <= 0) {
      setIndeterminate(false)

      setCheckAll_(false)
    }
    return () => {

    };
  }, [checkedValue]);


  const lockRowMultiple = async () => {
    if (checkedValue.length > 0) {

      setIsloading(-2);

      const response = await LockAccountantMultipleRequest({ ids: checkedValue });
      setIsloading(false);
      if (response.code == 200) {
        if (response.message) {
          message.success(response.message);
        } else {
          message.success("Cập nhật thành công");
        }
        setCheckedValue([])
        setCheckAll_(false)
        getListAccountant();

      } else {
        if (response.message) {
          if (typeof response.message == "string") {
            message.error(response.message);
          } else {
            (Object.values(response.message) || []).map((value, index) => {
              message.error(value);
            })
          }

        } else {
          message.warning("Cập nhật không thành công");
        }
      }
    }
  }

  const confirmDeleted = (row, record) => {
    Modal.confirm({
      title: 'Xác nhận',
      icon: <ExclamationCircleOutlined />,
      content: 'Bạn có chắc muốn xóa',
      okText: 'Xóa',
      cancelText: 'Bỏ qua',
      onOk: () => {
        deleteRow(row, record);

      }
    });
  };


  const confirmLocked = (row, record) => {
    Modal.confirm({
      title: 'Xác nhận khóa',
      icon: <LockOutlined />,
      content: 'Bạn có chắc khóa phiếu',
      okText: 'Khóa',
      cancelText: 'Bỏ qua',
      onOk: () => {
        lockRow(row, record);

      }
    });
  };

    // Hàm tìm kiếm dữ liệu
    const filterTreeNode = (inputValue, treeNode) => {
      const { title, description, code } = treeNode;
      // console.log(treeNode.props.data);
  
      return (
        title.toLowerCase().includes(inputValue.toLowerCase()) || // Tìm kiếm theo title
        description.toLowerCase().includes(inputValue.toLowerCase()) || // Tìm kiếm theo description
        code.toString().includes(inputValue) // Tìm kiếm theo code
      );
    };
  return (
    <div className='row w-100 bg-white p-2  position-relative'
    //  onClick={()=>setIsEdit(-1)}

    >




      <div className='col-lg-12 col-md-12 col-sm-12  d-flex'>
        <Link to={LINK.WEB_ACCOUNTANT}><Button><i className="fas fa-chevron-left"></i></Button></Link>
        <h4> Sổ sách</h4>
      </div>

      <div className='col-lg-12 col-md-12 col-sm-12'>
        <Form name="formsearch" ref={formSearchRef} onFinish={onSearch} style={{
          padding: "0px",
          width: "100%",
          display: "flex"
        }}>

          <Form.Item name="created_at" noStyle >
            <RangePicker style={{ width: "100%", }} />
          </Form.Item>
          <Form.Item name="code" noStyle >
            <Select
              multiple
              mode="multiple"
              style={{ width: "100%" }}
              maxTagCount='responsive'
              placeholder="Loại phiếu"
              options={DATA_KEY_CODE_ACCOUNT}
            />
          </Form.Item>
          <Form.Item name="precious" noStyle >
           <DatePicker picker="quarter" style={{ width: "100%" }}  placeholder='Chọn quý' /> 
            {/* <Select
              multiple
              mode="multiple"
              style={{ width: "100%" }}
              maxTagCount='responsive'
              placeholder="Quý "
              options={DATA_PRECIOUS}
            /> */}
          </Form.Item>
          {/* <Form.Item name="year" noStyle >
            <DatePicker picker="year" style={{ width: "100%" }}  placeholder='Chọn năm' />
          </Form.Item> */}
          <Form.Item name="account" noStyle >
            <TreeSelect
              multiple
              mode="multiple"
              style={{ width: "100%" }}
              maxTagCount='responsive'
              placeholder="Tài khoản"
              treeData={[...listAccount]}
              filterTreeNode={filterTreeNode}
            />
          </Form.Item>
          <Form.Item name="description" noStyle>

            <Input placeholder="Diễn giải/ số phiếu" />
          </Form.Item>
          <Form.Item name="money" noStyle>

            <Input placeholder="số tiền" />
          </Form.Item>
          <Form.Item noStyle style={{
            width: "20%"
          }}>
            <Button type="primary" htmlType="submit" style={{ width: "20%" }} icon={<SearchOutlined />} loading={isLoadingSearch} />
            <Button className='bg-warning' htmlType="button" style={{ width: "20%" }} loading={isLoadingSearch} onClick={resetFormSearch}>reset</Button>
          </Form.Item>

        </Form>
      </div>
      <div className='col-lg-12 col-md-12 col-sm-12'>

        <div className="table-responsive" style={{ height: "750px" }}>
          {checkedValue.length > 0 && <div className='popover-custom d-flex p-2' style={{ top: "50%", right: 0, zIndex: "1000", opacity: 1, visibility: "visible" }}>
            <Button size='small' htmlType='button' onClick={() => lockRowMultiple()} loading={isloading == -2}><i className="fas fa-lock text-danger"></i> Khóa chọn</Button>
            <Button size='small' htmlType='button' onClick={() => unlockMultipleRow()}><i className="fas fa-unlock text-success"></i> Mở khóa</Button>
          </div>}
          <Form ref={formRef}>
            <table className="table table-ledger table-sm table-hover" >
              <thead style={{ position: "sticky", top: 0, zIndex: 1000 }}>
                <tr>
                  <th className='p-1 text-center align-middle'  width="5%">
                    <Checkbox indeterminate={indeterminate} checked={checkAll_} onChange={onCheckAll} />
                  </th>
                  <th className='p-1 text-center align-middle'  width="3%">
                    <span> STT</span>
                  </th>
                  <th className='p-1 text-center align-middle' > Ngày tháng</th>
                  <th className='p-1 text-center align-middle'  >Số phiếu</th>
                  <th className='p-1 text-center align-middle' width="30%">Diễn giải</th>
                  <th className='p-1 text-center align-middle'  >Tài khoản</th>
                  <th className='p-1 text-center align-middle'  >TK đối ứng</th>
                  <th className='p-1 text-center align-middle' >Phát sinh nợ</th>
                  <th className='p-1 text-center align-middle'  >Phát sinh có</th>


                </tr>
                {/* <tr>
                  <th className='p-1 text-center align-middle' width="80px"  >Nợ</th>
                  <th className='p-1 text-center align-middle' width="80px" >Có</th>
                </tr> */}

              </thead>
              <tbody>
                {!data && <tr>
                  <td colSpan={9}>   <Skeleton />   </td>

                </tr>}
                {data && data.length <= 1 && <tr>
                  <td colSpan={9}>    <Empty /> </td>

                </tr>}

                {data && data.map((row, index) => {



                  const isLastRow = index === data.length - 1;
                  let i = 0;

                  return (
                    <tr className='position-relative ' key={index} onDoubleClick={() => editRow(index, row)}>
                      <td>
                          <div className={`d-flex me-1 align-items-center `}>
                            {!isLastRow && <Checkbox onChange={onCheck} className='me-1' value={row.id} checked={checkedValue.includes(row.id)} />}
                            {(row.is_close == 1) && <i className="fas fa-lock text-danger me-1 "></i>}
                            {(row.is_close == 0) && <i className="fas fa-unlock text-success me-1"></i>}
                            {(row.is_close_lv2 == 1) && <i className="far fa-window-close text-danger"></i>}

                          </div>
                      </td>
                      <td className='text-center'>
                          {index+1}
                      </td>
                      {keys.map((key, cellIndex) => {
                        i++;
                        let defaultValueDate=moment();
                        if(key == "date_cre_deb" && row[key] ){
                          defaultValueDate=moment.utc(row[key], "YYYY-MM-DD");
                        }
                        return <InputCell
                          key={`${index}-${cellIndex}`}
                          isLastRow={isLastRow}
                          isHidden={false}
                          value={row[key]}
                          onAddRow={addRow}
                          focusRef={isLastRow && (i == 1) ? newRowRef : null}
                          isLastCell={i == (keys.length)}

                          isFirtscell={i == 1}
                          row={index}
                          cell={cellIndex}
                          name={key}
                          listAccount={listAccount}
                          defaultValue={key == "date_cre_deb" ? defaultValueDate : row[key]}
                          isloading={isloading}
                          isEdit={isEdit}
                          formRef={formRef}
                          rowData={row}
                          editRow={editRow}
                          deleteRow={confirmDeleted}
                          onCheck={onCheck}
                          lockRow={confirmLocked}
                          copyRow={copyRow}
                          checkedValue={checkedValue}
                          unlockRow={unlockRow}

                        />


                      }
                      )}

                    </tr>
                  );
                })}


              </tbody>
            </table>

          </Form>
        </div>
      </div>

      <div className='col-lg-12 col-md-12 col-sm-12'>

      </div>

    </div>
  )
}

export default Index

