import { Button, Divider, Form, Input, InputNumber, message, Radio, Select, Space, TreeSelect, Typography } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { META } from '../../../../utils/constant';
import { ListAccountantBankRequest, ListAccountingAccountRequest, ListUnitNoDispatchRequest, SearchProductsNodisPatchRequest, SearchProductsWarehouseNoDispatchRequest, StoreUnitNoDispatchRequest } from '../../../../actions/indexWeb';
import { DATA_PERCENT_VAT } from '../../../../constants/DefaultData';
import {

    PlusOutlined,
} from '@ant-design/icons';
import { calculateDiscount, calculateFinalAmount, calculateTotal, calculateVat, filAcounts, formatMN1, formatNumber, handleNumberInput, parseNumberV_ } from '../../../../service';
const Index = ({ formRef, listDefaultAccountFil, setTotal, listSuplier, setIsProduct, isProduct, dataDetail }) => {

    const [tab, setTab] = useState(1);
    const [dataDetail1, setdataDetail1] = useState([
        { code_pro: "", name_pro: "", tkno: "", tkco: "", unit_id: "", amount: "", price: "", total_price: "", percent_discount: "", total_discount: "", expense: "", total: "", note_vat: "", percent_vat: "", vat_price: "", account_vat: "" },
    ]);
    const [listAccount, setListAccount] = useState([]);
    const [listProducts, setListProducts] = useState([]);
    const [listUnits, setListUnits] = useState([]);

    const [listTKno, setListTKno] = useState([]);
    const [listTKco, setListTKco] = useState([]);
    const [listTKvat, setListTKvat] = useState([]);

    const [meta, setMeta] = useState({ ...META });
    const [listBanks, setListBanks] = useState([]);

    const newRowRef = useRef(null);
    const tableRef = useRef(null);
    const getListBankTemplate = async () => {
        const response = await ListAccountantBankRequest({
            limit: meta.per_page * 100,
            page: meta.current_page, column: "code", sort: "ASC"
        });
        if (response.code == 200) {
            if (response.data) {

                if (response.data.data) {

                    setListBanks(response.data.data);
                }
            }
        }


    }
    useEffect(() => {


        if (dataDetail) {
            setdataDetail1([...dataDetail])
        }
    }, [dataDetail]);
    useEffect(() => {

        if ((listProducts && listProducts.length > 0) && dataDetail) {

            //   console.log("listDefaultAccountFillistDefaultAccountFil ",listDefaultAccountFil);
            setTimeout(() => {
                dataDetail.map((value, index) => {

                    for (const x in value) {
                        if (formRef.current) {
                            if (["price", "total", "total_price", "total_discount", "vat_price", "amount"].includes(x)) {
                                value[x] = formatNumber(value[x]);

                            }
                            if (isProduct == 2) {
                                value["product_warehouse_id"] = value["product_id"];
                            }
                            if(x=='unit_id'){
                                value[x]=parseInt(value[x])
                            }
                            formRef.current.setFieldsValue({ [`d-${x}-${index}`]: value[x] })
                        }
                    }

                })
                setTotal_();
            }, 1000);
            
        }


        return () => {

        };
    }, [listProducts, dataDetail1, listDefaultAccountFil, listTKno, listTKco, listTKvat]);
    const addRow = () => {
        const formvalue = formRef.current.getFieldsValue();
        let detail = {};
        for (const x in formvalue) {
            const xarr = x.split("-");
            if (xarr[0] == "d") {
                let key = xarr[1];
                let row = xarr[2];
                if (!detail[row]) {
                    detail[row] = {};
                }

                if (["price", "total", "total_price", "total_discount", "vat_price", "amount"].includes(key)) {
                    detail[row][key] = parseNumberV_(formvalue[x]);

                } else {
                    detail[row][key] = formvalue[x];
                }

            }

        }

        detail = Object.values(detail);
        setdataDetail1([...detail, { code_pro: "", name_pro: "", tkno: "", tkco: "", unit_id: "", amount: "", price: "", total_price: "", percent_discount: "", total_discount: "", expense: "", total: "", note_vat: "", percent_vat: "", vat_price: "", account_vat: "" },])

    }
    const deleteRow = (i) => {
        const formvalue = formRef.current.getFieldsValue();
        let detail = {};
        for (const x in formvalue) {
            const xarr = x.split("-");
            if (xarr[0] == "d") {
                let key = xarr[1];
                let row = xarr[2];
                if (!detail[row]) {
                    detail[row] = {};
                }
                if (["price", "total", "total_price", "total_discount", "vat_price", "amount"].includes(key)) {
                    detail[row][key] = parseNumberV_(formvalue[x]);

                } else {
                    detail[row][key] = formvalue[x];
                }

            }

        }

        detail = Object.values(detail);
        if (detail.length <= 1) {
            return;
        }
        const newrow = detail.filter((value, index) => {
            return index != i;
        })
        setdataDetail1(newrow);
    }
    const handleKeyDown = (e, isLastCell = false) => {


        // console.log(e);
        if (isLastCell && (e.key === 'Tab' || e.key === 'Enter')) {
            e.preventDefault();
            addRow();
        }

        if (!isLastCell && (e.key === 'Enter')) {
            e.preventDefault();
            addRow();

        }
    };
    useEffect(() => {

        processListAccount();

        if (newRowRef.current) {

            newRowRef.current.focus();
            if (formRef.current) {
                setTimeout(() => {
                    dataDetail1.map((value, index) => {

                        for (const x in value) {
                            if (formRef.current) {
                                if (["price", "total", "total_price", "total_discount", "vat_price", "amount"].includes(x)) {
                                    value[x] = formatNumber(value[x]);

                                }
                                formRef.current.setFieldsValue({ [`d-${x}-${index}`]: value[x] })
                            }
                        }
                    });
                }, 500);
            }
        }
    }, [dataDetail1 && dataDetail1.length]);

    const setTotal_ = () => {
        const total_ = {
            totalPrice: 0,
            totalVat: 0,
            totalDiscount: 0,
            total: 0,
        };
    
        if (formRef.current) {
            const formvalue = formRef.current.getFieldsValue();
            const vatGroups = {};
    
            dataDetail1.forEach((value, index) => {
                const amount = parseNumberV_(formvalue["d-amount-" + index]);
                const price = parseNumberV_(formvalue["d-price-" + index]);
                const percent_discount = parseNumberV_(formvalue["d-percent_discount-" + index]);
                const percent_vat = parseNumberV_(formvalue["d-percent_vat-" + index]);
    
                // Tính tổng tiền (sau giảm giá) cho sản phẩm
                const totalPrice = amount * price * (1 - percent_discount / 100);
    
                // Nhóm các sản phẩm theo phần trăm VAT
                if (!vatGroups[percent_vat]) {
                    vatGroups[percent_vat] = [];
                }
                vatGroups[percent_vat].push(totalPrice);
    
                // Cộng dồn tổng tiền và tổng giảm giá
                total_.totalPrice += totalPrice;
                total_.totalDiscount += parseNumberV_(formvalue["d-total_discount-" + index]);
            });
    
            console.log("vatGroups: ", vatGroups);
    
            // Kiểm tra số lượng phần trăm VAT
            const vatKeys = Object.keys(vatGroups);
            if (vatKeys.length === 1) {
                // Trường hợp chỉ có 1 loại phần trăm VAT
                const percent = vatKeys[0];
                const groupTotal = vatGroups[percent].reduce((sum, value) => sum + value, 0);
                total_.totalVat = Math.round(groupTotal * (parseFloat(percent) / 100));
            } else {
                // Trường hợp có nhiều loại phần trăm VAT
                for (const [percent, groupTotals] of Object.entries(vatGroups)) {
                    groupTotals.forEach((productTotal) => {
                        const productVat = Math.round(productTotal * (parseFloat(percent) / 100));
                        total_.totalVat += productVat; // Cộng dồn thuế đã làm tròn
                    });
                }
            }
        }
    
        // Tính tổng
        total_.total = total_.totalPrice + total_.totalVat;
    
        setTotal({ ...total_ });
    }
    useEffect(() => {
        scrollToElement(tab)
        getListAccount();
        getListProducts();
        getListUnits();
        getListBankTemplate();
        return () => {

        };
    }, []);
    const scrollToElement = (value) => {

        setTab(value)
        const element = document.getElementById("tab" + value); // Lấy phần tử dựa trên ID
        console.log(value, element);

        if (element && tableRef.current.contains(element)) {
            element.scrollIntoView({ behavior: "smooth", block: "nearest" });
        }
    };

    const storeUnit = async (e, type) => {
        const formvalues = formRef.current.getFieldsValue();

        const response = await StoreUnitNoDispatchRequest({
            "name": formvalues[type],
            "code": "",
            "type": "0",
            "hidden": "0",
            "deleted": "0"
        });
        if (response.code == 200) {
            if (response.message) {
                message.success(response.message);
            } else {
                message.success("Cập nhật thành công");
            }
            getListUnits();

        } else {
            if (response.message) {
                if (typeof response.message == "string") {
                    message.error(response.message);
                } else {
                    (Object.values(response.message) || []).map((value, index) => {
                        message.error(value);
                    })
                }

            } else {
                message.warning("Cập nhật không thành công");
            }
        }
    }
    const getListUnits = async () => {
        const response = await ListUnitNoDispatchRequest({
            limit: meta.per_page * 100,
            page: meta.current_page, column: "name", sort: "ASC"
        });
        console.log("getListUnitsgetListUnits", response);
        if (response.code == 200) {
            if (response.data) {

                if (response.data.data) {

                    setListUnits(response.data.data);
                }
            }
        }


    }
    useEffect(() => {
        if (isProduct == 1) {
            getListProductsWareHouse();
        }
        if (isProduct == 2) {
            getListProducts();
        }




        return () => {

        };
    }, [isProduct]);
    const getListProductsWareHouse = async () => {
        const response = await SearchProductsWarehouseNoDispatchRequest({
            limit: meta.per_page * 100,
            page: meta.current_page, column: "code", sort: "ASC"
        });
        if (response.code == 200) {
            if (response.data) {
                console.log(response.data.data);
                if (response.data.data) {

                    setListProducts(response.data.data);
                }
            }
        }


    }
    const getListProducts = async () => {
        const response = await SearchProductsNodisPatchRequest({
            limit: meta.per_page * 100,
            page: meta.current_page, column: "code", sort: "ASC"
        });
        if (response.code == 200) {
            if (response.data) {
                console.log(response.data.data);
                if (response.data.data) {

                    setListProducts(response.data.data);
                }
            }
        }


    }
    const getListAccount = async () => {
        const response = await ListAccountingAccountRequest({
            limit: meta.per_page * 100,
            page: meta.current_page, column: "code", sort: "ASC"
        });
        if (response.code == 200) {
            if (response.data) {
                console.log(response.data.data);
                if (response.data.data) {

                    setListAccount(response.data.data);
                }
            }
        }


    }

    // Hàm tìm kiếm dữ liệu
    const filterTreeNode = (inputValue, treeNode) => {
        const { title, description, code } = treeNode;
        // console.log(treeNode.props.data);

        return (
            title.toLowerCase().includes(inputValue.toLowerCase()) || // Tìm kiếm theo title
            description.toLowerCase().includes(inputValue.toLowerCase()) || // Tìm kiếm theo description
            code.toString().includes(inputValue) // Tìm kiếm theo code
        );
    };



    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const handleMouseDown = (e) => {


        if (e.target.tagName != "TH") return;
        setIsDragging(true);
        setStartX(e.pageX - tableRef.current.offsetLeft);
        setScrollLeft(tableRef.current.scrollLeft);

    };

    const handleMouseMove = (e) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - tableRef.current.offsetLeft;
        const walk = (x - startX) * 2; // Tốc độ cuộn (tăng hoặc giảm giá trị này tùy thích)
        tableRef.current.scrollLeft = scrollLeft - walk;
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleMouseLeave = () => {
        setIsDragging(false);
    };
    const validateInputPrice = (e) => {
        const allowedKeys = [
            "Backspace", "Tab", "ArrowLeft", "ArrowRight", "Delete", "Home", "End", "Ctr", "c", "v",
        ];
        const allowedCharacters = "0123456789,.";

        // Cho phép các phím điều hướng
        if (allowedKeys.includes(e.key)) return;

        // Ngăn các ký tự không hợp lệ
        if (!allowedCharacters.includes(e.key)) {
            e.preventDefault();
        }
    };
    const handleMoneyChange = (e, name, index) => {
        let inputValue = "";
        if (e.target.value) {
            inputValue = e.target.value // Loại bỏ ký tự không phải số
        } else {
            inputValue = e.toString() // Loại bỏ ký tự không phải số
        }
        const formattedValue = handleNumberInput(inputValue);

        formRef.current.setFieldsValue({
            [`${name}-${index}`]: formattedValue,
        });

    };
    const handleProcessMoneyChange = (e, name, index) => {

        if (formRef.current) {
            // switch (name) {
            //     case "":

            //         break;

            //     default:
            //         break;
            // }
            const values = formRef.current.getFieldsValue();
            const price = values["d-price-" + index] ?? 0;
            const amount = values["d-amount-" + index] ?? 0;
            const expense = values["d-expense-" + index] ?? 0;
            const percent_discount = values["d-percent_discount-" + index] ?? 0;

            const total_price = calculateTotal(amount, price);
            const total_discount = calculateDiscount(amount, price, percent_discount);

            const percent_vat = values["d-percent_vat-" + index] ?? 0;

            const total = calculateFinalAmount(amount, price, percent_discount, expense);

            const vat_price = calculateVat(amount, price, percent_discount, percent_vat);

            formRef.current.setFieldsValue({ ["d-total_price-" + index]: total_price, ["d-total-" + index]: (total), ["d-total_discount-" + index]: (total_discount.discount), ["d-vat_price-" + index]: (vat_price) })

        }
    };
    useEffect(() => {
        //  console.log("listDefaultAccountFil",listDefaultAccountFil);

        processListAccount();
        return () => {

        };
    }, [listDefaultAccountFil,listAccount]);

    const processListAccount = async () => {
        if (listDefaultAccountFil) {
            const listTKno_ = await filAcounts(listDefaultAccountFil, "tkno", listAccount);
            const listTKco_ = await filAcounts(listDefaultAccountFil, "tkco", listAccount);
            const listTKvat_ = await filAcounts(listDefaultAccountFil, "tk-thue-gtgt", listAccount);
            console.log("listTKno_listTKno_listTKno_ ", listTKno_, listTKco_, listTKvat_);

            setListTKno(listTKno_);
            setListTKco(listTKco_);
            setListTKvat(listTKvat_);



        }
    }
    useEffect(() => {
        if (formRef.current) {
            const formvalues = formRef.current.getFieldsValue();
            dataDetail1.map((row, index) => {
                formRef.current.setFieldsValue({ [`d-tkno-${index}`]: "", [`d-tkco-${index}`]: "", [`d-account_vat-${index}`]: "", })
                if (!formvalues[`d-tkno-${index}`]) {
                    if (listTKno.default) {
                        formRef.current.setFieldsValue({ [`d-tkno-${index}`]: listTKno.default })


                    }
                }
                if (!formvalues[`d-tkco-${index}`]) {
                    if (listTKco.default) {
                        formRef.current.setFieldsValue({ [`d-tkco-${index}`]: listTKco.default })

                    }
                }
                if (!formvalues[`d-account_vat-${index}`]) {
                    if (listTKvat.default) {
                        formRef.current.setFieldsValue({ [`d-account_vat-${index}`]: listTKvat.default })

                    }
                }
            });
        }
        return () => {

        };
    }, [listTKco, listTKno, listTKvat]);
    const changeSuplier = (e, index) => {

        const suplier = listSuplier.filter((value, index) => {
            return value.code == e;
        })
        if (suplier[0]) {
            if (formRef.current) {
                formRef.current.setFieldsValue({ [`d-suplier_name-${index}`]: suplier[0].name })

            }
        }


    }
    return (
        <div>
            <div className={`row`}>
                <div className='col-lg-12 col-md-12 col-sm-12 mt-2'>
                    <div className=' bg-white p-2'>
                        <Radio.Group
                            options={[{ value: 1, label: "Hàng tiền" }, { value: 2, label: "Thuế" }]}
                            onChange={(e) => {
                                const value = e.target.value;
                                scrollToElement(value)
                            }}
                            value={tab}
                            optionType="button"
                            buttonStyle="solid"
                            checked
                        />
                        <Radio.Group
                            options={[{ value: 1, label: "Sản phẩm kho" }, { value: 2, label: "Sản phẩm bán" }]}
                            onChange={(e) => {
                                const value = e.target.value;
                                setIsProduct(value)
                                if (value == 1) {
                                    getListProductsWareHouse();

                                }
                                if (value == 2) {
                                    getListProducts();

                                }
                            }}
                            value={isProduct}
                            optionType="checkbox"
                            buttonStyle="solid"
                            checked
                            className='ms-2'
                        />
                        <div className="table-responsive" style={{ height: "300px" }} ref={tableRef}
                            onMouseDown={handleMouseDown}
                            onMouseMove={handleMouseMove}
                            onMouseUp={handleMouseUp}
                            onMouseLeave={handleMouseLeave}
                        >
                            <table className="table table-ledger table-sm table-hover" style={{ width: "max-content", minWidth: "100%" }} >
                                <thead style={{ position: "sticky", top: 0, zIndex: 1000, cursor: "all-scroll" }} >

                                    <tr className='noscroll'>
                                        <th className='p-1 text-center align-middle' ></th>
                                        <th className='p-1 text-center align-middle' id='tab1'  > Mã hàng</th>
                                        <th className='p-1 text-center align-middle'  >Diễn giải</th>
                                        <th className='p-1 text-center align-middle' width="100px" >Tk nợ</th>
                                        <th className='p-1 text-center align-middle' width="100px">Tk có</th>

                                        <th className='p-1 text-center align-middle'  >Đơn vị tính</th>
                                        <th className='p-1 text-center align-middle' >Số lượng</th>
                                        <th className='p-1 text-center align-middle' >Đơn giá</th>
                                        <th className='p-1 text-center align-middle' >Thành tiền</th>
                                        <th className='p-1 text-center align-middle' >Tổng giá trị HH</th>


                                        {/* vat */}
                                        <th className='p-1 text-center align-middle bg_main_2'  >Diễn giải thuế</th>
                                        <th className='p-1 text-center align-middle bg_main_2' >Thuế GTGT %</th>
                                        <th className='p-1 text-center align-middle bg_main_2' >Tiền thuế GTGT</th>
                                        <th className='p-1 text-center align-middle bg_main_2' id='tab2'>TK Thuế GTGT</th>


                                    </tr>

                                </thead>
                                <tbody>

                                    {dataDetail1 && dataDetail1.map((row, index) => {
                                        const isLastRow = index === dataDetail1.length - 1;
                                        return <tr key={index}>
                                            <td>
                                                <Button size='small' onClick={() => deleteRow(index)}><i class="fas fa-trash-alt text-danger"></i></Button>

                                            </td>
                                            <td>
                                                <Form.Item name={"d-product_warehouse_id-" + index}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Mã hàng là bắt buộc"
                                                        },
                                                    ]}
                                                    style={{ width: "100px" }}
                                                    className='m-0'
                                                >
                                                    <Select
                                                        showSearch
                                                        style={{ width: '100%' }}
                                                        options={(listProducts || []).map((value, i) => {
                                                            return {
                                                                value: value.id,
                                                                label: value.code + "-" + value.title
                                                            }
                                                        })}

                                                        dropdownStyle={{ maxHeight: 400, minWidth: "400px", overflow: 'auto' }}
                                                        placeholder="Mã hàng"
                                                        allowClear
                                                        // multiple
                                                        treeDefaultExpandAll
                                                        onKeyDown={(e) => { handleKeyDown(e, false) }}
                                                        onChange={(e) => {
                                                            const product = listProducts.filter((item, i) => {
                                                                return item.id == e;

                                                            })
                                                            if (product[0]) {
                                                                if (formRef.current) {
                                                                    formRef.current.setFieldsValue({ ["d-product_name-" + index]: product[0].title, ["d-note_vat-" + index]: "Thuế GTGT - " + product[0].title });
                                                                }
                                                            }


                                                        }}
                                                        filterOption={(input, option) =>
                                                            option?.label?.toLowerCase().includes(input.toLowerCase())
                                                          }
                                                        ref={isLastRow ? newRowRef : null}
                                                    />

                                                </Form.Item>
                                            </td>
                                            <td>
                                                <Form.Item name={"d-product_name-" + index} style={{ width: "100%" }} className='m-0'>

                                                    <Input onKeyDown={(e) => { handleKeyDown(e, false) }} />
                                                </Form.Item>
                                            </td>
                                            <td>
                                                <Form.Item name={"d-tkno-" + index}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "TK có là bắt buộc"
                                                        },
                                                    ]}
                                                    className='m-0' style={{ width: "100px" }}
                                                // initialValue={listTKno.default}
                                                >
                                                    <TreeSelect
                                                        showSearch
                                                        style={{ width: '100%' }}
                                                        treeData={listTKno?.listAccount}
                                                        dropdownStyle={{ maxHeight: 400, minWidth: "250px", overflow: 'auto' }}
                                                        placeholder="Tk nợ"
                                                        filterTreeNode={filterTreeNode}
                                                        treeDefaultExpandAll
                                                        onKeyDown={(e) => { handleKeyDown(e, false) }}
                                                        allowClear

                                                    />

                                                </Form.Item>
                                            </td>
                                            <td>
                                                <Form.Item name={"d-tkco-" + index}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "TK có là bắt buộc"
                                                        },
                                                    ]}
                                                    className='m-0' style={{ width: "100px" }}
                                                // initialValue={listTKco.default}
                                                >
                                                    <TreeSelect
                                                        showSearch
                                                        style={{ width: '100%' }}
                                                        treeData={listTKco?.listAccount}

                                                        dropdownStyle={{ maxHeight: 400, minWidth: "250px", overflow: 'auto' }}
                                                        placeholder="Tk có"
                                                        filterTreeNode={filterTreeNode}
                                                        treeDefaultExpandAll

                                                        onKeyDown={(e) => { handleKeyDown(e, false) }}
                                                        allowClear
                                                    />

                                                </Form.Item>
                                            </td>

                                            <td>
                                                <Form.Item name={"d-unit_id-" + index}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Đơn vị tính là bắt buộc"
                                                        },
                                                    ]}
                                                    style={{ width: "100%" }} className='m-0'
                                                >
                                                    <Select
                                                        showSearch
                                                        style={{ width: '100%' }}
                                                        options={(listUnits || []).map((value, index) => {
                                                            return {
                                                                value: value.id,
                                                                label: value.name
                                                            }
                                                        })}
                                                        allowClear
                                                        dropdownStyle={{ maxHeight: 400, minWidth: "250px", overflow: 'auto' }}
                                                        placeholder="Đơn vị tính"
                                                        dropdownRender={(menu) => (
                                                            <>
                                                                {menu}
                                                                <Divider
                                                                    style={{
                                                                        margin: '8px 0',
                                                                    }}
                                                                />
                                                                <div className='d-flex align-items-center'>
                                                                    <Form.Item name={`unitadd`} noStyle >
                                                                        <Input placeholder="nhập tên đơn vị" />
                                                                    </Form.Item>
                                                                    <Typography.Link
                                                                        onClick={(event) => storeUnit(event, "unitadd")}
                                                                        style={{
                                                                            whiteSpace: 'nowrap', marginLeft: "5px"
                                                                        }}
                                                                    >
                                                                        <PlusOutlined /> Thêm
                                                                    </Typography.Link>
                                                                </div>

                                                            </>
                                                        )}
                                                        treeDefaultExpandAll

                                                        onKeyDown={(e) => { handleKeyDown(e, false) }}
                                                    />

                                                </Form.Item>
                                            </td>
                                            <td>
                                                <Form.Item name={"d-amount-" + index} style={{ width: "100%" }} className='m-0'>

                                                    <Input min={0} onKeyDown={(e) => { handleKeyDown(e, false) }}
                                                        onChange={(e) => {
                                                            handleMoneyChange(e, "d-amount", index);
                                                            handleProcessMoneyChange(e, "d-amount", index); setTotal_();
                                                        }}
                                                        placeholder='Số lượng' />
                                                </Form.Item>
                                            </td>
                                            <td>
                                                <Form.Item name={"d-price-" + index} style={{ width: "100%" }} className='m-0'>

                                                    <Input onKeyDown={(e) => { handleKeyDown(e, false); }} placeholder='Đơn giá'
                                                        onChange={(e) => {
                                                            handleMoneyChange(e, "d-price", index);
                                                            handleProcessMoneyChange(e, "d-price", index); setTotal_();
                                                        }}
                                                    />
                                                </Form.Item>
                                            </td>
                                            <td>
                                                <Form.Item name={"d-total_price-" + index} style={{ width: "100%" }} className='m-0'>

                                                    <Input onKeyDown={(e) => { handleKeyDown(e, false); validateInputPrice(e) }} placeholder='Thành tiền'
                                                        onChange={(e) => { handleMoneyChange(e, "d-total_price", index); setTotal_(); }}
                                                        readOnly
                                                    />
                                                </Form.Item>
                                            </td>
                                            <td>
                                                <Form.Item name={"d-total-" + index} style={{ width: "100%" }} className='m-0'>

                                                    <Input onKeyDown={(e) => { handleKeyDown(e, false); validateInputPrice(e) }} placeholder='Tổng gía trị'
                                                        onChange={(e) => { handleMoneyChange(e, "d-total", index); setTotal_(); }}
                                                        readOnly
                                                    />
                                                </Form.Item>
                                            </td>
                                            {/* vat */}
                                            <td className='bg_main_2'>
                                                <Form.Item name={"d-note_vat-" + index} style={{ width: "100%" }} className='m-0'>

                                                    <Input onKeyDown={(e) => { handleKeyDown(e, false) }} placeholder='Diễn giải thuế' />
                                                </Form.Item>
                                            </td>
                                            <td className='bg_main_2'>
                                                <Form.Item name={"d-percent_vat-" + index}
                                                    // rules={[
                                                    //     {
                                                    //         required: true,
                                                    //         message: "Thuế GTGT là bắt buộc"
                                                    //     },
                                                    // ]}
                                                    style={{ width: "100%" }}
                                                    className='m-0'

                                                >
                                                    <Select
                                                        showSearch
                                                        style={{ width: '100%' }}
                                                        options={DATA_PERCENT_VAT}

                                                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                        placeholder="Thuế GTGT"
                                                        allowClear
                                                        // multiple
                                                        treeDefaultExpandAll
                                                        onKeyDown={(e) => { handleKeyDown(e, false) }}
                                                        onChange={(e) => { handleProcessMoneyChange(e, "d-percent_vat", index); setTotal_(); }}

                                                    />

                                                </Form.Item>
                                            </td>
                                            <td className='bg_main_2'>
                                                <Form.Item name={"d-vat_price-" + index} style={{ width: "100%" }} className='m-0' initialValue={0}>

                                                    <Input onKeyDown={(e) => { handleKeyDown(e, false); validateInputPrice(e) }} placeholder='tiền thuế GTGT'
                                                        onChange={(e) => { handleMoneyChange(e, "d-vat_price", index); setTotal_(); }}
                                                    // readOnly
                                                    />
                                                </Form.Item>
                                            </td>

                                            <td className='bg_main_2'>
                                                <Form.Item name={"d-account_vat-" + index}
                                                    // rules={[
                                                    //     {
                                                    //         required: true,
                                                    //         message: "TK GTGT là bắt buộc"
                                                    //     },
                                                    // ]}
                                                    style={{ width: "100px" }}
                                                    className='m-0'
                                                // initialValue={1331}
                                                >
                                                    <TreeSelect
                                                        showSearch
                                                        style={{ width: '100%' }}
                                                        treeData={listTKvat?.listAccount}


                                                        dropdownStyle={{ maxHeight: 400, minWidth: "250px", overflow: 'auto' }}
                                                        placeholder="TK thuế GTGT"
                                                        filterTreeNode={filterTreeNode}
                                                        treeDefaultExpandAll
                                                        onKeyDown={(e) => { handleKeyDown(e, true); validateInputPrice(e) }}

                                                        allowClear

                                                    />

                                                </Form.Item>
                                            </td>
                                        </tr>
                                    })}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Index
