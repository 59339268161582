import React, { useEffect, useRef, useState } from 'react'
import { Button, DatePicker, Drawer, Empty, Form, Input, InputNumber, message, Popover, Select, Skeleton, Spin, Table, Tooltip, TreeSelect } from 'antd';
import "./../style.css"
import { SearchAccountantPurchaseRequest } from '../../../../actions/indexWeb';
import { META } from '../../../../utils/constant';
import {
  SearchOutlined
} from '@ant-design/icons';

import { Link, useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { DATA_PERCENT_VAT, DATA_STOCK_EXPORT, DATA_STOCK_IMPORT, DATA_TYPE_PURCHASE_RECEIPT, DATA_TYPE_SELL } from '../../../../constants/DefaultData';

const { RangePicker } = DatePicker;

const Index = ({ setpurchase, record }) => {

  const [data, setData] = useState(null);

  const [meta, setMeta] = useState({ ...META });
  const [isLoadingSearch, SetisLoadingSeach] = useState(false);

  const formSearchRef = useRef(null);
  const history = useHistory();

  const columns = [
    {
      title: 'Ngày hoạch toán',
      dataIndex: 'accounting_date',
      key: 'accounting_date',
    },
    {
      title: 'Ngày chứng từ',
      dataIndex: 'document_date',
      key: 'document_date',
    },
    {
      title: 'Mã',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Diễn giải',
      dataIndex: 'product_name',
      key: 'product_name',
    },
    {
      title: 'Tk Nợ',
      dataIndex: 'tkno',
      key: 'tkno',
    },
    {
      title: 'Tk Có',
      dataIndex: 'tkco',
      key: 'tkco',
    },
    {
      title: 'Số tiền',
      dataIndex: 'total_price',
      key: 'total_price',
    },
    {
      title: 'Chọn',
      dataIndex: '',
      align: "center",
      width: "25%",
      render: (text, record, index) => (
        <div style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }} >
          <span className="__action_">
            {/* <button className="reset_btn btn_edit btn__" onClick={() => editRow(record)}> <EditOutlined style={{ color: Colors.colorView }} /> </button> */}
            <Button className=" " onClick={() => { setpurchase(record) }}>Chọn phiếu</Button>
            {/* <button className="reset_btn btn_view btn__" onClick={() => { editRow(record) }}> <FolderViewOutlined   style={{ color: Colors.colorView }} /></button> */}

          </span>
        </div>
      ),
      key: 'group',
    },
  ];

  const getAccountantPurchase = async () => {
    const response = await SearchAccountantPurchaseRequest({
      limit: meta.per_page * 100,
      page: meta.current_page, column: "accountant_purchase.document_date", sort: "ASC"
    }, { data: { product_warehouse_id: record.id } });
    console.log("responseresponse ", response);

    if (response.code == 200) {
      if (response.data) {
        if (response.data.data) {
          setData([...response.data.data]);

        }
      }
    }


  }

  useEffect(() => {

    getAccountantPurchase();

    return () => {

    };
  }, []);

  const onSearch = async (values) => {
    const filteredvalues = Object.fromEntries(
      Object.entries(values).filter(([_, value]) => { return value !== undefined && value !== "" && value !== null })
    );




    SetisLoadingSeach(true);
    const response = await SearchAccountantPurchaseRequest({
      limit: meta.per_page * 100,
      page: meta.current_page, column: "document_date", sort: "ASC"
    }, { data: { ...filteredvalues, product_warehouse_id: record.id } });

    SetisLoadingSeach(false);

    if (response.code == 200) {

      console.log("responseresponseresponse ", response);

      if (response.data) {
        if (response.data.data) {
          setData([...response.data.data]);

        }
      }

    }

    const searchParams = new URLSearchParams(filteredvalues).toString();

    // history.push(`${LINK.WEB_ACCOUNTANT_INCREASE_TOOL}/${typeV}?${searchParams}`);


  }

  const resetFormSearch = () => {
    if (formSearchRef.current) {
      formSearchRef.current.resetFields();
      formSearchRef.current.submit();
    }
  }


  return (
    <div className='row w-100 bg-white p-0'
    // onClick={()=>setIsEdit(-1)}

    >
      <div className='col-lg-12 col-md-12 col-sm-12'>
        <Form name="formsearch" ref={formSearchRef} onFinish={onSearch} style={{
          padding: "0px",
          width: "100%",
          display: "flex"
        }}>
          <Form.Item name="code" noStyle >
            <Input placeholder='Mã' />
          </Form.Item>

          <Form.Item name="created_at" noStyle >
            <RangePicker style={{ width: "100%", }} />
          </Form.Item>
          <Form.Item name="type" noStyle >
            <Select
              showSearch
              style={{ width: '100%' }}
              options={[...DATA_TYPE_PURCHASE_RECEIPT,...DATA_TYPE_SELL,...DATA_STOCK_IMPORT,...DATA_STOCK_EXPORT]}

              dropdownStyle={{ maxHeight: 400, overflow: 'auto',minWidth:"250px" }}
              placeholder="Loại phiếu"
             

            />
          </Form.Item>
          <Form.Item noStyle style={{
            width: "20%"
          }}>
            <Button type="primary" htmlType="submit" style={{ width: "20%" }} icon={<SearchOutlined />} loading={isLoadingSearch} />
            <Button className='bg-warning' htmlType="button" style={{ width: "20%" }} loading={isLoadingSearch} onClick={resetFormSearch}>reset</Button>
          </Form.Item>

        </Form>
      </div>
      <div className='col-lg-12 col-md-12 col-sm-12'>
        <Table dataSource={data} columns={columns} />
      </div>



    </div>
  )
}

export default Index

